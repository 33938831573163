<template>
  <div class="cq-layout-main">
    <CQHeader />
    <CQMypageSidebar />
    <div class="cq-content">
      <slot />
    </div>
  </div>
</template>

<script>
import CQHeader from "@/components/CQHeader.vue";
import CQMypageSidebar from "@/components/CQMypageSidebar.vue";
import baseLayout from '@/utils/mixinsLayout'

export default {
  components: {
    CQHeader,
    CQMypageSidebar,
  },
  mixins: [ baseLayout ],
};
</script>

<style lang="scss" scoped>
.cq-layout-main {
  padding-top: 70px;
}
.cq-content {
  margin-left: 270px;
  min-height: calc(100vh - 70px);
  background-color: $cq-gray;
  @media (max-width: 1180px) {
    margin-left: 0rem;
  }
}
</style>
