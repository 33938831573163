<template>
  <div class="cq-option-group row mr-4" :class="classList">
    <div class="row cq-option-group-item" v-for="option in options" :key="option.value">
      <div class="col">
        <input
          type="radio"
          :name="`cq_option_radio_${componentId}`"
          :id="`cq_option_radio_${componentId}_${option.value}`"
          :value="option.value"
          v-model="currentValue"
          class="d-none cq-option-group-input"
        />
        <label :for="`cq_option_radio_${componentId}_${option.value}`" class="cq-option-group-label">
          <span class="cq-option-group-label-text">
            {{option.text}}
          </span>
        </label>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  emits: ["change"],
  props: {
    value: {
      type: [ String, Number ],
      default() {
        return 1;
      },
    },
    options: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    componentId () {
      return Math.round(Math.random() * new Date().getTime())
    }
  },
  data () {
    return {
      currentValue: this.value,
      classList: []
    }
  },
  watch: {
    currentValue (value) {
      this.$emit("input", value);
      this.$emit("change", value);
    },
    value (value) {
      this.currentValue = value
    },
  },
};
</script>
<style lang="scss" scoped>
.cq-option-group {
  width: 100%;
  column-gap: 3.5rem;
  margin-left: 0rem;
  &-label {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    text-align: center;
    &::before {
      content: "";
      width: 30px;
      height: 30px;
      @include screen(768) {
        width: 40px;
        height: 40px;
      }
      border-radius: 50%;
      border: 1px solid #5DAF5D ;
      margin-right: 1.0rem;
      transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    }
    &-text {
      width: max-content;
    }
  }
  &-input{
    &:checked {
      & + .cq-option-group-label {
        &::before {
          background: 50% / 50% 50% no-repeat;
          background-color: #C3E3C1;
          background-image: url('../assets/img/check.svg');
        }
      }
    }
  }
}
</style>