<template>
  <div class="cq-threadChat d-flex flex-column h-100 col px-0">
    <div class="cq-chat-listMess col" ref="listMessage" :class="{isUpload : listFileShow.length > 0, isTyping : adminTypingData.adminTyping}">
      <div v-for="(value, id) in listMess" :key="id">
        <div v-if="listDateShow.index.find(item => item === id) !== undefined" class="d-flex justify-content-center">
          <div class="cq-chat-itemMess-date" v-bind:class="{'mt-0' : id === 0}">{{$moment(value.create_at).tz('Asia/Tokyo').format('LL')}}</div>
        </div>
        <div class="cq-chat-itemMess d-flex" :class="value.user_id === userId ? 'mess-send' : 'mess-receive'">
          <div class="cq-chat-itemMess-box d-flex">
            <div class="cq-chat-itemMess-ava">
              <img v-if="value.from === 'user'" class="img-contain" src="@/assets/img/avatar-default.png" alt="">
              <img v-else class="img-contain" src="@/assets/img/admin-ava.png" alt="">
            </div>
            <div class="cq-chat-itemMess-message">
              <div v-if="!value.isFile" class="cq-chat-itemMess-text" v-html="renderMessage(value.message)"></div>
              <div v-else-if="value.type === 'image' || value.type === 'video'" class="cq-chat-itemMess-media cursor-pointer position-relative" @click="previewMedia(value)">
                  <div v-if="value.type === 'video'" class="cq-chat-itemMess-play position-absolute text-white">
                    <font-awesome-icon icon="fa-solid fa-play" />
                  </div>
                  <img v-if="value.type === 'image'" class="img-contain" :src="value.url" :alt="value.file_name" >
                  <video v-else class="img-contain" :src="value.url" />
                </div>
              <div v-else class="cq-chat-itemMess-file">
                <div class="d-flex align-items-center cq-chat-itemFile_box mx-2">
                  <div class="cq-chat-itemFile_icon d-flex justify-content-center align-items-center mr-1">
                    <font-awesome-icon icon="fa-solid fa-file-lines" />
                  </div>
                  <div class="cq-chat-itemFile_text">
                    {{ value.file_name }}
                  </div>
                </div>
              </div>
              <div v-if="id === listMess.length - 1 && value.from === 'user' && recentMessage.is_admin_read" class="d-flex justify-content-end align-items-center">
                <div class="cq-chat-itemMess-time mr-2">
                  既読
                </div>
                <div class="cq-chat-itemMess-time">
                  <font-awesome-icon icon="fa-regular fa-circle-check" />
                </div>
              </div>
            </div>
            <div class="cq-chat-itemMess-action d-flex justify-content-end flex-column" :class="{'mb-3': id === listMess.length - 1 && value.from === 'user' && recentMessage.is_admin_read}">
              <b-spinner class="mx-1" v-if="isDownloading.find(item => item === id) !== undefined" small />
              <div v-if="value.isFile && isDownloading.find(item => item === id) === undefined" class="cq-chat-itemMess-download cursor-pointer" @click="downloadFile(value.url, value.file_name, id)">
                <b-icon icon="download" class="mx-1 icon"></b-icon>
              </div>
              <div class="cq-chat-itemMess-time">{{ value.create_at | moment('timezone', 'Asia/Tokyo', 'HH:mm') }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="isPreview" class="cq-chat-itemMess-preview">
      <div class="cq-backdrop" @click="isPreview = false" />
      <div class="cq-close text-white cursor-pointer" @click="isPreview = false" >
        <font-awesome-icon icon="fa-solid fa-xmark" />
      </div>
      <div class="cq-chat-itemMess-previewContent">
        <img v-if="dataPreview.type === 'image'" class="img-contain" :src="dataPreview.url" :alt="dataPreview.file_name" >
        <video v-else class="img-contain cq-bg-black" controls autoplay >
          <source :src="dataPreview.url">
        </video>
      </div>
    </div>
    <div class="col-auto px-0">
      <form
        v-on:submit.stop.prevent="sendMess"
      >
        <div class="cq-chat-formSend w-100">
          <div v-if="listFileShow.length" class="d-flex mb-2 pb-2 cq-chat-listFile">
            <div v-for="(item, index) in listFileShow" class="cq-chat-itemFile position-relative" :key="index">
              <div class="position-absolute cq-chat-itemFile_del" @click="delFile(index)">
                <font-awesome-icon icon="fa-solid fa-xmark" />
              </div>
              <div v-if="item.type === 'image'" class="cq-chat-itemFile_box">
                <img class="img-cover" :src="item.url" alt="">
              </div>
              <div v-else-if="item.type === 'video'" class="cq-chat-itemFile_box position-relative">
                <video class="img-cover" :src="item.url"></video>
                <div class="position-absolute cq-chat-itemFile_playIcon">
                  <font-awesome-icon icon="fa-solid fa-play" />
                </div>
              </div>
              <div v-else class="d-flex align-items-center cq-chat-itemFile_box mx-2">
                <div class="cq-chat-itemFile_icon d-flex justify-content-center align-items-center mr-1">
                  <font-awesome-icon icon="fa-solid fa-file-lines" />
                </div>
                <div class="cq-chat-itemFile_text">
                  {{ item.name }}
                </div>
              </div>
            </div>
          </div>
          <div class="row justify-content-between m-0">
            <div class="cq-chat-typing font-weight-bold" v-if="adminTypingData.adminTyping">担当者入力中... </div>
            <div class="col p-0 pr-2">
              <b-textarea
                class="cq-chat-formText cq-border-green overflow-hidden"
                id="cq-chat-formText"
                @keyup="resizeChatBoxInput"
                v-model.trim="message"
                @focus="isFocus = true"
                @blur="isFocus = false"
                placeholder="Enter..."
                autocomplete="off"
              ></b-textarea>
            </div>
            <div class="col-auto p-0 pr-2">
              <b-button class="cq-chat-btnSend cq-btn-bg-green d-flex align-items-center" id="cq-chat-btnSend" type="submit"><span class="mr-2 d-md-block d-none">送信</span><font-awesome-icon icon="fa-solid fa-paper-plane" /></b-button>
            </div>
            <div class="col-auto p-0">
              <label
                for="cq-chat-inputFileUpload"
                class="cq-chat-btnFileUpload mb-0 btn d-flex justify-content-center align-items-center text-white cq-btn-bg-brown"
                id="cq-chat-btnFileUpload"
              >
                <font-awesome-icon icon="fa-solid fa-paperclip" />
              </label>
              <b-form-file
                id="cq-chat-inputFileUpload"
                @change="uploadFile"
                :multiple="true"
                class="d-none"
              ></b-form-file>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import 'moment/locale/ja'

export default {
  name: 'threadChat',
  props: {
    listMess: {
      type: Array,
      default() {
        return []
      }
    },
    userId: {
      type: String,
      default() {
        return ''
      }
    },
    adminTypingData: {
      type: Object,
      default() {
        return {}
      }
    },
    recentMessage: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data() {
    return {
      message: '',
      listFilesUpload: [],
      listFileShow: [],
      isRender: false,
      isPreview: false,
      listDateShow: {
        date: [],
        index: []
      },
      dataPreview: null,
      isDownloading: [],
      isFocus: false,
      idTimeOut: null,
      idTimeOutAdmin: null,
    }
  },
  watch: {
    listMess() {
      this.getDate()
    },
    message() {
      if(this.idTimeOut) {
        clearTimeout(this.idTimeOut)
        this.idTimeOut = null
      }
      if(this.isFocus && this.message){
        this.$emit('on-typing', true)
        this.idTimeOut = setTimeout(() => {
          this.$emit('on-typing', false)
        }, 5000)
      } else {
        this.$emit('on-typing', false)
      }
    },
    adminTypingData() {
      if(this.adminTypingData.adminTyping && !this.idTimeOutAdmin) {
        this.idTimeOutAdmin = setTimeout(() => {
          this.$emit('set-typing', false)
          this.idTimeOutAdmin = null 
        }, 5000)
      } else if(this.adminTypingData.adminTyping && this.idTimeOutAdmin) {
        clearTimeout(this.idTimeOutAdmin)
        this.idTimeOutAdmin = setTimeout(() => {
          this.$emit('set-typing', false)
          this.idTimeOutAdmin = null 
        }, 5000)
      } else if(this.idTimeOutAdmin) {
        clearTimeout(this.idTimeOutAdmin)
        this.idTimeOutAdmin = null
      }
    }
  },
  created() {
    this.getDate()
  },
  mounted() {
    this.$emit('is-reRender')
  },
  updated() {
    if(this.isRender) {
      this.isRender = false
      this.$emit('is-reRender')
    }
  },
  methods: {
    getDate() {
      this.listDateShow.date = []
      this.listDateShow.index = []
      this.listMess.forEach((item, index) => {
        this.showDate(index,item.create_at)
      })
    },

    uploadFile(e) {
      const listIsValid = []
      let isInValid = false
      e.target.files.forEach((item) => {
        const typeFile = item.type.split('/')[0]
        const sizeFile = item.size / (1024*1024) 
        if(sizeFile < 20) {
          if(typeFile === 'image' || typeFile === 'video') {
            const urlFile = window.URL.createObjectURL(item)
            this.listFileShow.push({
              type: typeFile,
              url: urlFile,
              name: item.name
            })
          } else {
            this.listFileShow.push({
              type: 'file',
              name: item.name
            })
          }
          listIsValid.push(item)
        } else {
          isInValid = true
        }
      });
      if(isInValid) {
        this.$toast.error('20MB以下のファイルをアップロードしてください。')
      }
      this.listFilesUpload = [...this.listFilesUpload, ...listIsValid]
      e.target.value = null
      this.isRender = true
    },

    showDate(index, date) {
      if(!this.listDateShow.date.find(item => {
        return this.$moment(date).format("YYYY-MM-DD") === item
      })) {
        this.listDateShow.index.push(index)
        this.listDateShow.date.push(this.$moment(date).format("YYYY-MM-DD"))
      } 
    },

    delFile(index) {
      this.listFilesUpload.splice(index, 1)
      this.listFileShow.splice(index, 1)
    },

    sendMess() {
      const data = {
        message: this.message,
        listFilesUpload: this.listFilesUpload
      }
      this.$emit('send-mess', data)
      this.message = ''
      this.listFilesUpload = []
      this.listFileShow = []
    },

    async downloadFile(url, name, index) {
      this.isDownloading.push(index)
      const data = await this.$store.dispatch('downloadFile', url)
      const blob = new Blob([data], {type: 'image/png'});
      const urlDownload = window.URL.createObjectURL(blob);
      const linkDownload = document.createElement('a');
      linkDownload.href = urlDownload;
      linkDownload.download = name;
      linkDownload.click();
      this.isDownloading = this.isDownloading.filter(item => item !== index)
    },

    previewMedia(data) {
      this.isPreview = true
      this.dataPreview = data
    },
    renderMessage (message) {
      // Replace all special character html to ecapse string for block XSS attack
      const mess =  message
       .replace(/&/g, "&amp;")
       .replace(/</g, "&lt;")
       .replace(/>/g, "&gt;")
       .replace(/"/g, "&quot;")
       .replace(/'/g, "&#039;")
       .replaceAll("\n", "<br/>");
      return mess
    },
    resizeChatBoxInput($event) {
      $event.currentTarget.style.height = 0;
      $event.currentTarget.style.height = `${$event.currentTarget.scrollHeight + 26}px`
    },
  }
}
</script>