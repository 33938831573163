<template>
  <div>
    <slot name="title"></slot>
    <form @submit.prevent="searchItem"> 
      <div class="d-flex w-100 justify-content-end align-items-center">
        <b-input v-model="searchSelection" type="text" class="w-25 pl-3 mr-2 col-md-auto col" id="input_select_item_small_search" :placeholder="textSearch" autocomplete="off"></b-input>
        <button class="btn btn-success cq-white mr-3" id="btn_mypage_select_item_small_search">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        </button>
        <b-form-checkbox @change="vModelSetAll" v-model="setAll" class="mt-3 text-right mr-3 mb-3 col-auto" id="input_select_item_small_checkbox_search">全て</b-form-checkbox>
      </div>
    </form>
    <div class="cq-switch-small row justify-content-center align-content-start">
      <div class="row w-100 cq-switch-small-content">
        <div class="col-6 col-lg-3 mt-3" v-for="(venue, index) in dataListSelection" :key="index">
          <input type="checkbox" class="d-none controller" :id="`test_${index}`" v-model="venue.picked" @input="handlerVmodel($event, venue.id)"  />
          <label class="cq-switch" :for="`test_${index}`">
            <div class="text-center px-3 text-content">{{venue.core_venue.name}}</div>
            <div class="toggle"></div>
          </label>
        </div>
        <div v-if="!dataListSelection.length" class="message-no-item w-100 text-center">{{NO_ITEM}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import SelectItem from './SelectItem.vue'
export default {
  extends: SelectItem,
}
</script>
