/**
 * Intro.JS
 * https://introjs.com/docs/intro/attributes/
 */

const default_setting = {
  "data-title": null,
  "data-tooltip-class": null,
  "data-highlight-class": null,
  "data-position": null,
  "data-scroll-to": null,
  "data-disable-interaction": true,
}

const default_configuration = {
  nextLabel: "次へ",
  prevLabel: "前へ",
  doneLabel: "終わり",
  showButtons: false,
}

const intro = {
  plan: [
    {
      ...default_setting,
      "data-intro": "プランは一つ選んでください。",
      "data-step": 1,
    }
  ],
  aa_account: [
    {
      ...default_setting,
      "data-intro": "こちらから会員情報を登録してください",
      "data-step": 1,
    },
    {
      ...default_setting,
      "data-intro": "現在お使いのオークションアカウントを登録してください",
      "data-step": 2,
    }
  ],
  stock_list: [
    {
      ...default_setting,
      "data-intro": "在庫情報を登録すると分析支援で価格の変動を確認できます。",
      "data-step": 1,
    },
    {
      ...default_setting,
      "data-intro": "分析支援機能を使用できるように「グラフ」アイコンを押下する",
      "data-step": 2,
    }
  ]
}
export default intro
export {
  default_configuration,
}