<template>
  <div class="cq-header d-flex justify-content-between align-items-center">
    <div class="d-flex justify-content-between" style="width: 240px;">
      <router-link to="/" id="link_header_logo">
        <div class="cq-header-logo">
          <img class="img-contain" src="../assets/img/cq-logo.png" alt="">
        </div>
      </router-link>
      <div class="mx-4 my-3 h4">
        <font-awesome-icon icon="fa-solid fa-bars" class="btn-toggle d-block d-xl-none" @click="toogleSidebar" />
      </div>
    </div>
    <div class="cq-header-btn">
      <b-button class="cq-btn-bg-green cq-btn-recomment" @click="toggleModal" id="btn_header_open_modal_recommend">
        <div class="d-flex align-items-center position-relative">
          <div class="mr-2 d-none d-md-block">リコメンド</div>
          <div class="d-md-none d-flex justify-content-center align-items-center cq-header-recomment">
            <font-awesome-icon icon="fa-solid fa-thumbs-up" />
          </div>
          <div class="cq-header-bagge d-flex justify-content-center align-items-center" v-if="recommendCount">{{recommendCount > 99 ? `99+` : recommendCount}}</div>
        </div>
      </b-button>
      <b-button class="cq-btn-bg-none cq-border-green cq-green ml-3 d-none d-md-none d-xl-block" id="btn_header_open_menu" @click="toogleMenu">
        <div class="d-flex align-items-center">
          <div class="bar">
            <div class="line cq-bg-green mb-1" />
            <div class="line cq-bg-green mb-1" />
            <div class="line cq-bg-green" />
          </div>
          <div class="ml-2 d-none d-md-block">メニュー</div>
        </div>
      </b-button>
    </div>
    <modalRecommendResult @resetModal="hiddenModal" @closeModal="closeModal" />
    <CQModalCheckExtension />
    <CQModalContact :modalId="'contactAdmin'" :typeStr="'通常のお問い合わせ'" />
    <template v-if="isOpenMenu">
      <CQMenu @closeMenu="toogleMenu" />
    </template>
  </div>
</template>

<script>
import modalRecommendResult from './recommend-result/modal.vue'
import CQModalCheckExtension from './CQModalCheckExtension.vue'
import CQModalContact from './CQModalContact.vue'
import CQMenu from './CQMenu.vue'
export default {
  name: "CQHeader",
  components: {
    modalRecommendResult,
    CQMenu,
    CQModalContact,
    CQModalCheckExtension
  },
  computed: {
    isHideSidebar: {
      get () {
        return this.$store.getters.isHideSidebar
      },
      set (value) {
        this.$root.$emit('closeAllModal')
        this.$store.commit('set', ['isHideSidebar', value])
      }
    },
    recommendCount () {
      const data = this.$store.getters.recommendCount
      const count = data.advantageous + data.flovehiclew + data.shared_inventory
      return count ?? 0
    }
  },
  data () {
    return {
      isShowModalRecommendResult: false,
      isOpenMenu: false
    }
  },
  methods: {
    toogleSidebar () {
      this.isHideSidebar = !this.isHideSidebar
    },
    hiddenModal() {
      if (this.isShowModalRecommendResult) this.isShowModalRecommendResult = false;
    },
    toggleModal () {
      if (this.isShowModalRecommendResult) {
        this.$bvModal.hide('modalRecommendResult')
      } else{
        this.$bvModal.show('modalRecommendResult')
      }
      this.isShowModalRecommendResult = !this.isShowModalRecommendResult
    },
    closeModal () {
      this.$bvModal.hide('modalRecommendResult')
      this.isShowModalRecommendResult = false;
    },
    countRecommend () {
      this.$store.dispatch('setRecommend')
    },
    toogleMenu () {
      this.isOpenMenu = !this.isOpenMenu
      this.isHideSidebar = true
    }
  },
  created () {
    this.countRecommend()
  }
}
</script>

<style lang="scss">
.cq-header {
  height: 70px;
  width: 100%;
  padding:0 60px;
  background: $white;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1051;
  @include screen (767) {
   padding: 0.0rem 1.3rem;
  }
  &-logo {
    width: 160px;
    height: auto;
    @include screen (767) {
      width: 140px;
    }
  }
  &-btn {
    display: flex;
    button {
      font-size: 1.125rem;
    }
  }
  &-bagge {
    width: 30px;
    height: 30px;
    font-size: 1rem;
    border-radius: 100%;
    background: $cq-red;
    @include screen (767) {
      position: absolute;
      width: 18px;
      height: 18px;
      font-size: 0.625rem;
      top: -5px;
      right: -5px;
    }
  }
  &-recomment {
    width: 30px;
    height: 30px;
    border: 2px solid;
    border-radius: 100px;
    @include screen (424) {
      width: 28px;
      height: 28px;
      padding: 5px;
      box-sizing: border-box;
    }
  }
  .cq-btn-recomment {
    @include screen (424) {
      padding: 0.5rem !important;
    }
  }
}
</style>