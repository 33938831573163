<template>
  <div class="recommendNew cq-bg-gray pt-3 position-relative">
    <CQPreLoad :preload="preLoading" />
    <div class="recommendNew-title">車両条件を編集</div>
    <div class="d-flex position-absolute alert-conditions">
      <div class="alert-conditions-icons" @click="showAlert = !showAlert">
        <font-awesome-icon v-if="showAlert"
          class="ml-2" icon="fa-solid fa-angle-right" 
        />
        <font-awesome-icon v-else
          class="ml-2" icon="fa-solid fa-angle-left" 
        />
      </div>
      <div class="alert-conditions-text text-center" v-if="showAlert">
        <p>同様の条件で</p>
        <p>過去１ヶ月で</p>
        <p class="text-warning">1,300件</p>
        <p>リコメンドしました</p>
      </div>
    </div>
    <div class="container-custom mt-3">
      <b-form class="recommendNew-form" @submit="($event) => { $event.preventDefault() }">
        <!-- basci infomations -->
        <div class="recommendNew-form-content mt-3">
          <div class="recommendNew-form-content-items w-100 position-relative">
            <label for="maker">条件名 <span class="text-danger">※</span>
              <font-awesome-icon
                v-b-tooltip.hover
                title="名前をつけることで他のリコメンド条件でも再利用が可能です" 
                class="text-secondary ml-2" icon="fa-solid fa-circle-info" 
              />
            </label>
            <b-input
              class=""
              v-model="$v.formData.name.$model"
              size="sm"
              id="condition-name"
              placeholder="条件名を入力してください。"
              autocomplete="off"
            ></b-input>
            <div v-if="$v.formData.name.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.name.required">{{ message.ERROR_REQUIRE }}</span>
            </div>
          </div>
        </div>
        <div class="recommendNew-form-title mt-3">条件ボックス</div>
        <div class="recommendNew-form-content mt-3">
          <div class="recommendNew-form-content-box w-100 row mx-auto">
            <div class="col-custom mt-3"
              v-for="(conditionBox, index) in listConditionBox" v-bind:key="index"
              v-bind:class="index === 0 ? 'col-custom-2' : 'col-custom'"  
            >
              <b-button
                class="cq-bg-orange w-100"
                :id="`btn_recommend_condition_car_info_edit_tabs_control_${index}`"
                v-on:click="showHideField(conditionBox, $event)">
                {{conditionBox}}
                <font-awesome-icon
                  class="ml-2" icon="fa-solid fa-square-plus" 
                />
              </b-button>
            </div>
          </div>
        </div>
        <div class="recommendNew-form-title mt-3" v-if="showVehicleInformation || showYear">基本情報</div>
        <div class="recommendNew-form-content mt-3">
          <div class="recommendNew-form-content-items" v-if="showVehicleInformation">
            <label class="text-left w-100" for="maker">メーカー<span class="text-danger">※</span></label>
            <ModelSelectBrands
              class=""
              v-model="$v.formData.core_brand_id.$model"
              :options="optionBrands"
              :is-convert2-full-width="true"
              size="sm"
              id="manufacturer-condition"
            ></ModelSelectBrands>
            <p
              v-if="$v.formData.core_brand_id.$error && !$v.formData.core_brand_id.required"
              class="error-name"
            >
              {{ message.ERROR_REQUIRE }}
            </p>
          </div>  
          <div class="recommendNew-form-content-items" v-if="showVehicleInformation">
            <label class="text-left w-100" for="maker">車名</label>
            <multiselect 
              tagPlaceholder="" 
              v-model="formData.carOption" 
              :is-convert2-full-width="true"
              size="sm"
              id="coreCar-condition"
              deselectLabel="" 
              @input="changeCarName" 
              selectLabel="" 
              selectedLabel="" 
              placeholder="------" 
              label="text" 
              track-by="value" 
              :options="optionCarName" 
              :multiple="true" 
              :taggable="true">
                <span slot="noOptions">データがありません</span>
            </multiselect>
          </div>
          <div class="w-100 mt-3" v-if="showVehicleInformation">
            <label class="text-left w-100" for="formData_model">型式</label>
            <model-select
              class=""
              v-model="formData.core_model_id"
              :options="optionsModel"
              :isDisabled="isMultiCarSelected"
              size="sm"
              id="formData_model"
            ></model-select>
          </div>
          <div class="recommendNew-form-content-items w-100 mt-3" v-if="showVehicleInformation">
            <label class="text-left w-100" for="maker">グレード</label>
            <multiselect tagPlaceholder="" :disabled="isMultiCarSelected" v-model="formData.gradeOption" deselectLabel="" @input="changeGrade" selectLabel="" selectedLabel="" placeholder="------" label="text" track-by="value" :options="optionGrade" :multiple="true" :taggable="true">
              <span slot="noOptions">データがありません</span>
            </multiselect>
          </div>  
          <div class="recommendNew-form-content-items w-100 mt-3" v-if="showYear">
            <label class="text-left w-100" for="maker">年式<span class="pl-3 font-weight-light">※初度登録</span></label>
            <div class="d-flex w-100">
              <div class="position-relative w-100">
                <model-select
                  :options="coreYearsFrom"
                  v-model="formData.year_start_on"
                  id="to_produced_on"
                  :max="formData.year_end_on"
                />
              </div>
              <span class="px-2 pt-1 span-date">~</span>
              <div class="position-relative w-100">
                <model-select
                  :options="coreYearsTo"
                  v-model="formData.year_end_on"
                  id="to_produced_on"
                  :min="formData.year_start_on"
                />
              </div>
            </div>
          </div>  
        </div>
        <div class="recommendNew-form-title mt-3" v-if="showColor || showDisplacement || showShift || showRunning || showEvaluationPoints">詳細情報</div>
        <div class="recommendNew-form-content mt-3">
          <div class="recommendNew-form-content-items position-relative" v-if="showColor">
            <label class="text-left w-100" for="maker">色</label>
            <multiselect tagPlaceholder="" v-model="colorOption" deselectLabel="" selectLabel="" @input="changeColor " selectedLabel="" placeholder="色" label="text" track-by="value" :options="optionColor" :multiple="true" :taggable="true">
              <span slot="noOptions">データがありません</span>
            </multiselect>
          </div>  
          <div class="recommendNew-form-content-items position-relative" v-if="showShift">
            <label class="text-left w-100" for="maker">シフト</label>
            <multiselect tagPlaceholder="" v-model="gearsOptions" deselectLabel="" selectLabel="" @input="changeGears" selectedLabel="" placeholder="シフト" label="text" track-by="value" :options="optionGears" :multiple="true" :taggable="true">
              <span slot="noOptions">データがありません</span>
            </multiselect>
          </div>
          <div class="recommendNew-form-content-items w-100 mt-3" v-if="showDisplacement">
            <label class="text-left w-100 mt-3" for="formData_displacement" >排気量</label>
            <div class="d-flex w-100">
              <div class="position-relative w-100">
                <b-input
                  class=""
                  size="sm"
                  id="formData_displacement_from"
                  v-model="$v.formData.displacement_from.$model"
                  v-on:keyup="convertPositive(formData.displacement_from, 'displacement_from')"
                  placeholder="例）1500cc"
                  autocomplete="off"
                ></b-input>
                <div v-if="$v.formData.displacement_from.$error" class="position-absolute input-error">
                  <span v-if="!$v.formData.displacement_from.integer">{{ message.NUMBER_ERROR }}</span>
                </div>
                <div v-else-if="isEngineFromError" class="position-absolute input-error">
                  <span >{{ isEngineFromError }}</span>
                </div>
              </div>
              <span class="pr-2 pl-2 pt-1 span-date">~</span>
              <div class="position-relative w-100" v-if="showDisplacement">
                <b-input
                  class=""
                  size="sm"
                  id="formData_displacement_to"
                  v-model="$v.formData.displacement_to.$model"
                  v-on:keyup="convertPositive(formData.displacement_to, 'displacement_to')"
                  placeholder="例）1500cc"
                  autocomplete="off"
                ></b-input>
                <div v-if="$v.formData.displacement_to.$error" class="position-absolute input-error">
                  <span v-if="!$v.formData.displacement_to.integer">{{ message.NUMBER_ERROR }}</span>
                </div>
                <div v-else-if="isEngineToError" class="position-absolute input-error">
                  <span >{{ isEngineToError }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="recommendNew-form-content-items w-100" v-if="showRunning">
            <label class="text-left w-100" for="formData_odo_from">走行距離</label>
            <div class="d-flex w-100">
              <div class="w-100 position-relative" >
                <model-select
                  :options="listOdoFrom"
                  v-model="formData.odo_from"
                  :max="formData.odo_to"
                />
              </div>
              <span class="pr-2 pl-2 pt-1 span-date">~</span>
              <div class="w-100 position-relative" >
                <model-select
                  :options="listOdoTo"
                  v-model="formData.odo_to"
                  :min="formData.odo_from"
                />
              </div>
            </div>
          </div>
          <div class="recommendNew-form-content-items w-100" v-if="showEvaluationPoints">
            <label class="text-left w-100" for="formData_score_from">評価点</label>
            <div class="w-100 d-flex">
              <div class="w-100 position-relative">
                <ModelSelect :options="options_score_from" v-model="formData.score_from" :max="formData.score_to" />
              </div>
              <span class="pl-2 pr-2 pt-1 span-date">~</span>
              <div class="w-100 position-relative">
                <ModelSelect :options="options_score_to" v-model="formData.score_to" :min="formData.score_from" />
              </div>
            </div>
          </div>  
        </div>
        <div class="w-100 mt-5 mb-4 d-flex justify-content-center">
           <b-button
            variant="secondary"
            class="w-50 btn-confirm mr-5 mt-5"
            v-on:click="$router.back()"
            id="btn_stock_new_return"
          >
            キャンセル
          </b-button>
          <b-button
            class="cq-btn-bg-green cq-border-green w-50 mt-5"
            id="btn_recommend_condition_car_info_edit_save"
            v-on:click="updateVehicleConditions">
            編集する
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/recommend-condition/new.scss'
import { Message } from '@/utils/message'
import { required, integer } from 'vuelidate/lib/validators'
import multiselect from 'vue-multiselect'
import CQPreLoad from "@/components/CQPreLoad.vue";
import SelectItemSmall from '@/components/mypage/SelectItemSmall.vue';
import { convertNumber } from "@/utils/commonEvent";
import { listOdoFrom, listOdoTo } from "@/utils/odoList"
import ModelSelectBrands from '@/components/ModelSelectBrands.vue';
import { SCORE } from "@/utils/score"
const options_score_from = [...SCORE]
options_score_from[0].text = "下限スコアー"
const options_score_to = [...SCORE]
options_score_to[0].text = "上限スコアー"

export default {
  name: 'recommend-condition-edit',
  layout: 'user',
  auth: "user",
  mixins: [convertNumber],
  head() {
    return {
      title: "車両条件を編集",
    };
  },
  components: { 
    multiselect,
    CQPreLoad,
    SelectItemSmall,
    ModelSelectBrands,
  },
  data() {
    return {
      messageDate: Message.LABLE_DATE,
      message: Message.RECOMMEND_CONDITION,
      listConditionBox: ['メーカー・車両・グレード・型式', '年式', '色', 'シフト', '排気量', '走行', '評価点'],
      preLoading: true,
      showVehicleInformation: false,
      gearsOptions: [],
      showYear: false,
      showColor: false,
      showShift: false,
      showDisplacement: false,
      showRunning: false,
      showEvaluationPoints: false,
      showAlert: true,
      optionBrands:[{
        text: "------",
        value: null,
      }],
      optionCarName: [{
        text: "------",
        value: null,
      }],
      optionsModel: [{
        text: "------",
        value: null,
      }],
      optionColor: [],
      optionGears: [],
      optionGrade: [{
        text: "------",
        value: null,
      }],
      coreYearsFrom: [{
        text: "------",
        value: null,
      }],
      coreYearsTo: [{
        text: "------",
        value: null,
      }],
      colorOption: [],
      dataDetail: false,
      formData: {
        name: null,
        grade_ids: [],
        core_brand_id: null,
        core_car_id: [],
        carOption: [],
        core_model_id: null,
        core_model_id: null,
        gradeOption: [],
        color_ids: [],
        gear_ids: [],
        equipments: [],
        year_start_on: null,
        year_end_on: null,
        history: null,
        odo_from: null,
        odo_to: null,
        displacement_from: null,
        displacement_to: null,
        score_from: null,
        score_to: null,
      },
      isEngineFromError: "",
      isEngineToError: "",
      loadedModel: false,
      loadedGrade: false,
      listOdoFrom,
      listOdoTo,
      groupByGrade: {},
      groupByCarName: {},
      options_score_from,
      options_score_to,
      isMultiCarSelected: false,
    }
  },
  computed: {
    canSubmit () {
      return !this.isEngineFromError && !this.isEngineToError
    },
  },
  watch: {
    'formData.displacement_from' () {
      this.isEngineToError = false
      const displacement_from = parseInt(this.formData.displacement_from)
      const displacement_to = parseInt(this.formData.displacement_to)
      this.isEngineFromError = displacement_from > displacement_to && displacement_from && displacement_to ? this.message.ENGINE_FROM_ERROR : false
    },
    'formData.displacement_to' () {
      this.isEngineFromError = false
      const displacement_from = parseInt(this.formData.displacement_from)
      const displacement_to = parseInt(this.formData.displacement_to)
      this.isEngineToError = displacement_to < displacement_from && displacement_from && displacement_to ? this.message.ENGINE_TO_ERROR : false
    },
  },
  created() {
    this.getOptionStock();
  },
  methods: {
    bindingWatch () {
      this.$watch("formData.core_model_id", () => {
        this.optionGrade = [this.optionGrade[0]];
        this.formData.gradeOption = [];
        this.formData.grade_ids = [];
        this.fetchModel()
      })
      this.$watch("formData.core_car_id", () => {
        this.formData.core_model_id = null;
        this.optionsModel = [this.optionsModel[0]];
        this.formData.grade_ids = [];
        this.formData.gradeOption = [];
        this.fetchCar()
      })
      this.$watch("formData.core_brand_id", () => {
        this.formData.core_car_id = [];
        this.optionCarName = [this.optionCarName[0]]
        this.formData.carOption = [];
        this.formData.gradeOption = [];
        this.formData.grade_ids = [];
        this.fetchBrand()
      })
    },
    async fetchBrand () {
      if(this.formData.core_brand_id){
        const getDataCarName = await this.$store.dispatch('getDataCarName', this.formData.core_brand_id);
        if (getDataCarName.success) {
          getDataCarName.data.cars.forEach(carNames => {
            this.optionCarName.push({ value: carNames.id, text: carNames.name });
          });
          this.groupByCarName = this.lodash.groupBy(getDataCarName.data.cars, (car) => car.name)
        } else {
          if (getDataCarName.errors.length && getDataCarName.errors[0].message) this.$toast.error(getDataCarName.errors[0].message);
        };
      }
      else {
        const getDataCarName = await this.$store.dispatch('getDataCarName', this.formData.core_brand_id);
        if (getDataCarName.success) {
          const carsRes = getDataCarName.data.cars
          const cars = this.lodash.uniqBy(carsRes, (car) => car.name).map((car) => {
            return { value: car.id, text: car.name }
          })
          this.groupByCarName = this.lodash.groupBy(carsRes, (car) => car.name)
          this.optionCarName = [this.optionCarName[0], ...cars]
        } else {
          if (getDataCarName.errors.length && getDataCarName.errors[0].message) this.$toast.error(getDataCarName.errors[0].message);
        };
      }
    },
    async fetchModel () {
      if(this.formData.core_model_id){
        const getDataGrade = await this.$store.dispatch('getDataGrade', this.formData.core_model_id);
        if (getDataGrade.success) {
          getDataGrade.data.grades.forEach(grade => {
            this.optionGrade.push({ value: grade.id, text: grade.name });
          });
          this.groupByGrade = this.lodash.groupBy(getDataGrade.data.grades, (grade) => grade.name)
        } else {
          if (getDataGrade.errors.length && getDataGrade.errors[0].message) this.$toast.error(getDataGrade.errors[0].message);
        };
      }
    },
    async fetchCar() {
      if(this.formData.core_car_id) {
        const getDataModel = await this.$store.dispatch('getDataModel', this.formData.core_car_id);
        if (getDataModel.success) {
          getDataModel.data.models.forEach(model => {
            this.optionsModel.push({ value: model.id, text: model.name });
          });
          const gradesRes = getDataModel.data.grades
          const grades = this.lodash.uniqBy(gradesRes, (grade) => grade.name).map((grade) => {
            return { value: grade.id, text: grade.name }
          })
          this.groupByGrade = this.lodash.groupBy(gradesRes, (grade) => grade.name)
          this.optionGrade = [this.optionGrade[0], ...grades]
        } else {
          if (getDataModel.errors.length && getDataModel.errors[0].message) this.$toast.error(getDataModel.errors[0].message);
        };
      }
    },
    changeCarName(value) {
      if (value.length > 1) {
        this.isMultiCarSelected = true;
      } else {
        this.isMultiCarSelected = false;
      }
      this.formData.core_car_id = [];
      value.forEach(car => {
        this.formData.core_car_id.push(car.value);
      });
    },
    changeGrade(value) {
      this.formData.grade_ids = [];
      value.forEach(grade => {
        this.formData.grade_ids.push(grade.value);
      });
    },
    changeGears(value) {
      this.formData.gear_ids = [];
      value.forEach(gear => {
        this.formData.gear_ids.push(gear.value);
      });
    },
    changeColor(value) {
      this.formData.color_ids = [];
      value.forEach(color => {
        this.formData.color_ids.push(color.value);
      });
    },
    setNumber (value, fields) {
      (!Number(value)) ? this[fields] = this.message.NUMBER_ERROR : this[fields] = "";
      return value;
    },
    async updateVehicleConditions() {
      this.$toast.clear();
      this.$v.formData.$touch();
      let result = !this.$v.formData.$error;
      if (!result && this.canSubmit) {
        if (!this.formData.name) {
          document.getElementById('condition-name').focus()
        } else {
          if (this.$v.formData.core_brand_id.$error) {
            this.showVehicleInformation = true;
            document.querySelectorAll(".recommendNew-form-content-box button")[0].classList.remove("cq-bg-orange");
          } else {
            this.showYear = true;
            document.querySelectorAll(".recommendNew-form-content-box button")[1].classList.remove("cq-bg-orange");
          }
          document.getElementById('condition-name').scrollIntoView({ behavior: "smooth", block: "start" });
        }
      }
      if (result && this.canSubmit) {
        const rqData = JSON.parse(JSON.stringify(this.formData))
        if(rqData.displacement_from){
          rqData.displacement_from = parseInt(rqData.displacement_from)
        }
        if(rqData.displacement_to){
          rqData.displacement_to = parseInt(rqData.displacement_to)
        }
        /**
         * //TODO Binding id to array id 
         */
        rqData.brand_ids = [rqData.core_brand_id]
        rqData.model_ids = [rqData.core_model_id]
        if(rqData.core_car_id) {
          const cars = rqData.core_car_id
          rqData.car_ids = []
          cars.forEach((car) => {
            const option = this.optionCarName.find(({ value }) => value === car)
            if(option) {
              const listCars = this.groupByCarName[option.text].map((car) => car.id)
              rqData.car_ids = [...rqData.car_ids, ...listCars]
            }
          })
        }
        if(rqData.car_ids.length > 1) this.isMultiCarSelected = true;
        if(rqData.grade_ids) {
          const grades = rqData.grade_ids
          rqData.grade_ids = []
          grades.forEach((grade) => {
            const option = this.optionGrade.find(({value}) => value === grade)
            if(option) {
              const listGrades = this.groupByGrade[option.text].map((grade) => grade.id)
              rqData.grade_ids = [...rqData.grade_ids, ...listGrades]
            }
          })
        }
        const updateVehicleConditions = await this.$store.dispatch('updateVehicleConditions', rqData);
        if (updateVehicleConditions.success) {
          this.$toast.success(updateVehicleConditions.message);
          this.$router.push('/recommend-condition/group/list');
        } else {
          if (updateVehicleConditions.errors.length && updateVehicleConditions.errors[0].message) this.$toast.error(updateVehicleConditions.errors[0].message);
        };
      }
    },
    async getOptionStock() {
      const dataMaster = this.$store.getters.carMap
      await dataMaster.loader
      this.coreYearsFrom = [this.coreYearsFrom[0], ...dataMaster.years]
      this.coreYearsTo = [this.coreYearsTo[0], ...dataMaster.years]
      dataMaster.colors.forEach(color => {
        this.optionColor.push({ value: color.id, text: color.name });
      });
      dataMaster.brands.forEach(brand => {
        this.optionBrands.push({ value: brand.id, text: brand.name, core_country_id: brand.core_country_id });
      });
      dataMaster.gears.forEach(gear => {
        if (gear.name === 'AT' || gear.name === 'MT') this.optionGears.push({ value: gear.id, text: gear.name });
      });
      this.getCarInfoDetail();
    },
    showHideField(btnName, event) {
      switch(btnName) {
        case 'メーカー・車両・グレード・型式':
          if (event) {
            this.showVehicleInformation = !this.showVehicleInformation;
            (this.showVehicleInformation) ?  event.currentTarget.classList.remove("cq-bg-orange") : event.currentTarget.classList.add("cq-bg-orange");
          } else {
            if (this.formData.core_brand_id) {
              this.showVehicleInformation = true;
              document.querySelectorAll(".recommendNew-form-content-box button")[0].classList.remove("cq-bg-orange");
            }
          }
          break;
        case '年式':
          if (event) {
            this.showYear = !this.showYear;
            (this.showYear) ?  event.currentTarget.classList.remove("cq-bg-orange") : event.currentTarget.classList.add("cq-bg-orange");
          } else {
            if (this.formData.year_start_on || this.formData.year_end_on) {
              this.showYear = true;
              document.querySelectorAll(".recommendNew-form-content-box button")[1].classList.remove("cq-bg-orange");
            }
          }
          break;
        case '色':
          if (event) {
          this.showColor = !this.showColor;
          (this.showColor) ?  event.currentTarget.classList.remove("cq-bg-orange") : event.currentTarget.classList.add("cq-bg-orange");
          } else {
            if (this.colorOption.length) {
              this.showColor = true;
              document.querySelectorAll(".recommendNew-form-content-box button")[2].classList.remove("cq-bg-orange");
            }
          }
          break;
        case 'シフト':
          if (event) {
            this.showShift = !this.showShift;
            (this.showShift) ?  event.currentTarget.classList.remove("cq-bg-orange") : event.currentTarget.classList.add("cq-bg-orange");
          } else {
            if (this.gearsOptions.length) {
              this.showShift = true;
              document.querySelectorAll(".recommendNew-form-content-box button")[3].classList.remove("cq-bg-orange");
            }
          }
          break;
        case '排気量':
          if (event) {
            this.showDisplacement = !this.showDisplacement;
            (this.showDisplacement) ?  event.currentTarget.classList.remove("cq-bg-orange") : event.currentTarget.classList.add("cq-bg-orange");
          } else {
            if (this.formData.displacement_from || this.formData.displacement_to) {
              this.showDisplacement = true;
              document.querySelectorAll(".recommendNew-form-content-box button")[4].classList.remove("cq-bg-orange");
            }
          }
          break;
        case '走行':
          if (event) {
            this.showRunning = !this.showRunning;
            (this.showRunning) ?  event.currentTarget.classList.remove("cq-bg-orange") : event.currentTarget.classList.add("cq-bg-orange");
          } else {
            if (this.formData.odo_from || this.formData.odo_to) {
              this.showRunning = true;
              document.querySelectorAll(".recommendNew-form-content-box button")[5].classList.remove("cq-bg-orange");
            }
          }
          break;
        default:
          if (event) {
            this.showEvaluationPoints = !this.showEvaluationPoints;
            (this.showEvaluationPoints) ?  event.currentTarget.classList.remove("cq-bg-orange") : event.currentTarget.classList.add("cq-bg-orange");
          } else {
            if (this.formData.score_from || this.formData.score_to) {
              this.showEvaluationPoints = true;
              document.querySelectorAll(".recommendNew-form-content-box button")[6].classList.remove("cq-bg-orange");
            }
          }
      }
    },
    async getCarInfoDetail() {
      const idCarInfo = this.$route.params.id;
      const getCarInfoDetail = await this.$store.dispatch('detailVehicleConditions', idCarInfo);
      if (getCarInfoDetail.success) {
        const tmp = {
          ...this.formData,
          ...getCarInfoDetail.data.rec_cond_car_infos
        };
        this.formData = JSON.parse(JSON.stringify(tmp))
        this.formData.core_brand_id = this.formData.brands[0]?.id ?? null
        this.formData.core_model_id = this.formData.model_ids?.[0] ?? null
        this.formData.gradeOption = this.lodash.uniqBy(this.formData.grades, (grade) => grade.name).map((grade) => {
          return {
            text: grade.name,
            value: grade.id
          }
        })
        this.formData.carOption = this.lodash.uniqBy(this.formData.cars, (car) => car.name).map((car) => {
          return {
            text: car.name,
            value: car.id
          }
        })
        this.fetchBrand()
        this.fetchCar()
        this.fetchModel()
        this.bindingWatch()
        if (this.gearsOptions.length) this.gearsOptions = this.optionGears.find(gears => this.formData.gear_ids.includes(gears.value));
        if (this.colorOption.length) this.colorOption = this.optionColor.find(color => this.formData.color_ids.includes(color.value));
        this.preLoading = false;
        this.formData.colors.forEach(color => {
          if (this.formData.color_ids.includes(color.id)) this.colorOption.push({ value: color.id, text: color.name });
        });
        this.formData.gears.forEach(gear => {
          if (this.formData.gear_ids.includes(gear.id)) this.gearsOptions.push({ value: gear.id, text: gear.name });
        });
        this.$nextTick(() => {
          this.listConditionBox.forEach(element => {
            this.showHideField(element);
          });
        })
      } else {
        if (getCarInfoDetail.errors.length && getCarInfoDetail.errors[0].message) this.$toast.error(getCarInfoDetail.errors[0].message);
      };
    },
  },
  validations () {
    return {
      formData: {
        name: { required },
        displacement_from: { integer },
        displacement_to: { integer },
        core_brand_id: { required },
      },
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>