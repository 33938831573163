<template>
  <div class="item p-3" :style="`--borderVarian: var(--${typeVarian}); height: 100%`">
    <div class="d-flex justify-content-between flex-nowrap align-items-center">
      <div class="w-75">
        <p class="text-muted text-small font-weight-bold">過去１ヶ月のリコメンド実績</p>
        <h5 class="mt-1" :class="'text-'+typeVarian">{{title}}</h5>
        <h4 class="mt-2">
          {{countFormated}}台
        </h4>
      </div>
      <div class="w-25">
        <div class="item-icon">
          <font-awesome-icon icon="fa-solid fa-car" class="icon-large" color="currentColor" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    count: {
      type: Number,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    typeVarian: {
      type: String,
      default () {
        return 'warning'
      }
    }
  },
  computed: {
    countFormated () {
      return new Intl.NumberFormat('en-IN').format(this.count)
    }
  }
};
</script>
<style scoped lang="scss">
.icon-large {
  width: 30px;
  height: 30px;
  fill: #9F9F9F;
  svg, path {
    fill: inherit
  }
}
.text-small {
  font-size: 0.8rem;
}
.item {
  border: 1px solid #9F9F9F;
  border-left: 3px solid var(--borderVarian);
  background-color: $white;
  &-icon{
    // width: 70px;
    text-align: center;
  }
}
</style>