<template>
  <div>
    
  </div>
</template>

<script>

export default {
  name: "Home",
  layout: "home",
  auth: false,
};
</script>
