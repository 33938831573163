import odoList from "./odoList";
import { halfwidth } from "./commonEvent";
const methods = {
  async search(params) {
    const formData = params?.formData ?? this.formData
    if(!formData.core_brand_id || !formData.core_car_id || !formData.model) this.checkSelectNull = true
    else this.checkSelectNull = false;
    if (!this.checkSelectNull) {
      this.isLoading = true 
      const params = { ...formData }
      for (const key in params) {
        if(params[key] ==='') {
          params[key] = null
        }
        if(params[key]?.constructor !== Array) {
          params[key] = params[key]?.toString() ?? null
        }
      }
      this.redirectAnalyzePage(params.core_car_id, params)
      this.isLoading = false
    }
  },
  redirectAnalyzePage (id, params) {
    this.$bvModal.hide('modal-analyze')
    this.$router.push({
      name: 'analyzePage',
      params: {
        id,
      },
      query: params,
    })
  },
  importDataFromStock(data) {
    const { core_brand, core_car, core_grade_id, core_color_id, produced_on, core_gear_id, odo, core_model , displacement, equipments, id } = data
    const dataResult = {}
    dataResult.core_brand_id = core_brand.id
    dataResult.core_car_id = core_car.id
    dataResult.core_grade_id = core_grade_id
    dataResult.core_color_id = core_color_id
    dataResult.produced_on_from = produced_on
    dataResult.produced_on_to = produced_on
    dataResult.core_gear_id = core_gear_id
    const odo_from = odo ? odoList.findLast((odoObj) => odoObj.value < odo ) : null
    const odo_to = odo ? odoList.find((odoObj) => odo < odoObj.value ) : null
    dataResult.odo_from = odo_from?.value ?? null
    dataResult.odo_to = odo_to?.value ?? null
    dataResult.model = core_model.id
    dataResult.displacement_from = displacement
    dataResult.displacement_to = displacement
    dataResult.exterior_score_from = null
    dataResult.core_equipment_id = equipments[0]?.id ?? null
    return dataResult
  },
}

export {
  methods
}

export default {
  methods,
  data () {
    return {
      watcherCarInfo: {
        core_brand_id: {
          key: "brand",
          options: "brands",
          handler: halfwidth,
        },
        core_car_id: {
          key: "car_name",
          options: "cars",
          handler: halfwidth,
        },
        model : {
          key: "models",
          options: "models",
          handler: halfwidth,
        },
        core_grade_id: {
          key: "grades",
          options: "grades",
          handler: halfwidth,
        },
      },
      scopeWatch :{
        core_color_id: {
          key: "color",
          options: "colors",
        },
        core_gear_id: {
          key: "gear",
          options: "gears",
        },
        core_venue_id: {
          key: "auction_venue",
          options: "venues",
        },
        score_from_id: {
          key: "score_from",
          options: "scores"
        },
        score_to_id: {
          key: "score_to",
          options: "scores"
        },
      }
    }
  }
}