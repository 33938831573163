<template>
  <div class="cq-plan_mypage">
    <CQPreLoad :preload="preLoading" />
    <div v-if="!preLoading">
      <div class="cq-plan_mypage-title pl-3 pt-3">
        {{ getContent($route.name).title }}
      </div>
      <b-container class="container-custom pb-5">
        <div class="cq-plan_mypage-infoplan d-flex mt-4">
          <div class="cq-plan_mypage-infoplan-leftContent">
            <div
              class="cq-plan_mypage-infoplan-bager d-flex justify-content-center align-items-center"
              :class="
                infoPlan.isPopular
                  ? 'cq-bg-orange cq-white'
                  : 'cq-btn-bg-green cq-white'
              "
            >
              {{ infoPlan.status }}
            </div>
            <div class="cq-plan_mypage-infoplan-planName cq-title mt-3 mt-md-4">
              {{ infoPlan.planName }}
            </div>
            <div v-if="infoPlan.planName === 'フリー'" class="cq-plan_mypage-infoplan-planPrice mt-2"><span>無料プラン</span></div>
            <div class="cq-plan_mypage-infoplan-listPlanPrice">
              <div
                v-for="(price, i) in infoPlan.price"
                class="cq-plan_mypage-infoplan-planPrice mt-2"
                :key="i"
              >
                <p v-if="$route.name === 'mypage plan register'">
                    <span>{{price}}</span>
                </p>
                <p v-else>
                  <span v-if="price.includes('初期登録手数料') && userPlanInfo.user_plan.is_first">
                    <span>{{price}}</span>
                  </span>
                  <span v-if="price.includes('毎月') && !userPlanInfo.user_plan.is_first">
                    <span v-if="!isFreePlan || ($route.name === 'mypage plan upgrade')">{{ price }}</span>
                  </span>
                </p>
              </div>
            </div>
            <hr class="cq-hr-green mt-2 mb-4" />
            <div class="cq-plan_mypage-infoplan-listData">
              <div
                v-for="(infoPlan, indexInfo) in infoPlan.info"
                class="cq-plan_mypage-infoplan-data d-flex align-items-center mt-2"
                :key="indexInfo"
              >
                <div class="cq-green mr-2">
                  <font-awesome-icon icon="fa-solid fa-check" />
                </div>
                <div>
                  <div
                    v-for="(infoPlanDetail, indexPlanDetail) in infoPlan"
                    :key="indexPlanDetail"
                  >
                    {{ infoPlanDetail }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="cq-plan_mypage-infoplan-rightContent">
            <div
              class="cq-plan_mypage-infoplan-planName cq-title d-flex align-items-center flex-wrap"
            >
              <span v-if="isRegister" class="mr-2">決済</span>
            </div>
            <div class="cq-plan_mypage-infoplan-boxCheckout">
              <div v-if="!isBankTransfer">
                <b-form-group
                  v-if="!isDowngradeToFree"
                  class="mt-3 mt-md-4 cq-plan_mypage-infoplan-select"
                  label="お支払方法:"
                >
                  <b-form-radio-group
                    id="radio-paymentType"
                    :class="{ 'error-valid': $v.selectedPaymentType.$error }"
                    v-model="$v.selectedPaymentType.$model"
                    name="radio-paymentType"
                    :disabled="isDowngradeSelectedTime"
                  >
                    <b-form-radio
                      v-for="(option,index) in optionsPaymentType"
                      class="row col-md-6 col-12"
                      :value="option.value"
                      :key="option.value"
                      :style="index === 0 ? 'max-width: 200px' : ''"
                    >
                      {{option.text}}
                      <div class="text-messger pt-2 text-danger" v-if="selectedPaymentType === 2 && option.value == 2" >
                        ※運営事務局による入金確認が完了してからプランが適用されます。
                      </div>
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>
                <b-form-group
                  v-if="!isRegister && !isFreePlan"
                  class="mt-3 mt-md-4 cq-plan_mypage-infoplan-select"
                  :label="titelPlan"
                >
                  <div>
                    <b-form-radio-group
                      id="radio-upgradeTime"
                      :class="{ 'error-valid': $v.selectedUpgradeTime.$error }"
                      v-model="$v.selectedUpgradeTime.$model"
                      name="radio-upgradeTime"
                      class="position-relative"
                    >
                    <b-form-radio
                      v-for="(option,index) in optionsUpgradeTime"
                      class="row col-md-6 col-12"
                      :value="option.value"
                      :key="option.value"
                      :style="index === 0 ? 'max-width: 200px' : ''"
                    >
                      <span>{{option.text}}</span>
                      <span v-if="option.value === 2 && nextCycleDate" style="font-size: 12px">{{getDayMonths("next-cycle")}}</span>
                      <div class="text-messger pt-2 text-danger" v-if="option.value === 2 && infoPlan.planName !== 'フリー'">
                        {{getDayMonths(selectedUpgradeTime)}}
                      </div>
                    </b-form-radio>
                    </b-form-radio-group>
                  </div>
                </b-form-group>
              </div>
              <div v-if="!isDowngradeToFree && !isDowngradeSelectedTime && this.userPlanInfo.user_plan.is_first">
                <div class="mt-3 mt-md-4">クーポンを利用する:</div>
                <div class="cq-plan_mypage-infoplan-input">
                  <form @submit.prevent.stop="handleCheckCoupon">
                    <div class="mt-2 d-flex align-items-center flex-wrap">
                      <b-form-input placeholder="例）HO19BXQOZ" v-model="couponCodeValue" autocomplete="off" />
                      <button class="btn cq-btn-bg-orange btn-secondary" :disabled="isDisableSearchCoupon" id="btn_mypage_plan_search_coupon">適用</button>
                    </div>
                  </form>
                </div>
              </div>
              <div v-if="!isDowngradeToFree && !isDowngradeSelectedTime && $route.name !== 'mypage plan bank transfer'" class="mt-3 mt-md-4">
                合計金額：<span class="text-money">{{(canApplyCoupon ? fee : fee + fee * 0.1) | setComma}}円</span> <span class="text-money" v-if="canApplyCoupon"> - {{couponData.discount_price | setComma}}円 = <span class="text-money">{{appliedDiscount | setComma}}円</span></span>
                <span class="messager-total" v-if="selectedPaymentType === 1">（税込10%）</span>
                <span class="messager-total ml-2" v-else-if="selectedPaymentType === 2">（税込）＊請求書発行手数料200円が必要となります。</span>
              </div>
              <div v-else-if="!isDowngradeToFree && !isDowngradeSelectedTime && $route.name === 'mypage plan bank transfer'" class="mt-3 mt-md-4">
                合計金額：<span class="text-money">{{((totalBankTransfer + 200) * 10) / 100 + (totalBankTransfer + 200) | setComma}}円</span>
                <span class="messager-total ml-2">（税込）＊請求書発行手数料200円が必要となります。</span>
              </div>
            </div>
            <div v-if="couponData">
              <hr class="mt-2 mb-4" />
              <div
                class="cq-plan_mypage-infoplan-planName cq-title d-flex align-items-center flex-wrap"
              >
                <span class="mr-2">クーポン情報</span>
              </div>
              <div class="cq-plan_mypage-infoplan-boxCheckout mt-2">
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>クーポン名</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    {{couponData.name}}
                  </div>
                </div>
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>適用範囲</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    <div class="text-danger">
                      {{scopeList[couponData.scope]}}
                    </div>
                  </div>
                </div>
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>開始日</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    {{couponData.valid_from_on  | moment('YYYY/MM/DD')}}
                  </div>
                </div>
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>終了日</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    {{couponData.valid_till_on | moment('YYYY/MM/DD')}}
                  </div>
                </div>
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>利用可能金額</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    {{couponData.discount_price | setComma}}円
                  </div>
                </div>
              </div>
            </div>
            <div v-if="isBankingInfo || isBankTransfer">
              <hr class="mt-2 mb-4" />
              <div
                class="cq-plan_mypage-infoplan-planName cq-title d-flex align-items-center flex-wrap"
              >
                <span class="mr-2">お振込先</span>
              </div>
              <div class="cq-plan_mypage-infoplan-boxCheckout mt-2">
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>金融機関</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    みずほ銀行
                  </div>
                </div>
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>支店名</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    大森支店
                  </div>
                </div>
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>支店番号</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    196
                  </div>
                </div>
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>科目</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    普通
                  </div>
                </div>
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>口座番号</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    1249607
                  </div>
                </div>
                <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>受取人名</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    カ）ハッピー
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-noti">※請求書発行手数料は200円</div>
                </div>
                <div class="cq-plan_mypage-infoplan-partInfo text-danger d-flex">
                  <div class="cq-plan_mypage-infoplan-partInfo-left">
                    <span>確認コード</span>
                  </div>
                  <div class="cq-plan_mypage-infoplan-partInfo-right">
                    {{ (userPlanInfo.user_plan.list_payment_transaction.length && userPlanInfo.user_plan.list_payment_transaction[0].confirm_code ) ? userPlanInfo.user_plan.list_payment_transaction[0].confirm_code : this.confirmCode }}
                  </div>
                </div>
                <div class="input-error w-content">
                  ※振込時に振込依頼人名の前に、4桁の確認コードをご入力ください。
                </div>
              </div>
            </div>
            <div class="cq-plan_mypage-infoplan-boxCheckout">
              <div
                class="mt-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap"
              >
                <router-link
                  to="/mypage/plan"
                  id="link_to_mypage_plan"
                  class="btn-bot text-white d-flex justify-content-center align-items-center btn btn-secondary"
                  >キャンセル</router-link
                >
                <b-button class="cq-btn-bg-green btn-top" id="btn_mypage_plan_change_handle_payment" :disabled="isLoading" @click="selectedPaymentType === 2 && selectedUpgradeTime === 1 && !isBankingInfo || selectedPaymentType === 2 && isFreePlan && !isBankingInfo ? getBankInfo() : changePlan()">
                  <b-spinner v-if="isLoading" small />
                  {{ ((selectedPaymentType === 2 && selectedUpgradeTime === 1 && !isBankingInfo || selectedPaymentType === 2 && isFreePlan && !isBankingInfo) && (appliedDiscount !== 0)) ? '振込銀行の情報' : getContent($route.name).btn }}
                </b-button>
              </div>
            </div>
            <b-modal
              id="confirmBank"
              ref="confirmBank"
              modal-class="modal-confirm modal-confirm-sm"
              body-class="p-md-4 p-3"
              hide-footer
              hide-header
              centered
            >
              <div class="d-block text-center title w-100">
                <h1 class="text-warning">
                  <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
                </h1>
                <p v-for="message in messagesConfirm" :key="message">
                  {{message}}
                </p>
              </div>
              <div
                class="mt-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap"
              >
                <b-button class="btn-bot" @click="$bvModal.hide('confirmBank')" id="btn_mypage_plan_change_hide_confirm">いいえ</b-button>
                <b-button class="cq-btn-bg-green btn-top" @click="confirmBankTransfer()" id="btn_mypage_plan_change_confirm">はい</b-button >
              </div>
            </b-modal>
            <b-modal
              id="confirmAlert"
              ref="confirmAlert"
              modal-class="modal-confirm modal-confirm-sm"
              body-class="p-md-4 p-3"
              hide-footer
              hide-header
              centered
            >
              <div class="d-block text-center title w-100">
                <h1 class="text-warning">
                  <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
                </h1>
                <p v-for="message in messagesConfirm" :key="message">
                  {{message}}
                </p>
              </div>
              <div
                class="mt-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap"
              >
                <b-button class="btn-bot" @click="$bvModal.hide('confirmAlert')" id="btn_mypage_plan_change_hide_confirm">いいえ</b-button>
                <b-button class="cq-btn-bg-green btn-top" @click="handleChangePlan(formDataAlert)" id="btn_mypage_plan_change_confirm" :disabled="isLoading">
                  <b-spinner v-if="isLoading" small />
                  はい
                </b-button>
              </div>
            </b-modal>
            <b-modal
              id="confirmUpdateProfile"
              ref="confirmUpdateProfile"
              modal-class="modal-confirm modal-confirm-sm"
              body-class="p-md-4 p-3"
              hide-footer
              centered
              @hide="$router.push({path: '/mypage/profile', query: { 'action': 'validation'}})"
            >
              <div class="d-block text-center title w-100">
                <h1 class="text-warning">
                  <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
                </h1>
                <p>
                  有料プランを登録する前に会員情報を入力してください。
                </p>
              </div>
            </b-modal>
            <b-modal
            id="completeBanking"
            ref="completeBanking"
            modal-class="modal-confirm modal-confirm-sm"
            body-class="pt-0 pb-"
            hide-footer
            centered
            headerClass="border-bottom-0 pb-0"
            >
            <div class="">
              <div class="cq-signup-check">
                <font-awesome-icon icon="fa-solid fa-check" />
              </div>
              <div class="text-center mt-3">お申込頂きありがとうございます。入金の確認が取れ次第承認いたします。</div>
            </div>
            </b-modal>
          </div>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/mypage/plan.scss";
import { loadListPlan } from "@/utils/listPlan";
import { required } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import CQPreLoad from "@/components/CQPreLoad.vue";

export default {
  name: "MypagePlan",
  layout: "user",
  auth: "user",
  head() {
    return {
      title: "プラン情報表示",
    };
  },
  components: {
    CQPreLoad,
  },
  data() {
    return {
      preLoading: true,
      listPlan: loadListPlan(),
      infoPlan: {},
      selectedPaymentType: null,
      optionsPaymentType: [
        { text: "クレジットカード", value: 1 },
        { text: "銀行振込", value: 2 },
      ],
      selectedUpgradeTime: null,
      dataCoupon: "",
      isBankTransfer: false,
      isRegister: false,
      isUpgrade: false,
      isDowngrade: false,
      isLoading: false,
      isBankingInfo: false,
      isFreePlan: false,
      isDowngradeToFree: false,
      isSearchingCoupon: false,
      couponCode: null,
      couponCodeValue: null,
      confirmCode: null,
      couponData: null,
      scopeList: ['全て','初期登録手数料','プラン費用','追加オプション'],
      isBanking: "",
      titelPlan: "",
      nextCycleDate: false,
      totalBankTransfer: 0,
      messagesConfirm: "",
      formDataAlert: {}
    };
  },
  computed: {
    ...mapGetters({
      userPlanInfo: "userPlanInfo",
      userInfo: "userInfo",
    }),
    isDisableSearchCoupon () {
      return !(this.couponCodeValue && this.couponCodeValue != this.couponCode)
    },
    canApplyCoupon (){
      if (this.couponData) {
        if(this.couponData.scope === 1) {
          return this.userPlanInfo.user_plan.is_first
        }
        else if (this.couponData.scope === 3) {
          return false
        }
        return true
      }
      return false
    },
    appliedDiscount () {
      if(this.canApplyCoupon) {
        const cost = this.fee - this.couponData.discount_price
        const fee = cost + cost * 0.1
        return fee > 0 ? Math.round(fee) : 0
      }
      return ''
    },
    fee () {
      let fee = 0
      fee += this.infoPlan.changeFee
      if(this.isRegister) fee += this.infoPlan.fisrtFee
      if(this.selectedPaymentType === 2) fee += this.infoPlan.bankingFee
      return fee
    },
    optionsUpgradeTime () {
      const options = [
        { text: "すぐ変更", value: 1 },
        { text: "次回更新日", value: 2 },
      ]
      return options
    },
    isDowngradeSelectedTime () {
      return this.$route.name === 'mypage plan downgrade' && (this.selectedUpgradeTime == '1' || this.selectedUpgradeTime == null ) && !this.isDowngradeToFree ? true : false
    },
  },
  watch: {
    userPlanInfo() {
      this.preLoading = false;
      this.getCheckRoute(this.$route.name);
      if (!this.isBankTransfer) this.checkPlan();
    },
    selectedPaymentType() {
      this.isBankingInfo = false
      this.couponCode = this.couponCodeValue = null
      this.couponData = null
      if(this.selectedPaymentType  === 2 && !this.isBankingInfo && this.selectedUpgradeTime || (this.isRegister || this.isFreePlan) && this.selectedPaymentType  === 2) {
        this.getBankInfo()
      }
    },
    selectedUpgradeTime(value) {
      this.nextCycleDate = (value === 2)
      if(this.selectedPaymentType  === 2 && !this.isBankingInfo) {
        this.getBankInfo()
      }
      if(this.isDowngradeSelectedTime) {
        this.selectedPaymentType = null
      }
    },
  },
  async created() {
    const { planId } = this.$route.params;
    this.infoPlan = this.listPlan.find((item) => item.planId == planId);
    this.isDowngradeToFree = planId == 1
    if(planId == 1) this.selectedPaymentType = 1
    await this.getPlanInfo()
    this.checkUserInfo()
    .then((isHasUserInfo) => {
      if(!isHasUserInfo) {
        this.$bvModal.show("confirmUpdateProfile")
      }
    })
    localStorage.setItem("upDownGrade", this.$route.name);
    if (this.$route.name === 'mypage plan downgrade') {
      this.titelPlan = 'ダウングレードタイミング';
    } else if (this.$route.name === 'mypage plan upgrade') this.titelPlan = 'アップグレードタイミング';
    this.$root.$on("payjp:error", this.onTokenFailed)
    this.$root.$on("payjp:created", this.onTokenCreated)
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if(modalId === 'completeBanking') {
        this.$router.push('/mypage/plan')
      }
    })
  },
  beforeDestroy () {
    this.$root.$off("payjp:error", this.onTokenFailed)
    this.$root.$off("payjp:created", this.onTokenCreated)
  },
  methods: {
    ...mapActions({
      getPlanInfo: "getPlanInfo",
    }),
    checkPlan() {
      const userPlan = this.userPlanInfo.user_plan;
      this.isBanking = this.userPlanInfo.user_plan.list_payment_transaction.find(item => item.id === this.userPlanInfo.user_plan.payment_transactions_detail?.payment_transaction_id)?.payment_type === "wire_transfer"
      const { planId } = this.$route.params;
      const currentPlanId = this.listPlan.find(
        (item) => item.planName == userPlan.plan_info.plan_name
      ).planId;
      this.isFreePlan = currentPlanId === 1
      if (
        (!userPlan.is_first && this.isRegister) ||
        planId == currentPlanId ||
        (this.isUpgrade && planId < currentPlanId) ||
        (this.isDowngrade && planId > currentPlanId) ||
        (userPlan.is_first && !this.isRegister) ||
        !this.listPlan.find((item) => item.planId == planId)
      ) {
        this.$router.push("/mypage/plan");
      }
    },
    async checkUserInfo () {
      await this.userInfo.handler
      const check = (userInfo) => {
        const list_fields_corporate = [
          "corporate_name",
          "first_representative_name",
          "last_representative_name",
          "first_representative_kata",
          "last_representative_kata",
          "first_name_kanji",
          "last_name_kanji",
          "first_name_kata",
          "last_name_kata",
          "zip_code",
        ]
        const list_fields_profile = [
          "first_name_kanji",
          "last_name_kanji",
          "first_name_kata",
          "last_name_kata",
          "zip_code",
        ]
        const business_type = userInfo.business_type
        let res = true
        let fields = list_fields_profile
        if(business_type == "company"){
          fields = list_fields_corporate
        }
        for (const field of fields) {
          if(!userInfo[field]) {
            res = false
            break;
          }
        }
        return res
      }
      if(this.userInfo.data) {
        return check(this.userInfo.data)
      }
      return false
    },
    async changePlan() {
      this.$v.$touch();
      const result = !this.$v.$error;
      if (result) {
        if (this.selectedPaymentType === 1) {
          const data = await this.$store.dispatch('getCardInfo')
          if(data.data && data.data.cards.count || this.isDowngradeToFree) {
            const { planId, planName} = this.infoPlan
            const isUpdateNow = this.selectedUpgradeTime === 1 || this.isFreePlan ? true : false
            const formData = {
              plan_id: planId,
              payment_type: this.selectedPaymentType,
              is_update_now: isUpdateNow,
              coupon_code: this.couponCode
            };
            if(this.isRegister) {
              this.handleCreatePlan(formData)
            } else {
              this.handleChangePlan(formData)
            }
          } else {
            document.getElementById("payjp_checkout_box").childNodes[0].click();
          }
        } else if (this.selectedPaymentType === 2 && this.selectedUpgradeTime === 1 || this.selectedPaymentType === 2 && this.isFreePlan) {
          (this.appliedDiscount === 0) ? this.confirmBankTransfer() : this.handlerConfirmBank();
        } else {
          const { planId, planName} = this.infoPlan
          const isUpdateNow = this.selectedUpgradeTime === 1 ? true : false
          let payment_type = this.selectedPaymentType
          if(isUpdateNow && this.isDowngrade) {
            payment_type = this.userPlanInfo.user_plan.plan_info.payment_type
          }
          const formData = {
            plan_id: planId,
            payment_type,
            is_update_now: isUpdateNow,
            coupon_code: this.couponCode
          };
          this.formDataAlert = formData
          this.$bvModal.show("confirmAlert")
          this.messagesConfirm = ["プランをダウングレードしてもよろしいでしょうか？"]
        }
      }
    },
    getCheckRoute(routeName) {
      if (routeName === "mypage plan register") {
        this.isRegister = true;
      } else if (routeName === "mypage plan upgrade") {
        this.isUpgrade = true;
      } else if (routeName === "mypage plan downgrade") {
        this.isDowngrade = true;
      } else {
        const currentPlanName = this.userPlanInfo.user_plan.plan_info.plan_name;
        this.isBankTransfer = true;
        this.infoPlan = this.listPlan.find(
          (item) => item.planName == currentPlanName
        );
        this.totalBankTransfer = Number(this.infoPlan.price[0].replace("毎月", "").replace("円 (税抜)", "").replace(",", ""));
      }
    },
    async getBankInfo() {
      this.isLoading = true
      const getBankInfo = this.$store.dispatch('getBankInfo')
      const handlers = []
      handlers.push(getBankInfo)
      if(!this.isDisableSearchCoupon) {
        handlers.push(this.handleCheckCoupon())
      }
      Promise.all(handlers)
      .then((results) => {
        const bankInfo = results[0]
        this.isLoading = false
        if(bankInfo.success) {
          this.isBankingInfo = true
          this.confirmCode = bankInfo.data
        } else {
          if(bankInfo.errors.length && bankInfo.errors[0].message) this.$toast.error(bankInfo.errors[0].message)
        }
      })
    },
    getContent(routeName) {
      if (routeName === "mypage plan register") {
        return {
          title: "プラン登録",
          btn: "申し込み",
        };
      } else if (routeName === "mypage plan upgrade") {
        return {
          title: "プランをアップグレード",
          btn: "アップグレード",
        };
      } else if (routeName === "mypage plan downgrade") {
        return {
          title: "プランをダウングレード",
          btn: "ダウングレード",
        };
      } else {
        return {
          title: "今月分を振り込み",
          btn: "振り込みした",
        };
      }
    },
    onTokenCreated(data) {
      const { planId } = this.infoPlan
      const isUpdateNow = this.selectedUpgradeTime === 1 || this.isFreePlan ? true : false
      const formData = {
        payjp_token: data.id,
        plan_id: planId,
        payment_type: this.selectedPaymentType,
        is_update_now: isUpdateNow,
        coupon_code: this.couponCode
      };
      if(this.isRegister) {
        this.handleCreatePlan(formData)
      } else {
        this.handleChangePlan(formData)
      }
    },
    onTokenFailed(data) {
      console.log(data);
    },
    hanlderShowModalBankInfo () {
      this.$bvModal.show("completeBanking")
    },
    handlerConfirmBank () {
      if(this.isRegister) this.messagesConfirm = ["「はい」ボタンをクリックした後、「プラン」画面の請求書一覧に請求書を確認して銀行振込を行います。入金確認後にプランが適用となります。"]
      else if(this.isUpgrade) this.messagesConfirm = ["「はい」ボタンをクリックした後、「プラン」画面の請求書一覧に請求書を確認して銀行振込を行います。入金確認後にプランが適用となります。"]
      else this.messagesConfirm = ["プランをダウングレードしてもよろしいでしょうか。"]
      this.$bvModal.show('confirmBank')
    },
    confirmBankTransfer() {
      this.$bvModal.hide('confirmBank')
      const { planId } = this.infoPlan
      const isUpdateNow = this.selectedUpgradeTime === 1 || this.isFreePlan ? true : false
      const formData = {
        plan_id: planId,
        payment_type: this.selectedPaymentType,
        is_update_now: isUpdateNow,
      };
      if(this.selectedPaymentType === 2) {
        formData.coupon_code = this.couponCode
      }
      if(this.isRegister) {
        this.handleCreatePlan(formData, () => {
          this.hanlderShowModalBankInfo()
          this.messagesConfirm = ["プランの申し込みの銀行振込を実行しましたか？", "銀行振込が成功に実行した場合は「はい」ボタンを押下してください。"]
        })
      } else {
        this.handleChangePlan(formData, () => {
          this.hanlderShowModalBankInfo()
          this.messagesConfirm = ["プランのアップグレードの銀行振込を実行しましたか？", "銀行振込が成功に実行した場合は「はい」ボタンを押下してください。"]
        })
      }
    },
    async handleCreatePlan(formData, callback_success) {
      this.isLoading = true
      const data = await this.$store.dispatch('createPlan', formData)
      if(data.success) {
        this.isLoading = false
        if(callback_success) {
          callback_success(data)
          return
        }
        this.$toast.success(data.message)
        this.$router.push('/mypage/plan')
      }else {
        if(data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message)
        this.isLoading = false
      }
    },
    async handleChangePlan(formData, callback_success) {
      this.isLoading = true
      const data = await this.$store.dispatch('changePlan', formData)
      if(data.success) {
        this.isLoading = false
        if(callback_success) {
          callback_success(data)
          return
        }
        this.$toast.success(data.message)
        this.$router.push('/mypage/plan')
      }else {
        this.isLoading = false
        if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message)
      }
    },
    async handleCheckCoupon () {
      this.couponCode = this.couponCodeValue
      this.isSearchingCoupon = true
      const res = await this.$store.dispatch('searchCoupon', {
        code: this.couponCode
      })
      if(res.success) {
        this.couponData = res.data.coupon
      }else {
        if(res.errors.length && res.errors[0].message) this.$toast.error(res.errors[0].message)
      }
      this.isSearchingCoupon = false
    },
    getDayMonths(upgradeTime) {
      let today = new Date()
      if(this.userPlanInfo?.user_plan?.actived_date_on){
        today = new Date(this.userPlanInfo?.user_plan?.actived_date_on);
      }
      today.setMonth(today.getMonth() + 1);
      let messger = "";
      if (upgradeTime === 'next-cycle') return this.$moment(today).format('YYYY/MM/DD');
      if (upgradeTime === 2) {
        if (this.selectedPaymentType === 1) {
          messger = `※${this.$moment(today.setDate(today.getDate() - 3)).format('YYYY/MM/DD')}に現行プランの請求書が自動発行されます。${this.$moment(today.setDate(today.getDate() + 2)).format('YYYY/MM/DD')}にシステムのバッチ自動決済となる。
          ${this.$moment(today.setDate(today.getDate() - 1)).format('YYYY/MM/DD')}にどちらかのご入金がないとプランが自動的に無料プランに変更となりますのでご注意ください。`;
        } else if (this.selectedPaymentType > 0) {
          messger = `※${this.$moment(today.setDate(today.getDate() - 7)).format('YYYY/MM/DD')}までにご入金が確認できない場合は現行プランの請求書が自動発行されます。どちらかのご入金がないとプランが自動的に無料プランに変更となりますのでご注意ください。`;
        }
      }
      return messger;
    },
  },
  validations() {
    return {
      selectedPaymentType: !this.isDowngradeToFree && !this.isDowngradeSelectedTime ? {
        required,
      } : {},
      selectedUpgradeTime: this.isRegister || this.isFreePlan ? {} : {
        required,
      },
    };
  },
};
</script>
