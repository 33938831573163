<template>
  <div></div>
</template>

<script>
import { getAuth, signOut } from "firebase/auth";
import { logout } from "../utils/localStorage";

export default {
  name: 'logout',
  async created() {
    const data = await this.$store.dispatch('logout')
    if(data) {
      logout()
      const auth = getAuth();
      signOut(auth)
      .then(() => {})
      .catch((error) => {});
      this.$router.push('/login')
    }
  }
}
</script>

<style>

</style>