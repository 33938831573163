<template>
  <div class="cq-forgot d-flex justify-content-center align-items-center">
    <div class="cq-forgot-box">
      <div class="cq-forgot-title text-center mb-3">パスワード再設定</div>
      <div class="cq-forgot-desc text-center mt-3">
        <p>ご利用中のメールアドレスを入力してください。</p>
        <p>パスワード再設定のためのURLをお送りします。</p>
      </div>
      <form
        class="cq-forgot-form mt-4"
        v-on:submit.stop.prevent="handleForgotInput">
        <div class="position-relative">
          <b-input v-model="$v.formData.email.$model" :state="$v.formData.email.$error ? false : null"  placeholder="登録したメールアドレス" id="forgotInput-input" />
          <div v-if="$v.formData.email.$error" id="forgotInput-error" class="position-absolute input-error">
            <span v-if="!$v.formData.email.required">{{ message.ERROR_REQUIRE }}</span>
            <span v-if="!$v.formData.email.email">{{ message.EMAIL_INCORRECT }}</span>
          </div>
        </div>
        <b-button :disabled="isLoading" id="forgotInput-btn" class="mt-4 w-100 forgot-btn cq-btn-bg-green" type="submit">
          <b-spinner v-if="isLoading" small />
          送信
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/forgot/forgot-input.scss'
import { required, email } from 'vuelidate/lib/validators'
import { Message } from '@/utils/message';
export default {
  name: "forgotInput",
  layout: "main",
  auth: false,
  head() {
    return {
      title: "パスワード再設定 - AIハイト",
    };
  },
  data() {
    return {
      formData: {
        email: null
      },
      isLoading: false,
      message: Message.FORGOT_INPUT_EMAIL,
    }
  },
  watch: {
    'formData.email'() {
      this.$v.$reset()
    },
  },  
  methods: {
    async handleForgotInput() {
      this.$toast.clear()
      this.$v.$touch()
      const result = !this.$v.$error
      if(result) {
        this.isLoading = true
        const data = await this.$store.dispatch('forgot', this.formData)
        this.isLoading = false
        if(data.success) {
          this.$router.push('/forgot/sended')
        } else {
          if(data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message)
        }
      }
    }
  },
  validations () {
    return {
      formData: {
        email: { required, email },
      },
    }
  }
};
</script>
