<template>
  <div
    class="ui fluid search selection dropdown"
    :class="{
      'active visible': showMenu && !menuTop,
      'visible active-top': showMenu && menuTop,
      error: isError,
      disabled: isDisabled,
    }"
    @click="handleOpenOptions"
    @focus="handleOpenOptions"
    ref="dropdown"
  >
    <i class="dropdown icon"></i>
    <input
      class="search"
      :tabindex="isDisabled ? -1 : 0"
      autocomplete="off"
      :id="id"
      :name="name"
      :value="searchText"
      @input="searchText = convertToFullWidth($event.target.value)"
      ref="input"
      @focus.prevent="handleOpenOptions"
      @keyup.esc="handleCloseOptions"
      @blur="handleBlurInput"
      @keydown.up="prevItem"
      @keydown.down="nextItem"
      @keydown.enter.prevent=""
      @keyup.enter.prevent="enterItem"
      @keydown.delete="deleteTextOrItem"
    />
    <div
      class="text"
      :class="[textClass, classModelSelectText]"
      :data-vss-custom-attr="searchTextCustomAttr"
    >
      {{ inputText }}
    </div>
    <div
      class="menu"
      ref="menu"
      @mousedown.prevent
      :class="menuClasses"
      :style="menuStyle"
      tabindex="-1"
    >
      <div
        v-for="(country, index) in optionsSelect" :key="index">
        <div class="font-weight-bold p-2">{{country.name}}</div>
        <div
          v-for="(option, idx) in country.brands"
          :disabled="canSelect(option)"
          :key="idx"
          class="item"
          :class="[
            { selected: option.selected || pointer === `${index}_${idx}` || canSelect(option) },
            classModelSelectText,
          ]"
          :data-vss-custom-attr="customAttrs[`${index}_${idx}`] ? customAttrs[`${index}_${idx}`] : ''"
          @click.stop="handleSelectItem(option)"
          @mousedown="mousedownItem"
          @mouseenter="pointerSet(`${index}_${idx}`)"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ModelSelect from "./ModelSelect.vue";
export default {
  mixins: [ModelSelect],
  data() {
    return {
    };
  },
  computed: {
    dataMaster() {
      return this.$store.getters.carMap;
    },
    optionsSelect() {
      const { countries } = this.dataMaster
      countries.forEach(country => {
        const options = this.filteredOptions.filter((brand) => {
          return brand.core_country_id === country.id
        })
        country.brands = options
      });
      const result = countries.filter((country) => country.brands.length)
      return result
    }
  },
};
</script>
<style scoped>
ui.selection.dropdown .menu .item {
    border-top: 1px solid #fafafa;
    padding: 0.78571429rem 1.14285714rem !important;
    white-space: normal;
    word-wrap: normal;
}
.ui.dropdown .menu .item:first-child {
    border-top-width: 0;
}
.ui.dropdown .menu .item {
    font-size: 14px;
}
.ui.dropdown .menu .item {
    position: relative;
    cursor: pointer;
    display: block;
    border: none;
    height: auto;
    text-align: left;
    border-top: none;
    line-height: 1em;
    color: rgba(0, 0, 0, 0.87);
    padding: 0.78571429rem 1.14285714rem !important;
    font-size: 1rem;
    text-transform: none;
    font-weight: 400;
    box-shadow: none;
    -webkit-touch-callout: none;
}
</style>