<template>
  <div class="cq-chat d-flex flex-column">
    <CQPreLoad :preload="preLoading" />
    <CQThreadChat
      ref="threadChat"
      v-if="!preLoading"
      :list-mess="listMess"
      :user-id="userId"
      :admin-typing-data="{adminTyping, typingAt}"
      :recent-message="recent_message"
      @send-mess="sendMess"
      @is-reRender="scrollBottom"
      @on-typing="onTyping"
      @set-typing="setTyping"
    />
  </div>
</template>

<script>
import "@/assets/scss/chat/index.scss";
import { getDatabase, ref, set , push, onValue, query, update, limitToLast, get, off } from "firebase/database";
import CQPreLoad from "@/components/CQPreLoad.vue";
import CQThreadChat from "@/components/chat/CQThreadChat.vue";
import { mapGetters } from 'vuex';

export default {
  name: "Home",
  layout: "user",
  auth: "user",
  head() {
    return {
      title: 'チャット'
    }
  },
  components: {
    CQPreLoad,
    CQThreadChat
  },
  data() {
    return {
      message: "",
      listMess: [],
      userId: localStorage.getItem('userId'),
      role: localStorage.getItem('auth_user'),
      listFilesUpload: [],
      listFileShow: [],
      isRender: false,
      preLoading: true,
      limit: 50,
      fisrtLoad: true,
      startScroll: 0,
      afterScroll: null,
      loadMore: true,
      adminTyping: null,
      typingAt: null,
      isCreated: false,
      refOnMessage: null,
      recent_message: {}
    }
  },
  computed: {
    ...mapGetters({
      isReadChat: 'isReadChat',
    }),
    userInfo () {
      return this.$store.getters.userInfo.data
    }
  },
  async created() {
    const { data } = await this.$store.getters.userInfo.handler
    localStorage.setItem('userId', this.userId = data.user.id.toString())
    this.getListMess()
    this.checkExist()
    .then(() => {
      this.setRead()
    })
  },
  mounted() {
    this.$nextTick(() => {
      document.addEventListener("scroll", this.onScroll);
    })
    window.addEventListener("visibilitychange", this.onWindowFocus)
  },
  updated() {
    if(this.isRender) {
      this.isRender = false
      this.scrollBottom()
    }
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.onScroll);
    if(this.refOnMessage){
      off(this.refOnMessage, "value", this.onMessage)
    }
    window.removeEventListener("visibilitychange", this.onWindowFocus)
  },
  methods: {
    checkExist() {
      const conversation = ref(getDatabase(this.$firebase), `conversations/${this.userId}`)
      onValue(conversation, (snapshot) => {
        const data = snapshot.val();
        if(data) {
          this.recent_message = data.recent_message
          this.isCreated = true
        }
      });
      return new Promise((resolve, reject) => {
        get(query(conversation)).then((snapshot) => {
          const data = snapshot.val();
          if(data) {
            this.recent_message = data.recent_message
            this.isCreated = true
            resolve()
          }
        })
      })
    },
    onTyping(isTyping) {
      if(this.isCreated){
        const typingMessage = ref(getDatabase(this.$firebase), `conversations/${this.userId}/typing_message`);
        const createAt = new Date().valueOf()

        update(typingMessage, {
          user_typing: isTyping,
          user_typing_at: createAt
        })
      }
    },

    setTyping(isTyping) {
      if(this.isCreated){
        const typingMessage = ref(getDatabase(this.$firebase), `conversations/${this.userId}/typing_message`);

        update(typingMessage, {
          admin_typing: isTyping,
        })
      }
    },

    setRead({ payload = true } = {}) {
      if(this.isCreated){
        const isReadChat = ref(getDatabase(this.$firebase), `conversations/${this.userId}/recent_message/is_user_read`);
        if(!this.isReadChat && !document.hidden) set(isReadChat, true)
        else if(payload && !document.hidden) {
          set(isReadChat, true)
        }
      }
    },
    
    onMessage (snapshot) {
        const data = snapshot.val();
        this.setRead({ payload: true })
        this.setListMess(data);
        this.scrollBottom()
    },

    getListMess() {
      const listMess = this.refOnMessage = query(ref(getDatabase(this.$firebase), `message/${this.userId}`), limitToLast(this.limit) );
      const adminTyping = query(ref(getDatabase(this.$firebase), `conversations/${this.userId}/typing_message`));
      onValue(listMess, this.onMessage);

      onValue(adminTyping, (snapshot) => {
        const data = snapshot.val();
        this.adminTyping = data?.admin_typing
        this.typingAt = data?.admin_typing_at
      });
    },

    setListMess(data) {
      this.preLoading = false
      this.listMess = data ? Object.values(data) : []
      this.isRender = true
      this.loadMore = false
    },

    onWindowFocus (e) {
      this.setRead()
    },

    onScroll() {
      if(window.scrollY <= 5 && !this.loadMore && this.afterScroll > window.scrollY && !this.fisrtLoad) {
        this.limit += 50
        this.loadMore = true
        this.startScroll = document.body.scrollHeight
        this.getListMess()
      }
      this.afterScroll = window.scrollY
      this.fisrtLoad = false
    },

    scrollBottom() {
      setTimeout(() => {
        const listMess = this.$refs.threadChat.$refs.listMessage
        listMess.scrollTo(0, listMess.scrollHeight)
      },100)
    },

    sendMess(data) {
      this.startScroll = 0
      this.limit = 50
      const listMess = ref(getDatabase(this.$firebase), `message/${this.userId}`);
      const listThreadChat = ref(getDatabase(this.$firebase), `conversations/${this.userId}`);
      const createAt = new Date().valueOf()
      const { email ,id , first_name_kanji, last_name_kanji, first_name_kata, last_name_kata, email_firebase } = this.userInfo
      const email_firebase_lowcase = email_firebase.toLowerCase()
      const userInfo = { email ,id , first_name_kanji, last_name_kanji, first_name_kata, last_name_kata, email_firebase: email_firebase_lowcase }
      if(data.message) {
        push(listMess, {
        message: data.message,
        type: 'text',
        user_id: this.userId,
        from: this.role,
        create_at: createAt
        });
        set(listThreadChat, {
          user_info: userInfo,
          recent_message: {
            message_laster: data.message,
            type: 'text',
            from: this.role,
            is_user_read: true,
            is_admin_read: false,
            create_at: createAt
          }
        });
        this.scrollBottom()
      }
      data.listFilesUpload.forEach(async item => {
        const formData = new FormData
        formData.append('file', item)
        const data = await this.$store.dispatch('uploadFileChat', formData)
        const { url, type, file_name } = data.data
        if(data.success) {
          push(listMess, {
          url,
          isFile: true,
          type: type.split('/')[0],
          file_name,
          user_id: this.userId,
          from: this.role,
          create_at: createAt,
          });
          set(listThreadChat, {
            user_info: userInfo,
            recent_message: {
              type: type.split('/')[0],
              isFile: true,
              from: this.role,
              is_user_read: true,
              is_admin_read: false,
              create_at: createAt,
            }
          });
          this.scrollBottom()
        } else {
          if(data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message)
        }
      });
    },
  }
}
</script>