const odoList = [
  { value: 1000, text: "1,000km", class: "noupcase" },
  { value: 5000, text: "5,000km", class: "noupcase" },
  { value: 10000, text: "10,000km", class: "noupcase" },
  { value: 20000, text: "20,000km", class: "noupcase" },
  { value: 30000, text: "30,000km", class: "noupcase" },
  { value: 40000, text: "40,000km", class: "noupcase" },
  { value: 50000, text: "50,000km", class: "noupcase" },
  { value: 60000, text: "60,000km", class: "noupcase" },
  { value: 70000, text: "70,000km", class: "noupcase" },
  { value: 80000, text: "80,000km", class: "noupcase" },
  { value: 90000, text: "90,000km", class: "noupcase" },
  { value: 100000, text: "100,000km", class: "noupcase" },
  { value: 150000, text: "150,000km", class: "noupcase" },
  { value: 200000, text: "200,000km", class: "noupcase" },
  { value: 250000, text: "250,000km", class: "noupcase" },
  { value: 300000, text: "300,000km", class: "noupcase" },
];
const listOdoFrom = [{ text: "下限走行（km）", value: null }, ...odoList];
const listOdoTo = [{ text: "上限走行（km）", value: null }, ...odoList];
export default odoList;
export { listOdoFrom, listOdoTo };
