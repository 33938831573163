export default {
  data () {
    return {
      hasInstalled: false,
      isLoadingCheck: false
    }
  },
  methods: {
    async checkExtensionInstalled (context) {
      this.isLoadingCheck = true
      this.hasInstalled = false
      if(chrome && chrome.runtime){
        const extensionId = context?.$store.getters.extensionId || this.$store.getters.extensionId
        const handle = new Promise((solver, reject) => {
          try{
            chrome.runtime.sendMessage(extensionId, { message: "ping" }, (reply) => {
              let hasExtension = false
              if (reply) {
                hasExtension = true
              }
              solver(hasExtension)
            });
          }
          catch (e) {
            reject(false)
          }
        })
        this.hasInstalled = await handle
      }
    },
    async sendToExtension(context, data, callback) {
      if(chrome && chrome.runtime){
        const extensionId = context?.$store.getters.extensionId || this.$store.getters.extensionId
        return chrome.runtime.sendMessage(extensionId, data, (reply) => {
          // Handle data reply from extension here
          callback(reply)
        });
      }
      return Promise.reject(new Error("The extension is not running!"))
    },
    sendDataRecommend(context, data) {
      return this.sendToExtension(context, { message: "send-data-recommend", payload: data })
    },
    async checkCanUseUssSite(context, data) {
      if(context.canRunUSS === null)
      return this.sendToExtension(context, { message: "check-uss", payload: data }, (reply) => {
        context.canRunUSS = reply
      })
    },
    async toPreview (e, checkExtension, mode, payload) {
      e?.preventDefault()
      // Checking for extension has installed
      const data = {
        hasInstalled: false,
        isLoadingCheck: false
      }
      await checkExtension.methods.checkExtensionInstalled.call(data, this)
      const { hasInstalled } = data
      if(!hasInstalled) {
          this.$root.$emit('bv::show::modal', 'modalCheckExtension')
        return
      }
      checkExtension.methods.handleRecommend.call(this, checkExtension, mode, payload)
    },
    cleanJSON(str) {
      return str.replaceAll("'",'"');
    },
    handleRecommend(context, mode, payload = {}) {
      let urlJSON = context.methods.cleanJSON(this.moreInfo.url)
      if(this.site.id == 2) { // ASNET
        const formData = {
          ...JSON.parse(urlJSON),
          moreInfo: JSON.stringify(payload),
        }
        formData.ai_hunt_recommend = 1
        const url = new URL('https://www.asnet.jp/asnet/')
        url.search = new URLSearchParams(formData).toString()
        if(mode === "replace") {
          window.location.href = url.href
          return
        }
        window.open(url.href, '_blank')
      }
      else if(this.site.id == 4) { // Junavi
        const formData = JSON.parse(urlJSON)
        formData.ai_hunt_recommend = 1
        const searchParams = new URLSearchParams(formData).toString()
        const url = new URL('https://www.junavi.jp/ASP/navi/JUCN020_03.asp')
        url.search = searchParams
        if(mode === "replace") {
          window.location.href = url.href
          return
        }
        window.open(url, "_blank")
      }
      else if(this.site.id == 1) { // USS
        const formData = {
          ...payload,
          ...JSON.parse(urlJSON)
        }
        formData.ai_hunt_recommend = 1
        formData.lot_no ??= this.moreInfo.lot_no 
        const options = { url: formData.url, data: formData, siteName: 'uss'}
        if(mode === "replace") {
          options.mode = "replace"
        }
        context.methods.sendDataRecommend(this, options)
      }
      else if(this.site.id == 5) { // iauc
        const targetUrl = new URL("https://www.iauc.co.jp/vehicle/")
        const url = new URL(this.moreInfo.url)
        const searchParams = new URLSearchParams(url.search)
        searchParams.append("ai_hunt_recommend", 1)
        searchParams.set("__tid", "")
        url.search = searchParams.toString()
        const targetUrlSearchParams = new URLSearchParams(targetUrl.search)
        targetUrlSearchParams.append("ai_hunt_recommend", 1)
        targetUrlSearchParams.append("data", url.href)
        targetUrl.search = targetUrlSearchParams.toString()
        if(mode === "replace") {
          window.location.href = targetUrl.href
          return
        }
        window.open(targetUrl, '_blank')
      }
    }
  }
}