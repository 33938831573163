export const Message = {
    LABLE_DATE: {
        ja: {
          labelHelp: '',
          labelNoDateSelected: '日付が選択されていません',
          labelResetButton: 'リセット',
          labelCurrentMonth: '今月',
          labelNextMonth: '来月',
          labelNextNear: '来年',
          labelPrevMonth: '先月',
          labelPrevYear: '去年',

        },
    },
    DATA_TABLE: {
        NO_ITEM: "データがありません"
    },
    LOGIN: {
        ERROR_REQUIRE: "※必須",
        EMAIL_INCORRECT: "※メールアドレスの形式が違っています",
        LOGIN_FIRST: "メール変更通知のリンクを実行するために「AIハント」システムに再ログインが必要です。"
    },
    SIGNUP: {
        ERROR_REQUIRE: "※必須",
        EMAIL_INCORRECT: "※メールアドレスの形式が違っています",
        UNCONFIRM: "サイト利用規約に同意のチェックボックスを選択してください"
    },
    SIGNUP_COMPLETE: {
        SUCCESS: 'アカウント登録用のメールを送信しました。メールが届かない場合は迷惑メールフォルダをご確認いただくか、「 ' + process.env.VUE_APP_ROOT_EMAIL_DEFAULT + ' 」 からのメールを受信できるように設定をお願いいたします。'
    },
    SIGNUP_INPUT: {
        ERROR_REQUIRE: "※必須",
        ERROR_LENGTH: "※パスワードは6～64文字で半角の英数大文字と小文字を含めてください。",
        ERROR_FORMAT: "※入力したパスワードが正しくありません。",
        ERROR_CONFIRMPASS: "※パスワードと一致しません。",
    },
    EDIT_PROFILE: {
        ERROR_REQUIRE: "※必須",
        SUCCESS: "会員情報を更新しました。",
        SUCCESS_CHANGEPASSWORD: "パスワードを変更しました",
        COMFIRMPASSWORD_ERROR: "パスワードと一致しません。",
        EMAIL_INCORRECT: "※メールアドレスの形式が違っています",
        NUMBER_ERROR: "※数字で入力してください",
        ADD_ACCOUNT_SUCCESS: "AAアカウントを追加しました",
        ADD_PLACE_SUCCESS: "会場を追加しました",
        MESSAGE_SEND_MAIL: '新しいメールアドレスへ確認用のリンクを送信しました。メールが届かない場合は迷惑メールフォルダをご確認いただくか、「' + process.env.VUE_APP_ROOT_EMAIL_DEFAULT + '」からのメールを受信できるように設定をお願いいたします。',
        ERROR_LENGTH: "※パスワードは6～64文字で半角の英数大文字と小文字を含めてください。",
        ERROR_FORMAT: "※入力したパスワードが正しくありません。",
        CONFIRM_ACOUNT: "※現在アカウントを確認しています",
        CANCEL_CONFIRM: "※申請したアカウントが拒否されました。",
        MESSAGE_ADD_ACOUNT: "出品車両の検索を支援する検索サジェスト機能（chrome用）をご利用可能です。",
        FONT_ERROR: "※全角カタカナで入力してください。",
        MESSAGE_TOOLTIP: "陸送費は必須ではありません。より精度の高い情報をご希望の場合は入力してください。",
        ERROR_TEL_NUMBER: "※電話番号が無効です",
        ERROR_ZIPCODE: "※郵便番号は半角7数字以内で入力してください",
        ADD_AASITE: "オークションアカウントの登録をお願い致します。提案車両の情報は登録後に配信されます。",
        ERROR_KANJI: "※カタカナ以外で入力してください。",
        NO_ITEM: "データがありません",
        EMAIL_EXIST: "※新しいメールアドレスは現在のメールアドレスと違う必要があります",
        LOGOUT: "ログアウトしてもよろしいでしょうか？",
        NOT_NUMBER: "数字以外で入力してください。",
        ZIP_CODE_WRONG: "※入力した郵便番号が間違っています。再入力してください。",
        PHONE_REQUIRED: "※電話番号（固定）又は電話番号（携帯）を入力してください。",
    },
    STOCK: {
        ERROR_REQUIRE: "※必須",
        NUMBER_ERROR: "※数字で入力してください",
        ERROR_NUMBER_STOCK: "※1～5の数字を半角で入力してください",
        NUMBER_EXTERIOR_SCORE: "※外装スコアーは数値で入力してください",
        NUMBER_INTERIOR_SCORE: "※内装スコアーは数値で入力してください",
        ONLY_HALFWIDTH: "半角文字で入力してください。"
    },
    FORGOT_INPUT_EMAIL: {
        ERROR_REQUIRE: "※必須",
        EMAIL_INCORRECT: "※メールアドレスの形式が違っています",
        SUCCESS: "パスワード再設定用のメールを送信しました。メールが届かない場合は迷惑メールフォルダをご確認いただくか、「ai-hunt.jp」からのメールを受信できるように設定をお願いいたします。"
    },
    PASSWORD_RESET: {
        ERROR_REQUIRE: "※必須",
        ERROR_LENGTH: "※パスワードは6～64文字で半角の英数大文字と小文字を含めてください。",
        ERROR_FORMAT: "※入力したパスワードが正しくありません。",
        ERROR_CONFIRMPASS: "※パスワードと一致しません。",
    },
    COUPON_CREATE: {
        ERROR_REQUIRE: "※必須",
        ERROR_FORMAT: "円の形式が無効です。"
    },
    RECOMMEND_CONDITION: {
        ERROR_REQUIRE: "※必須",
        NUMBER_ERROR: "※数字で入力してください",
        EXTERIOR_SCORE_START_ERROR: "※下限スコアーには上限スコアーより小さい値を入力してください",
        EXTERIOR_SCORE_END_ERROR: "※上限スコアーには下限スコアーより大きい値を入力してください",
        YEAR_START_ERROR: "※開始年式には終了年式より小さい値を入力してください",
        YEAR_END_ERROR: "※終了年式には開始年式より大きい値を入力してください",
        EXTERIOR_SCORE_START: "※下限スコアーには上限スコアーより小さい値を入力してください",
        EXTERIOR_SCORE_END: "※上限価格には下限価格より大きい値を入力してください",
        ENGINE_FROM_ERROR: "※右側の数字よりも小さい数字を入力してください。",
        ENGINE_TO_ERROR: "※左側の数字よりも大きい数字を入力してください。",
        ODO_FROM_ERROR: "※右側の数字よりも小さい数字を入力してください。",
        ODO_TO_ERROR: "※左側の数字よりも大きい数字を入力してください。",
        DELETE: "削除しても良いですか？",
        DUPLICATE: "重複しても良いですか？",
        YEAR_START_REQUIRED: "下限年式を入力してください。",
        YEAR_END_REQUIRED: "上限年式を入力してください。",
        NAME_REQUIRED: "条件のグループ名を入力してください",
        ERROR_NUMBER_SCORE: "※1～5の数字を半角で入力してください",
    },
    RECOMMEND_CONDITION_PRICE: {
        ERROR_REQUIRE: "※必須",
        NUMBER_ERROR: "※数字で入力してください",
        TO_ERROR: "※上限価格には下限価格より大きい値を入力してください",
        FROM_ERROR: "※下限価格には上限価格より小さい値を入力してください ",
        ERROR_FORMAT: "※数字で入力してください",
    },
    RECOMMEND_CONDITION_PROFIT: {
        ERROR_REQUIRE: "※必須",
        NUMBER_ERROR: "※数字で入力してください",
        TO_ERROR: "※上限粗利には下限粗利より大きい値を入力してください",
        FROM_ERROR: "※下限粗利には上限粗利より小さい値を入力してください",
    },
    CHANGE_PLAN: {
        ERROR_MIN_VALUE: "※レコメンド車両オプションの数は正の数を入力してください。",
        NUMBER_ERROR: "※数字で入力してください",
        CANCEL_PLAN: "プランを 解約しました"
    },
    ANALYZE_GRAPH: {
        REQUIRED: "※必須",
        NO_DATA: "選択した車両のデータがありません。"
    },
    CONTACT_PLAN: {
        ERROR_REQUIRE: "※必須",
        ERROR_KANJI: "※数字以外で入力してください。",
        ERROR_TEL_NUMBER: "※電話番号が無効です",
        EMAIL_INCORRECT: "※メールアドレスの形式が違っています",
    },
    CHROME_EXTENSION: {
        MESSAGE_INSTALL: "このサイトの詳細車両情報を見るためにAI-HUNTのChrome拡張をインストールしてください。",
        LINK_TO_STORE: "Chromeウェブストアからインストールする",
        RECHECK_INSTALL: "インストールの成功を確認する",
        ERROR_INSTALL: "Chrome拡張のインストールが失敗しました。"
    },
    COMMONS_ERROR: {
        EXCEPTION: "エラーが発生しました。"
    },
}