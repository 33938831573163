<template>
  <LandingPage />
</template>

<script>
import LandingPage from "@/components/LandingPage.vue";

export default {
  components: {
    LandingPage,
  },
};
</script>
