<template>
  <div class="cq-layout-main">
    <CQHeaderAuth />
    <div class="cq-content">
      <slot />
    </div>
  </div>
</template>

<script>
import CQHeaderAuth from "@/components/CQHeaderAuth.vue";

export default {
  components: {
    CQHeaderAuth,
  },
};
</script>

<style lang="scss" scoped>
.cq-layout-main {
  padding-top: 70px;
  .cq-content {
    min-height: calc(100vh - 70px);
  }
}
</style>
