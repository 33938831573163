<template>
  <div class="cq-layout-main">
    <CQHeaderGuest />
    <div class="cq-content">
      <slot />
    </div>
  </div>
</template>

<script>
import CQHeaderGuest from "@/components/CQHeaderGuest.vue";
import baseLayout from '@/utils/mixinsLayout'

export default {
  components: {
    CQHeaderGuest,
  },
  mixins: [ baseLayout ]
};
</script>

<style lang="scss" scoped>
.cq-layout-main {
  padding-top: 70px;
}
</style>
