<template>
  <div class="cq-reset d-flex justify-content-center align-items-center">
    <div class="cq-reset-box">
      <div class="cq-reset-title text-center">パスワード再設定</div>
      <form class="cq-reset-form mt-5"  v-on:submit.stop.prevent="handleReset" >
          <div class="position-relative">
          <b-input v-model="$v.formData.password.$model" :state="$v.formData.password.$error ? false : null" id="passwordReset-input-password" autocomplete="new-password" placeholder="新しい パスワード" type="password"/>
          <div v-if="$v.formData.password.$error" class="position-absolute input-error">
            <span v-if="!$v.formData.password.required">{{ message.ERROR_REQUIRE }}</span>
            <span v-else-if="!$v.formData.password.minLength || !$v.formData.password.maxLength">{{ message.ERROR_LENGTH }}</span>
          </div>
        </div>
        <div class="position-relative mt-4">
          <b-input v-model="$v.formData.passwordConfirm.$model" :state="$v.formData.passwordConfirm.$error ? false : null" id="passwordReset-input-confirmPass" autocomplete="new-password-confirm" placeholder="新しいパスワード再入力" type="password"/>
          <div v-if="$v.formData.passwordConfirm.$error" class="position-absolute input-error">
            <span v-if="!$v.formData.passwordConfirm.required">{{ message.ERROR_REQUIRE }}</span>
            <span v-else-if="!$v.formData.passwordConfirm.sameAsPassword">{{ message.ERROR_CONFIRMPASS }}</span>
          </div>
        </div>
        <b-button :disabled="isLoading" id="passwordReset-btn" class="mt-4 cq-btn-bg-green" type="submit">
          <b-spinner v-if="isLoading" small />
          変更
        </b-button>
      </form>
      <p class="mt-4">
        <router-link  id="passwordReset-link" class="cq-link-green" to="/login">ログイン画面へ戻る</router-link>
      </p>
    </div>
  </div>
</template>

<script>
import "@/assets/scss/password/password-reset.scss";
import { required , minLength, sameAs, maxLength } from "vuelidate/lib/validators";
import { Message } from '@/utils/message'

export default {
  name: "passwordReset",
  layout: "main",
  auth: false,
  head() {
    return {
      title: "パスワード再設定",
    };
  },
  data() {
    return {
      isLoading: false,
      formData: {
        password: null,
        passwordConfirm:null,
        token_reset_password: this.$route.params.token
      },
      message: Message.PASSWORD_RESET
    }
  },
  watch: {
    'formData.password'() {
      this.$v.$reset()
      this.$v.formData.password.$touch()
    },
    'formData.passwordConfirm'() {
      this.$v.$reset()
      this.$v.formData.passwordConfirm.$touch()
    },
  },
  methods: {
    async handleReset() {
      this.$toast.clear()
      this.$v.$touch()
      const result = !this.$v.$error
      if(result) {
        this.isLoading = true
        const data = await this.$store.dispatch('newPassword', this.formData)
        this.isLoading = false
        if(data.success) {
          this.$router.push('/password/reset/done')
        } else {
          if(data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message)
        }
      }
    }
  },
  validations () {
    return {
      formData: {
        password: { 
          required,
          minLength: minLength(6),
          maxLength: maxLength(64),
        },
        passwordConfirm: { 
          required,
          minLength: minLength(6),
          maxLength: maxLength(64),
          sameAsPassword: sameAs('password'),
        }
      }
    }
  }
};
</script>
