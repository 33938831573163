<template>
<div>
  <div class="footer mt-3">
    <b-table
    v-bind:items="dataTableTransaction"
    v-bind:fields="fields"
    hover
    responsive
    thead-class="header-custom"
    thClass="th-custom"
    :busy="isLoading"
  >
    <template #table-busy>
      <b-spinner variant="success" />
    </template>
    <template v-if="dataTableTransaction.length === 0" #top-row>
      <td v-bind:colspan="fields.length" class="message-no-item">{{messageTable.NO_ITEM}}</td>
    </template>
    <template #cell(is_new)="item">
      <!-- <div class="table-imgNew m-auto">
        <img v-if="isNew(item)" class="img-contain" src="@/assets/img/new.png" alt="">
      </div> -->
    </template>
    <template #cell(option_name)="{ item }">
      <div class="d-flex align-items-center justify-content-center">
        <div class="dots mr-2" :class="getClassTitle(item.option_name)"/>{{ item.option_name }}
      </div>
    </template>
    <template #cell(option_total)="{ item }">
      {{ item.option_total || 0 }}
    </template>
    <template #cell(recommend_count)="{ item }">
      <div class="table-optionUser">
        {{ item.recommend_count }}
      </div>
    </template>
    <template #cell(amount)="{ item }">
      {{ item.amount | setComma }}円
    </template>
  </b-table>
  <CQPagination
      v-if="dataTable.length"
      class="justify-content-center"
      :perPage="perPage"
      :totalRows="dataTable.length"
      v-model="page"
    />
  </div>
</div>
  
</template>

<script>
import { tableFields } from '@/utils/table-fields'
import { Message } from '@/utils/message'
import CQPagination from '../CQPagination.vue';

export default {
  name: 'TableOptionPayment',
  props: {
    msg: String,
    isLoading: {
      type: Boolean,
      default: () => true
    },
    dataTable: {
      type: Array,
      default: () => []
    }
  },
  components: {
    CQPagination
  },
  data() {
    return {
      messageTable: Message.DATA_TABLE,
      fields: tableFields.LIST_OPTION_RECOMMENT,
      dataTableTransaction: [],
      page: 1,
      perPage: 6,
    }
  },
  watch: {
    page() {
      this.dataTableTransaction = this.getDataPagination(this.dataTable, this.perPage, this.page)
    },
  },
  methods: {
    isNew(data) {
      const currentTime = new Date()
      currentTime.setHours(0,0,0,0)
      const planTime = new Date(data.item.date)
      return planTime.getTime() === currentTime.getTime() && data.index === 0
    },
    getClassTitle(title) {
      return title === '流れ車両AIアラート' ? 'green' : 'brown'
    },
    setUserOption () {
      this.dataTableTransaction = this.getDataPagination(this.dataTable, this.perPage, this.page) 
    },
    getDataPagination(listOption, page_size, page_number) {
      return listOption.slice((page_number - 1) * page_size, page_number * page_size);
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.header-custom {
  th {
    color: #C4C4C4;
    border-top: unset;
    text-align: center;
  }
}
.table {
  &-imgNew {
    width: 40px;
  }
  td {
    text-align: center;
  }
  .dots {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    &.green {
      background: #4BB543;
    }
    &.brown {
      background: #423931;
    }
  }
  &-optionUser {
    color: #F37F14;
    font-weight: 400;
  }
}
</style>