<template>
  <div class="stockList">
    <CQPreLoad :preload="preLoading" />
    <div class="stockList-header" v-if="!preLoading">
      <div class="stockList-header-title position-relative cursor-pointer">在庫情報 
        <font-awesome-icon @click="toggleSidebar" icon="fa-solid fa-circle-info cursor-pointer" class="stockList-tooltip position-absolute" />
      </div>
      <div class="stockList-header-button mt-2 mt-md-0">
          <router-link to="/stock/new-car" id="stockList-header-button-link" class="stockList-header-button-link mr-0 mr-md-2" v-bind="intro_stock[0]">新規登録</router-link>
          <router-link to="/stock/deleted-car/list" id="stockList-header-button-btn" class="stockList-header-button-btn mt-2 mt-md-0"><font-awesome-icon class="ml-2 mr-1" icon="fa-solid fa-trash" /> 削除済みの車両</router-link>
      </div>
    </div>
    <div class="container-custom mt-3 mt-lg-5">
      <div class="stockList-search" >
        <b-button v-on:click="isHiddenSearchNav = !isHiddenSearchNav" class="stockList-search-btnToggle" id="btn_stock_filter_mobile" ><font-awesome-icon icon="fa-solid fa-filter" /></b-button>
        <div class="d-none d-md-block">
        <CQFormGroupSelectCar v-model="listSearchStock" />
          <div class="row">
            <div class="col-lg-6 col-md-12 mt-2">
              <div class="row align-items-center" @click="setOptionTop($event)">
                <div class="col-lg col-6">
                  <model-select
                    v-model="listSearchStock.color"
                    :options="optionsColor"
                    size="sm"
                    id="stockList-search-color"
                    class="mt-3"
                    :is-convert2-full-width="true"
                  ></model-select>
                </div>
                <div class="col-auto col-span d-none d-lg-block"></div>
                <div class="col-lg col-6">
                  <model-select 
                    id="produced_on"
                    v-model="listSearchStock.produced_on"
                    :options="listYear"
                    class="mt-3"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-2">
              <div class="row align-items-center" @click="setOptionTop($event)">
                <div class="col-lg col-6">
                  <model-select
                    v-model="listSearchStock.min_amount"
                    :options="listPriceFrom"
                    size="sm"
                    id="stockList-search-min-price"
                    class="mt-3"
                    :max="listSearchStock.max_amount"
                  />
                </div>
                <div class="col-auto col-span d-none d-lg-block">~</div>
                <div class="col-lg col-6">
                  <model-select
                    v-model="listSearchStock.max_amount"
                    :options="listPriceTo"
                    size="sm"
                    id="stockList-search-max-price"
                    class="mt-3"
                    :min="listSearchStock.min_amount"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="stockList-search-form">
          <div class="w-100">
            <b-button id="stockList-search-form-btn-submit" 
              type="submit" 
              class="stockList-search-form-btn cq-btn-bg-green ml-2 mt-2"
              @click="searchListStock"
              autocomplete="off"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </b-button>
            <b-button id="stockList-search-form-btn-reset" 
              class="stockList-search-form-btn cq-btn-gray ml-2 mt-2"
              v-on:click="reloadDataSearch"
            >
              <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
            </b-button>
          </div>
        </div>
          <!-- form mobile -->
        <div v-if="!isHiddenSearchNav" class="stockList-search-formNav">
          <span v-on:click="isHiddenSearchNav = !isHiddenSearchNav" class="stockList-search-formNav-btncClose mb-3" ><font-awesome-icon icon="fa-solid fa-x" /></span>
            <CQFormGroupSelectCarMobile v-model="listSearchStock" />
            <model-select
            v-model="listSearchStock.color"
            :is-convert2-full-width="true"
            :options="optionsColor"
            size="sm"
            id="stockList-search-color"
            class="mt-3"
          ></model-select>
          <model-select 
              id="produced_on"
              v-model="listSearchStock.produced_on"
              :options="listYear"
              class="mt-3"
            />
          <div class="row">
            <div class="col-6">
              <model-select
                v-model="listSearchStock.min_amount"
                :options="listPriceFrom"
                size="sm"
                id="stockList-search-min-price"
                class="mt-3"
                :max="listSearchStock.max_amount"
              />
            </div>
            <div class="col-6">
              <model-select
                v-model="listSearchStock.max_amount"
                :options="listPriceTo"
                size="sm"
                id="stockList-search-max-price"
                class="mt-3"
                :min="listSearchStock.min_amount"
              />
            </div>
          </div>
          <b-button id="stockList-search-formNav-btn-submit" @click="searchListStock" type="submit" class="cq-btn-bg-green w-100 mt-3"><font-awesome-icon class="mr-2" icon="fa-solid fa-magnifying-glass" />検索</b-button>
        </div>
      </div>
      <!-- list card -->
      <div class="stockList-list mt-4 px-md-2">
        <div class="stockList-list-header d-flex justify-content-between align-items-center">
          <p class="stockList-list-header-title">
            {{rows}}台見つかりました
          </p>
          <b-form-select 
            v-model="listSearchStock.per_page"
            :options="optionsPage"
            v-on:change="searchListStock"
            id="stockList-list-header-selectPage"
            class="stockList-list-header-select mb-2" 
          />
        </div>
        <div class="stockList-list-content">
          <itemStock
            v-for="(stockData, index) in listDataStock"
            v-bind:key="index"
            :stockData="stockData"
            :index="index"
            @changeFavorited="changeFavorited"
            @editStock="editStock"
            @getIdStock="getIdStock"
            :intro="index === 0 ? intro_stock[1] : {}"
          />
        </div>
        <div v-if="!listDataStock.length" class="h4 text-secondary text-center pt-3">在庫情報がありません</div>
        <CQPagination
          v-if="listDataStock.length"
          v-model="listSearchStock.page"
          :total-rows="rows"
          :per-page="listSearchStock.per_page"
          class="justify-content-center mt-3"
        />
      </div>
      <b-modal
        id="removeStock"
        ref="removeStock"
        modal-class="modal-confirm modal-confirm-sm"
        body-class="p-md-4 p-3" 
        hide-footer
        hide-header
        centered
        >
        <div class="d-block text-center w-100 mb-5">
          <h1 class="text-warning">
            <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
          </h1>
          <p>削除してもよろしいですか？</p>
        </div>
        <div class="mt-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap">
          <b-button class="btn-bot" v-on:click="$bvModal.hide('removeStock')" id="btn_stock_hide_modal_remove">いいえ</b-button>
          <b-button class="cq-btn-bg-red btn-top" v-on:click="deleteStock" id="btn_stock_confirm_modal_remove">はい</b-button >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import itemStock from '@/components/stock/item.vue';
import CQPagination from '@/components/CQPagination.vue';
import '@/assets/scss/stock/stockList.scss'
import { Message } from '@/utils/message'
import CQPreLoad from "@/components/CQPreLoad.vue";
import { convertNumber } from "@/utils/commonEvent";
import { listPriceFrom, listPriceTo } from "@/utils/priceList"
import listYear from "@/utils/listYear"
import CQFormGroupSelectCar from '@/components/CQFormGroupSelectCar.vue';
import CQFormGroupSelectCarMobile from '@/components/CQFormGroupSelectCarMobile.vue';
import intro, { default_configuration } from '@/utils/intro';

export default {
  name:'stock',
  layout:'user',
  auth: 'user',
  mixins: [convertNumber],
  head() {
    return {
      title: "在庫情報一覧",
    };
  },
  components: {
    CQPreLoad,
    CQPagination,
    itemStock,
    CQFormGroupSelectCar,
    CQFormGroupSelectCarMobile,
  },
  data() {
    return {
      prevHtml: '<b-button class="prev-btn" id="btn_stock_prev">戻る</b-button>',	
      nextHtml: '<b-button class="next-btn" id="btn_stock_next">次へ</b-button>',
      messageDate: Message.LABLE_DATE,
      preLoading: true,
      rows: 20,
      ishiddenItem_1: false,
      refInside: '4.9',
      refOutside: '4.0',
      prices: '250万円',
      idStock: 0,
      ishiddenStar_1: false,
      isHiddenSearchNav: true,
      listDataStock: [],
      optionsBrands:[{
        text: "メーカー",
        value: null,
      }],
      optionsCarName: [{
        text: "車名",
        value: null,
      }],
      optionsModel: [{
        text: "型式",
        value: null,
      }],
      optionsGrade: [{
        text: "グレード",
        value: null,
      }],
      optionsColor: [{
        text: "色",
        value: null,
      }],
      selectedDate: null,
      inputMinPrice: null,
      inputMaxPrice: null,
      optionsPage: [
        { value: 5, text: "5件" },
        { value: 10, text: "10件" },
        { value: 20 , text: "20件"}
      ],
      listSearchStock: {
        core_brand_id: null,
        core_car_id: null,
        model: null,
        core_model_ids: [],
        core_grade_id: null,
        core_grade_ids: [],
        color: null,
        produced_on: null,
        min_amount: null,
        max_amount: null,
        page: 1,
        per_page: 10,
      },
      listSearchStockBase: {},
      numberCheck: 0,
      listPriceFrom,
      listPriceTo,
      listYear,
      intro_stock: intro.stock_list
    }
  },
  computed: {
    dataMaster () {
      return this.$store.getters.carMap
    }
  },
  watch: {
    'listSearchStock.page'() {
      this.searchListStock()
    },
  },
  async created() {
    await this.getListStock();
    this.fetchDataMaster()
    if (!this.listDataStock.length) {
      this.introJs().setOptions(default_configuration).start()
    }
    this.listSearchStockBase = {...this.listSearchStock}
  },
  methods: {
    toggleSidebar () {
      this.introJs().setOptions(default_configuration).start()
    },
    async getListStock() {
      const listStock = await this.$store.dispatch('searchListStock');
      if (listStock.success) {
        this.listDataStock = listStock.data.stocks;
        this.rows = listStock.data.meta.total_objects;
        this.preLoading = false;
      } else {
        if (listStock.errors.length && listStock.errors[0].message) this.$toast.error(listStock.errors[0].message);
      };
    },
    async fetchDataMaster () {
      await this.dataMaster.loader
      const years = this.dataMaster.years
      this.listYear = [
        ...this.listYear,
        ...years
      ]
      this.dataMaster.colors.forEach(colors => {
        this.optionsColor.push({ value: colors.id, text: colors.name });
      });
      this.dataMaster.brands.forEach(brands => {
        this.optionsBrands.push({ value: brands.id, text: brands.name, core_country_id: brands.core_country_id });
      });
    },
    reloadDataSearch() {
      this.listSearchStock = { ...this.listSearchStockBase }
      this.searchListStock();
    },
    getIdStock(id) {
      this.idStock = id
    },
    async changeFavorited(idStock) {
      const objStock = this.listDataStock.find(x => x.id === idStock);
      objStock.is_favorited = !objStock.is_favorited;
      const dataUpdate = {
        id: idStock,
        data: {"favorite": (objStock.is_favorited) ? 1 : 0}
      }
      const updateFavorited = await this.$store.dispatch('updateFavorited', dataUpdate);
      if (updateFavorited.success) {
        console.log(updateFavorited.message);
      } else {
        if (updateFavorited.errors.length && updateFavorited.errors[0].message) this.$toast.error(updateFavorited.errors[0].message);
      };
    },
    editStock (id) {
      this.$router.push(`/stock/car/${id}/edit`);
    },
    async searchListStock() {
      this.isHiddenSearchNav = true
      const listSearch = await this.$store.dispatch('searchListStock', {
        params: this.listSearchStock
      });
      if (listSearch.success) {
        this.listDataStock = listSearch.data.stocks;
        this.rows = listSearch.data.meta.total_objects;
      } else {
        if (listSearch.errors.length && listSearch.errors[0].message) this.$toast.error(listSearch.errors[0].message);
      };
    },
    async deleteStock() {
      this.ishiddenItem_1 = true;
      const deleteStockCar = await this.$store.dispatch('deleteStock', this.idStock);
      if (deleteStockCar.success) {
        this.$refs['removeStock'].hide();
        this.getListStock();
      } else {
        if (deleteStockCar.errors.length && deleteStockCar.errors[0].message) this.$toast.error(deleteStockCar.errors[0].message);
      };
    },
    isNumber: function(evt) {
      if (Number(evt) && evt.indexOf('.') > -1) {
        return true;
      }
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  }
}
</script>