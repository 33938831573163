<template>
  <div class="cq-analyze p-4">
    <h3 class="d-block d-md-none text-center">
      {{ aaCarInfo.name }}の推移グラフ
    </h3>
    <div class="header">
      <div class="btn-filter">
        <b-button class="d-flex align-items-center" variant="warning" @click="reOpenModalAnalyze()">
          <font-awesome-icon icon="fa-solid fa-filter" class="mr-2" />
          <span class="d-none d-md-block"> フィルター </span>
        </b-button>
      </div>
      <h3 class="d-none d-md-block col text-center">
        {{ aaCarInfo.name }}の推移グラフ
      </h3>
      <div class="content-bar">
        <b-dropdown id="dropdown-left" class="m-2" no-caret right @show="getDatasetMeta">
          <template #button-content>
            <font-awesome-icon icon="fa-solid fa-bars" />
          </template>
          <b-dropdown-item link-class="py-3" :variant="(!maOptions.ma25.hidden && !maOptions.ma90.hidden && !maOptions.ma200.hidden ? 'success': '')"
            @click.native.capture.stop="setMaAll()"
            >最低価格、最高価格、平均価格
          </b-dropdown-item>
          <b-dropdown-item href="#" link-class="py-3" :variant="(!maOptions.ma25.hidden ? 'success': '')"
            @click.native.capture.stop="setMa(['ma25'])"
            >短期移動平均線（MA25)</b-dropdown-item
          >
          <b-dropdown-item href="#" link-class="py-3" :variant="(!maOptions.ma90.hidden ? 'success': '')"
            @click.native.capture.stop="setMa(['ma90'])"
            >中期移動平均線（MA90)</b-dropdown-item
          >
          <b-dropdown-item href="#" link-class="py-3" :variant="(!maOptions.ma200.hidden ? 'success': '')"
            @click.native.capture.stop="setMa(['ma200'])"
            >長期移動平均線（MA200)</b-dropdown-item
          >
        </b-dropdown>
      </div>
    </div>
    <div class="mt-4 position-relative">
      <div class="d-flex tab-graph">
        <div
          class="col-auto item cursor-pointer"
          :class="{ 'text-warning': option.value === currentOptionDate }"
          v-for="(option, i) in optionsDate"
          :key="i"
          @click="setOptionDate(option.value)"
        >
          {{ option.text }}
        </div>
      </div>
    </div>
    <div class="title-chart d-block d-md-none mt-4">
      {{ title }}
    </div>
    <div class="mt-4 d-flex align-items-center">
      <div class="col-auto title-chart p-0 d-none d-md-block">
        {{ title }}
      </div>
      <div class="col p-0">
        <canvas ref="chart"></canvas>
      </div>
    </div>
    <CQPreLoad :preload="isLoading" />
  </div>
</template>
<script>
import "@/assets/scss/analyze/index.scss";
import CQPreLoad from "@/components/CQPreLoad.vue";
import { Message } from "@/utils/message";
import moment from "moment";
import "chartjs-adapter-moment"
export default {
  layout: "user",
  auth: "user",
  head() {
    return {
      title: "分析支援機能",
      meta: [
        { charset: "utf-8" },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
        },
      ],
    };
  },
  components: {
    CQPreLoad,
  },
  data() {
    return {
      config: {
        type: "line",
        data: {
          labels: [],
          datasets: [
            {
              label: "最低",
              borderColor: "#ec8036",
              backgroundColor: "#ec8036",
              data: [],
              tension: 0.4,
            },
            {
              label: "平均",
              borderColor: "#0677db",
              backgroundColor: "#0677db",
              data: [],
              tension: 0.4,
            },
            {
              label: "最高",
              borderColor: "#5DAF5D",
              backgroundColor: "#5DAF5D",
              data: [],
              tension: 0.4,
            },
            {
              label: "長期移動平均",
              borderColor: "#cc65fe",
              backgroundColor: "#cc65fe",
              data: [],
              tension: 0.4,
            },
            {
              label: "中期移動平均",
              borderColor: "#7308e9",
              backgroundColor: "#7308e9",
              data: [],
              tension: 0.4,
            },
            {
              label: "短期移動平均",
              borderColor: "#ffce56",
              backgroundColor: "#ffce56",
              data: [],
              tension: 0.4,
            },
          ],
        },
        options: {
          scales: {
            x: {
              type: "time",
              beginAtZero: true,
              time: {
                tooltipFormat: "YYYY-MM-DD",
                unit: 'day',
                displayFormats: {
                  'month': 'MM月',
                  'day': 'DD日'
                }
              },
            },
          },
        },
      },
      days: [],
      data: [0, 10, 5, 2, 20, 30, 45],
      title: "価格（円）",
      isLoading: true,
      message: Message.ANALYZE_GRAPH,
      coreCars: [],
      coreVenues: [],
      optionsDate: [
        {
          text: "7日間",
          value: "1",
        },
        {
          text: "1ヶ月",
          value: "2",
        },
        {
          text: "6ヶ月",
          value: "3",
        },
        {
          text: "1年間",
          value: "4",
        },
      ],
      currentOptionDate: "1",
      chartContext: null,
      maOptions: {
        ma25: {
          hidden: false,
        },
        ma90: {
          hidden: false,
        },
        ma200: {
          hidden: false,
        },
      }
    };
  },
  computed: {
    aaCarId() {
      return this.$route.params.id;
    },
    coreCarId: {
      get() {
        return this.$route.query.core_car_id;
      },
    },
    aaCarInfo() {
      return this.coreCars.find((car) => car.id == this.coreCarId) || {};
    },
  },
  watch: {
    currentOptionDate() {
      this.setConfig();
      this.refreshData();
    },
    maOptions: {
      handler() {
        this.chartContext.data.datasets[3].hidden = this.maOptions.ma200.hidden || null
        this.chartContext.data.datasets[4].hidden = this.maOptions.ma90.hidden || null
        this.chartContext.data.datasets[5].hidden = this.maOptions.ma25.hidden || null
        this.chartContext.update()
      },
      deep: true,
    },
    '$route': {
      handler() {
        this.refreshData();
      },
      deep: true,
    }
  },
  mounted() {
    this.fetchData().then(() => {
      const Chart = this.Chart;
      this.chartContext = new Chart(this.$refs.chart, this.config);
    });
  },
  methods: {
    setConfig() {
      const option = this.currentOptionDate;
      let key = "";
      if (option === "3" || option === "4") {
        key = "month";
      } else {
        key = "day";
      }
      this.config.options.scales.x.time.unit = key
    },
    refreshData() {
      this.fetchData().then(() => {
        this.chartContext.data = this.config.data;
        this.chartContext.option = this.config.option;
        this.chartContext.update();
      });
    },
    async fetchData() {
      this.isLoading = true;
      const dataMaster = this.$store.getters.carMap;
      await dataMaster.loader;
      this.coreCars = dataMaster.cars;
      const res = await this.$store.dispatch("getDataChart", {
        params: { ...this.$route.query, date: this.currentOptionDate },
      });
      if (res.success) {
        if (!res.data?.final_price_array) {
          this.isLoading = false;
          this.config.data.labels = [];
          const ma25 = res.data?.ma?.ma25
          const ma90 = res.data?.ma?.ma90
          const ma200 = res.data?.ma?.ma200
          if(!ma25 && !ma90 && !ma200) {
            if(res.data?.message) this.$toast.success(res.data.message);
            else this.$toast.success(this.message.NO_DATA);
          }
          this.config.data.datasets.forEach(set => set.data = [])
          if(ma25) this.buildDataSet(5, ma25);
          if(ma90) this.buildDataSet(4, ma90);
          if(ma200) this.buildDataSet(3, ma200);
          return;
        }
        const { final_price_array, ma } = res.data
        const min_array = final_price_array.min_final_price
        const avg_array = final_price_array.avg_final_price
        const max_array = final_price_array.max_final_price
        const ma25 = ma.ma25
        const ma90 = ma.ma90
        const ma200 = ma.ma200
        this.buildDataSet(0, min_array);
        this.buildDataSet(1, avg_array);
        this.buildDataSet(2, max_array);
        this.buildDataSet(3, ma200);
        this.buildDataSet(4, ma90);
        this.buildDataSet(5, ma25);
      } else {
        if (res.errors.length && res.errors[0].message)
          this.$toast.error(res.errors[0].message);
      }
      this.isLoading = false;
    },
    buildDataSet(index, dataBase) {
      this.config.data.datasets[index].data = dataBase.map((point) => {
        /**
         * point: Array
         * Example point [1651622400, 35052]
         * 1651622400 => Timestamp (second)
         * 35052 => data point
         */
        return {
          x: moment(point[0]).format(),
          y: point[1],
        };
      });
    },
    setLabel(dataBase) {
      this.config.data.labels = dataBase.map(([date,]) => date);
    },
    setOptionDate(date) {
      this.currentOptionDate = date;
    },
    setMa(maArr) {
      maArr.forEach((ma) => {
        this.maOptions[ma].hidden = !this.maOptions[ma].hidden
      })
    },
    setMaAll() {
      const listMa = ['ma25', 'ma90', 'ma200']
      let checkedMaAll = false
      listMa.forEach(ma => {
        if(!this.maOptions[ma].hidden) {
          checkedMaAll = true
        }
      });
      if(checkedMaAll) {
        listMa.forEach(ma => {
          this.maOptions[ma].hidden = true
        });
      }
      else {
        listMa.forEach(ma => {
          this.maOptions[ma].hidden = false
        });
      }
      this.maOptions = {...this.maOptions}
    },
    setCheckedVenueAll () {
      const checked = !this.isVenueCheckedAll
      this.coreVenues.forEach((_) => { 
        _.checked = checked;
      })
    },
    getDatasetMeta() {
      this.maOptions.ma200.hidden = !this.chartContext.getDatasetMeta(3).visible
      this.maOptions.ma90.hidden = !this.chartContext.getDatasetMeta(4).visible
      this.maOptions.ma25.hidden = !this.chartContext.getDatasetMeta(5).visible
    },
    reOpenModalAnalyze() {
      this.$store.commit('set', ['analyzeGetDataOld',true])
      this.$bvModal.show('modal-analyze')
    }
  },
};
</script>
