import axios from "axios";

const requestServer = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  timeout: 600000,
  headers: {
    // "Content-Type": "application/json",
  },
  // onUploadProgress: progressEvent => console.log(progressEvent.loaded),
  // onDownloadProgress: progressEvent => console.log(progressEvent.loaded)
});

requestServer.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token_user');

    if (token) {
      if(!config.headers.Authorization) {
        config.headers.Authorization = `Bearer ${token}`;
      }
    } else {
      delete requestServer.defaults.headers.common.Authorization;
    }
    return config;
  },

  (error) => Promise.reject(error)
);

requestServer.interceptors.response.use(function (response) {
  return response;
}, function (error) {
  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  // Modify error 
  error.response.data ||= {}
  error.response.data.errors = []
  return Promise.reject(error);
});

export const Api = {
  requestServer,
};
