<template>
  <b-modal
    :id="modalId"
    :ref="modalId"
    modal-class="modal-confirm modal-confirm-sm"
    body-class="p-md-4 p-3"
    hide-footer
    centered
    title="お問い合わせ"
  >
    <div class="d-block text-center title w-100">
      <div class="row">
        <div class="col-12 col-lg-6 mt-4 position-relative">
          <b-input
            placeholder="氏名"
            v-model="$v.formContact.full_name.$model"
            :state="$v.formContact.full_name.$error ? false : null"
            :disabled="userInfo.first_name_kanji ? true: false"
            id="formContact_name"
            autocomplete="off"
          />
          <div
            v-if="$v.formContact.full_name.$error"
            class="input-error text-left position-absolute ml-3"
          >
            <span v-if="!$v.formContact.full_name.required">{{
              messageContact.ERROR_REQUIRE
            }}</span>
            <span v-else-if="!$v.formContact.full_name.isWrong">{{
              messageContact.ERROR_KANJI
            }}</span>
          </div>
        </div>
        <div class="col-12 col-lg-6 mt-4">
          <b-input
            autocomplete="off" 
            placeholder="電話番号（固定）" 
            @keypress="isInteger"
            v-model="formContact.phone_number" 
            :disabled="userInfo.tel_mobile ? true: false"
            class="w-100"
            maxlength="11"
          ></b-input>
        </div>
        <div class="col-12 mt-4" @click="setOptionTop($event)">
          <model-select
            :options="listOptionsType"
            v-model="selectContactType"
            id="select_plan_contactPlanId"
            :isDisabled="true"
          />
        </div>
        <div class="col-12 mt-3">
          <b-textarea
            placeholder="メッセージ"
            v-model="$v.formContact.note.$model"
            :state="$v.formContact.note.$error ? false : null"
            :rows="5"
          />
          <div
            v-if="$v.formContact.note.$error"
            class="input-error text-left"
          >
            <span v-if="!$v.formContact.note.required">{{
              messageContact.ERROR_REQUIRE
            }}</span>
          </div>
        </div>
        <div class="col-12 mt-3">
          <b-input
            placeholder="メールアドレス"
            v-model="$v.formContact.email.$model"
            id="formContact_email"
            :state="$v.formContact.email.$error ? false : null"
            :disabled="userInfo.email ? true: false"
            autocomplete="off"
          />
          <div
            v-if="$v.formContact.email.$error"
            class="input-error text-left"
          >
            <span v-if="!$v.formContact.email.required">{{
              messageContact.ERROR_REQUIRE
            }}</span>
            <span v-else-if="!$v.formContact.email.email">{{
              messageContact.EMAIL_INCORRECT
            }}</span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="
        mt-3 mt-md-4
        cq-plan_mypage-infoplan-groupBtn
        d-flex
        justify-content-between
        flex-wrap
      "
    >
      <button
        class="cq-btn-bg-green btn-top btn btn-secondary w-100"
        @click="handleContactPlan"
        :disabled="contactPlanIsLoading"
        id="btn_mypage_plan_contact_submit"
      >
        <b-spinner v-if="contactPlanIsLoading" small />
        送信する
      </button>
    </div>
  </b-modal>
</template>
<script>
import { convertNumber, regexKanji, isInteger } from "@/utils/commonEvent"
import { required, email } from 'vuelidate/lib/validators'
import { Message } from '@/utils/message';
const notNumber = function (evt) {
  const test = (evt) ? evt.split('') : '';
  if (!test) return true;
  for (let index = 0; index < test.length; index++) {
    if (Number(test[index])) {
      return false;
    }
  }
  return true;
}
export default {
  mixins: [convertNumber],
  props: {
    modalId: {
      type: String,
      required: true
    },
    typeStr: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      contactPlanIsLoading: false,
      formContact: {
        full_name: null,
        phone_number: null,
        email: null,
        note: "",
        contact_type: this.typeStr
      },
      messageContact: Message.CONTACT_PLAN,
      baseFormContact: {},
      selectContactType: null,
      telOfficeErr: false,
    }
  },
  computed: {
    listOptionsType () {
      return [{
        value: null,
        text: this.typeStr
      }]
    },
    userInfo() {
      return this.$store.getters.userInfo
    }
  },
  created () {
    this.baseFormContact = { ...this.formContact }
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === this.modalId) {
        this.telOfficeErr = false
        this.$v.$reset()
        this.formContact = { ...this.baseFormContact }
      }
      this.fetchUserInfo()
    })
  },
  methods: {
    async fetchUserInfo () {
      await this.userInfo.handler
      const userInfo = this.userInfo.data
      if(userInfo.first_name_kanji || userInfo.last_name_kanji) {
        this.formContact.full_name = `${userInfo.first_name_kanji} ${userInfo.last_name_kanji}`
      }
      this.formContact.phone_number = userInfo.tel_mobile
      this.formContact.email = userInfo.email
    },
    async handleContactPlan () {
      this.$v.$touch()
      const result = !this.$v.formContact.$error && !this.telOfficeErr
      if(result) {
        this.contactPlanIsLoading = true
        const res = await this.$store.dispatch('sendContact', { body: this.formContact })
        if(res.success) {
          this.$toast.success(res.message)
        }
        else {
          if(res.errors.length && res.errors[0].message) this.$toast.error(res.errors[0].message)
        }
        this.$bvModal.hide(this.modalId)
        this.contactPlanIsLoading = false
      }
    },
    addDashes(phone, field, length){
      const checkPhone = /\(?([0-9]{3})\)?([ .-]?)([0-9]{3})\2([0-9]{4})/;
      if (phone) {
        phone = phone.replaceAll("-", "");
        if(phone.length === 0){
          this.telOfficeErr = false
          return
        }
        if (checkPhone.test(phone) && length === phone.length) {
          this.telOfficeErr = false
          this.formContact[field] = phone.slice(0,3) + "-" + ((field === "tel_office") ? phone.slice(3,7) : phone.slice(3,6)) + "-" + 
          ((field === "tel_office") ? phone.slice(7) : phone.slice(6));
        } else this.telOfficeErr = true
      }
    },
    isInteger
  },
  validations () {
    return {
      formContact: {
        full_name: {
          isWrong: notNumber,
          required
        },
        email: {
          required,
          email
        },
        note: {
          required,
        }
      }
    }
  }
}
</script>