<template>
  <div class="text-center item">
    <h6 class="title">
      {{title}}
    </h6>
    <div class="number-recommend">
      <div class="number-recommend-start">
        {{number}}
      </div>
      <div class="number-recommend-end">
        /{{total}}{{fallbackTotal}}
      </div>
    </div>
    <h6 :class="`text-${typeVarian}`" class="description">{{description}}</h6>
  </div>
</template>
<script>
export default {
  props: {
    number: {
      type: Number,
      required: true
    },
    total: {
      type: Number,
      required: true
    },
    typeVarian: {
      type: String,
      default () {
        return 'warning'
      }
    },
    title: {
      type: String,
      default () {
        return ''
      }
    },
    description: {
      type: String,
      default () {
        return ''
      }
    },
    fallbackTotal: {
      type: String,
      default () {
        return '台'
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.item {
  .title, .description {
    letter-spacing: 3px;
  }
  .number-recommend {
    display: flex;
    justify-content: center;
    align-items: baseline;
    margin: 0.7rem 0.0rem;
    column-gap: 3px;
    &-start, &-end {
      font-weight: bold;
      color: var(--secondary);
      letter-spacing: 3px;
    }
    &-start {
      font-size: 2.5rem;
    }
    &-end {
      font-size: 1.7rem;
    }
  }
  .description {
    font-weight: 700;
  }
}
</style>