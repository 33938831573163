<template>
  <div class="recommend-car-price-new cq-bg-gray pt-3 position-relative">
    <div class="recommend-car-price-new-title">価格帯条件を編集</div>
    <div class="d-flex position-absolute alert-conditions">
      <div class="alert-conditions-icons" @click="showAlert = !showAlert">
        <font-awesome-icon v-if="showAlert"
          class="ml-2" icon="fa-solid fa-angle-right" 
        />
        <font-awesome-icon v-else
          class="ml-2" icon="fa-solid fa-angle-left" 
        />
      </div>
      <div class="alert-conditions-text text-center" v-if="showAlert">
        <p>同様の条件で</p>
        <p>過去１ヶ月で</p>
        <p class="text-warning">1,300件</p>
        <p>リコメンドしました</p>
      </div>
    </div>
    <div class="container-custom mt-3">
      <b-form class="recommend-car-price-new-form">
        <div class="recommend-car-price-new-form-content mt-3">
          <div class="recommend-car-price-new-form-content-items w-100 position-relative">
            <label for="formData_name">条件名 <span class="text-danger">※</span>
              <font-awesome-icon
                v-b-tooltip.hover
                title="名前をつけることで他のリコメンド条件でも再利用が可能です" 
                class="text-secondary ml-2" icon="fa-solid fa-circle-info" 
              />
            </label>
            <b-input
              class=""
              size="sm"
              v-model="$v.formData.name.$model"
              id="formData_name"
              placeholder="例）価格条件１"
              autocomplete="off"
            ></b-input>
            <div v-if="$v.formData.name.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.name.required">{{ message.ERROR_REQUIRE }}</span>
            </div>
          </div>
        </div>
        <div class="recommend-car-price-new-form-title mt-3">価格帯<span>（単位：万円）</span></div>
          <div class="recommendNew-form-content-items w-100 mt-3 d-flex">
            <div class="stockNewCar-form-content-items position-relative">
              <b-input
                class=""
                size="sm"
                v-model="$v.formData.from.$model"
                v-on:keyup="convertPositive(formData.from, 'from')"
                maxLength="4"
                id="formData_from"
                placeholder="例）250"
                autocomplete="off"
              ></b-input>
              <div v-if="$v.formData.from.$error" class="position-absolute input-error">
                <span v-if="!$v.formData.from.decimal">{{ message.NUMBER_ERROR }}</span>
                <span v-else-if="!$v.formData.from.minValue">{{ message.NUMBER_ERROR }}</span>
              </div>
              <div v-else-if="fromError && formData.to && formData.from" class="position-absolute input-error">
                <span>{{ fromError }}</span>
              </div>
            </div>
            <span class="pl-2 pr-2 pt-3 span-date">~</span>
            <div class="stockNewCar-form-content-items position-relative">
              <b-input
                class=""
                size="sm"
                v-model="$v.formData.to.$model"
                v-on:keyup="convertPositive(formData.to, 'to')"
                id="formData_to"
                maxLength="4"
                placeholder="例）350"
                autocomplete="off"
              ></b-input>
              <div v-if="$v.formData.to.$error" class="position-absolute input-error">
                <span v-if="!$v.formData.to.decimal">{{ message.NUMBER_ERROR }}</span>
                <span v-else-if="!$v.formData.to.minValue">{{ message.NUMBER_ERROR }}</span>
              </div>
              <div v-else-if="toError && formData.to && formData.from" class="position-absolute input-error">
                <span>{{ toError }}</span>
              </div>
            </div>
          </div>
        <div class="w-100 mt-5 mb-4 d-flex justify-content-center">
          <b-button
            variant="secondary"
            class="w-50 btn-confirm mr-5 mt-5"
            v-on:click="$router.back()"
            id="btn_stock_new_return"
          >
            キャンセル
          </b-button>
          <b-button
            id="btn_recommend_condition_car_price_edit_save"
            class="cq-btn-bg-green cq-border-green w-50 mt-5"
            v-on:click="updateCarPrice()"
            :disabled="formData.from === '' || formData.to === '' || toError || fromError || $v.formData.$error">
            編集する
          </b-button>
        </div>
      </b-form>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/recommend-condition/newPrice.scss'
import { Message } from '@/utils/message'
import { required, decimal, minValue } from 'vuelidate/lib/validators'
import { convertNumber } from "@/utils/commonEvent";

export default {
  name: 'recommend-condition-car-price-edit',
  layout: 'user',
  auth: "user",
  mixins: [convertNumber],
  head() {
    return {
      title: "価格帯を編集",
    };
  },
  data() {
    return {
      message: Message.RECOMMEND_CONDITION_PRICE,
      showAlert: true,
      fromError: "",
      toError: "",
      formData: {
        name: null,
        from: null,
        to: null
      }
    }
  },
  watch: {
    'formData.from'() {
      this.toError = "";
      ((this.formData.to * 1) <= (this.formData.from* 1) && this.formData.to) ? this.fromError = this.message.FROM_ERROR : this.fromError = "";
    },
    'formData.to'() {
      this.fromError = "";
      ((this.formData.to * 1) <= (this.formData.from* 1) && this.formData.to) ? this.toError = this.message.TO_ERROR : this.toError = "";
    },
  },
  created() {
    this.getCarPriceDetail();
  },
  methods: {
    async getCarPriceDetail() {
      const idCarPrice = this.$route.params.id;
      const getCarPriceDetail = await this.$store.dispatch('getCarPriceDetail', idCarPrice);
      if (getCarPriceDetail.success) {
        this.formData = {
          ...this.formData,
          ...getCarPriceDetail.data.rec_cond_price,
          from: getCarPriceDetail.data.rec_cond_price.from / 10000,
          to: getCarPriceDetail.data.rec_cond_price.to / 10000
        };
      } else {
        if (getCarPriceDetail.errors.length && getCarPriceDetail.errors[0].message) this.$toast.error(getCarPriceDetail.errors[0].message);
      };
    },
    async updateCarPrice() {
      this.$toast.clear();
      this.$v.formData.$touch();
      let result = !this.$v.formData.$error;
      if (result && !this.fromError && !this.toError) {
        const dataRequest = {...this.formData};
        dataRequest.from = parseInt(dataRequest.from * 10000)
        dataRequest.to = parseInt(dataRequest.to * 10000)
        const updateCarPrice = await this.$store.dispatch('updateCarPrice', dataRequest);
        if (updateCarPrice.success) {
          this.$toast.success(updateCarPrice.message);
          this.$router.push('/recommend-condition/group/list')
        } else {
          if (updateCarPrice.errors.length && updateCarPrice.errors[0].message) this.$toast.error(updateCarPrice.errors[0].message);
        };
      }
    },
  },
  validations () {
    return {
      formData: {
        name: {required},
        from: { decimal, minValue: minValue(0) },
        to: { decimal, minValue: minValue(0) },
      },
    }
  }
}
</script>