import { Message } from '@/utils/message'
export default {
  computed: {
    isError () {
      return this.$store.getters.isError
    }
  },
  watch: {
    isError (isError) {
      if(isError) {
        this.$toast.error(Message.COMMONS_ERROR.EXCEPTION, {
          onDismiss: () => {
          this.$store.commit('set', ['isError', false])
          }
        })
      }
    }
  },
  mounted () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      this.$store.commit('pushModalOpenQueued', modalId)
    })
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      this.$store.commit('deleteModalOpenQueued', modalId)
    })
    this.$root.$on('closeAllModal', () => {
      const modals = this.$store.getters.modalOpenQueued
      modals.forEach(modalId => {
        this.$root.$emit('bv::hide::modal', modalId)
      });
    })
  }
}