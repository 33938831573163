<template>
  <div class="row custom-pagination align-items-center">
    <div class="col-auto p-0 p-md-2">
      <button class="btn d-flex align-items-center" @click="toPage(1)" :disabled="value == 1" id="btn_pagination_button_control_first">
        <font-awesome-icon icon="fa-solid fa-backward" class="mr-2" />
        <span class="custom-pagination-text">
          先頭へ
        </span>
      </button>
    </div>
    <div class="col-auto p-0 p-md-2">
      <button class="btn d-flex align-items-center" @click="toPage(value - 1)" :disabled="value <= 1" id="btn_pagination_button_control_prev">
        <font-awesome-icon icon="fa-solid fa-play" class="mr-2 r-180 icon-sm" />
        <span class="custom-pagination-text">
          戻る
        </span>
      </button>
    </div>
    <div class="col-auto p-0 p-md-2">
      <select class="form-control custom-pagination-input" @change="onChange">
        <option
          v-for="index in totalPage"
          :value="index"
          :key="index"
          :selected="index === value"
        >
          {{ index }} / {{ totalPage }}
        </option>
      </select>
    </div>
    <div class="col-auto p-0 p-md-2">
      <button class="btn d-flex align-items-center" @click="toPage(value + 1)" :disabled="value >= totalPage" id="btn_pagination_button_control_next">
      <span class="custom-pagination-text">
        次へ
      </span>
        <font-awesome-icon icon="fa-solid fa-play" class="ml-2 icon-sm"/>
      </button>
    </div>
    <div class="col-auto p-0 p-md-2">
      <button class="btn d-flex align-items-center" @click="toPage(totalPage)" :disabled="value == totalPage" id="btn_pagination_button_control_last">
      <span class="custom-pagination-text">
        最終へ
      </span>
       <font-awesome-icon icon="fa-solid fa-forward" class="ml-2"/>
      </button>
    </div>
  </div>
</template>
<style scoped lang="scss">
.custom-pagination {
  &-input {
    width: max-content;
    border: 1px solid !important;
  }
  .r-180 {
    transform: rotate(180deg);
  }
  .icon-sm {
    width: 1rem;
    height: 1rem;
  }
  .custom-pagination-text {
    display: none;
    @media only screen and (min-width: 800px) {
      display: block;
    } 
  }
}
</style>
<script>
export default {
  props: {
    totalRows: {
      type: Number,
      default() {
        return 1;
      },
    },
    perPage: {
      type: Number,
      default() {
        return 10;
      },
    },
    value: {
      type: Number,
      default() {
        return 1;
      },
    },
  },
  computed: {
    totalPage() {
      return Math.ceil(this.totalRows / this.perPage) || 1;
    },
  },
  emits: ["input"],
  methods: {
    onChange($event) {
      this.$nextTick(() => {
        this.$emit("input", parseInt($event.target.value));
      });
    },
    toPage (page) {
      this.$nextTick(() => {
        this.$emit("input", parseInt(page));
      });
    }
  },
};
</script>