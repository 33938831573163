<template>
  <div id="app">
    <component v-bind:is="layoutComponent">
      <router-view />
    </component>
    <payjp-checkout
      :api-key="payjpKey"
      class="wrap-payment-btn d-none"
      text="ポップアップを開く"
      submit-text="このカードを登録する"
      name-placeholder="JOHN DOE"
      @created="onTokenCreated"
      @failed="onTokenFailed"
    >
    </payjp-checkout>
  </div>
</template>

<script>
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "vue-toast-notification/dist/theme-sugar.css";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import "./assets/scss/main.scss";

export default {
  data() {
    return {
      layoutComponent: null,
      payjpKey: process.env.VUE_APP_PAYJP_APIKEY
    };
  },
  watch: {
    "$route.path"() {
      this.loadLayout();
    },
  },
  created() {
    this.loadLayout();
  },
  methods: {
    loadLayout() {
      if (this.$route.matched.length) {
        const component = this.$route.matched[0].components.default;
        const layout = component.layout;
        if (layout) {
          this.layoutComponent = require(`./layout/${layout}`).default;
        } else {
          this.layoutComponent = require(`./layout/main`).default;
        }
      }
    },
    onTokenFailed(data) {
      this.$root.$emit("payjp:error", data)
    },
    onTokenCreated(data) {
      this.$root.$emit("payjp:created", data)
    }
  },
};
</script>

<style lang="scss"></style>
