<template>
  <div class="cq-signup d-flex justify-content-center align-items-center">
    <div class="cq-signup-box">
      <div class="cq-signup-title text-center">新規登録</div>
      <form
        class="cq-signup-form mt-4"
        v-on:submit.stop.prevent="handleSignup"
        >
        <div class="position-relative">
          <b-input placeholder="メールアドレス" id="signup-email" v-model.trim="$v.formData.email.$model" :state="$v.formData.email.$error ? false : null" />
          <div v-if="$v.formData.email.$error" class="position-absolute input-error mt-1">
            <span v-if="!$v.formData.email.required">{{ message.ERROR_REQUIRE }}</span>
            <span v-else-if="!$v.formData.email.email">{{ message.EMAIL_INCORRECT }}</span>
          </div>
        </div>
        <p class="mt-4">
          <span class="cq-link-green cursor-pointer custom-link" @click="handlerKiyaku">AIハントサイト利用規約</span>に同意の上、『登録』ボタンを押してください。
        </p>
        <div v-b-tooltip.bottom="{customClass: 'tooltip-bg-gray'}" title="利用規約をご一読の上、同意をお願い致します。" class="mt-4">
          <b-button :disabled="isLoading || !confirmTerm" id="signup-submit" class="cq-btn-bg-green" type="submit">
            <b-spinner v-if="isLoading" small />
            登録
          </b-button>
        </div>
      </form>
      <p class="mt-4"><router-link class="cq-link-green" to="/login" id="link_to_login">ログイン画面へ戻る</router-link></p>
    </div>
  </div>
</template>

<script>
import '../../assets/scss/auth/signup.scss'
import { Message } from '../../utils/message'
import { required, email } from 'vuelidate/lib/validators'

export default {
  name: "signup",
  layout: "main",
  auth: false,
  head() {
    return {
      title: "会員登録",
    };
  },
  data() {
    return {
      formData: {
        email: this.$route.query.email ?? null
      },
      isLoading: false,
      message: Message.SIGNUP,
      confirmTerm: false,
      linkToPrivacy: "/kiyaku"
    }
  },
  created() {
    this.setConfirm()
    if(this.formData.email) this.$v.$touch()
  },
  methods: {
    async handleSignup() {
      this.$toast.clear()
      this.$v.$touch()
      const result = !this.$v.$error
      if(result) {
        this.isLoading = true
        const params = {...this.formData}
        params.email = params.email.toLowerCase()
        const data = await this.$store.dispatch('signupSendmail', params)
        if(data.success) {
          this.isLoading = false
          this.$router.push('/signup/send-mail-done')
        }else {
          if(data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message)
          this.isLoading = false
        }
      }
    },
    setConfirm() {
      if(this.$route.query.kiyaku) {
        this.confirmTerm = true
      }
    },
    handlerKiyaku() {
      const query = {}
      if(this.formData.email) query.email = this.formData.email
      this.$router.push({
        path: this.linkToPrivacy,
        query,
      })
    }
  },
  validations () {
    return {
      formData: {
        email: { required, email }
      }
    }
  }
}
</script>