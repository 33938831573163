<template>
  <div class="h-100">
    <b-card class="rounded h-100"  :border-variant="type" header-class="position-relative" :header-bg-variant="type" :class="`card-type-${type}`" :no-body="noButton" footer-class="p-4" @click="updateReadedRecommend">
      <template #header>
        <div class="d-flex header align-items-center">
          <div class="cq-badge" :style="`--type-color: var(--${type})`" v-if="isShowbadge">
            <div class="cq-badge-content rounded">
              new
            </div>
          </div>
          <div class="w-50 align-items-center title-group" v-b-tooltip="header.venueName">
            <div class="title">
              {{header.venueName}}
            </div>
            <div>
          </div>
        </div>
        <div class="cq-badge cq-badge-bottom" :style="`--type-color: var(--${type})`">
          <div class="cq-badge-content rounded">
            {{itemData.recommended_at | moment('timezone', 'Asia/Tokyo', 'YYYY/MM/DD HH:mm')}}
          </div>
        </div>
          <div class="w-50 header-content" style="overflow: hidden">
            <p class="nowrap">
              <span class="mr-2 nowrap" v-b-tooltip="header.brandName">
                {{header.brandName}}
              </span>
              <span class="nowrap" v-b-tooltip="header.carName">
                {{header.carName}}
              </span>
              </p>
            <p class="nowrap">
              <span class="mr-2 nowrap" v-b-tooltip="header.year">{{header.year}}</span>
              <span v-b-tooltip="header.odo">{{header.odo}}</span>
            </p>
            <p class="nowrap" v-b-tooltip="header.id">
              出品番号：<span>{{header.id}}</span>
            </p>
            <p v-b-tooltip="recCondName">条件のグループ名： <span>{{recCondName}}</span></p>
            <template v-if="!noButton && isFinished">
              <span>※結果は当日しかみれません</span>
            </template>
            <template v-else>
              <p v-if="!noButton && canRun"><u class="cursor-pointer" @click="toPreview">詳細情報を見る</u></p>
            </template>
          </div>
        </div>
      </template>
      <div class="footer" v-if="!noButton">
        <div class="d-flex align-items-center">
          <div class="col d-flex justify-content-center" :class="`border-${type}`">
            <button class="btn btn-action" :style="`fill: var(--${type})`" v-if="itemData.is_like === true" @click.stop="handleFeedback('like')" id="btn_recommend_result_like">
              <font-awesome-icon icon="fa-solid fa-thumbs-up" />
            </button>
            <button class="btn btn-action" :style="`fill: var(--${type})`" @click.stop="handleFeedback('like')" id="btn_recommend_result_like_disabled" v-else>
              <font-awesome-icon :icon="['fa-regular', 'thumbs-up']" />
            </button>
          </div>
          <div class="col d-flex justify-content-center" :class="`border-${type}`">
            <button class="btn btn-action" :style="`fill: var(--${type})`" v-if="itemData.is_like === false" id="btn_recommend_result_dislike_disabled"  @click.stop="handleFeedback('dislike', 'force')">
              <font-awesome-icon icon="fa-solid fa-thumbs-down" />
            </button>
            <button class="btn btn-action" :style="`fill: var(--${type})`" @click.stop="handleFeedback('dislike')" id="btn_recommend_result_dislike" v-else>
              <font-awesome-icon :icon="['fa-regular', 'thumbs-down']" />
            </button>
          </div>
        </div>
      </div>
      <template #footer v-if="!isNoMessage" >
        <div v-if="canRun">
          <slot name="body" :handlePreview="toPreview" :isFinished="isFinished">
            <div class="px-4">
              <div class="row align-items-center">
                <div class="col">
                  {{comment}} 
                </div>
                <div class="col-auto ml-3" v-if="itemData.is_like === false">
                  <font-awesome-icon icon="fa-solid fa-pen-to-square" class="icon-large" @click.stop="editFeedback"/>
                </div>
              </div>
            </div>
          </slot>
        </div>
      </template>
    </b-card>
  </div>
</template>
<script>
import checkExtension from '@/utils/checkExtension'
import { convertYear } from "@/utils/listYear"
import { isMobile, isTablet } from '@/utils/commonEvent'
export default {
  props: {
    itemData: {
      type: Object,
      default () {
        return {
          footer: {
            status: "like"
          }
        }
      }
    },
    showBadge: {
      type: Boolean,
      default () {
        return true
      }
    },
    noButton: {
      type: Boolean,
      default () {
        return false
      }
    },
    referer: {
      type: String,
      default () {
        return 'index'
      }
    },
    canRunUSS: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  computed: {
    isShowbadge () {
      return this.showBadge ? !this.itemData.is_readed : false
    },
    carMap () {
      return this.$store.getters.carMap ?? []
    },
    type() {
      const mapColor = {
        "advantageous_vehicle_automatic_search": "warning",
        "flow_vehicle_AI_alert": "success",
        "shared_inventory_AI_alert": "secondary" 
      }
      return mapColor[this.itemData.rec_type]
    },
    carInfo () {
      return this.itemData.aa_car ?? this.itemData.auction_aa_car ?? this.itemData.finished_aa_car ?? this.itemData.finished_one_price_aa_car ?? this.itemData.one_price_aa_car
    },
    site () {
      const site = this.carMap.site.find((site) => this.moreInfo?.core_site_id === site.id)
      return site
    },
    siteName () {
      return this.site?.name
    },
    car () {
      const car = this.carMap.cars.find((car) => this.carInfo.core_car_id === car.id) ?? { name: this.carInfo.car_name }
      return car
    },
    brand () {
      const brand = this.carMap.brands.find((brand) => this.carInfo?.core_brand_id === brand.id) ?? { name: this.carInfo.core_brand_name }
      return brand
    },
    color () {
      const color = this.carMap.colors.find((color) => this.carInfo?.core_color_id === color.id)
      return color
    },
    year () {
      const year = convertYear(this.carMap.years, this.carInfo?.produced_on)
      return year
    },
    odo () {
      const odo = this.carInfo?.odo
      return odo
    },
    moreInfo () {
      return this.itemData.auction ?? this.itemData.finished_auction ?? this.itemData.finished_one_price ?? this.itemData.one_price ?? this.itemData.before_auction
    },
    recommendInfo () {
      return this.itemData.auction ?? this.itemData.finished_auction ?? this.itemData.finished_one_price ?? this.itemData.one_price ?? this.itemData.before_auction
    },
    venueName () {
      const auction_venue = this.carMap.auctions_venue.find((auctions_venue) => auctions_venue.id === this.moreInfo?.auctions_venue_id)
      if(auction_venue) {
        const venue = this.carMap.venues.find((venue) => venue.id === auction_venue.core_venue_id)
        return venue?.name
      }
      return this.moreInfo.auction_site_name
    },
    isFinished () {
      // return (this.itemData.finished_auction_id || this.itemData.finished_one_price_id) ? true : false
      return false
    },
    canRun () {
      if(this.site && this.site.id == 1 && !this.canRunUSS) return false
      return true
    },
    header () {
      const data = {
        siteName: this.siteName,
        venueName: this.venueName,
        carName: this.car?.name,
        brandName: this.brand?.name,
        year: `${this.year}`,
        color: this.color?.name !== "その他" ? this.color?.name : "",
        odo: `${this.odo && this.odo < 1000 ? 0 : Math.ceil(this.odo / 1000)}千km`,
        id: this.recommendInfo?.lot_no
      }
      return data
    },
    comment () {
      return this.itemData.comment
    },
    isNoMessage () {
      return !(this.comment ?? false) && this.referer === 'index'
    },
    recCondName () {
      return this.itemData.rec_cond_name ?? ""
    }
  },
  emits: ['handleFeedback', 'editFeedback'],
  methods: {
    handleFeedback(type, force) {
      this.$emit('handleFeedback', { type, id: this.itemData.id, force })
      this.updateReadedRecommend()
    },
    editFeedback(type) {
      this.$emit('editFeedback', {type: null, id: this.itemData.id, message: this.itemData.comment })
      this.updateReadedRecommend()
    },
    async updateReadedRecommend($event) {
      if(this.itemData.is_readed){
        return
      }
      await this.$store.dispatch('updateReadedRecommend', {
        recommendId: this.itemData.id
      })
      this.itemData.is_readed = true
      this.$store.dispatch('setRecommend')
    },
    handlePreview (e) {
      if (this.deviceType() == 'mobile') return
      e?.preventDefault()
      if(this.referer !== 'popup'){
        this.toPreview()
      }
    },
    toPreview (e) {
      if (this.deviceType() != 'desktop') return
      const payload = {
        brand: this.carInfo.core_brand_name,
        car_name: this.carInfo.core_car_name,
        model_name: this.carInfo.core_model_name,
        grade: this.carInfo.core_grade_name,
      }
      checkExtension.methods.toPreview.call(this, e, checkExtension, null, payload)
    },
    deviceType() {
      const ua = navigator.userAgent || navigator.vendor || window.opera;
      if (isTablet(ua)) {
          return "tablet";
      }
      else if (isMobile(ua)) {
          return "mobile";
      }
      return "desktop";
    }
  },
}
</script>
<style scoped lang="scss">
.header{
  color: $white;
  .title-group{
    text-align: center;
    .title {
      max-width: 150px;
      overflow: hidden;
      text-overflow: ellipsis;
      font-size: 1.5rem;
      font-weight: bold;
      white-space: nowrap;
    }
  }
  &-content {
    font-size: 0.9em;
  }
  p {
    margin-bottom: 0.7rem;
  }
}
.footer {
  .btn {
    &-action {
      width: 45px;
      height: 45px!important;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid!important;
      border-color: inherit!important;
      border-radius: 50%!important;
      svg, path {
        width: 23px;
        height: 23px;
        fill: inherit;
      }
    }
  }
}
.nowrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.card-header {
  flex: 1 1 auto;
}
.card-body {
  flex: 0 0 auto;
}
.header {
  .col, .col-auto {
    padding: 0.0rem;
  }
}
.cq-badge {
  position: absolute;
  top: 0px;
  left: 0px;
  &-bottom{
    top: unset;
    bottom: 0px;
  }
  &-content {
    position: relative;
    border-bottom-left-radius: 0px!important;
    background-color: var(--danger);
    color: $white;
    padding: 0.3rem;
    padding-left: 0.7rem;
    padding-right: 1.3rem;
    white-space: nowrap;
    text-transform: uppercase;
    font-weight: 300;
    width: max-content;
    font-size: 0.8em;
    &::after {
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      background-color: var(--type-color);
      border-radius: 50%;
      right: -5px;
      width: 16px;
      height: 16px;
    }
  }
}
.card-footer {
  min-height: 4.5rem;
}
</style>