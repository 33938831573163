<template>
  <div class="cq-signupToken d-flex justify-content-center align-items-center">
    <div class="cq-signupToken-box">
      <div class="cq-signupToken-title text-center">アカウント情報</div>
      <form class="cq-signupToken-form mt-4" v-on:submit.stop.prevent="handleSignup">
        <div class="cq-signupToken-groupInput d-flex align-items-md-center flex-md-row flex-column">
          <div class="groupInput-label">
            <label for="input-email">メールアドレス</label>
          </div>
          <div class="groupInput-input">
            <b-input id="input-email" disabled placeholder="メールアドレス" v-model="formData.email" />
          </div>
        </div>
        <div class="cq-signupToken-groupInput d-flex align-items-md-center flex-md-row flex-column  mt-4">
          <div class="groupInput-label">
            <label for="input-password">パスワード <span class="text-danger groupInput-label-required">※</span></label>
          </div>
          <div class="groupInput-input position-relative">
            <b-input id="input-password" placeholder="••••••" type="password" @keydown="preventSpace" v-model.trim="$v.formData.password.$model" :state="$v.formData.password.$error ? false : null"/>
            <div v-if="$v.formData.password.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.password.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.password.minLength || !$v.formData.password.maxLength">{{ message.ERROR_LENGTH }}</span>
              <span v-else>{{ message.ERROR_FORMAT }}</span>
            </div>
          </div>
        </div>
        <div class="cq-signupToken-groupInput d-flex align-items-md-center justify-content-start flex-md-row flex-column mt-4">
          <div class="groupInput-label">
            <label for="input-passwordConfirm">パスワードを認証する <span class="text-danger groupInput-label-required">※</span></label>
          </div>
          <div class="groupInput-input position-relative">
            <b-input id="input-passwordConfirm" placeholder="••••••" type="password" @keydown="preventSpace" v-model.trim="$v.formData.passwordConfirm.$model" :state="$v.formData.passwordConfirm.$error ? false : null"/>
            <div v-if="$v.formData.passwordConfirm.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.passwordConfirm.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else>{{ message.ERROR_CONFIRMPASS }}</span>
            </div>
          </div>
        </div>
        <b-button :disabled="isLoading" id="btn_signup_verify_email_confirm" class="mt-5 cq-btn-bg-green" type="submit">
          <b-spinner v-if="isLoading" small />
          登録する
        </b-button>
      </form>
    </div>
  </div>
</template>

<script>
import { Message } from '../../../utils/message'
import { required, maxLength, minLength, sameAs } from 'vuelidate/lib/validators'
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import { preventSpace } from '@/utils/commonEvent'

export default {
  name: "signup",
  layout: "main",
  auth: false,
  head() {
    return {
      title: "アカウント情報",
    };
  },
  data() {
    return {
      formData: {
        email: null,
        password: null,
        passwordConfirm: null,
        token_confirm: null
      },
      isLoading: false,
      message: Message.SIGNUP_INPUT
    }
  },
  // watch: {
  //   'formData.password'() {
  //     this.$v.formData.password.$touch()
  //   },
  //   'formData.passwordConfirm'() {
  //     this.$v.formData.passwordConfirm.$touch()
  //   }
  // },
  created() {
    const { email } = this.$route.query
    const { token } = this.$route.params
    if(email)this.formData.email = email
    if(token)this.formData.token_confirm = token
  },
  methods: {
    async handleSignup() {
      this.$toast.clear()
      this.$v.$touch()
      const result = !this.$v.$error
      if(result) {
        this.isLoading = true
        const data = await this.$store.dispatch('signupInput', this.formData)
        if(data.success) {
          this.isLoading = false
          localStorage.setItem('token_user', data.data.token)
          localStorage.setItem('userId', data.data.user.id)
          localStorage.setItem('auth_user', 'user')
          const {email_firebase, password_firebase} = data.data.user
          this.sigupFirebase(email_firebase, password_firebase)
          this.$router.push('/signup/input/done')
        }else {
          const message = data.errors.length !== 0 ? data.errors[0].message : ''
          if(message) this.$toast.error(message)
          this.isLoading = false
        }
      }
    },
    sigupFirebase(email_firebase, password_firebase) {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email_firebase, password_firebase)
      .then((data) => {})
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
      });
    },
    preventSpace,
  },
  validations () {
    return {
      formData: {
        password: { 
          required,
          minLength: minLength(6),
          maxLength: maxLength(64),
          formatPass: () => {
            if(this.formData.password) {
              return /^(?=.*[a-z])(?=.*[A-Z])([0-9a-zA-Z!-~]{6,64})$/.test(this.formData.password)
            }
            return false
          }
        },
        passwordConfirm: { 
          required,
          minLength: minLength(6),
          maxLength: maxLength(64),
          sameAsPassword: sameAs('password'),
          formatPass: () => {
            if(this.formData.passwordConfirm) {
              return /^(?=.*[a-z])(?=.*[A-Z])([0-9a-zA-Z!-~]{6,64})$/.test(this.formData.passwordConfirm)
            }
            return false
          }
        }
      }
    }
  },
}
</script>

<style lang="scss">
.cq-signupToken {
  background: url("@/assets/img/auth-bg.png");
  width: 100%;
  height: calc(100vh - 70px);
  min-height: 500px;
  background-size: cover;
  &-box {
    width: 700px;
    max-width: calc(100% - 20px);
    padding: 2.7rem;
    background: $white;
    border-radius: 10px;
  }
  &-title {
    font-size: 1.5rem;
    font-weight: 400;
  }
  &-form {
    input {
      background: $cq-inputgray;
      &:hover,
      &:active,
      &:focus {
        background-color: $cq-inputgray;
      }
      &:disabled {
        background-color: #e9ecef;
      }
    }
    button {
      width: 100%;
    }
  }
  &-groupInput {
    .groupInput-label {
      width: 190px;
      &-required {
        font-size: 0.75rem;
      }
    }
    .groupInput-input {
      width: calc(100% - 190px);
    }
    @include screen (767) {
      .groupInput-label {
        width: 100%;
      }
      .groupInput-input {
        width: 100%;
      }
    }
  }
}
</style>