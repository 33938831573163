<template>
  <div class="cq-done d-flex justify-content-center align-items-center">
    <div class="cq-done-box">
      <div class="vcheck d-flex justify-content-center align-items-center mb-3">
        <img src="@/assets/img/check.svg" alt="check" />
      </div>
      <div class="cq-done-title text-center">パスワードを変更しました</div>
      <b-button id="passwordDone-btn" to="/login" class="cq-btn-bg-green w-100 d-flex align-items-center justify-content-center mt-5">ログイン画面に戻る</b-button>
      <hr class="mt-5">
    </div>
  </div>
</template>

<script>
import "@/assets/scss/password/reset/done.scss";

export default {
  name: "passwordResetDone",
  layout: "main",
  auth: false,
  head() {
    return {
      title: "認証メール送信完了",
    };
  },
  data() {
    return {};
  },
};
</script>
