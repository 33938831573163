<template>
  <div>
    <ModelSelectBrands
      v-model="value.core_brand_id"
      :options="brands"
      size="sm"
      id="selectedBrands"
      class="mt-3"
      style="clear: both"
      :is-convert2-full-width="true"
    ></ModelSelectBrands>
    <model-select
      v-model="value.core_car_id"
      :options="cars"
      size="sm"
      id="selectedCarName"
      class="mt-3"
      :is-convert2-full-width="true"
    ></model-select>
    <model-select
        v-model="value.model"
        :options="models"
        size="sm"
        id="stockList-search-model"
        class="mt-3"
      ></model-select>
      <model-select
        v-model="value.core_grade_id"
        :options="grades"
        size="sm"
        id="stockList-search-grade"
        class="mt-3"
        :is-convert2-full-width="true"
      ></model-select>
  </div>
</template>
<script>
import ModelSelectBrands from './ModelSelectBrands.vue'
import CQFormGroupSelectCar from "./CQFormGroupSelectCar.vue"
export default {
  extends: CQFormGroupSelectCar,
  components: {
    ModelSelectBrands
  },
}
</script>