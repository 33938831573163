const priceList = [
  { value: 50000, text: "5万円" },
  { value: 100000, text: "10万円" },
  { value: 150000, text: "15万円" },
  { value: 200000, text: "20万円" },
  { value: 250000, text: "25万円" },
  { value: 300000, text: "30万円" },
  { value: 350000, text: "35万円" },
  { value: 400000, text: "40万円" },
  { value: 450000, text: "45万円" },
  { value: 500000, text: "50万円" },
];
const listPriceFrom = [{ text: "下限価格（万円）", value: null }, ...priceList];
const listPriceTo = [{ text: "上限価格（万円）", value: null }, ...priceList];
export default priceList;
export { listPriceFrom, listPriceTo };
