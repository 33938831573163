<template>
  <div class="newCredit">
    <CQPreLoad :preload="preLoading" />
    <div v-if="!preLoading" class="container-custom">
      <div class="credit_title">支払い情報</div>
      <div class="credit-box cq-radius-8 ">
        <div class="credit-box-title">クレジットカード登録</div>
        <div class="add-credit cq-radius-8 d-flex align-items-center justify-content-center"
          v-if="isHiddenDelete"
          v-on:click="openPayjp(), checkEditCard = false">
          <div class="d-flex flex-row align-items-center justify-content-center">
            <div class="credit-box-items d-flex justify-content-center align-items-center">
              <img
                v-if="!isLoading"
                src="@/assets/img/newcredit-vector.png"
                alt="vector-newcredit"
              />
              <b-spinner class="spinner-custom" v-if="isLoading" />
            </div>
            <div class="credit-box-text">決済方法を追加する</div>
          </div>
        </div>
        <!-- deleteCard -->
        <div v-if="!isHiddenDelete" class="credit-box-card cq-radius-8">
          <div class="d-flex justify-content-center align-items-center m-auto">
            <!-- content card is here -->
            <img src="@/assets/img/visa.png" alt="visaCard">
            <div class="ml-3">
              <div class="card-number">{{ infoCard.brand }} **** **** **** {{ infoCard.last4 }}</div>
              <span class="card-times">有効期限 {{("00" + infoCard.exp_month).substr(-2)}}/{{(infoCard.exp_year) ? infoCard.exp_year.toString().substring(2,4) : ""}}</span>
            </div>
          </div>
          <div class="deleteBox d-flex align-items-center justify-content-end">
            <button id="btn_mypage_credit_new_delete" v-on:click="openPayjp(), checkEditCard = true" class="deleteBtn cq-bg-blue mr-2">
              編集
            </button>
            <button id="btn_mypage_credit_new_delete" v-on:click="$bvModal.show('alertMessage')" class="deleteBtn">
              削除
            </button>
          </div>
        </div>
      </div>
      <b-modal
        id="alertMessage"
        ref="alertMessage"
        modal-class="modal-confirm modal-confirm-sm"
        body-class="p-md-4 p-3"
        hide-footer
        centered
      >
        <div class="d-block text-center title w-100 mb-4">
          <h1 class="text-danger">
            <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
          </h1>
          <p class="mt-1">カードの削除をご希望の場合は <router-link id="link-forgot" class="cq-link-green" to="/chat">カスタマーセンター</router-link>までお問い合わせください。</p>
        </div>
      </b-modal>
    </div>
  </div>
</template>
<script>
import "@/assets/scss/mypage/credit/new.scss";
import CQPreLoad from "@/components/CQPreLoad.vue";

export default {
  name: "newCredit",
  layout: "user",
  auth: "user",
  components: {
    CQPreLoad,
  },
  head() {
    return {
      title: "支払い情報",
    };
  },
  data() {
    return {
      preLoading: true,
      isHiddenDelete: true,
      isLoading: false,
      infoCard: {
        brand: '',
        last4: '',
        exp_month: '',
        exp_year: ''
      },
      checkEditCard: false
    };
  },
  computed: {
    action () {
      return this.$route.query.action
    }
  },
  async created() {
    const data = await this.$store.dispatch('getCardInfo')
    this.preLoading = false
    if (data.success && data.data && data.data.cards.count) {
      const { brand, last4, exp_month, exp_year } = data.data.cards.data[0]
      this.infoCard = { brand, last4, exp_month, exp_year }
      this.isHiddenDelete = false
    }
    else {
      if(this.action === "new_credit") {
        this.openPayjp()
      }
    }
    this.$root.$on("payjp:error", this.onTokenFailed)
    this.$root.$on("payjp:created", this.onTokenCreated)
  },
  beforeDestroy () {
    this.$root.$off("payjp:error", this.onTokenFailed)
    this.$root.$off("payjp:created", this.onTokenCreated)
  },
  methods: {

    openPayjp() {
      document.getElementById('payjp_checkout_box').childNodes[0].click();
    },

    async onTokenCreated(cardInfo) {
      this.isLoading = true
      const data = await this.$store.dispatch((this.checkEditCard) ? 'editCreditCard' : 'createCreditCard', { payjp_token: cardInfo.id })
      this.isLoading = false
      if(data.success) {
        this.$toast.success(data.message)
        const { brand, last4, exp_month, exp_year } = cardInfo.card
        this.infoCard = { brand, last4, exp_month, exp_year }
        this.isHiddenDelete = false
      }else {
        if(data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message)
      }
    },

    onTokenFailed(data) {
      console.log(data);
    }
  }
};
</script>

<style lang="scss" scoped></style>
