<template>
    <div class="iframe-container">
        <iframe :src="htmlUrl" frameborder="0" allowfullscreen id="myIframe"></iframe>
    </div>
</template>

<script>

export default {
    name: "LandingPage",
    data() {
        return {
            htmlUrl: process.env.BASE_URL + "ai_hunt/landingPage.html",
        };
    },
    mounted() {
        const iframe = document.getElementById('myIframe');
        iframe.onload = () => {
            const env = process.env.VUE_APP_ROOT_API;
            iframe.contentWindow.postMessage({ type: 'env', data: env }, '*');
        }
        window.addEventListener('message', (event) => {
            if (event.data.type === 'router') {
                this.$router.push('/login')
            }
        });
    }
};
</script>

<style>
.iframe-container {
  position: relative;
  overflow: hidden;
  padding-top: 56.25%; /* Tỷ lệ khung hình 16:9 */
}

.iframe-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* Style cho PC */
@media screen and (min-width: 768px) {
  .iframe-container {
    padding-top: 0;
    height: 100vh; /* Kích thước mong muốn cho PC */
  }
}

/* Style cho Mobile */
@media screen and (max-width: 767px) {
  .iframe-container {
    padding-top: 0;
    height: 90vh; /* Kích thước mong muốn cho Mobile */
  }
}

/* Style cho Tablet */
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .iframe-container {
    padding-top: 0;
    height: 95vh; /* Kích thước mong muốn cho Tablet */
  }
}
</style>

