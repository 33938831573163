<template>
  <div>
    <b-modal
      modal-class="cq-modal-analyze"
      id="modal-analyze"
      title="フィルター条件"
      size="xl"
      centered
      dialog-class="cq-dialog-analyze"
    >
      <div class="cq-modal-content mx-md-5 m-0">
        <div>
          <a href="#" @click="$bvModal.show('modal-stock')" class="text-success">
            在庫から車両選択　→
          </a>
        </div>
        <div class="content-form mt-4">
          <form>
            <div class="row">
              <div class="col-lg-6 col-12">
                <div class="row mt-2">
                  <div class="col-md col-12 mt-2" @click="setOptionTop($event)">
                    <model-select-brands :options="brands" v-model="formData.core_brand_id" id="formData_core_brand_id" :is-convert2-full-width="true" />
                    <div v-if="!formData.core_brand_id && checkSelectNull" class="text-danger text-sm mt-1 ml-1">{{message.REQUIRED}}</div>
                  </div>
                  <div class="col-auto col-span mt-2 mt-2"></div>
                  <div class="col-md col-12 mt-2" @click="setOptionTop($event)">
                    <model-select :options="cars" v-model="formData.core_car_id" id="formData_core_car_id" :is-convert2-full-width="true" />
                    <div v-if="!formData.core_car_id && checkSelectNull" class="text-danger text-sm mt-1 ml-1">{{message.REQUIRED}}</div>
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="row mt-2">
                  <div class="col-md col-12 mt-2" @click="setOptionTop($event)">
                    <model-select :options="models" v-model="formData.model" id="formData_model" />
                    <div v-if="!formData.model && checkSelectNull" class="text-danger text-sm mt-1 ml-1">{{message.REQUIRED}}</div>
                  </div>                 
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-6 col-12">
                <div class="row mt-2">
                  <div class="col-md col-12 mt-2" @click="setOptionTop($event)">
                    <model-select :options="grades" v-model="formData.core_grade_id" id="formData_core_grade_id" :is-convert2-full-width="true" />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="row flex-md-nowrap mt-2">
                  <div class="col-md col-12 mt-2" @click="setOptionTop($event)">
                    <model-select :options="colors" v-model="formData.core_color_id" id="formData_core_color_id" :is-convert2-full-width="true" />
                  </div>
                  <div class="col-auto col-span mt-2 mt-2"></div>
                  <div class="col-md col-12 mt-2" @click="setOptionTop($event)">
                    <model-select :options="gears" v-model="formData.core_gear_id" id="formData_core_gear_id" />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-6 col-12">
                <div class="row mt-2">
                  <div class="col mt-2">
                    <model-select
                      v-model="formData.produced_on_from"
                      :options="listYearFrom"
                      :max="formData.produced_on_to"
                    />
                  </div>
                  <div class="col-auto col-span mt-2 mt-2">~</div>
                  <div class="col mt-2">
                    <model-select
                      v-model="formData.produced_on_to"
                      :options="listYearTo"
                      :min="formData.produced_on_from"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="row mt-2">
                  <div class="col mt-2">
                    <model-select
                      v-model="formData.odo_from"
                      id="formData_odo_from"
                      :options="listOdoFrom"
                      :max="formData.odo_to"
                    />
                  </div>
                  <div class="col-auto col-span mt-2">~</div>
                  <div class="col mt-2">
                    <model-select
                      v-model="formData.odo_to"
                      id="formData_odo_from"
                      :options="listOdoTo"
                      :min="formData.odo_from"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-2">
              <div class="col-lg-6 col-12">
                <div class="row mt-2">
                  <div class="col mt-2">
                    <b-input
                      v-model="formData.displacement_from"
                      placeholder="排気量"
                      id="formDatadisplacementFrom"
                      autocomplete="off"
                    />
                  </div>
                  <div class="col-auto col-span mt-2">~</div>
                  <div class="col mt-2">
                    <b-input
                      v-model="formData.displacement_to"
                      placeholder="排気量"
                      id="formDatadisplacementTo"
                      autocomplete="off"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-12">
                <div class="row mt-2">
                  <div class="col mt-2">
                    <model-select
                      v-model="formData.score_from_id"
                      id="formData_score_from_id"
                      :max="formData.score_to_id"
                      :options="scores"
                    />
                  </div>
                  <div class="col-auto col-span mt-2">~</div>
                  <div class="col mt-2">
                    <model-select
                      v-model="formData.score_to_id"
                      id="formData_score_to_id"
                      :min="formData.score_from_id"
                      :options="scores"
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div class="content-search-venue">
            <div class="form-group mt-4">
              <label>会場</label>
              <SelectItemSmall :dataListOption="venues" @change="onChangeVenue" :text-search="'会場検索'" ref="selectionVenue" />
            </div>
          </div>
        </div>
      </div>
      <template #modal-footer>
        <div class="row justify-content-center align-items-center w-100">
          <div class="col-auto mt-2">
            <button class="btn btn-secondary btn-confirm" @click="$bvModal.hide('modal-analyze')" id="btn_modal_analyze_hide">キャンセル</button>
          </div>
          <div class="col-auto mt-2">
            <button class="btn btn-success btn-confirm" @click="search()" :disabled="isLoading" id="btn_modal_analyze_search">
              <b-spinner v-if="isLoading" small class="mr-2"/>
              適用する
            </button>
          </div>
        </div>
      </template>
    </b-modal>
    <stockModal @close="importData" />
  </div>
</template>
<style scoped lang="scss">
.cq-modal-analyze {
  .btn-confirm {
    width: 300px;
  }
  .cq-modal-content {
    display: flex;
    flex: 1 1 auto;
    flex-direction: column;
    .content-form {
      display: flex;
      flex-direction: column;
      height: calc(100% - 30px);
      .content-search-venue {
        flex: 0 1 auto;
        overflow: hidden auto;
      }
      .row {
        .col {
          &-span {
            font-size: 1.875rem;
            font-weight: 400;
            width: 25px;
            text-align: center;
          }

          &-span:not(.padding) {
            padding: 0.0rem;
          }
        }
      }
      .form-control:not(.b-form-datepicker),
      .custom-select {
        border: 1px solid #dcdcdc !important;
      }

      .b-form-datepicker {
        ::v-deep .form-control {
          padding: 0.7rem 1.0rem;
          border: unset !important;
          min-height: unset;
        }

        &.form-control {
          border-radius: 0.5rem !important;
        }
      }
    }
  }
  .cq-checbox-custom {
    input[type="checkbox"] {
      & + label {
        display: flex;
        align-items: center;
        .box {
          width: 35px;
          height: 35px;
          border: 1px solid;
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 0.7rem;
          .icon-check {
            width: 25px;
            height: 25px;
            fill: #5daf5d;
            display: none;
            svg,
            path {
              fill: inherit;
            }
          }
        }
      }
      &:checked + label {
        .icon-check {
          display: block;
        }
      }
    }
  }
}
</style>
<style lang="scss">
.cq-modal-analyze {
  padding: 0.0rem!important;
  @media (min-width: 992px) {
    .modal-lg,
    .modal-xl {
      max-width: 800px;
    }
  }
  @media (min-width: 1200px) {
    .modal-xl {
      max-width: 1140px;
    }
  }
  .modal-content {
    @include screen(768){
      height: calc(100vh - 100px);
    }
    height: unset;
    min-height: calc(100vh - 100px);
    display: flex;
    flex-direction: column;
  }
  .cq-dialog-analyze .modal-body {
    display: flex;
    flex: 1 1 auto;
    height: calc(100% - 150px);
  }
}
</style>
<script>
import { Message } from '@/utils/message';
import stockModal from '@/components/stock/modal.vue'
import { mapActions, mapGetters } from 'vuex';
import SelectItemSmall from '../mypage/SelectItemSmall.vue';
import { convertNumber } from "@/utils/commonEvent";
import analyze from "@/utils/analyze";
import { setOptionTop } from "@/utils/commonEvent"
import listOdo from "@/utils/odoList"
import ModelSelectBrands from '../ModelSelectBrands.vue';
import CQFormGroupSelectCar from '../CQFormGroupSelectCar.vue';
import { SCORE } from "@/utils/score"

export default {
  mixins: [convertNumber],
  components: {
    stockModal,
    SelectItemSmall,
    ModelSelectBrands,
    CQFormGroupSelectCar
  },
  computed: {
    ...mapGetters({
      showModalCondition: 'showModalCondition',
      analyzeGetDataOld: 'analyzeGetDataOld',
    }),
    isShowSubmitButton () {
      return this.formData.core_brand_id && this.formData.core_car_id && this.formData.core_grade_id
    }
  },
  mixins: [analyze],
  data() {
    return {
      brands: [
        {
          text: "メーカー",
          value: null,
        },
      ],
      cars: [
        {
          text: "車名",
          value: null,
        },
      ],
      models: [
        {
          text: "型式",
          value: null,
        },
      ],
      grades: [
        {
          text: "グレード",
          value: null,
        },
      ],
      colors: [
        {
          text: "色",
          value: null,
        },
      ],
      gears: [
        {
          text: "シフト",
          value: null,
        },
      ],
      venues: [],
      formData: {
        core_brand_id: null,
        core_car_id: null,
        core_grade_id: null,
        core_color_id: null,
        produced_on_from: null,
        produced_on_to: null,
        core_gear_id: null,
        odo_from: null,
        odo_to: null,
        model: null,
        displacement_from: null,
        displacement_to: null,
        auction_venues: null,
        score_from_id: null,
        score_to_id: null,
      },
      forceUpdate: false,
      isLoading: false,
      stockFill: {},
      currentId: null,
      message: Message.ANALYZE_GRAPH,
      checkSelectNull: false,
      listOdoFrom: [
        {
          value: null,
          text: "走行(km)",
        },
        ...listOdo
      ],
      listOdoTo: [
        {
          value: null,
          text: "走行(km)",
        },
        ...listOdo
      ],
      listYearFrom: [{
        value: null,
        text: "年式",
      }],
      listYearTo: [{
        value: null,
        text: "年式"
      }],
      scores: [],
      oldData: {}
    };
  },
  watch: {
    showModalCondition (value) {
      if (value) {
        Object.keys(this.formData).forEach(key => {
          this.formData[key] = null;
        });
        this.venues = this.venues.map((venue) => {
          return {
            ...venue,
            picked: true
          }
        })
        this.$store.commit('set', ['showModalCondition',false]);
      }
    },
    async "formData.core_brand_id"(newValue) {
      if (!this.forceUpdate) this.formData.core_car_id = null
      this.cars = [this.cars[0]]
      if (newValue) {
        const getDataCarName = await this.$store.dispatch('getDataCarName', newValue);
        if (getDataCarName.success) {
          getDataCarName.data.cars.forEach(carNames => {
            this.cars.push({ value: carNames.id, text: carNames.name });
          });
          if(this.oldData.core_car_id) {
            this.formData.core_car_id = this.oldData.core_car_id
            this.oldData.core_car_id = null
          }
          this.bindingData("core_brand_id", this.formData.core_brand_id)
        } else {
          if (getDataCarName.errors.length && getDataCarName.errors[0].message) this.$toast.error(getDataCarName.errors[0].message);
        };
      }
    },
    async "formData.core_car_id"(newValue) {
      if(!this.forceUpdate) this.formData.model = null
      this.models = [this.models[0]];
      if(newValue){
        const getDataModel = await this.$store.dispatch('getDataModel', newValue);
        if (getDataModel.success) {
          getDataModel.data.models.forEach(model => {
            this.models.push({ value: model.id, text: model.name });
          });
          if(this.oldData.model) {
            this.formData.model = this.oldData.model
            this.oldData.model = null
          }
          this.bindingData("core_car_id", this.formData.core_car_id)
        } else {
          if (getDataModel.errors.length && getDataModel.errors[0].message) this.$toast.error(getDataModel.errors[0].message);
        };
      }
    },
    async "formData.model"(newValue) {
      if(!this.forceUpdate) this.formData.core_grade_id = null
      this.forceUpdate = false
      this.grades = [this.grades[0]];
      if(newValue){
        const getDataGrade = await this.$store.dispatch('getDataGrade', newValue);
        if (getDataGrade.success) {
          getDataGrade.data.grades.forEach(grades => {
            this.grades.push({ value: grades.id, text: grades.name });
          });
          if(this.oldData.core_grade_id) {
            this.formData.core_grade_id = this.oldData.core_grade_id
            this.oldData.core_grade_id = null
          }
          this.bindingData("model", this.formData.model)
        } else {
          if (getDataGrade.errors.length && getDataGrade.errors[0].message) this.$toast.error(getDataGrade.errors[0].message);
        };
      }
    },
    async "formData.core_grade_id"() {
      this.bindingData("core_grade_id", this.formData.core_grade_id)
    },
    analyzeGetDataOld (value) {
      if(value) {
        this.oldData = {
          ...this.$route.query,
        }
        
        this.formData.core_brand_id = this.oldData.core_brand_id
        const listParse = [
          "core_color_id",
          "core_gear_id",
          "odo_from",
          "odo_to",
          "score_from_id",
          "score_to_id",
          "displacement_from",
          "displacement_to",
        ]
        if(this.oldData["produced_on_from"]) {
          this.formData["produced_on_from"] = this.oldData.produced_on_from
        }
        else this.formData["produced_on_from"] = null
        if(this.oldData["produced_on_to"]) {
          this.formData["produced_on_to"] = this.oldData.produced_on_to
        }
        else this.formData["produced_on_to"] = null
        this.$nextTick(() => {
          for (const key of listParse) {
            const element = this.oldData[key];
            if(element){
              if(parseInt(element)) this.formData[key] = parseInt(element)
              else this.formData[key] = element
            }
            else {
              this.formData[key] = null
            }
          }
        })
        this.venues.forEach((venue) => {
          if(this.oldData.auction_venues.includes(venue.name)) venue.picked = true
          else venue.picked = false
        })
      }
      else {
        this.oldData = {}
      }
    }
  },
  methods: {
    bindingData(keyWatcher, id) {
      const searchObj = this.watcherCarInfo[keyWatcher]
      const option = this[searchObj.options].find(({value}) => value === id)
      if(option) {
        let res = option.text
        if(searchObj.handler.constructor === Function) {
          res = searchObj.handler(res)
        }
        this.formData[searchObj.key] = res
      }
    },
    handleToStockLink(e, navigate) {
      navigate(e);
      this.$bvModal.hide('modal-analyze')
    },
    onChangeVenue (venues) {
      this.formData.auction_venues = venues.map((venue) => {
        return venue.name
      })
    },
    setOptionTop,
    importData(data) {
      this.forceUpdate = true
      this.formData = {...this.formData, ...this.importDataFromStock(data) }
      this.stockFill = { ...this.formData }
      this.currentId = data.id
      this.$bvModal.hide("modal-stock")
    },
    async fetchUserVenues () {
      const res = await this.$store.dispatch("getCoreVenueStock")
      const { data } = res ?? {}
      this.venues = data?.core_venues?.map(venue => {
        venue.picked = true
        venue.core_venue = {}
        venue.core_venue.name = venue.name
        return venue
      }) ?? []
      this.onChangeVenue(this.venues)
    },
    async initData() {
      const dataMaster = this.$store.getters.carMap
      await dataMaster.loader
      dataMaster.brands.forEach(brand => {
        this.brands.push({
          text: brand.name,
          value: brand.id,
          core_country_id: brand.core_country_id,
        })
      });
      dataMaster.colors.forEach(color => {
        this.colors.push({
          text: color.name,
          value: color.id
        })
      });
      dataMaster.gears.forEach(gear => {
        this.gears.push({
          text: gear.name,
          value: gear.id
        })
      });
      this.listYearFrom = [this.listYearFrom[0], ...dataMaster.years]
      this.listYearTo = [this.listYearTo[0], ...dataMaster.years]
    },
  },
  created () {
    /**
     * Binding data to form search
     */
     SCORE[0].text = "評価点"
    this.scores = SCORE
    const scopeWatch = this.scopeWatch
    for(let key in scopeWatch) {
      const searchObj = scopeWatch[key]
      this.formData[searchObj.key] = ""
      this.$watch(`formData.${key}`, function (id) {
        if(id){
          const itemSearch = this[searchObj.options].find(({value}) => value === id)
          if(itemSearch) {
            this.formData[searchObj.key] = itemSearch.text
            return
          }
        }
        this.formData[searchObj.key] = null
      })
    }
    this.initData()
    this.fetchUserVenues()
  }
};
</script>
