<template>
  <div class="cq-mypage-dashboard p-3 p-md-5">
    <h4 class="title">
      ダッシュボード
    </h4>
    <div class="cq-mypage-dashboard-content" v-if="Object.keys(listDashboard).length">
      <div class="content-statistic">
        <div class="row">
          <div class="col-lg-4 col-12 mt-3">
            <ItemStatistic :count="listDashboard.advantageous.advantageous_month_ago" :title="'お得車両AIサーチ'" />
          </div>
          <div class="col-lg-4 col-12 mt-3">
            <ItemStatistic :count="listDashboard.flow_vehicle.flow_vehicle_month_ago" typeVarian="success" :title="'流れ車両AIアラート'" />
          </div>
          <div class="col-lg-4 col-12 mt-3">
            <ItemStatistic :count="listDashboard.shared_inventory.shared_inventory_month_ago" typeVarian="secondary" :title="'共有在庫AIアラート'" />
          </div>
        </div>
      </div>
      <h5 class="text-secondary my-4 my-md-5">最新のリコメンド情報</h5>
      <div class="content-recommend-number">
        <div class="row">
          <div class="col-lg-4 col-12 mt-1 mt-md-3">
            <ItemRecommend :number="listDashboard.advantageous.advantageous_today" :total="listDashboard.advantageous.advantageous_plan" :title="'今日の提案一覧'" :description="'お得車両AIサーチ'" />
          </div>
          <div class="col-lg-4 col-12 mt-5 mt-md-3">
            <ItemRecommend :number="listDashboard.flow_vehicle.flow_vehicle_today" :total="listDashboard.flow_vehicle.flow_vehicle_plan" typeVarian="success" :title="'今日の提案情報'" :description="'流れ車両AIアラート'"/>
          </div>
          <div class="col-lg-4 col-12 mt-5 mt-md-3">
            <ItemRecommend :number="listDashboard.shared_inventory.shared_inventory_today" :total="listDashboard.shared_inventory.shared_inventory_plan" typeVarian="secondary" :title="'今日の提案情報'" :description="'共有在庫AIアラート'" :fallbackTotal="'台'" />
          </div>
        </div>
      </div>
      <h6 class="text-secondary mt-5 mb-2">本日のリコメンド車両</h6>
      <div class="content-recommend">
        <template v-if="isLoading">
          <div class="d-flex my-4 justify-content-center">
            <b-spinner variant="primary" />
          </div>
        </template>
        <template v-else>
           <div class="row">
              <div
                v-for="(item, index) in recommendList"
                :key="index"
                class="col-12 col-md-6 col-lg-4 mt-4"
              >
                <Item
                  :itemData="item"
                  @handleFeedback="handleFeedback"
                  @editFeedback="editFeedback"
                />
              </div>
            </div>
        </template>
      </div>
    </div>
    <b-modal
      id="confirmFeedback"
      ref="confirmFeedback"
      modal-class="modal-confirm modal-confirm-sm"
      body-class="p-md-4 p-3"
      hide-footer
      hide-header
      centered
    >
      <div class="d-block title w-100">
        <div>
          <div
            style="position: absolute; top: 20px; right: 20px"
            @click="$bvModal.hide('confirmFeedback')"
          >
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="icon-xl"
              style="fill: var(--gray)"
            />
          </div>
        </div>
        <div class="mt-4 row">
          <div class="col-auto">
            <font-awesome-icon
              :icon="['fa-regular', 'thumbs-down']"
              class="icon-xl"
              style="fill: var(--danger)"
            />
          </div>
          <div class="col">
            <h4>リコメンドの評価</h4>
          </div>
        </div>
        <div class="mt-4">
          <div class="form-group">
            <label for="descriptionFeedback"> フィードバック： </label>
            <textarea
              class="form-control"
              id="descriptionFeedback"
              style="resize: none"
              rows="6"
              v-model="messageFeedback"
            ></textarea>
          </div>
        </div>
        <div class="mt-2">
          <button class="btn btn-success w-100" @click="confirmFeedbackDisLike()" :disabled="isLoadingFeedBack" id="btn_mypage_recommend_result_confirm_dislike">
            <b-spinner v-if="isLoadingFeedBack" small />
            送信
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Item from "@/components/recommend-result/all/item.vue";
import ItemStatistic from '@/components/mypage/ItemStatistic.vue';
import ItemRecommend from '@/components/mypage/ItemRecommend.vue';
import recommendResult from "@/utils/recommendResult"
import "@/assets/scss/mypage/dashboard.scss"
export default {
  layout: "user",
  auth: "user",
  head() {
    return {
      title: "ダッシュボード",
      meta: [
        { charset: "utf-8" },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
        },
      ],
    };
  },
  components: {
    ItemStatistic,
    ItemRecommend,
    Item
  },
  mixins: [recommendResult],
  data () {
    return {
      isLoading: false,
      recommendList: [],
      messageFeedback: "",
      currentIdFeedback: null,
      isLoadingFeedBack: false,
      listDashboard: {
        advantageous: {
          advantageous_total: 0,
          advantageous_today: 0,
          advantageous_plan: 0,
          advantageous_month_ago: 0,
        },
        flow_vehicle: {
          flow_vehicle_total: 0,
          flow_vehicle_today: 0,
          flow_vehicle_plan: 0,
          flow_vehicle_month_ago: 0,
        },
        shared_inventory: {
          shared_inventory_total: 0,
          shared_inventory_today: 0,
          shared_inventory_plan: 10,
          shared_inventory_month_ago: 0
        }
      },
    }
  },
  methods: {
    async fetchData () {
      this.isLoading = true
      const params = {
        recommend_type: 0,
        page: 1,
        per_page: 100,
      }
      const { data } = await this.$store.dispatch('recommnedDate', {
        params
      })
      this.recommendList = (data) ? data.recommends : []
      this.isLoading = false
    },
    async dashboardInfo() {
      const dashboardInfo = await this.$store.dispatch('dashboardInfo')
      if (dashboardInfo.success) {
        this.listDashboard = dashboardInfo.data;
      } else {
        if (dashboardInfo.errors.length && dashboardInfo.errors[0].message) {
          this.$toast.error(dashboardInfo.errors[0].message);
        }
      }
    },
  },
  created () {
    this.fetchData();
    this.dashboardInfo();
  }
}
</script>