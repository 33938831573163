<template>
  <b-modal
    id="modalCheckExtension"
    ref="modalCheckExtension"
    modal-class="modal-confirm modal-confirm-sm"
    body-class="p-md-4 p-3"
    hide-footer
    hide-header
    centered
  >
    <div class="d-block text-center title w-100">
      <div>
        <div
          style="position: absolute; top: 20px; right: 20px"
          @click="$bvModal.hide('modalCheckExtension')"
        >
          <font-awesome-icon
            icon="fa-solid fa-xmark"
            class="icon-xl"
            style="fill: var(--gray)"
          />
        </div>
      </div>
      <div class="px-4 mt-4">
        <h6>
          {{message_extension.MESSAGE_INSTALL}}
        </h6>
      </div>
      <div class="mt-5">
        <button
          v-if="step === 1"
          class="btn btn-primary w-100"
          id="modal_check_extension_link_store"
          @click="openInChromeStore"
          >
          {{message_extension.LINK_TO_STORE}}
        </button>
        <button
          v-else-if="step === 2"
          class="btn btn-success w-100"
          id="btn_modal_check_extension"
          :disabled="isLoadingCheck"
          @click="reCheckExtension()"
          >
          <b-spinner v-if="isLoadingCheck" small />
          {{message_extension.RECHECK_INSTALL}}
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
import checkExtension from '../utils/checkExtension';
import { Message } from '../utils/message';
export default {
  mixins: [ checkExtension ],
  data () {
    return {
      step: 1,
      message_extension: Message.CHROME_EXTENSION
    }
  },
  methods: {
    openInChromeStore () {
      window.open( this.$store.getters.extensionLink, "_blank")
      this.step = 2
    },
    async reCheckExtension() {
      await this.checkExtensionInstalled()
      if(this.hasInstalled) {
        // Handle success here
        this.isLoadingCheck = false
        this.$refs.modalCheckExtension.hide()
      }
      else {
        setTimeout(() => {
          this.isLoadingCheck = false
          this.$toast.error(this.message_extension.ERROR_INSTALL)
        }, 2000)
      }
    }
  }
}
</script>