<template>
  <div class="cq-menu">
    <div class="cq-menu-bg" @click="$emit('closeMenu', true)"></div>
    <div class="cq-menu-content">
      <div class="d-flex swipper" :class="{'swipped': dataLink[0].isCollapse}">
        <div class="cq-menu-control">
          <div v-if="!dataLink[0].isCollapse">
            <div v-for="(link, index) in dataLink" :key="index">
              <router-link custom v-slot="{ href, navigate }" :to="{name: link.routerName}">
                <a :href="href" @click="handleNavigate($event, navigate, link)" class="p-2 row justify-content-between align-items-center mt-2 cq-menu-link" >
                  <div class="row align-items-center col">
                    <div class="col-auto">
                      <div class="cq-menu-icon">
                        <font-awesome-icon class="" :icon="link.icon" />
                      </div>
                    </div>
                    <div class="col pl-0">
                      <div class="cq-menu-text">
                        {{link.text}}
                      </div>
                    </div>
                  </div>
                  <div class="col-auto cq-menu-icon-arrow" v-if="link.links">
                    <font-awesome-icon icon="fa-solid fa-angle-right" />
                  </div>
                </a>
              </router-link>
            </div>
          </div>
        </div>
        <div class="cq-menu-profile">
          <div v-if="dataLink[0].isCollapse">
            <div class="row align-items-center mb-2">
              <div class="col-auto">
                <div class="cq-menu-icon-back cursor-pointer" @click="dataLink[0].isCollapse = false">
                  <font-awesome-icon icon="fa-solid fa-arrow-left" />
                </div>
              </div>
              <div class="col">
                <div class="cq-menu-title">
                  マイページ
                </div>
              </div>
            </div>
            <div v-for="(link, index) in dataLink[0].links" :key="index">
              <router-link :to="{name: link.routerName}" custom v-slot="{ href, navigate }">
                <a :href="href" @click="handleNavigate($event, navigate, link)" class="p-2 row justify-content-between align-items-center w-100 cq-menu-link" >
                  <div class="row align-items-center col">
                    <div class="col-auto">
                      <div class="cq-menu-icon">
                        <font-awesome-icon class="" :icon="link.icon" />
                      </div>
                    </div>
                    <div class="col pl-0">
                      <div class="cq-menu-text">
                        {{link.text}}
                      </div>
                    </div>
                  </div>
                </a>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      dataLink: [{
        text: 'マイページ',
        icon: 'fa-solid fa-user',
        routerName: '',
        isCollapse: false,
        links: [{
          text: 'ダッシュボード',
          routerName: 'MypageDashboard',
          icon: 'fa-solid fa-house-user'
        }, {
          text: '会員情報',
          routerName: 'profileMypage',
          icon: 'fa-solid fa-id-badge'
        }, {
          text: 'プラン情報',
          routerName: 'mypagePlan',
          icon: 'fa-solid fa-rectangle-list'
        }, {
          text: '支払い情報',
          routerName: 'newCredit',
          icon: 'fa-solid fa-credit-card'
        }]
      }, {
        text: '在庫',
        icon: 'fa-solid fa-warehouse',
        routerName: 'stockViewsList'
      }, {
        text: 'リコメンド条件',
        icon: 'fa-solid fa-heart',
        routerName: 'recommend-condition-list'
      }, {
        text: 'チャット',
        icon: 'fa-solid fa-message',
        routerName: 'chat'
      }, {
        text: 'Chrome拡張機能',
        icon: 'fa-brands fa-chrome',
        routerName: '',
        click: () => {
          const url = this.$store.getters.extensionLink
          window.open(url, 'blank')
        }
      }, {
        text: 'お問い合わせ',
        icon: 'fa-solid fa-circle-question',
        routerName: '',
        click: () => {
          this.$bvModal.hide('contactPlan')
          this.$bvModal.show('contactAdmin')
        }
      },
      // {
      //   text: 'Abo',
      //   icon: 'fa-solid fa-envelope',
      //   // routerName: 'chromeExtensionPage'
      // }
      ],
      
    }
  },
  emits: ['closeMenu'],
  methods: {
    handleNavigate(e, callback, link) {
      callback(e)
      if(Object.prototype.hasOwnProperty.call(link, 'links')) {
        link.isCollapse = true
        return // Break code below at function
      }
      if(Object.prototype.hasOwnProperty.call(link, 'click')) {
        link.click()
      }
      this.$emit('closeMenu', true)
    }
  }
}
</script>
<style scoped lang="scss">
.cq-menu {
  max-width: 350px;
  padding: 1rem;
  border: 1px solid #BDBDBD;
  width: 100%;
  position: absolute;
  z-index: 1000;
  top: 65px;
  right: 10px;
  border-radius: 5px;
  background: $cq-white;
  overflow: hidden;
  box-shadow: #bdbdbd 0px 0px 10px 2px;
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    border-radius: 50%;
    background-color: #BDBDBD;
    &-arrow {
      font-size: 1.5rem;
    }
    &-back {
      font-size: 1.25rem;
    }
  }
  &-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: -1;
  }
  &-text {
    font-weight: 400;
    font-size: 1.1rem;
  }
  &-link {
    color: inherit;
  }
  &-content {
    width: 100%;
    overflow: hidden;
    .swipper {
      transition: transform .25s;
      &.swipped {
        transform: translateX(-100%);
      }
    }
  }
  &-control, &-profile {
    flex-shrink: 0;
    width: 100%;
  }
  &-title {
    font-size: 1.3rem;
    font-weight: bold;
  }
}
</style>