<template>
  <div class="stockNewCar cq-bg-gray pt-3">
    <CQPreLoad :preload="preLoading" />
    <div class="stockNewCar-title" v-if="!preLoading">在庫の車両情報編集</div>
    <div class="container-custom mt-3" v-if="!preLoading">
      <b-form class="stockNewCar-form">
        <b-modal
          id="alert-from-lot-no"
          ref="alert-from-lot-no"
          modal-class="modal-confirm modal-confirm-sm modal-auction-information"
          body-class="p-md-4 p-3"
          hide-footer
          hide-header
          centered
          scrollable
          v-on:shown="setZindex()"
        >
          <div class="d-block title w-100">
            <div class="text-center titel-header-modal mb-3 mt-4">オークション情報</div>
            <div class="stockNewCar-form-content mt-4">
              <div class="stockNewCar-form-content-items position-relative" @click="setOptionTop($event)">
                <label class="text-left w-100" for="maker">会場 <span class="text-danger">※</span></label>
                <model-select
                  class=""
                  v-model="auctionInformation.core_venue_id"
                  :options="optionCoreVenues"
                  size="sm"
                  id="core_venue_id"
                ></model-select>
                <div v-if="$v.auctionInformation.core_venue_id.$error" class="position-absolute input-error">
                  <span v-if="!$v.auctionInformation.core_venue_id.required">{{ message.ERROR_REQUIRE }}</span>
                </div>
              </div>  
              <div class="stockNewCar-form-content-items position-relative">
                <label class="text-left w-100" for="auctionInformation_lot_no">出品番号 <span class="text-danger">※</span></label>
                <b-input
                  class=""
                  size="sm"
                  v-model="$v.auctionInformation.lot_no.$model"
                  id="auctionInformation_lot_no"
                  placeholder="例）NPQ1934AAAD"
                  type="text"
                  autocomplete="off"
                ></b-input>
                <div v-if="$v.auctionInformation.lot_no.$error" class="position-absolute span-noti input-error">
                  <span v-if="!$v.auctionInformation.lot_no.required">{{ message.ERROR_REQUIRE }}</span>
                </div>
              </div>  
              <div class="w-100 mt-4">
                <label class="text-left w-100" for="maker">開催日</label>
                <b-form-datepicker
                  v-model="auctionInformation.open_on"
                  class="w-100 pl-2"
                  placeholder="開催日"
                  reset-button
                  label-reset-button="リセット"
                  v-bind="messageDate.ja || {}"
                  v-bind:date-format-options="{
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit'
                  }"
                  locale="ja"
                  dropzone
                  right
                  label-help=""
                  hide-header=""
                  menu-class="mt-1"
                  :max="currentDate"
              >
              <template #button-content>
                <font-awesome-icon icon="fa-solid fa-calendar-days" class="icon-large" />
              </template>
              </b-form-datepicker>
              </div>
            </div>
          </div>
          <div class="w-100 mt-5">
            <template v-if="dataLotNo.length">
              <b-table
                responsive
                sticky-header
                :items="dataLotNo"
                :fields="fields"
                class="w-100 h-100"
              >
                <template #cell(アクション)="data">
                  <b-button
                  id="modal-btn-continue"
                  class="cq-btn-bg-green"
                  v-on:click="$bvModal.show('selectionStock'), setZindex('selectionStock'), idGetStock = data.item.id"
                  >承認</b-button
                >
                </template>
              </b-table>
            </template>
          </div>
          <div class="w-100 d-flex mt-4 btn-action">
            <b-button
              id="modal-btn-cancel"
              variant="secondary"
              class="w-50 btn-confirm ml-4 mr-5"
              v-on:click="$bvModal.hide('alert-from-lot-no')"
              >キャンセル</b-button
            >
            <b-button
              id="modal-btn-continue"
              class="w-50 btn-confirm mr-4 cq-btn-bg-green"
              v-on:click="getDataLotNo"
              :disabled="isLoadingImport"
              >
              <b-spinner
              small
              v-if="isLoadingImport" />
              確定する
              </b-button
            >
          </div>
        </b-modal>
        <!-- basci infomations -->
        <div class="stockNewCar-form-title mt-3">基本情報</div>
        <div class="stockNewCar-form-content mt-3">
          <div class="stockNewCar-form-content-items">
            <label for="maker">メーカー <span class="text-danger">※</span></label>
            <ModelSelectBrands
              class=""
              v-model="$v.formData.core_brand_id.$model"
              :options="optionBrands"
              size="sm"
              id="core_brand_id"
              :disabled="disabledSelect"
              :is-convert2-full-width="true"
            ></ModelSelectBrands>
            <p
              v-if="!disabledSelect && $v.formData.core_brand_id.$error && !$v.formData.core_brand_id.required"
              class="error-name"
            >
              {{ message.ERROR_REQUIRE }}
            </p>
          </div>
          <div class="stockNewCar-form-content-items">
            <label for="maker">車名 <span class="text-danger">※</span></label>
            <model-select
              class=""
              v-model="$v.formData.core_car_id.$model"
              :options="optionCarName"
              :is-convert2-full-width="true"
              size="sm"
              id="core_car_id"
              :disabled="disabledSelect"
            ></model-select>
            <p
              v-if="!disabledSelect && $v.formData.core_car_id.$error && !$v.formData.core_car_id.required"
              class="error-name"
            >
              {{ message.ERROR_REQUIRE }}
            </p>
          </div>
          <div class="stockNewCar-form-content-items">
            <label for="formData_core_model_id">型式</label>
            <model-select
              class=""
              v-model="formData.core_model_id"
              :options="optionModel"
              size="sm"
              id="core_model_id"
            ></model-select>
          </div>  
          <div class="stockNewCar-form-content-items">
            <label for="maker">グレード <span class="text-danger">※</span></label>
            <model-select
              class=""
              v-model="$v.formData.core_grade_id.$model"
              :options="optionGrade"
              :is-convert2-full-width="true"
              size="sm"
              id="core_grade_id"
              :disabled="disabledSelect"
            ></model-select>
            <p
              v-if="!disabledSelect && $v.formData.core_grade_id.$error && !$v.formData.core_grade_id.required"
              class="error-name"
            >
              {{ message.ERROR_REQUIRE }}
            </p>
          </div>  
          <div class="w-100 stockNewCar-form-content-items">
            <label for="maker">年式</label>
            <model-select 
              v-model="formData.produced_on"
              class="w-100 pl-2"
              :options="coreYears"
            />
          </div>  
        </div>
        <!-- Detailed information -->
        <div class="stockNewCar-form-title mt-3">詳細情報</div>
         <div class="stockNewCar-form-content mt-3">
          <div class="stockNewCar-form-content-items position-relative">
            <label for="formData_core_color_id">色</label>
            <div>
              <model-select
                class=""
                v-model="formData.core_color_id"
                :is-convert2-full-width="true"
                :options="optionColor"
                size="sm"
                id="formData_core_color_id"
              ></model-select>
            </div>
          </div>
          <div class="stockNewCar-form-content-items position-relative">
            <label for="formData_core_gear_id">シフト</label>
            <div>
              <model-select
                class=""
                v-model="formData.core_gear_id"
                :options="optionGears"
                size="sm"
                id="formData_core_gear_id"
              ></model-select>
            </div>
          </div>
          <div class="stockNewCar-form-content-items position-relative">
            <label for="formData_chassis_number">車台番号</label>
            <b-input
              class=""
              v-model="$v.formData.chassis_number.$model" :state="$v.formData.chassis_number.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.chassis_number.$error }"
              size="sm"
              id="formData_chassis_number"
              autocomplete="off"
            ></b-input>
            <div v-if="$v.formData.chassis_number.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.chassis_number.regexCharacter">{{ message.ONLY_HALFWIDTH }}</span>
            </div>
          </div>  
          <div class="stockNewCar-form-content-items position-relative">
            <label for="formData_displacement">排気量</label>
            <b-input
              class=""
              v-model="$v.formData.displacement.$model" :state="$v.formData.displacement.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.displacement.$error }"
              v-on:keyup="convertPositive(formData.displacement, 'displacement')"
              size="sm"
              id="formData_displacement"
              placeholder="例）1500cc"
              autocomplete="off"
            ></b-input>
            <div v-if="$v.formData.displacement.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.displacement.integer">{{ message.NUMBER_ERROR }}</span>
            </div>
          </div>  
          <div class="stockNewCar-form-content-items position-relative">
            <label for="mortar_stock">走行</label>
             <b-input
              class=""
              v-model="$v.formData.odo.$model" :state="$v.formData.odo.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.odo.$error }"
              v-on:keyup="convertPositive(formData.odo, 'odo')"
              size="sm"
              id="mortar_stock"
              placeholder="例）1500km"
              autocomplete="off"
            ></b-input>
            <div v-if="$v.formData.odo.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.odo.integer">{{ message.NUMBER_ERROR }}</span>
            </div>
          </div> 
          <div class="stockNewCar-form-content-items">
            <label for="formData_fuel">燃料</label>
            <b-input
              class=""
              v-model="formData.fuel"
              size="sm"
              id="formData_fuel"
              type="text"
              autocomplete="off"
            ></b-input>
          </div> 
          <div class="w-100 mt-3 stockNewCar-form-content-items">
            <label for="maker">備考</label>
            <b-textarea
              class="p-3"
              v-model="formData.info"
              size="sm"
              id="maker"
              placeholder="例）ﾚﾝﾀｶｰ"
              type="text"
              rows="3"
              max-rows="6"
            ></b-textarea>
          </div> 
        </div>
        <!-- others -->
        <div class="stockNewCar-form-title mt-3">その他</div>
        <div class="stockNewCar-form-content mt-3">
          <div class="stockNewCar-form-content-items position-relative">
            <label for="exterior_score">外装評価</label>
            <model-select
              :options="exterior_score"
              v-model="formData.exterior_score"
              id="exterior_score"
            />
          </div> 
          <div class="stockNewCar-form-content-items position-relative">
            <label for="interior_score">内装評価</label>
            <model-select
              :options="listInteriorScore"
              v-model="formData.interior_score"
              id="interior_score"
            />
          </div> 
          <div class="w-100 mt-3 position-relative stockNewCar-form-content-items">
            <label for="formData_estimated_price">車体価格</label>
            <b-input
              class=""
              v-model="$v.formData.estimated_price.$model"
              :state="$v.formData.estimated_price.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.estimated_price.$error }"
              size="sm"
              v-on:keyup="convertPositive(formData.estimated_price, 'estimated_price')"
              id="formData_estimated_price"
              placeholder="例）230万円"
              autocomplete="off"
            ></b-input>
            <div v-if="$v.formData.estimated_price.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.estimated_price.decimal">{{ message.NUMBER_ERROR }}</span>
            </div>
          </div> 
        </div>
        <div class="stockNewCar-form-title-img mt-3">車両の画像 <span>(jpg, png, gif, jpeg)</span></div>
        <div class="stockNewCar-form-listImg d-flex justify-content-center">
          <div class="input-honnin mt-4 mb-4">
            <div class="position-relative">
              <label
                class="input-honnin-item"
                v-bind:class="{ active: !listImageStock.img1Base64 }"
                for="input-image1"
              >
                <div v-if="!listImageStock.img1Base64" class="input-honnin-item-times">
                  <font-awesome-icon class="cq-sidebar-mypage-icon" icon="fa-solid fa-plus" />
                </div>
                <div
                  v-if="listImageStock.img1Base64"
                  class="input-honnin-item-image"
                >
                  <img class="img-contain" v-bind:src="listImageStock.img1Base64" alt="" />
                </div>
              </label>
              <div
                v-if="listImageStock.img1Base64"
                class="input-honnin-item-close"
                v-on:click="deleteImage(1)"
              >
                <font-awesome-icon class="cq-sidebar-mypage-icon" icon="fa-solid fa-minus" />
              </div>
            </div>
            <div class="position-relative">
              <label
                class="input-honnin-item"
                v-bind:class="{ active: !listImageStock.img2Base64 && listImageStock.img1Base64 }"
                for="input-image2"
              >
                <div
                  v-if="!listImageStock.img2Base64 && listImageStock.img1Base64"
                  class="input-honnin-item-times"
                >
                  <font-awesome-icon class="cq-sidebar-mypage-icon" icon="fa-solid fa-plus" />
                </div>
                <div
                  v-if="listImageStock.img2Base64"
                  class="input-honnin-item-image"
                >
                  <img class="img-contain" v-bind:src="listImageStock.img2Base64" alt="" />
                </div>
              </label>
              <div
                v-if="listImageStock.img2Base64"
                class="input-honnin-item-close"
                v-on:click="deleteImage(2)"
              >
                <font-awesome-icon class="cq-sidebar-mypage-icon" icon="fa-solid fa-minus" />
              </div>
            </div>
            <div class="position-relative item-bot">
              <label
                class="input-honnin-item"
                v-bind:class="{ active: !listImageStock.img3Base64 && listImageStock.img2Base64 }"
                for="input-image3"
              >
                <div
                  v-if="!listImageStock.img3Base64 && listImageStock.img2Base64"
                  class="input-honnin-item-times"
                >
                  <font-awesome-icon class="cq-sidebar-mypage-icon" icon="fa-solid fa-plus" />
                </div>
                <div
                  v-if="listImageStock.img3Base64"
                  class="input-honnin-item-image"
                >
                  <img class="img-contain" v-bind:src="listImageStock.img3Base64" alt="" />
                </div>
              </label>
              <div
                v-if="listImageStock.img3Base64"
                class="input-honnin-item-close"
                v-on:click="deleteImage(3)"
              >
                <font-awesome-icon class="cq-sidebar-mypage-icon" icon="fa-solid fa-minus" />
              </div>
            </div>
            <div class="position-relative item-bot">
              <label
                class="input-honnin-item"
                v-bind:class="{ active: !listImageStock.img4Base64 && listImageStock.img3Base64 }"
                for="input-image4"
              >
                <div
                  v-if="!listImageStock.img4Base64 && listImageStock.img3Base64"
                  class="input-honnin-item-times"
                >
                  <font-awesome-icon class="cq-sidebar-mypage-icon" icon="fa-solid fa-plus" />
                </div>
                <div
                  v-if="listImageStock.img4Base64"
                  class="input-honnin-item-image"
                >
                  <img class="img-contain" v-bind:src="listImageStock.img4Base64" alt="" />
                </div>
              </label>
              <div
                v-if="listImageStock.img4Base64"
                class="input-honnin-item-close"
                v-on:click="deleteImage(4)"
              >
                <font-awesome-icon class="cq-sidebar-mypage-icon" icon="fa-solid fa-minus" />
              </div>
            </div>
          </div>
          <input
            id="input-image1"
            hidden
            v-bind:disabled="listImageStock.img1Base64"
            type="file"
            accept="image/png, .pdf, .jpg, .gif"
            v-on:change="changeImage($event, 1)"
          />
          <input
            id="input-image2"
            hidden
            v-bind:disabled="listImageStock.img2Base64 || !listImageStock.img1Base64"
            type="file"
            accept="image/png, .pdf, .jpg, .gif"
            v-on:change="changeImage($event, 2)"
          />
          <input
            id="input-image3"
            hidden
            v-bind:disabled="listImageStock.img3Base64 || !listImageStock.img2Base64"
            type="file"
            accept="image/png, .pdf, .jpg, .gif"
            v-on:change="changeImage($event, 3)"
          />
          <input
            id="input-image4"
            hidden
            v-bind:disabled="listImageStock.img4Base64 || !listImageStock.img3Base64"
            type="file"
            accept="image/png, .pdf, .jpg, .gif"
            v-on:change="changeImage($event, 4)"
          />
        </div>
        <div class="w-100 mt-3 mb-4 d-flex justify-content-center">
         <b-button
            variant="secondary"
            class="w-50 btn-confirm mr-5"
            v-on:click="$router.push('/stock/list')"
            id="btn_stock_edit_return"
          >
            キャンセル
          </b-button>
          <b-button
            class="cq-btn-bg-green cq-border-green w-50"
            id="btn_stock_edit_save"
            v-on:click="updateStockCar">
            編集する
          </b-button>
        </div>
      </b-form>
      <b-modal
        id="selectionStock"
        ref="selectionStock"
        modal-class="modal-confirm modal-confirm-sm modal-selection-stock"
        body-class="p-md-4 p-3" 
        hide-footer
        hide-header
        centered
        >
        <div class="d-block text-center w-100 mb-5">
          <h1 class="text-warning">
            <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
          </h1>
          <p>この車両の情報を選択しますか？</p>
        </div>
        <div class="mt-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap">
          <b-button class="btn-bot" v-on:click="$bvModal.hide('selectionStock')" id="btn_stock_hide_modal_selection">いいえ</b-button>
          <b-button class="cq-btn-bg-green btn-top" v-on:click="selectionStock" id="btn_stock_new_confirm_modal_selection" :disabled="isMapping">
            <b-spinner
              small
              v-if="isMapping"
            />
            はい
          </b-button>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import '@/assets/scss/stock/new-car/newcar.scss'
import { Message } from '@/utils/message'
import { required, integer, decimal, helpers } from 'vuelidate/lib/validators'
import Multiselect from 'vue-multiselect'
import { convertNumber, characterRegex } from "@/utils/commonEvent";
import CQPreLoad from "@/components/CQPreLoad.vue";
import { tableFields } from '@/utils/table-fields'
import { INTERIOR_SCORE, SCORE } from '@/utils/score';
import fullwidth from "fullwidth"
import ModelSelectBrands from '@/components/ModelSelectBrands.vue';
const exterior_score = JSON.parse(JSON.stringify(SCORE))
exterior_score[0].text = '---'

const regexCharacter = helpers.regex('alpha', /^([a-zA-Z0-9]+-[a-zA-Z0-9]+)$|^([a-zA-Z0-9]+-*)$|^(-*[a-zA-Z0-9]+)$/)

export default {
  name: 'stockEditCar',
  layout: 'user',
  auth: "user",
  mixins: [convertNumber],
  head() {
    return {
      title: "車両編集",
    };
  },
  components: { 
    Multiselect,
    CQPreLoad,
    ModelSelectBrands,
  },
  data() {
    return {
      messageDate: Message.LABLE_DATE,
      message: Message.STOCK,
      fields: tableFields.LIST_AUCTION_INFORMATION,
      listInteriorScore: INTERIOR_SCORE,
      items: [],
      preLoading: true,
      listImageStock: {
        img1Base64: null,
        img2Base64: null,
        img3Base64: null,
        img4Base64: null,
      },
      listImage: [],
      dataStock: [],
      dataDetail: false,
      optionBrands:[{
        text: "------",
        value: null
      }],
      optionCarName: [{
        text: "------",
        value: null
      }],
      optionModel: [{
        text: "------",
        value: null
      }],
      optionGrade: [{
        text: "------",
        value: null
      }],
      optionColor: [{
        text: "------",
        value: null
      }],
      optionGears: [{
        text: "------",
        value: null
      }],
      optionCoreVenues: [{
        text: "------",
        value: null,
      }],
      coreYears: [{
        text: "------",
        value: null,
      }],
      selectedInteriorScore: null,
      imgData1: null,
      imgData2: null,
      imgData3: null,
      imgData4: null,
      listRemoveImg: [],
      currentDate: new Date(),
      auctionInformation: {
        core_venue_id: null,
        auction_venue: null,
        open_on: new Date(),
        lot_no: null,
      },
      formData: {
        chassis_number: null,
        displacement: null,
        equipments: [],
        fuel: null,
        core_brand_id: null,
        core_car_id: null,
        core_model_id: null,
        grade_ids: [],
        model_ids: [],
        produced_on: null,
        core_gear_id: null,
        core_grade_id: null,
        core_color_id: null,
        odo: null,
        exterior_score: null,
        interior_score: null,
        info: "",
        model: null,
        estimated_price: null,
        imgs: []
      },
      idGetStock: null,
      dataLotNo: [],
      disabledSelect: false,
      error_exterior_score: "",
      error_interior_score: "",
      groupByGrade: {},
      exterior_score,
      dataImport: {
        brand: "",
        car_name: "",
        model: "",
        grade: "",
      },
      isLoadingImport: false,
    }
  },
  watch: {
    async 'formData.core_model_id'() {
      if(!this.dataImport.grade && !this.dataImport.core_grade_id) this.formData.core_grade_id = null
      this.optionGrade = [this.optionGrade[0]]
      if(this.formData.core_model_id === null) {
        if(!this.formData.core_car_id) return
        const getDataModel = await this.$store.dispatch('getDataModel', this.formData.core_car_id);
        if (getDataModel.success) {
          const gradesRes = getDataModel.data.grades
          if(this.dataImport.grade || this.dataImport.core_grade_id) {
            const grade = gradesRes.find(({ name, id }) => id && (this.dataImport.core_grade_id == id || this.fullWidthUpperCase(name) == this.fullWidthUpperCase(this.dataImport.grade)))
            if(grade) this.formData.core_grade_id = grade.id
          }
          const grades = this.lodash.uniqBy(gradesRes, (grade) => grade.name).map((grade) => {
            if(grade.id && (this.dataImport.core_grade_id == grade.id || this.fullWidthUpperCase(this.dataImport.grade) == this.fullWidthUpperCase(grade))) return {
              value: this.formData.core_grade_id,
              text: grade.name
            }
            return { value: grade.id, text: grade.name }
          })
          this.groupByGrade = this.lodash.groupBy(gradesRes, (grade) => grade.name)
          this.optionGrade = [this.optionGrade[0], ...grades]
          this.dataImport.core_grade_id = this.dataImport.grade = ""
        }
        return
      }
      const getDataGrade = await this.$store.dispatch('getDataGrade', this.formData.core_model_id);
      if (getDataGrade.success) {
        if(this.dataImport.grade || this.dataImport.core_grade_id) {
          const grade = getDataGrade.data.grades.find(({ name, id }) => id && (this.dataImport.core_grade_id == id || this.fullWidthUpperCase(name) == this.fullWidthUpperCase(this.dataImport.grade)))
          if(grade) this.formData.core_grade_id = grade.id
          this.dataImport.core_grade_id = this.dataImport.grade = ""
        }
        getDataGrade.data.grades.forEach(grades => {
          this.optionGrade.push({ value: grades.id, text: grades.name });
        });
        getDataGrade.data.grades.forEach(grades => {
          this.optionGrade.push({ value: grades.id, text: grades.name });
        });
        this.groupByGrade = this.lodash.groupBy(getDataGrade.data.grades, (grade) => grade.name)
      } else {
          if (getDataGrade.errors.length && getDataGrade.errors[0].message) this.$toast.error(getDataGrade.errors[0].message);
      };
    },
    async 'formData.core_car_id'() {
      if(!this.dataImport.model && !this.dataImport.core_model_id) this.formData.core_model_id = null
      this.optionModel = [this.optionModel[0]]
      if(this.formData.core_car_id === null) {
        return
      }
      const getDataModel = await this.$store.dispatch('getDataModel', this.formData.core_car_id);
      if (getDataModel.success) {
        getDataModel.data.models.forEach(model => {
          this.optionModel.push({ value: model.id, text: model.name });
        });
        const model = this.optionModel.find((model) => model.value && (this.dataImport.core_model_id == model.value || this.fullWidthUpperCase(model.text) == this.fullWidthUpperCase(this.dataImport.model)))
        if(model) this.formData.core_model_id = model.value
        const gradesRes = getDataModel.data.grades
        if(this.dataImport.grade || this.dataImport.core_grade_id) {
          const grade = gradesRes.find(({ name, id }) => id && (id == this.dataImport.core_grade_id || this.fullWidthUpperCase(name) == this.fullWidthUpperCase(this.dataImport.grade)))
          if(grade) this.formData.core_grade_id = grade.id
        }
        const grades = this.lodash.uniqBy(gradesRes, (grade) => grade.name).map((grade) => {
          if(grade.id && (this.fullWidthUpperCase(this.dataImport.grade) == this.fullWidthUpperCase(grade.name) || this.dataImport.core_grade_id == grade.id)) {
            return {
              value: this.formData.core_grade_id,
              text: grade.name
            }
          }
          return { value: grade.id, text: grade.name }
        })
        this.groupByGrade = this.lodash.groupBy(gradesRes, (grade) => grade.name)
        this.optionGrade = [this.optionGrade[0], ...grades]
        if(!this.dataImport.model && !this.dataImport.core_model_id) this.dataImport.grade =  ""
        this.core_model_id = this.dataImport.model = ""
      } else {
          if (getDataModel.errors.length && getDataModel.errors[0].message) this.$toast.error(getDataModel.errors[0].message);
      };
    },
    async 'formData.core_brand_id'() {
      this.optionCarName = [this.optionCarName[0]];
      if(!this.dataImport.car_name && !this.dataImport.core_car_id) this.formData.core_car_id = null
      if(this.formData.core_brand_id === null) {
        return
      }
      const getDataCarName = await this.$store.dispatch('getDataCarName', this.formData.core_brand_id);
      if (getDataCarName.success) {
          getDataCarName.data.cars.forEach(carNames => {
            this.optionCarName.push({ value: carNames.id, text: carNames.name });
          });
          const car = this.optionCarName.find((carNames) => (this.dataImport.core_car_id == carNames.value || this.fullWidthUpperCase(this.dataImport.car_name) == this.fullWidthUpperCase(carNames.text)))
          if(car) this.formData.core_car_id = car.value
          this.dataImport.core_car_id = this.dataImport.car_name = ""
      } else {
          if (getDataCarName.errors.length && getDataCarName.errors[0].message) this.$toast.error(getDataCarName.errors[0].message);
      }
    }
  },
  async created() {
    await this.getOptionStock();
    this.getDetailStock();
    this.getCoreVenueStock();
  },
  methods: {
    fullwidth,
    fullWidthUpperCase(str) {
      const _str = str ?? ""
      return this.fullwidth(_str.toLocaleUpperCase())
    },
    setZindex(elementId) {
      setTimeout(() => {
        let alertFrom = document.getElementById("alert-from-lot-no");
        if (elementId) alertFrom = document.getElementById(elementId);
        alertFrom.parentNode.style["z-index"] = "1052";
      },200)
    },
    async getDetailStock () {
      this.dataDetail = true;
      const idStock  = this.$route.params.id;
      const getDetailStock = await this.$store.dispatch('getDetailStock', idStock);
      if (getDetailStock.success) {
        this.formData = {...this.formData, ...getDetailStock.data.stock};
        this.formData.core_brand_id  = getDetailStock.data.stock.core_brand.id;
        this.dataImport.core_car_id = getDetailStock.data.stock.core_car.id;
        this.dataImport.core_model_id = getDetailStock.data.stock.core_model.id;
        this.dataImport.core_grade_id = getDetailStock.data.stock.core_grade.id;
        this.formData.estimated_price = getDetailStock.data.stock.estimated_price / 10000;
        if (getDetailStock.data.stock.imgs) {
          let index = 0;
          for (const key in this.listImageStock) {
            if ((index + 1) > getDetailStock.data.stock.imgs.length) break;
            this.listImageStock[key] = getDetailStock.data.stock.imgs[index].url;
            this.listImage.push({
              type: 'pdf',
              src: getDetailStock.data.stock.imgs[index].url
            })
            this['imgData' + (index + 1)] = getDetailStock.data.stock.imgs[index];
            index ++;
          }
        }
        this.preLoading = false;
      } else {
          if (getDetailStock.errors.length && getDetailStock.errors[0].message) this.$toast.error(getDetailStock.errors[0].message);
      };
    },
    async getCoreVenueStock() {
      const getCoreVenueStock = await this.$store.dispatch('getCoreVenueStock');
      if (getCoreVenueStock.success) {
        getCoreVenueStock.data.core_venues.forEach(coreVenues => {
          this.optionCoreVenues.push({ value: coreVenues.id, text: coreVenues.name });
        });
      } else {
        if (getCoreVenueStock.errors.length && getCoreVenueStock.errors[0].message) this.$toast.error(getCoreVenueStock.errors[0].message);
      };
    },
    showModalLotNo() {
      this.$v.auctionInformation.core_venue_id.$reset();
      this.$v.auctionInformation.lot_no.$reset();
      this.auctionInformation.auction_venue = null; 
      this.auctionInformation.core_venue_id = null; 
      this.auctionInformation.lot_no = null; 
      this.auctionInformation.open_on = new Date(); 
      this.items = [];
      this.$refs['alert-from-lot-no'].show();
    },
    changeImage(e, id) {
      if (e.target.files.length) {
        const fileReader = new FileReader()
        const _this = this
        fileReader.readAsDataURL(e.target.files[0])
        if (id === 1) {
          fileReader.onload = (fileLoadedEvent) => {
            if (e.target.files[0].type === 'application/pdf') {
              _this.listImageStock.img1Base64 = require('@/assets/img/stock-img-3.jpg')
              _this.listImage.push({
                type: 'pdf',
                src: fileLoadedEvent.target.result
              })
            } else {
              _this.listImageStock.img1Base64 = fileLoadedEvent.target.result
              _this.listImage.push({ type: 'image', src: _this.listImageStock.img1Base64 })
            }
            _this.imgData1 = e.target.files[0]
            e.target.value = null
          }
        } else if (id === 2) {
          fileReader.onload = (fileLoadedEvent) => {
            if (e.target.files[0].type === 'application/pdf') {
              _this.listImage.push({
                type: 'pdf',
                src: fileLoadedEvent.target.result
              })
            } else {
              _this.listImageStock.img2Base64 = fileLoadedEvent.target.result
              _this.listImage.push({ type: 'image', src: _this.listImageStock.img2Base64 })
            }
            _this.imgData2 = e.target.files[0]
            e.target.value = null
          }
        } else if (id === 3) {
          fileReader.onload = (fileLoadedEvent) => {
            if (e.target.files[0].type === 'application/pdf') {
              _this.listImage.push({
                type: 'pdf',
                src: fileLoadedEvent.target.result
              })
            } else {
              _this.listImageStock.img3Base64 = fileLoadedEvent.target.result
              _this.listImage.push({ type: 'image', src: _this.listImageStock.img3Base64 })
            }
            _this.imgData3 = e.target.files[0]
            e.target.value = null
          }
        } else {
          fileReader.onload = (fileLoadedEvent) => {
            if (e.target.files[0].type === 'application/pdf') {
              _this.listImage.push({
                type: 'pdf',
                src: fileLoadedEvent.target.result
              })
            } else {
              _this.listImageStock.img4Base64 = fileLoadedEvent.target.result
              _this.listImage.push({ type: 'image', src: _this.listImageStock.img4Base64 })
            }
            _this.imgData4 = e.target.files[0]
            e.target.value = null
          }
        }
      }
    },
    uploadDataStock() {
      let dataForm = new FormData()
      for (const key in this.formData) {
        if (this.formData[key]) {
          if (Array.isArray(this.formData[key])) {
            for (const value of this.formData[key]) {
              if (key === "equipments") dataForm.append("equipments[]", value);
            }
            if (key === "equipments" && !this.formData[key].length) dataForm.append(dataForm.getAll("equipments[]"), []);
          } else {
            if(key === "estimated_price") dataForm.append("estimated_price", this.formData[key] * 10000) // Convert from 万 to integer
            else dataForm.append(key, this.formData[key]);
          } 
        } else if (typeof this.formData[key] === "boolean") {
          dataForm.append(key, false);
        } else dataForm.append(key, "");
      }
      dataForm.append("model_ids[]",this.formData.core_model_id)
      const optionGrade = this.optionGrade.find((grade) => grade.value === this.formData.core_grade_id)
      if(optionGrade) {
        const grades = this.groupByGrade[optionGrade.text]
        grades.forEach((grade) => {
          dataForm.append("grade_ids[]", grade.id)
        })
      }
      if (this.imgData1 && !this.imgData1.url) {
        dataForm.append('images[]', this.imgData1);
      }
      if (this.imgData2 && !this.imgData2.url) {
        dataForm.append('images[]', this.imgData2);
      }
      if (this.imgData3 && !this.imgData3.url) {
        dataForm.append('images[]', this.imgData3);
      }
      if (this.imgData4 && !this.imgData4.url) {
        dataForm.append('images[]', this.imgData4);
      }
      if (this.listRemoveImg.length) {
        this.listRemoveImg.forEach((listRemoveImg) => {
          dataForm.append('delete_images[]', listRemoveImg);
        })
      }
      return dataForm;
    },
    deleteImage(id) {
      if (id === 1) {
        const obj = this.listImage.find((e) => e.src === this.listImageStock.img1Base64)
        this.listImage.splice(this.listImage.indexOf(obj), 1)
        if (this.imgData1.url) {
          this.listRemoveImg.push(this.imgData1.id)
        }
        this.listImageStock.img1Base64 = this.listImageStock.img2Base64
        this.listImageStock.img2Base64 = this.listImageStock.img3Base64
        this.listImageStock.img3Base64 = this.listImageStock.img4Base64
        this.listImageStock.img4Base64 = null
        this.imgData1 = this.imgData2
        this.imgData2 = this.imgData3
        this.imgData3 = this.imgData4
        this.imgData4 = null
      } else if (id === 2) {
        const obj = this.listImage.find((e) => e.src === this.listImageStock.img2Base64)
        this.listImage.splice(this.listImage.indexOf(obj), 1)
        if (this.imgData2.url) {
          this.listRemoveImg.push(this.imgData2.id)
        }
        this.listImageStock.img2Base64 = this.listImageStock.img3Base64
        this.listImageStock.img3Base64 = this.listImageStock.img4Base64
        this.listImageStock.img4Base64 = null
        this.imgData2 = this.imgData3
        this.imgData3 = this.imgData4
        this.imgData4 = null
      } else if (id === 3) {
        const obj = this.listImage.find((e) => e.src === this.listImageStock.img3Base64)
        this.listImage.splice(this.listImage.indexOf(obj), 1)
        if (this.imgData3.url) {
          this.listRemoveImg.push(this.imgData3.id)
        }
        this.listImageStock.img3Base64 = this.listImageStock.img4Base64
        this.listImageStock.img4Base64 = null
        this.imgData3 = this.imgData4
        this.imgData4 = null
      } else {
        const obj = this.listImage.find((e) => e.src === this.listImageStock.img4Base64)
        this.listImage.splice(this.listImage.indexOf(obj), 1)
        if (this.imgData4.url) {
          this.listRemoveImg.push(this.imgData4.id)
        }
        this.listImageStock.img4Base64 = null
        this.imgData4 = null
      }
    },
    async getDataLotNo() {
      this.$toast.clear()
      this.$v.auctionInformation.$touch()
      let result = !this.$v.auctionInformation.$error
      if (result) {
        this.isLoadingImport = true
        const venue  = this.optionCoreVenues.find(venue => venue.value === this.auctionInformation.core_venue_id);
        const getDataLotNo = await this.$store.dispatch('getDataLotNo', {
          params: {
            ...this.auctionInformation,
            auction_venue: venue.text
          },
        });
        this.isLoadingImport = false
        if (getDataLotNo.success) {
          let alertFrom = document.getElementById("alert-from-lot-no");
          alertFrom.parentNode.style["z-index"] = "1042";
          if (getDataLotNo.data.data.length) {
            this.dataLotNo = getDataLotNo.data.data;
          }
          if (!this.formData.core_brand_id) {
            this.formData.core_brand_id = null;
            this.formData.core_grade_id = null;
            this.formData.core_car_id = null;
          }
        } else {
          if (getDataLotNo.errors.length && getDataLotNo.errors[0].message) this.$toast.error(getDataLotNo.errors[0].message);
        };
      }
    },
    async updateStockCar() {
      this.$toast.clear()
      this.$v.formData.$touch()
      const result = !this.$v.formData.$error
      if (result) {
        const uploadData = this.uploadDataStock();
        const formdata = {
            id: this.$route.params.id,
            data: uploadData,
        }
        const updateStockCar = await this.$store.dispatch('updateStockCar', formdata);
        if (updateStockCar.success) {
            this.$toast.success(updateStockCar.message);
            this.$router.push("/stock/list");
        } else {
            if (updateStockCar.errors.length && updateStockCar.errors[0].message) this.$toast.error(updateStockCar.errors[0].message);
        };
      } else this.focusFormData(this.$v.formData);
    },
    async getOptionStock() {
      const dataMaster = this.$store.getters.carMap
      await dataMaster.loader
      this.coreYears = [this.coreYears[0], ...dataMaster.years]
      dataMaster.colors.forEach(color => {
        this.optionColor.push({ value: color.id, text: color.name });
      });
      dataMaster.brands.forEach(brand => {
        this.optionBrands.push({ value: brand.id, text: brand.name, core_country_id: brand.core_country_id });
      });
      dataMaster.gears.forEach(gear => {
        if (gear.name === 'AT' || gear.name === 'MT') this.optionGears.push({ value: gear.id, text: gear.name });
      });
    },
    async mappingCarToMaster (carInfo) {
      const brands = this.optionBrands.filter(({ text, value }) => this.fullwidth(text) == this.fullWidthUpperCase(carInfo.brand))
      for (const brand of brands) {
        let checkBrand = false
        const getDataCarName = await this.$store.dispatch('getDataCarName', brand.value);
        if (getDataCarName.success) {
          const cars = getDataCarName.data.cars.filter(({ name}) => {
            return this.fullWidthUpperCase(carInfo.car_name) == this.fullWidthUpperCase(name)
          })
          let checkCar = false
          for (const car of cars) {
            const getDataModel = await this.$store.dispatch('getDataModel', car.id);
            if (getDataModel.success) {
              const models = getDataModel.data.models.filter(({ name}) => {
                return this.fullWidthUpperCase(carInfo.model) == this.fullWidthUpperCase(name)
              })
              let checkModel = false
              for (const model of models) {
                const getDataGrade = await this.$store.dispatch('getDataGrade', model.id);
                const grade = getDataGrade.data.grades.find(({ name }) => this.fullWidthUpperCase(name) == this.fullWidthUpperCase(carInfo.grade))
                if(grade) {
                  this.dataImport.core_grade_id = grade.id
                  this.dataImport.core_model_id = model.id
                  this.dataImport.model = ""
                  this.dataImport.grade = ""
                  checkModel = true
                  break
                }
              }
              if(checkModel) {
                this.dataImport.core_car_id = car.id
                this.dataImport.car_name = ""
                checkCar = true
                break
              }
            } else {
                if (getDataModel.errors.length && getDataModel.errors[0].message) this.$toast.error(getDataModel.errors[0].message);
            };
          }
          if(checkCar) {
            this.dataImport.core_brand_id = brand.value
            this.dataImport.brand = ""
            checkBrand = true
            break
          }
        } else {
            if (getDataCarName.errors.length && getDataCarName.errors[0].message) this.$toast.error(getDataCarName.errors[0].message);
        }
        if(checkBrand) break
      }
    },
    async selectionStock() {
      const objStock = this.dataLotNo.find(x => x.id === this.idGetStock);
      this.dataImport = {
        brand: "",
        car_name: "",
        model: "",
        grade: "",
        core_grade_id: "",
        core_brand_id: "",
        core_car_id: "",
        core_model_id: "",
        ...objStock, ...objStock.aa_car
      };
      if(!this.dataImport.model) this.dataImport.model = ""
      for (const key in this.formData) {
        if (Object.hasOwnProperty.call(this.formData, key) && typeof this.formData[key] !== "object") {
          this.formData[key] = null
        }
      }
      this.isMapping = true
      await this.mappingCarToMaster({...this.dataImport})
      this.isMapping = false
      if(this.dataImport.brand || this.dataImport.core_brand_id) {
        const brand = this.optionBrands.find(({ text, value }) => value == this.dataImport.core_brand_id || fullwidth(text) == fullwidth(this.dataImport.brand))
        if(brand) this.formData.core_brand_id = brand.value
        this.dataImport.brand = ""
        const carName = this.optionCarName.find(({ text, value }) => value == this.dataImport.core_car_id || fullwidth(text) == fullwidth(this.dataImport.car_name))
        if(carName) this.formData.core_car_id = carName.value
        const model = this.optionModel.find(({ text, value }) => value == this.dataImport.core_model_id || fullwidth(text) == fullwidth(this.dataImport.model))
        if(model) this.formData.core_model_id = model.value
        const grade = this.optionGrade.find(({ text, value }) => value == this.dataImport.core_grade_id || fullwidth(text) == fullwidth(this.dataImport.grade))
        if(grade) this.formData.core_grade_id = grade.value
      }
      if(this.dataImport.gear) {
        const gear = this.optionGears.find(({ text }) => this.fullWidthUpperCase(text) == this.fullWidthUpperCase(this.dataImport.gear))
        if(gear) this.formData.gear = gear.value
      }
      if(this.dataImport.color) {
        const color = this.optionColor.find(({ text }) => this.fullWidthUpperCase(text) == this.fullWidthUpperCase(this.dataImport.color))
        if(color) this.formData.core_color_id = color.value
      }
      if(this.dataImport.exterior_score) {
        this.formData.exterior_score = this.dataImport.exterior_score
      }
      if(this.dataImport.interior_score) {
        this.formData.interior_score = this.dataImport.interior_score
      }
      if(this.dataImport.odo) {
        this.formData.odo = this.dataImport.odo
      }
      if(this.dataImport.chassis_number) {
        this.formData.chassis_number = this.dataImport.chassis_number
      }
      if(this.dataImport.produced_on) {
        const year = this.coreYears.find(({ value }) => {
          if(value) {
            const year = new Date(value)
            return year.getFullYear() == this.dataImport.produced_on
          } 
        })
        if(year) this.formData.produced_on = year.value
      }
      if(this.dataImport.displacement) {
        this.formData.displacement = this.dataImport.displacement
      }
      if(this.dataImport.fuel) {
        this.formData.fuel = this.dataImport.fuel
      }

      this.listImageStock = {
        img1Base64: null,
        img2Base64: null,
        img3Base64: null,
        img4Base64: null,
      },
      this.disabledSelect = true;
      this.$refs['alert-from-lot-no'].hide();
      this.$refs['selectionStock'].hide();
    },
    setNumber (value, fields) {
      this[fields] = "";
      if (!Number(value)) this[fields] = (fields === "error_exterior_score") ? this.message.NUMBER_EXTERIOR_SCORE : this.message.NUMBER_INTERIOR_SCORE;
      if (Number(value) > 5.0 || Number(value) < 0.0) this[fields] = this.message.ERROR_NUMBER_STOCK;
      return value;
    },
  },
  validations () {
    return {
      formData: {
        core_brand_id: { required },
        core_car_id: { required },
        core_grade_id: { required },
        chassis_number: { regexCharacter },
        displacement: {integer},
        odo: {integer},
        estimated_price: {decimal},
      },
      auctionInformation: {
        core_venue_id: { required },
        lot_no: { required },
      },
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
