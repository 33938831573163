import { BFormDatepicker } from "bootstrap-vue";
import { constrainDate, formatYMD } from 'bootstrap-vue/src/utils/date'
export default {
  extends: BFormDatepicker,
  computed: {
    computedResetValue() {
      return formatYMD(constrainDate(this.resetValue)) || null
    },
  },
  watch: {
    localYMD(newValue) {
      // We only update the v-model when the datepicker is open
      if (this.isVisible) {
        this.$emit('input', this.valueAsDate ? parseYMD(newValue) || null : newValue || null)
      }
    },
  }
}