<template>
<div>
    <b-modal
      modal-class="cq-modal-stock"
      id="modal-stock"
      title="在庫情報"
      size="xl"
      centered
      dialog-class="cq-dialog-stock"
    >
      <div class="cq-modal-content">
        <div class="content-form mt-2">
          <div>
            <div class="row flex-nowrap">
                <div class="col-12 col-lg-3 mt-3" @click="setOptionTop($event)">
                  <ModelSelectBrands
                    v-model="formData.core_brand_id"
                    id="formData_brand"
                    :is-convert2-full-width="true"
                    :options="optionsBrands"
                  />
                </div>
                <div class="col-12 col-lg-3 mt-3" @click="setOptionTop($event)">
                  <model-select
                    v-model="formData.core_car_id"
                    :options="optionsCarName"
                    id="formData_core_car_id"
                    :is-convert2-full-width="true"
                  />
                </div>
                <div class="col-12 col-lg-3 mt-3" @click="setOptionTop($event)">
                  <model-select
                    v-model="formData.core_model_id"
                    :options="optionsModel"
                    id="formData_core_model_id"
                  />
                </div>
                <div class="col-12 col-lg mt-3" @click="setOptionTop($event)">
                  <model-select
                    v-model="formData.core_grade_id"
                    :options="optionsGrade"
                    :is-convert2-full-width="true"
                    id="formData_grade"
                  />
                </div>
              </div>
              <div class="row">
                <div class="col-12 col-lg-3 mt-3" @click="setOptionTop($event)">
                  <model-select
                    v-model="formData.color"
                    :options="optionsColor"
                    id="formData_color"
                    @shown="noOverflow = true"
                    @hidden="noOverflow = false"
                  />
                </div>
                <div class="col-12 col-lg-3 mt-3">
                  <model-select 
                    v-model="formData.produced_on"
                    :options="listYear"
                  />
                </div>
                <div class="col-12 col-lg-3 mt-3">
                  <model-select
                    v-model="formData.min_amount"
                    id="formData_min_amount"
                    :options="listPriceFrom"
                    :max="formData.max_amount"
                  />
                </div>
                <div class="col-12 col-lg-3 mt-3">
                  <model-select
                    v-model="formData.max_amount"
                    id="formData_max_amount"
                    :options="listPriceTo"
                    :min="formData.min_amount"
                  />
                </div>
                <div class="col-12 mt-3 d-md-none d-block">
                  <button
                    class="btn btn-success w-100"
                    :disabled="isLoading"
                    id="btn_modal_stock_mobile_search"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-magnifying-glass"
                    />
                    <span class="ml-2">
                      検索
                    </span>
                  </button>
                </div>
            </div>
            <div class="row">
              <div class="col-lg-auto mt-3 d-md-block d-none">
                <button
                  class="btn btn-success btn-search"
                  :disabled="isLoading"
                  id="btn_modal_stock_search"
                  @click=fetchData(1)
                >
                  <font-awesome-icon
                    icon="fa-solid fa-magnifying-glass"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
        <template v-if="isLoading">
          <div class="d-flex justify-content-center mt-4">
            <b-spinner variant="primary" />
          </div>
        </template>
        <template v-else>
          <div class="content-result mt-2 px-md-3 px-2">
            <div class="border-bottom pb-2 mt-5">
              {{ countRows }}車両が見つかりました
            </div>
            <itemStock
              v-for="(stockData, index) in listStock"
              v-bind:key="index"
              :stockData="stockData"
              :noButton="true"
              @click.native="exportData(stockData)"
            />
          </div>
        </template>
        <div class="footer mt-3">
          <CQPagination
            v-if="listStock.length"
            class="justify-content-center"
            :perPage="formData.per_page"
            :totalRows="countRows"
            v-model="formData.page"
          />
        </div>
      </div>
      <template #modal-footer>
        <div class="row justify-content-center align-items-center w-100">
          <div class="col-lg-6 col-12 mt-2">
            <button class="btn btn-secondary btn-confirm w-100" @click="$bvModal.hide('modal-stock')" id="btn_modal_stock_hide">キャンセル</button>
          </div>
        </div>
      </template>
    </b-modal>
  </div>
</template>
<style lang="scss">
.cq-modal-stock {
  padding: 0.0rem!important;
  .cq-dialog-stock {
    transform: translateY(55px)!important;
  }
}
</style>
<style scoped lang="scss">
.form-control:not(.b-form-datepicker),
.custom-select {
  border: 1px solid #dcdcdc !important;
}

.b-form-datepicker {
  ::v-deep .form-control {
    padding: 0.7rem 1.0rem;
    border: unset !important;
    min-height: unset;
  }

  &.form-control {
    border-radius: 0.5rem !important;
  }
}
.btn {
  &-search {
    width: 45px;
  }
}
.content-result  {
  padding: 0px 30px;
}
.cq-modal-content{
  min-height: 250px;
  &.noOverflow{
    min-height: 350px;
  }
}
.cq-modal-content ::v-deep {
  .stockList-list-content-items {
    cursor: pointer;
    &:hover {
      background-color: #D3D3D3
    }
  }
}
</style>
<script>
import { Message } from '@/utils/message';
import itemStock from '@/components/stock/item.vue';
import CQPagination from '../CQPagination.vue';
import { convertNumber } from "@/utils/commonEvent";
import priceList from "@/utils/priceList"
import listYear from "@/utils/listYear"
import ModelSelectBrands from '../ModelSelectBrands.vue';

export default {
  mixins: [convertNumber],
  components: {
    itemStock,
    CQPagination,
    ModelSelectBrands,
  },
  data () {
    return {
      listStock: [],
      isLoading: false,
      formData: {
      },
      formDataBase: {
        core_brand_id: null,
        core_car_id: null,
        core_model_id: null,
        core_model_ids: [],
        core_grade_id: null,
        color: null,
        produced_on: null,
        min_amount: null,
        max_amount: null,
        page: 1,
        per_page: 3,
      },
      optionsBrands:[{
        text: "メーカー",
        value: null,
      }],
      optionsCarName: [{
        text: "車名",
        value: null,
      }],
      optionsModel: [{
        text: "型式",
        value: null,
      }],
      optionsGrade: [{
        text: "グレード",
        value: null,
      }],
      optionsColor: [{
        text: "色",
        value: null,
      }],
      listPriceFrom: [{
        value: null,
        text: "下限金額 (万円)"
      },
      ...priceList
      ],
      listPriceTo: [{
        value: null,
        text: "上限金額 (万円)"
      },
      ...priceList
      ],
      listYear,
      countRows: 0,
      messageDate: Message.LABLE_DATE,
      noOverflow: false
    }
  },
  computed: {
    dataMaster () {
      return this.$store.getters.carMap
    }
  },
  watch: {
    async 'formData.core_model_id'() {
      this.formData.code_grade_id = null
      if(this.formData.core_model_id){
        this.formData.core_model_ids = [this.formData.core_model_id]
        const getDataGrade = await this.$store.dispatch('getDataGrade', this.formData.core_model_id);
        if (getDataGrade.success) {
            this.optionsGrade = [this.optionsGrade[0]];
            getDataGrade.data.grades.forEach(grades => {
              this.optionsGrade.push({ value: grades.id, text: grades.name });
            });
        } else {
            if (getDataGrade.errors.length && getDataGrade.errors[0].message) this.$toast.error(getDataGrade.errors[0].message);
        };
      }
    },
    async 'formData.core_car_id'() {
      this.formData.core_model_id = null
      if(this.formData.core_car_id){
        const getDataModel = await this.$store.dispatch('getDataModel', this.formData.core_car_id);
        if (getDataModel.success) {
            this.optionsModel = [this.optionsModel[0]];
            getDataModel.data.models.forEach(model => {
              this.optionsModel.push({ value: model.id, text: model.name });
            });
        } else {
            if (getDataModel.errors.length && getDataModel.errors[0].message) this.$toast.error(getDataModel.errors[0].message);
        };
      }
    },
    async 'formData.core_brand_id'() {
      this.formData.core_car_id = null
      if(this.formData.core_brand_id){
        const getDataCarName = await this.$store.dispatch('getDataCarName', this.formData.core_brand_id);
        if (getDataCarName.success) {
            this.optionsCarName = [this.optionsCarName[0]];
            getDataCarName.data.cars.forEach(carNames => {
              this.optionsCarName.push({ value: carNames.id, text: carNames.name });
            });
        } else {
            if (getDataCarName.errors.length && getDataCarName.errors[0].message) this.$toast.error(getDataCarName.errors[0].message);
        };
      }
    },
    'formData.page' () {
      this.fetchData()
    }
  },
  methods: {
    async fetchData (page = null) {
      this.isLoading = true
      const res = await this.$store.dispatch('searchListStock', {
        params: this.formData
      });
      this.isLoading = false
      if (res.success) {
        this.listStock = res.data.stocks
        this.countRows = res.data.meta.total_objects;
        this.formData.page = page || res.data.meta.current_page 
      } else {
        if (listSearch.errors.length && listSearch.errors[0].message) this.$toast.error(listSearch.errors[0].message);
      };
    },
    async initForm () {
      await this.dataMaster.loader
      this.dataMaster.colors.forEach(colors => {
          this.optionsColor.push({ value: colors.id, text: colors.name });
      });
      this.dataMaster.brands.forEach(brands => {
        this.optionsBrands.push({ value: brands.id, text: brands.name, core_country_id: brands.core_country_id });
      });
      this.listYear = [
        this.listYear[0],
        ...this.dataMaster.years
      ]
    },
    exportData (data) {
      this.$emit("close", data)
    }
  },
  emits: ["close"],
  mounted () {
    this.initForm()
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId === 'modal-stock') {
        this.formData = { ...this.formDataBase }
          this.fetchData()
          .finally(() => {
            this.isLoading = false
          })
      }
    })
  },
}
</script>