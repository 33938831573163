<template>
  <div class="cq-select-item">
    <slot name="title"></slot>
    <form @submit.prevent="searchItem"> 
      <div class="d-flex w-100 justify-content-end align-items-center">
        <b-input v-model="searchSelection" type="text" class="w-25 pl-3 mr-2 col-md-auto col" :placeholder="textSearch" id="input_select_item_search" autocomplete="off"></b-input>
        <button class="btn btn-success cq-white mr-3" id="btn_mypage_select_item_search">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
        </button>
        <b-form-checkbox @change="vModelSetAll" v-model="setAll" class="mt-3 text-right mr-3 mb-3 col-auto" id="input_select_item_checkbox_search">全て</b-form-checkbox>
      </div>
    </form>
    <div class="cq-switch-small row justify-content-center align-content-start">
      <div class="row w-100 cq-switch-small-content justify-content-center">
        <div class="col-12 col-lg-3 mt-3 row" v-for="(venue, index) in dataListSelection" :key="index">
          <div class="col-6 p-0">
            <input type="checkbox" class="d-none controller" :id="`test_${index}`" v-model="venue.picked" @input="handlerVmodel($event, venue.id)" />
            <label class="cq-switch" :for="`test_${index}`" :id="`label_${index}`">
              <div class="text-center px-3 text-content">{{venue.core_venue.name}}</div>
              <div class="toggle"></div>
            </label>
            <b-tooltip :target="`label_${index}`" triggers="hover">
             {{venue.core_venue.name}}
            </b-tooltip>
          </div>
          <div class="col-6">
            <b-input class="switch-action-input" placeholder="陸送費（円）" 
              :value="venue.shipping_fee | setComma"
              @input="setFee(venue.core_venue_id, $event)"
              :disabled="!venue.picked"
              v-on:keyup="convertPositive"
              @keypress="isNumber"
              v-b-tooltip.hover
              :title="MESSAGE_TOOLTIP" 
              id="venue.shipping_fee"
              autocomplete="off"
            ></b-input>
          </div>
        </div>
        <div v-if="!dataListSelection.length" class="message-no-item w-100 text-center">{{NO_ITEM}}</div>
      </div>
    </div>
  </div>
</template>
<script>
import { Message } from '@/utils/message'
export default {
  props: {
    dataListOption: {
      type: Array,
      required: true
    },
    textSearch: {
      type: String,
      default () {
        return '会場検索'
      }
    },
  },
  data() {
    return {
      NO_ITEM: Message.EDIT_PROFILE.NO_ITEM,
      MESSAGE_TOOLTIP: Message.EDIT_PROFILE.MESSAGE_TOOLTIP,
      searchSelection: "",
      listSelectAll: [...this.dataListOption],
      dataListSelection: [...this.dataListOption],
    }
  },

  computed: {
    setAll: {
      get () {
        return this.listSelectAll.findIndex((selectData) => !selectData.picked) === -1
      },
      set (value) {
        
      }
    },
  },
  created () {
     const data = this.listSelectAll.filter((selectData) => selectData.picked)
      this.$emit('change', data);
      this.$emit('number-select', data.length);
  },
  
  methods: {
    setFee(core_venue_id, event) {
      const item = this.listSelectAll.find((_) => _.core_venue_id === core_venue_id)
      item.shipping_fee = event.replaceAll(',','')
      const data = this.listSelectAll.filter((selectData) => selectData.picked)
      this.$emit('change', data);
      this.$emit('number-select', data.length);
    },
    isNumber (evt) {
      if (Number(evt) && evt.indexOf('.') > -1) {
        return true;
      }
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    convertPositive(e) {
      this.listSelectAll = this.listSelectAll.map((venue) => {
        const shipping_fee = venue.shipping_fee?.toString().replaceAll(',','')
        return {
        ...venue,
        shipping_fee: (isNaN(shipping_fee) || (shipping_fee < 0)) ? 0 : venue.shipping_fee
        }
      })
      e.currentTarget.value = this.$options.filters.setComma(e.currentTarget.value.replaceAll(',',''))
    },
    searchItem () {
      this.dataListSelection.forEach((dataSelect, index) => {
        this.listSelectAll[index].shipping_fee = dataSelect.shipping_fee
      })
      if (this.searchSelection) {
        const listSelect = this.listSelectAll.filter(dataSelect => (dataSelect.core_venue.name.toUpperCase()).includes(this.searchSelection.toUpperCase()));
        this.dataListSelection = listSelect
      } else this.dataListSelection = [ ...this.listSelectAll ];
    },
    handlerVmodel ($e, id) {
      const item = this.listSelectAll.findIndex((dataSelect) => dataSelect.id === id)
      if(item != -1){
        this.listSelectAll[item].picked = $e.target.checked
        const data = this.listSelectAll.filter((selectData) => selectData.picked)
        this.$emit('change', data);
        this.$emit('number-select', data.length);
      }
    },
    vModelSetAll (checked) {
      this.dataListSelection.forEach((dataSelect) => {
        dataSelect.picked = checked
      })
      const data = this.listSelectAll.filter((selectData) => selectData.picked)
      this.$emit('change', data);
      this.$emit('number-select', data.length);
    }
  },
};
</script>
<style lang="scss" scoped>
  .cq-select-item:not(.not-scroll) {
    .cq-switch-small {
      overflow: auto;
    }
  }
  .cq-switch-small {
    height: 406px;
    .cq-switch-small-content {
      // max-width: 900px;
      margin: auto;
      .controller:checked ~ .cq-switch {
        background-color: #F37F14;
        color: $white;
        .toggle {
          right: 5px;
          left: unset;
        }
      }
    }
    .cq-switch {
      display: block;
      transition: background .25s;
      background-color: $cq-darkwhite;
      border-radius: 5px;
      padding: 0.8rem 1.5rem;
      width: 100%;
      position: relative;
      -moz-user-select: none !important;
      -webkit-touch-callout: none!important;
      -webkit-user-select: none!important;
      -khtml-user-select: none!important;
      -moz-user-select: none!important;
      -ms-user-select: none!important;
      user-select: none!important;
      .text-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .toggle {
        transition: all 1s;
        width: 9%;
        max-width: 15px;
        top: 4px;
        bottom: 4px;
        position: absolute;
        left: 5px;
        border-radius: 3px;
        background-color: $white;
      }
    }

  }
</style>