<template>
  <div
    class="
      stockList-list-content-items
      cq-radius-8
      mt-3
      d-flex
      flex-wrap
      position-relative
    "
  >
    <div class="position-absolute carousel-content" v-show="showModalMessger && index === 0">
      <div class="stockList-carousel-triangle"></div>
      <div class="stockList-carousel-close cursor-pointer">
        <font-awesome-icon @click="toggleSidebar" icon="fa-solid fa-circle-xmark cursor-pointer" class="stockList-tooltip position-absolute" />
      </div>
    </div>
    <div
      class="
        stockList-list-content-items-star
        stockList-lg-none col-auto
      "
    >
      <img
        v-if="!stockData.is_favorited"
        v-on:click="changeFavorited(stockData.id)"
        class="stockList-list-content-items-star-img"
        src="@/assets/img/star-regular.svg"
        alt=""
      />
      <img
        v-if="stockData.is_favorited"
        v-on:click="changeFavorited(stockData.id)"
        class="stockList-list-content-items-star-img"
        src="@/assets/img/star-solid.svg"
        alt=""
      />
    </div>
    <div class="img-stock-list col-auto">
      <img :src="stockData.imgs[0].url" alt="" v-if="stockData.imgs" />
      <img v-else src="@/assets/img/stock-img-3.jpg" alt="" />
    </div>
    <div class="stockList-list-content-car pr-5 col pl-5">
      <div class="items-title d-flex flex-row align-items-center">
        {{ stockData.core_car ? stockData.core_car.name : "" }}｜{{ stockData.core_grade ? stockData.core_grade.name : "" }}
        <div class="stockList-list-content-items-star stockList-lg-flex ml-2">
          <img
            v-if="!stockData.is_favorited"
            v-on:click="changeFavorited(stockData.id)"
            class="stockList-list-content-items-star-img"
            src="@/assets/img/star-regular.svg"
            alt=""
          />
          <img
            v-if="stockData.is_favorited"
            v-on:click="changeFavorited(stockData.id)"
            class="stockList-list-content-items-star-img"
            src="@/assets/img/star-solid.svg"
            alt=""
          />
        </div>
      </div>
      <div class="items-text">
        <span v-if="stockData.core_brand">{{ stockData.core_brand.name }}</span>
        <span v-if="stockData.produced_on || stockData.core_color || stockData.odo">　|　</span>
        <span v-if="stockData.produced_on">{{ renderYear(stockData.produced_on) }}</span>
        <span v-if="stockData.core_color">　{{ stockData.core_color.name }}</span>
        <span v-if="stockData.odo">　{{ stockData.odo | setComma }}km</span>
      </div>
      <div class="items-text">
        <span
          class="pr-2"
          v-for="(equipmentsName, x) in stockData.equipments_name"
          v-bind:key="x"
          >{{ equipmentsName }}</span
        >
      </div>
      <div class="items-reviews-point">
          <label v-if="stockData.exterior_score">外装 <span class="mr-2"> {{ SCORE_OBJ[stockData.exterior_score] }} </span> </label>
          <label v-if="stockData.interior_score">内装 <span class="mr-5"> {{ EXTERIOR_SCORE_OBJ[stockData.interior_score] }} </span> </label>
          <label v-if="stockData.estimated_price">予定価格 <span>{{ stockData.estimated_price / 10000 | setComma }}万円</span></label>
      </div>
    </div>
    <div class="stockList-list-content-items-actions col-auto" v-if="!noButton">
      <b-button
        v-on:click="editStock(stockData.id)"
        class="stockList-list-content-items-actions-button cq-btn-bg-green"
        id="btn_item_stock_edit"
        ><font-awesome-icon icon="fa-solid fa-info"
      /></b-button>
      <b-button
        class="
          cq-btn-bg-orange
          stockList-list-content-items-actions-button
          mt-0 mt-xl-1
        "
        id="btn_item_stock_analyze"
        :disabled="isLoading"
        @click="handlerSearchAnalyze"
        v-bind="intro"
        >
        <b-spinner small v-if="isLoading" />
        <font-awesome-icon icon="fa-solid fa-chart-column" v-else />
      </b-button>
      <b-button
        v-on:click="$bvModal.show('removeStock'), getIdStock(stockData.id)"
        class="
          stockList-list-content-items-actions-button
          cq-btn-bg-red
          mt-0 mt-xl-1
        "
        id="btn_item_stock_move_to_trash"
        ><font-awesome-icon icon="fa-solid fa-trash-can"
      /></b-button>
    </div>
  </div>
</template>
<script>
import { methods } from "@/utils/analyze"
import { EXTERIOR_SCORE_OBJ, SCORE_OBJ } from '@/utils/score';
import { halfwidth } from '@/utils/commonEvent';
export default {
  props: {
    stockData: {
      type: Object,
      required: true,
    },
    noButton: {
      type: Boolean,
      default () {
        return false
      }
    },
    index: {
      type: Number,
    },
    intro: {
      type: Object,
      required: false,
    }
  },
  data() {
    return {
      prevHtml: '<b-button class="prev-btn" id="btn_item_stock_prev">戻り</b-button>',	
      nextHtml: '<b-button class="next-btn" id="btn_item_stock_next">次へ</b-button>',
      showModalMessger: false,
      flagClick: false,
      isLoading: false,
      EXTERIOR_SCORE_OBJ,
      SCORE_OBJ,
    }
  },
  emits: ["changeFavorited", "editStock", "getIdStock"],
  methods: {
    changeFavorited(stockId) {
      this.$emit("changeFavorited", stockId);
    },
    renderYear (produced_on) {
      const dataMaster = this.$store.getters.carMap
      let producedOn = ""
      if (!produced_on) return 
      if(produced_on) {
        for (const yearItem of dataMaster.years) {
          const year = new Date(yearItem.value)
          const yearProducedOn = new Date(produced_on)
          if(year.getFullYear() == yearProducedOn.getFullYear()) {
            producedOn = yearItem.text
            break;
          }
        }
      }
      producedOn = producedOn.replace(/^(\d{4})/,"$1年")
      return producedOn
    },
    getIdStock(stockId) {
      this.$emit("getIdStock", stockId);
    },
    editStock(stockId) {
      this.$emit("editStock", stockId);
    },
    toggleSidebar () {
      this.showModalMessger = !this.showModalMessger
      if (this.showModalMessger) {
        document.querySelector("body").style.overflow = 'hidden'
        return
      }
      document.querySelector("body").style.removeProperty('overflow')
    },
    async convert2DataRaw(dataCore) {
      const dataRaw = {}
      this.isLoading = true
      let [ venues ] = await Promise.all([this.$store.dispatch("getCoreVenueStock"), this.$store.getters.carMap.loader])
      this.isLoading = false
      let listUserVenues = []
      if(venues.success) {
        listUserVenues = venues.data.core_venues?.map((venue) => {
          venue.checked = false
          return venue
        }) || []
      }
      else {
        if (venues.errors.length && venues.errors[0].message) this.$toast.error(venues.errors[0].message);
        return
      }
      const { brands, cars, colors, gears } = this.$store.getters.carMap
      dataRaw.brand = halfwidth(brands.find(brand => brand.id === dataCore.core_brand_id )?.name) ?? null
      dataRaw.car_name= halfwidth(cars.find((car) => car.id === dataCore.core_car_id)?.name) ?? null
      dataRaw.color = colors.find((color) => color.id === dataCore.core_color_id)?.name ?? null
      dataRaw.gear = gears.find((gear) => gear.id === dataCore.core_gear_id)?.name ?? null
      const res = await this.$store.dispatch(
        "getDataModel",
        dataCore.core_car_id
      );
      const modelsGrades = res.data
      dataRaw.models = halfwidth(modelsGrades.models.find((model) => model.id === dataCore.model)?.name) ?? null
      dataRaw.grade = halfwidth(modelsGrades.grades.find((grade) => grade.id === dataCore.core_grade_id)?.name) ?? null
      dataRaw.auction_venues = listUserVenues.map((venue) => venue.name)
      if(dataCore.exterior_score_from >=1) dataRaw.exterior_score_from = this.EXTERIOR_SCORE_OBJ[Math.floor(dataCore.exterior_score_from)] ?? null
      if(dataCore.exterior_score_to >=1) dataRaw.exterior_score_to = this.EXTERIOR_SCORE_OBJ[Math.ceil(dataCore.exterior_score_to)] ?? null
      return dataRaw
    },
    redirectAnalyzePage: methods.redirectAnalyzePage,
    async handlerSearchAnalyze () {
      const stockData = methods.importDataFromStock(this.stockData)
      const formData = await this.convert2DataRaw(stockData)
      const params = 
      {
        formData: {...stockData, ...formData}
      }
      const search = methods.search.bind(this, params)
      search()
    }
  },
};
</script>
<style scoped lang="scss">
.stockList-list {
  &-content {
    &-car {
      @include screen(991) {
        position: unset !important;
      }
    }
    &-items {
      border: 1px solid $brown;
      padding: 1.3rem 0.6rem;
      background-color: $white;
      align-items: center;
      @include screen(1199) {
        flex-direction: column;
        align-items: flex-start;
        padding: 1.3rem;
      }
      @include screen(991) {
        padding: 0.8rem;
      }
      @include screen(576) {
        padding: 0.3rem;
      }
      .carousel-content {
        right: 5rem !important;
        top: 0.4rem !important;
        width: 330px;
        z-index: 1111;
        @media (max-width: 1200px){
          bottom: 4rem !important;
          top: unset !important;
          left: 0px;
          top: unset !important;
        }
        @media (max-width: 572px){
          bottom: 3rem !important;
          left: -1.7rem !important;
        }
        @media (max-width: 1200px){
          .stockList-carousel-triangle {
            left: 7rem;
            top: 11.4rem;
            width: 0; 
            height: 0; 
            border-left: 10px solid transparent !important;
            border-right: 10px solid transparent !important;
            border-bottom: unset !important;
            border-top: 20px solid #ffffff !important;
          }
        }
        .bg-blur {
          z-index: 1;
          position: fixed;
          inset: 0;
          background-color: rgba($color: #000000, $alpha: 0.4);
        }
        .stockList-carousel {
          background-color: $white;
          width: 100%;
          z-index: 2;
          height: 154px;
        }
      }
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
        @include screen(1199) {
          width: 100%;
          height: auto;
        }
      }
      &-star {
        @include screen(991) {
          transform: translateY(0);
        }
        &-img {
          width: 20px !important;
          height: 20px !important;
          @include screen(576) {
            width: 12px !important;
            height: 12px !important;
          }
        }
      }
      .stockList-lg-none {
        @include screen(1199) {
          display: none;
        }
      }
      .stockList-lg-flex {
        display: none;
        @include screen(1199) {
          display: flex;
        }
      }
      .items-reviews-point {
        color: $cq-boldgray;
        font-weight: 400;
        font-size: 1rem;
        @include screen(991) {
          font-size: 0.675rem;
        }
        span {
          font-weight: 500;
          font-size: 1.875rem;
          color: $cq-red;
          @include screen(1023) {
            font-size: 1.3rem;
          }
          @include screen(991) {
            font-size: 1.2rem;
          }
          @include screen(576) {
            font-size: 0.875rem;
          }
        }
      }
      .items-title {
        font-weight: 400;
        font-size: 1.5rem;
        color: $black;
        @include screen(576) {
          font-size: 0.875rem;
        }
      }
      .items-text {
        font-weight: 400;
        font-size: 0.875rem;
        color: $cq-darkgray;
        @include screen(576) {
          font-size: 0.5rem;
        }
      }
      &-actions {
        display: flex;
        flex-direction: column;
        @include screen(1199) {
          flex-direction: row;
          margin-top: 0.7rem;
        }
        .cq-btn-bg-red {
          background: $cq-red !important;
          background-color: $cq-red !important;
          &:hover,
          &:active,
          &:focus {
            background: $red !important;
            background-color: $red !important;
          }
        }
        &-button {
          width: 30px;
          height: 30px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          @include screen(1199) {
            margin-right: 0.7rem;
          }
          @include screen(576) {
            width: 20px;
            height: 20px !important;
            font-size: 0.675rem;
          }
        }
      }
    }
  }
}
</style>