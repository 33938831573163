import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

/**
 * Dynamic importing for splitting code
 */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { faChrome } from '@fortawesome/free-brands-svg-icons'
import { faThumbsDown, faThumbsUp, faUserCircle, faCheckCircle } from '@fortawesome/free-regular-svg-icons'



import firebase from './utils/firebase'
import ModelSelect from './components/ModelSelect.vue';
import BFormDatepicker from './utils/b-form-datepicker'
import "intro.js/introjs.css"

Promise.all([
  import("bootstrap-vue"),
  import('@fortawesome/fontawesome-svg-core'),
  import('@fortawesome/vue-fontawesome'),
  import('@bugsnag/js'),
  import('@bugsnag/plugin-vue'),
  import('vuelidate'),
  import('vue-toast-notification'),
  import('vue-slick-carousel'),
  import('vue-payjp-checkout'),
  import('vue-moment'),
  import('moment-timezone'),
  import('chart.js/auto'),
  import("lodash"),
  import("vue-gtag"),
  import("@formkit/auto-animate"),
  import("intro.js"),
])
.then(([
  { BootstrapVue, IconsPlugin },
  { library },
  { FontAwesomeIcon },
  { default: Bugsnag },
  { default: BugsnagPluginVue },
  { default: Vuelidate },
  { default: VueToast },
  { default: VueSlickCarousel },
  { default: PayjpCheckout },
  { default: VueMoment },
  { default: moment },
  { default: Chart },
  { default: lodash },
  { default: VueGtag },
  { default: autoAnimate },
  { default: introJs },
]) => {
  Vue.use(BootstrapVue)
  Vue.use(IconsPlugin)
  Vue.component('font-awesome-icon', FontAwesomeIcon)
  library.add(fas, faThumbsDown, faThumbsUp, faUserCircle, faChrome, faCheckCircle)
  Vue.prototype.$firebase = firebase
  Vue.prototype.Chart = Chart
  Vue.prototype.introJs = introJs
  Vue.config.productionTip = false
  Vue.prototype.lodash = lodash
  Vue.use(VueMoment, {
    moment
  })
  Vue.directive("auto-animate", {
    inserted: function (el, binding) {
      autoAnimate(el, binding.value);
    }
  });

  Vue.use(Vuelidate)
  Vue.use(VueToast, {
    position: 'top-right',
    type: "success",
    duration: 3000,
    dismissible: true
  })
  Vue.use(PayjpCheckout)
  Vue.use(VueGtag, {
    config: { id: process.env.VUE_APP_ROOT_GG_ANALYTICS_CODE }
  }, router);

  Vue.component('VueSlickCarousel', VueSlickCarousel)
  Vue.component('BFormDatepicker', BFormDatepicker)

  Vue.component('ModelSelect', ModelSelect)

  Vue.filter('setComma', function (value) {
    return Number(value).toLocaleString('ja');
  })
  if (process.env.VUE_APP_ROOT_BUGSNAG) {
    Bugsnag.start({
      apiKey: process.env.VUE_APP_ROOT_BUGSNAG,
      plugins: [new BugsnagPluginVue()]
    })
    
    const bugsnagVue = Bugsnag.getPlugin('vue')
    bugsnagVue.installVueErrorHandler(Vue)
  }
  
})
.then(() => {
  new Vue({
    router,
    store,
    render: h => h(App)
  }).$mount('#app')
})