<template>
  <div class="cq-header d-flex justify-content-between align-items-center">
    <router-link to="/" id="link_header_auth_logo">
      <div class="cq-header-logo">
        <img class="img-contain" src="../assets/img/cq-logo.png" alt="">
      </div>
    </router-link>
  </div>
</template>

<script>

export default {
  name: "CQHeader",
}
</script>

<style lang="scss">
.cq-header {
  height: 70px;
  width: 100%;
  padding:0 60px;
  background: $white;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1051;
  @include screen (767) {
   padding: 0.0rem 1.3rem;
  }
  &-logo {
    width: 160px;
    height: auto;
    @include screen (767) {
      width: 140px;
    }
  }
  &-btn {
    button {
      font-size: 1.125rem;
    }
  }
  &-bagge {
    width: 30px;
    height: 30px;
    font-size: 1rem;
    border-radius: 100%;
    background: $cq-red;
    @include screen (767) {
      position: absolute;
      width: 15px;
      height: 15px;
      font-size: 0.625rem;
      top: -5px;
      right: -5px;
    }
  }
  &-recomment {
    width: 30px;
    height: 30px;
    border: 2px solid;
    border-radius: 100px;
  }
}
</style>