<template>
  <div class="cq-footer text-secondary row justify-content-between">
    <div class="cq-footer-left col-12 col-xl-5">
      <div class="cq-footer-logo"></div>
      <div class="ml-2 text-center mt-3">
        Copyright © 2022 株式会社HAPPY - All rights reserved.
      </div>
    </div>
    <div class="cq-footer-right col-12 col-md-7">
      <div class="row">
        <div class="col-xl-auto col-12 mr-4">
          <ul class="meta-link">
            <li>
              <router-link to="/signup" class="active">
                新規登録
              </router-link>
            </li>
            <li>
              <router-link to="/login">
                ログイン
              </router-link>
            </li>
            <li>
              <router-link to="/news">
                お知らせ
              </router-link>
            </li>
            <li>
              <router-link to="/help">
                ヘルプ
              </router-link>
            </li>
          </ul>
        </div>
        <div class="col-xl-auto col-12">
          <ul class="meta-link">
            <li>
              <router-link to="/kiyaku">
                サイト利用規約
              </router-link>
            </li>
            <li>
              <router-link to="/about/profile">
                会社概要
              </router-link>
            </li>
            <!-- <li>
              <router-link to="/sign">
                特定商取引法に基づく表記
              </router-link>
            </li> -->
            <!-- <li>
              <router-link to="/policy-privacy">
                プライバシーポリシー
              </router-link>
            </li> -->
            <!-- <li>
              <router-link to="/environment">
                推奨環境
              </router-link>
            </li> -->
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.cq-footer {
  background-color: #192B29;
  padding: 1.3rem 0.0rem;
  @include screen(1200) {
    padding: 2.0rem 6.7rem;
  }
  .cq-footer-left {
    @include screen(1200) {
      max-width: 300px;
    }
  }
  &-logo {
    background-image: url('@/assets/img/cq-logo.png');
    background-size: cover;
    background-position: center center;
    width: 100%;
    height: 100px;
    margin: auto;
    @include screen(1200) {
      width: 150px;
      height: 50px;
    }
  }
  .meta-link {
    padding-left: 1.3rem;
    list-style: none;
    li {
      margin-bottom: 0.3rem;
    }
    a {
      color: inherit;
      &:hover, &.active {
        color: var(--orange);
        text-decoration: underline;
      }
    }
  }
}
</style>