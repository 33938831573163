<template>
  <div class="cq-recommmend-result px-4 py-4">
    <div class="d-flex align-items-center justify-content-between m-0">
      <div class="col-auto">
        <div class="h4">リコメンド車両検索</div>
      </div>
    </div>
    <div class="mt-4 content" :class="{ 'tab-hidden': isShowContentSearch }">
      <div
        class="show-only-mobile close-filter"
        :class="{ 'd-none': !isShowContentSearch }"
        @click="toogleContentSearch"
      >
        <font-awesome-icon icon="fa-solid fa-xmark" class="icon-large" />
      </div>
      <b-tabs content-class="mt-3" v-model="dataSearch.recommend_type" id="tabNavs" align="center">
        <b-tab title-item-class="ml-2">
          <template #title>
            <div class="d-flex align-items-center">
              <span> 全て </span>
            </div>
          </template>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="d-flex align-items-center">
              <span class="number-notify"  v-if="recommendCount && recommendCount.advantageous">{{recommendCount.advantageous}}</span>
              <span> お得車両AIサーチ </span>
            </div>
          </template>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="d-flex align-items-center">
              <span class="number-notify"  v-if="recommendCount && recommendCount.flovehiclew">{{recommendCount.flovehiclew}}</span>
              <span> 流れ車両AIアラート </span>
            </div>
          </template>
        </b-tab>
        <b-tab>
          <template #title>
            <div class="d-flex align-items-center">
              <span class="number-notify" v-if="recommendCount && recommendCount.shared_inventory">{{recommendCount.shared_inventory}}</span>
              <span> 共有在庫AIアラート </span>
            </div>
          </template>
        </b-tab>
      </b-tabs>
      <div
        class="form-search"
        :class="isShowContentSearch ? 'show-only-mobile' : 'show-only-pc'"
      >
        <div>
          <CQFormGroupSelectCar v-model="dataSearch" />
          <div class="row mt-md-2">
            <div class="col-lg-6 col-md-12 mt-md-2">
              <div class="row align-items-center">
                <div class="col col-mobile mt-2 mt-md-0">
                  <model-select
                    v-model="dataSearch.from_price"
                    :options="listPriceFrom"
                    id="min_prices"
                    :max="dataSearch.to_price"
                  />
                </div>
                <div class="col-auto col-span d-none d-md-block">~</div>
                <div class="col col-mobile mt-2 mt-md-0">
                  <model-select
                    v-model="dataSearch.to_price"
                    :options="listPriceTo"
                    id="max_prices"
                    :min="dataSearch.from_price"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-md-2">
              <div class="row align-items-center">
                <div class="col col-mobile mt-2 mt-md-0">
                  <model-select
                    v-model="dataSearch.year_start_on"
                    :options="listYearFrom"
                    :max="dataSearch.year_end_on"
                  />
                </div>
                <div class="col-auto col-span d-none d-md-block">~</div>
                <div class="col col-mobile mt-2 mt-md-0">
                  <model-select
                    v-model="dataSearch.year_end_on"
                    :options="listYearTo"
                    :min="dataSearch.year_start_on"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-md-2">
            <div class="col-lg-6 col-md-12 mt-md-2">
              <div class="row align-items-center">
                <div class="col col-mobile mt-2 mt-md-0">
                  <model-select
                    v-model="dataSearch.from_profit"
                    id="min_profits"
                    :options="listProfitFrom"
                    :max="dataSearch.to_profit"
                  />
                </div>
                <div class="col-auto col-span d-none d-md-block">~</div>
                <div class="col col-mobile mt-2 mt-md-0">
                  <model-select
                    v-model="dataSearch.to_profit"
                    id="max_profits"
                    :options="listProfitTo"
                    :min="dataSearch.from_price"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-md-2">
              <div class="row">
                <div class="col col-mobile mt-2 mt-md-0">
                  <model-select
                    v-model="dataSearch.odo_from"
                    :options="listOdoFrom"
                    id="min_odo"
                    :max="dataSearch.odo_to"
                  />
                </div>
                <div class="col-auto col-span d-none d-md-block">~</div>
                <div class="col col-mobile mt-2 mt-md-0">
                  <model-select
                    v-model="dataSearch.odo_to"
                    :options="listOdoTo"
                    id="max_odo"
                    :min="dataSearch.odo_from"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-md-2">
            <div class="col-lg-6 col-md-12 mt-md-2">
              <div class="row align-items-center">
                <div class="col col-mobile mt-2 mt-md-0">
                  <model-select
                      :options="coreColors"
                      v-model="dataSearch.core_color_id"
                      id="core_color_id"
                    />
                </div>
                <div class="col-auto col-span d-none d-md-block"></div>
                <div class="col col-mobile mt-2 mt-md-0">
                  <model-select
                      :options="coreGears"
                      v-model="dataSearch.core_gear_id"
                      id="core_gear_id"
                    />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-md-2">
              <div class="row">
                <div class="col col-mobile mt-2 mt-md-0">
                  <b-input v-model="dataSearch.displacement" placeholder="排気量" id="dataSearch_displacement" autocomplete="off" />
                </div>
                <div class="col-auto col-span d-none d-md-block"></div>
                <div class="col col-mobile mt-2 mt-md-0">
                  <b-form-datepicker
                    v-model="dataSearch.open_on"
                    class="w-100 pl-2"
                    placeholder="開催日"
                    reset-button
                    label-reset-button="リセット"
                    v-bind="messageDate.ja || {}"
                    v-bind:date-format-options="{
                      year: 'numeric',
                      month: '2-digit',
                      day: '2-digit'
                    }"
                    locale="ja"
                    dropzone
                    right
                    label-help=""
                    hide-header=""
                    menu-class="mt-1"
                    :max="currentDate"
                  >
                  <template #button-content>
                    <font-awesome-icon icon="fa-solid fa-calendar-days" class="icon-large" />
                  </template>
                  </b-form-datepicker>
                </div>
              </div>
            </div>
          </div>
         
          <div class="row mt-4 show-only-mobile">
            <div class="col-12">
              <button
                class="btn btn-success btn-search"
                :disabled="isLoading"
                @click="fetchSearch"
                id="btn_recommend_result_search_mobile"
              >
                <font-awesome-icon
                  icon="fa-solid fa-magnifying-glass"
                  class="icon"
                />
                <span class="text">検索</span>
              </button>
            </div>
          </div>
          <div class="show-only-pc mt-3">
            <button class="btn search__button px-3 btn-success" id="btn_recommend_result_search" @click="fetchSearch">
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </button>
            <b-button type="submit" id="btn_recommend_condition_reset_form" class="ml-3 search__button px-3 cq-btn-gray" @click="resetForm">
                <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
              </b-button>
          </div>
        </div>
      </div>
      <div
        class="content-result mt-4"
        :class="{ 'd-none': isShowContentSearch }"
      >
        <template v-if="isLoading">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner variant="primary"></b-spinner>
          </div>
        </template>
        <template v-else>
          <div class="content-result-header">
            <h6 class="pb-2">{{countResult}}台見つかりました</h6>
          </div>
          <div class="mt-4">
            <div class="row">
              <div
                v-for="(item, index) in recommendList"
                :key="index"
                class="col-12 col-md-6 col-lg-4 mt-4"
              >
                <Item
                  :itemData="item"
                  @handleFeedback="handleFeedback"
                  @editFeedback="editFeedback"
                />
              </div>
            </div>
          </div>
          <div class="mt-4">
            <CQPagination
              v-if="recommendList.length"
              v-model="dataSearch.page"
              :total-rows="countResult"
              :per-page="dataSearch.per_page"
              class="justify-content-center"
            />
          </div>
        </template>
      </div>
    </div>
    <b-modal
      id="confirmFeedback"
      ref="confirmFeedback"
      modal-class="modal-confirm modal-confirm-sm"
      body-class="p-md-4 p-3"
      hide-footer
      hide-header
      centered
    >
      <div class="d-block title w-100">
        <div>
          <div
            style="position: absolute; top: 20px; right: 20px"
            @click="$bvModal.hide('confirmFeedback')"
          >
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="icon-xl"
              style="fill: var(--gray)"
            />
          </div>
        </div>
        <div class="mt-4 row">
          <div class="col-auto">
            <font-awesome-icon
              :icon="['fa-regular', 'thumbs-down']"
              class="icon-xl"
              style="fill: var(--danger)"
            />
          </div>
          <div class="col">
            <h4>リコメンドの評価</h4>
          </div>
        </div>
        <div class="mt-4">
          <div class="form-group">
            <label for="descriptionFeedback"> フィードバック： </label>
            <textarea
              class="form-control"
              id="descriptionFeedback"
              style="resize: none"
              rows="6"
              v-model="messageFeedback"
            ></textarea>
          </div>
        </div>
        <div class="mt-2">
          <button class="btn btn-success w-100" @click="confirmFeedbackDisLike()" id="btn_recommend_result_confirm_feedback" :disabled="isLoadingFeedBack">
            <b-spinner v-if="isLoadingFeedBack" small />
            送信
          </button>
        </div>
      </div>
    </b-modal>
    <div class="show-only-mobile">
      <button
        class="btn btn-filter"
        v-if="!isShowContentSearch"
        @click="toogleContentSearch"
        id="btnShowSearch"
      >
        <font-awesome-icon icon="fa-solid fa-filter" />
      </button>
    </div>
    <b-modal
      id="notiFeedback"
      ref="notiFeedback"
      modal-class="modal-confirm modal-confirm-sm"
      body-class="p-md-4 p-3"
      hide-footer
      hide-header
      centered
      v-model="isShowModalNotify"
    >
      <div class="d-block text-center title w-100">
        <div>
          <h4>リコメンドの評価</h4>
          <div
            style="position: absolute; top: 20px; right: 20px"
            @click="$bvModal.hide('notiFeedback')"
          >
            <font-awesome-icon
              icon="fa-solid fa-xmark"
              class="icon-xl"
              style="fill: var(--gray)"
            />
          </div>
        </div>
        <div class="px-4 mt-4">
          <h6>
            品質向上のためリコメンドへの評価をフィードバック頂けると幸いです。
          </h6>
        </div>
        <div class="px-4">
          <div class="mt-4 row">
            <div class="col-auto">
              <font-awesome-icon
                :icon="['fa-regular', 'thumbs-up']"
                class="icon-xl"
                style="fill: var(--success)"
              />
            </div>
            <div class="col">
              ご提案に満足している場合はGoodボタンをクリックしてください。
            </div>
          </div>
          <div class="mt-4 row">
            <div class="col-auto">
              <font-awesome-icon
                :icon="['fa-regular', 'thumbs-down']"
                class="icon-xl"
                style="fill: var(--danger)"
              />
            </div>
            <div class="col">
              満足されない場合はBadボタンをクリックしていただき理由をご連絡ください。
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import CQPagination from "@/components/CQPagination.vue";
import Item from "@/components/recommend-result/all/item.vue";
import "@/assets/scss/recommend-result/all.scss";
import { convertNumber } from "@/utils/commonEvent";
import recommendResult from "@/utils/recommendResult"
import checkExtension from "@/utils/checkExtension";
import { listOdoFrom, listOdoTo } from "@/utils/odoList"
import  listPrice from "@/utils/priceList"
import listYear from "@/utils/listYear"
import CQFormGroupSelectCar from "@/components/CQFormGroupSelectCar.vue";
import { Message } from '@/utils/message'

export default {
  layout: "user",
  auth: "user",
  mixins: [recommendResult, convertNumber],
  head() {
    return {
      title: "リコメンド結果",
      meta: [
        { charset: "utf-8" },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
        },
      ],
    };
  },
  components: {
    Item,
    CQPagination,
    CQFormGroupSelectCar,
  },
  data() {
    return {
      messageDate: Message.LABLE_DATE,
      currentDate: new Date(),
      dataSearch: {
        recommend_type: 0,
        core_brand_id: null,
        core_car_id: null,
        model: null,
        core_model_ids: [],
        core_grade_id: null,
        core_grade_ids: [],
        to_price: null,
        from_price: null,
        year_start_on: null,
        year_end_on: null,
        from_profit: null,
        to_profit: null,
        odo_from: null,
        odo_to: null,
        core_color_id: null,
        core_gear_id: null,
        model: null,
        displacement: null,
        interior_score_from: null,
        interior_score_end: null,
        core_equipment_id: null,
        exterior_score_from: null,
        exterior_score_end: null,
        open_on: null,
        page: 1,
        per_page: 9,
      },
      coreBrands: [{
        value: null,
        text: 'メーカー'
      }],
      coreCars: [{
        value: null,
        text: '車名'
      }],
      coreModels: [{
        value: null,
        text: '型式'
      }],
      coreGrades: [{
        value: null,
        text: 'グレード'
      }],
      coreColors: [{
        value: null,
        text: '色'
      }],
      coreGears: [{
        value: null,
        text: 'シフト'
      }],
      coreEquipments: [{
        value: null,
        text: '装備'
      }],
      listYearFrom: listYear,
      listYearTo: [{
        value: null,
        text: "上限年式"
      }],
      isShowFormMore: false,
      recommendList: [],
      isSendingFeedback: false,
      countResult: 0,
      totalPage: 0,
      isShowContentSearch: false,
      isShowModalNotify: true,
      isLoading: false,
      messageFeedback: "",
      currentIdFeedback: null,
      isLoadingFeedBack: false,
      site: {},
      moreInfo: {},
      editing: false,
      listOdoFrom,
      listOdoTo,
      listPriceFrom: [
        {
          value: null,
          text: "下限価格（万円）"
        },
        ...listPrice
      ],
      listPriceTo: [
        {
          value: null,
          text: "上限価格（万円）"
        },
        ...listPrice
      ],
      listProfitFrom: [
        {
          value: null,
          text: "下限粗利（万円)"
        },
        ...listPrice
      ],
      listProfitTo: [
        {
          value: null,
          text: "上限粗利（万円）"
        },
        ...listPrice
      ],
      isResetting: false,
      canRunUSS: null,
    };
  },
  computed: {
    recommendCount () {
      return this.$store.getters.recommendCount
    }
  },
  methods: {
    isNumber(evt) {
      if (Number(evt) && evt.indexOf('.') > -1) {
        return true;
      }
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    toogleShowForm($event) {
      $event.preventDefault();
      this.isShowFormMore = !this.isShowFormMore;
    },
    toogleContentSearch() {
      this.isShowContentSearch = !this.isShowContentSearch;
    },
    async fetchSearch() {
      const params = {...this.dataSearch}
      if(params.recommend_type === 0){
        params.recommend_type = null
      }
      this.isLoading = true;
      const { data } = await this.$store.dispatch("searchRecommendResult", {
        params,
      });
      this.isLoading = false;
      this.recommendList = data.recommends
      this.dataSearch.page = data.meta.current_page
      this.countResult = data.meta.total_objects
      this.totalPage = data.meta.total_pages
    },
    async initData () {
      const dataMaster = this.$store.getters.carMap
      await dataMaster.loader
      dataMaster.colors.forEach(color => {
        this.coreColors.push({
          text: color.name,
          value: color.id
        })
      });
      dataMaster.gears.forEach(gear => {
        this.coreGears.push({
          text: gear.name,
          value: gear.id
        })
      });
      dataMaster.equipments.forEach(equipment => {
        this.coreEquipments.push({
          text: equipment.name,
          value: equipment.id
        })
      });
      this.listYearFrom = [this.listYearFrom[0], ...dataMaster.years]
      this.listYearTo = [this.listYearTo[0], ...dataMaster.years]
    },
    resetForm() {
      this.isResetting = true
      Object.keys(this.dataSearch).forEach(key => {
        this.dataSearch[key] = null;
      });
      this.dataSearch.page = 1
      this.dataSearch.per_page = 9
    },
  },
  watch: {
    'dataSearch.recommend_type' () {
      if(!this.isResetting) this.fetchSearch()
      this.isResetting = false
    },
    'dataSearch.page' () {
      if(!this.isResetting) this.fetchSearch()
      this.isResetting = false
    }
  },
  created() {
    const urlSearchParams = new URLSearchParams(window.location.search)
    if(urlSearchParams.get("core_site_id") && urlSearchParams.get("data")) {
      this.site.id = urlSearchParams.get("core_site_id")
      if(urlSearchParams.get("type") ==="base64") {
        this.moreInfo.url = atob(urlSearchParams.get("data"))
      }
      else this.moreInfo.url = decodeURIComponent(urlSearchParams.get("data"))
      const type = urlSearchParams.get("type") ? urlSearchParams.get("type") : "replace"
      const brand = urlSearchParams.get("brand")
      const car_name = urlSearchParams.get("car_name")
      const model = urlSearchParams.get("model")
      const grade = urlSearchParams.get("grade")
      const payload = {
        ...this.moreInfo,
        type,
        brand,
        car_name,
        model,
        grade,
      }
      checkExtension.methods.toPreview.call(this, undefined, checkExtension, "replace", payload)
    }
    else {
      this.initData()
      checkExtension.methods.checkCanUseUssSite(this, null)
    }
    const feedbackShowed = localStorage?.getItem("feedbackShowed") ?? false
    if(feedbackShowed) {
      this.isShowModalNotify = false
    }
    localStorage?.setItem("feedbackShowed", true)
    this.fetchSearch();
  },
};
</script>
