import Vue from 'vue'
import VueRouter from 'vue-router'
import role from '../utils/role.js'
import home from '../views'
import login from '../views/login.vue'
import logout from '../views/logout.vue'
import signup from '../views/signup/'
import signupSendmailComplete from '../views/signup/complete'
import signupComplete from '../views/signup/input/complete'
import signupToken from '../views/signup/input/_token'
import MypagePlan from '../views/mypage/plan'
import MypagePlanChange from '../views/mypage/plan/_planId/change'
import page404 from '../views/pages/404.vue'
import changeMail from '../views/mypage/profile/change-mail'
import profileMypage from '../views/mypage/profile'
import forgotInput from '../views/forgot/input'
import forgotSended from '../views/forgot/sended.vue'
import passwordReset from '../views/password/reset'
import newCredit from '../views/mypage/credit/new.vue'
import passwordResetDone from '../views/password/reset/done.vue'
import chat from '../views/chat'
import stock from '../views/stock/'
import stockDeletedList from '../views/stock/deleted-car/list/index.vue'
import stockNewCar from '../views/stock/new-car/index.vue'
import stockEditCar from '../views/stock/car/_stockId/edit.vue'
import recommendNew from '../views/recommend-condition/car-info/new.vue'
import recommendEdit from '../views/recommend-condition/car-info/_carInfoId/edit.vue'
import recommendList from '../views/recommend-condition/group/list.vue'
import recommendCarPriceNew from '../views/recommend-condition/car-price/new.vue'
import recommendCarPriceEdit from '../views/recommend-condition/car-price/_carPriceId/edit.vue'
import recommendCarProfitNew from '../views/recommend-condition/car-profit/new.vue'
import recommendCarProfitEdit from '../views/recommend-condition/car-profit/_carProfitId/edit.vue'
import recommendResultAll from '../views/recommend-result/all/index.vue'
import analyzePage from '../views/analyze/_id/index.vue'
import accountLocked from '../views/lock/index.vue'
import maintenancePage from '../views/maintenance/index.vue'
import MypageDashboard from '../views/mypage/index.vue'

/**
 * Dynamic import for splitting code
 */
const Privacy = import('../views/guest/kiyaku/index.vue')
const Help = import("../views/guest/help/index.vue")
const News = import("../views/guest/news/index.vue")
const AboutProfile = import("../views/guest/about/profile.vue")
const Sign = import("../views/guest/sign/index.vue")
const PolicyPrivacy = import("../views/guest/policy/privacy.vue")
const Environment = import("../views/guest/environment/index.vue")
const Contact = import("../views/guest/contact/index.vue")

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: home
  },
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/logout',
    name: 'logout',
    component: logout
  },
  {
    path: '/signup',
    name: 'signup',
    component: signup
  },
  {
    path: '/signup/send-mail-done',
    name: 'signupSendmailComplete',
    component: signupSendmailComplete
  },
  {
    path: '/signup/input/done',
    name: 'signupComplete',
    component: signupComplete
  },
  {
    path: '/signup/input/:token',
    name: 'signupToken',
    component: signupToken
  },
  {
    path: '/mypage/plan',
    name: 'mypagePlan',
    component: MypagePlan
  },
  {
    path: '/mypage/plan/:planId/register',
    name: 'mypage plan register',
    component: MypagePlanChange
  },
  {
    path: '/mypage/plan/:planId/upgrade',
    name: 'mypage plan upgrade',
    component: MypagePlanChange
  },
  {
    path: '/mypage/plan/:planId/downgrade',
    name: 'mypage plan downgrade',
    component: MypagePlanChange
  },
  {
    path: '/mypage/plan/bank-transfer',
    name: 'mypage plan bank transfer',
    component: MypagePlanChange
  },
  {
    path: '/pages/404',
    name: 'page404',
    component: page404
  },
  {
    path: '/mypage/profile/',
    name: 'profileMypage',
    component: profileMypage
  },
  {
    path: '/mypage/profile/change-mail/:token',
    name: 'changeMail',
    component: changeMail
  },
  {
    path: '/forgot/input',
    name: 'forgotInput',
    component: forgotInput
  },
  {
    path: '/forgot/sended',
    name: 'forgotSended',
    component: forgotSended
  },
  {
    path: '/password/reset/done',
    name: 'passwordResetDone',
    component: passwordResetDone
  },
  {
    path: '/password/reset/:token',
    name: 'passwordReset',
    component: passwordReset
  },
  {
    path: '/mypage/credit/new',
    name: 'newCredit',
    component: newCredit
  },
  {
    path: '/chat',
    name: 'chat',
    component: chat
  },
  {
    path: '/stock/list',
    name: 'stockViewsList',
    component: stock
  },
  {
    path: '/stock/search',
    name: 'stock search',
    component: stock
  },
  {
    path: '/stock/car/:id/remove',
    name: 'stock remove out of list',
    component: stock
  },
  {
    path: '/stock/car/:id/like',
    name: 'stock wishlist',
    component: stock
  },
  {
    path: '/stock/deleted-car/list',
    name: 'stock-deleted-list',
    component: stockDeletedList
  },
  {
    path: '/stock/car/:id/restore',
    name: 'restore deleted car',
    component: stockDeletedList
  },
  {
    path: '/stock/car/:id/like',
    name: 'wishlist',
    component: stockDeletedList
  },
  {
    path: '/stock/new-car',
    name: 'stockNewCar',
    component: stockNewCar
  },
  {
    path: '/stock/car/:id/edit',
    name: 'stockEditCar',
    component: stockEditCar
  },
  {
    path: '/recommend-condition/car-info/new',
    name: 'recommend-condition-new',
    component: recommendNew
  },
  {
    path: '/recommend-condition/car-info/:id/edit',
    name: 'recommend-condition-edit',
    component: recommendEdit
  },
  {
    path: '/recommend-condition/group/list',
    name: 'recommend-condition-list',
    component: recommendList
  },
  {
    path: '/recommend-condition/car-price/new',
    name: 'recommend-condition-car-price-new',
    component: recommendCarPriceNew
  },
  {
    path: '/recommend-condition/car-price/:id/edit',
    name: 'recommend-condition-car-price-edit',
    component: recommendCarPriceEdit
  },
  {
    path: '/recommend-condition/car-profit/new',
    name: 'recommend-condition-car-profit-new',
    component: recommendCarProfitNew
  },
  {
    path: '/recommend-condition/car-profit/:id/edit',
    name: 'recommend-condition-car-profit-edit',
    component: recommendCarProfitEdit
  },
  {
    path: '/recommend-result/all',
    name: '/recommend-result-all',
    component: recommendResultAll
  },
  {
    path: '/analyze/:id',
    name: 'analyzePage',
    component: analyzePage
  },
  {
    path: '/locked',
    name: 'accountLocked',
    component: accountLocked
  },
  {
    path: '/maintenance',
    name: 'maintenancePage',
    component: maintenancePage
  },
  {
    path: '/mypage',
    name: 'MypageDashboard',
    component: MypageDashboard
  },
  {
    path: '/kiyaku',
    name: 'Privacy',
    component: () => Privacy
  },
  {
    path: '/help',
    name: 'Help',
    component: () => Help
  },
  {
    path: '/news',
    name: 'News',
    component: () => News
  },
  {
    path: '/about/profile',
    name: 'AboutProfile',
    component: () => AboutProfile
  },
  {
    path: '/sign',
    name: 'Sign',
    component: () => Sign
  },
  {
    path: '/policy-privacy',
    name: 'PolicyPrivacy',
    component: () => PolicyPrivacy
  },
  {
    path: '/environment',
    name: 'Environment',
    component: () => Environment
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => Contact
  },
]

const router = new VueRouter({
  mode: 'history',
  linkActiveClass: "active",
  scrollBehavior: (from, to) => {
    if (from.name != to.name) return { x: 0, y: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  //get role component
  if (to.matched.length === 0) {
    next('/pages/404')
  } else {
    let authComponent = to.matched[0].components.default.auth
    const roleTempComponent = authComponent ? role.find(item => item.role === authComponent) : null
    const roleComponent = roleTempComponent ? roleTempComponent.priority : null
    const token = localStorage.getItem('token_user')
    const auth = localStorage.getItem('auth_user')
    const roleTempAuth = role.find(item => item.role === auth)
    const roleAuth = roleTempAuth ? roleTempAuth.priority : null
    if (!roleComponent && (to.path === '/login' || to.path === '/') && token && auth === 'user') {
      next('/mypage')
    } else if (!roleComponent && to.path === '/admin/login' && token && auth === 'admin') {
      next('/admin/dashboad')
    } else if (!roleComponent) {
      next()
    } else if (!roleAuth && authComponent === 'user') {
      localStorage.removeItem('token_user')
      localStorage.removeItem('auth_user')
      next('/login')
    } else if (!roleAuth && authComponent === 'admin') {
      localStorage.removeItem('token_user')
      localStorage.removeItem('auth_user')
      next('admin/login')
    } else if (roleAuth && token && roleAuth >= roleComponent) {
      next()
    } else {
      next('/pages/404')
    }
  }
})
//set head
router.afterEach((to, from) => {
  let component = to.matched[0].components.default
  Vue.nextTick(() => {
    let head = ''
    if (component.head) {
      head = component.head()
    }
    document.title = head.title || "AIハント";
    (head.meta || []).forEach(item => {
      let meta = document.createElement("meta");
      Object.keys(item).forEach(ele => {
        meta.setAttribute(ele, item[ele]);
      })
      document.head.appendChild(meta)
    })

  });
});

export default router
