<template>
  <div class="cq-plan_mypage">
    <CQPreLoad :preload="preLoading" />
    <div v-if="!preLoading">
      <div class="cq-plan_mypage-title pl-3 pt-3">プラン</div>
      <b-container class="container-custom pb-5">
        <div class="cq-plan_mypage-infoplan d-flex mt-4">
          <div class="cq-plan_mypage-infoplan-leftContent d-flex justify-content-between flex-column">
            <div>
              <div
                class="cq-plan_mypage-infoplan-bager cq-white cq-bg-orange d-flex justify-content-center align-items-center"
              >
                現在のプラン
              </div>
              <div class="cq-plan_mypage-infoplan-planName cq-title mt-3 mt-md-4">
                {{ infoPlan.planName }}
              </div>
              <div class="cq-plan_mypage-infoplan-listPlanPrice">
                <div v-for="(price, i) in infoPlan.price" class="cq-plan_mypage-infoplan-planPrice mt-2" :key="i">
                  <span>{{price}}</span>
                </div>
                <div v-if="!isFreePlan && userPlanInfo.is_first" class="cq-plan_mypage-infoplan-planPrice mt-2">
                  初回事務手数料30,000円（税抜）
                </div>
              </div>
              <hr class="cq-hr-green mt-2 mb-4" />
              <div class="cq-plan_mypage-infoplan-listData">
                <div v-for="(infoPlan, indexInfo) in infoPlan.info" class="cq-plan_mypage-infoplan-data d-flex align-items-center  mt-2" :key="indexInfo">
                  <div class="cq-green mr-2">
                    <font-awesome-icon icon="fa-solid fa-check" />
                  </div>
                  <div>
                    <div v-for="(infoPlanDetail, indexPlanDetail) in infoPlan" :key="indexPlanDetail">
                      {{ infoPlanDetail }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mt-3 mt-md-4">
              <router-link
                v-if="isBanking && userPlanInfo.has_invoice_plan && (userPlanInfo.user_plan.plan_info.amount === 0 && !isFreePlan)"
                to="/mypage/plan/bank-transfer"
                id="link_to_bank_transfer"
                class="cq-plan_mypage-infoplan-banking btn text-white d-flex justify-content-center align-items-center cq-btn-bg-green mb-2"
                >今月分を振り込み</router-link
              >
              <b-button
                v-if="infoPlan.planName !== 'フリー'"
                class="cq-plan_mypage-infoplan-cancelPlan cq-btn-bg-brown"
                v-on:click="$bvModal.show('cancelPlan')"
                id="btn_mypage_plan_cancel"
                >解約する</b-button
              >
            </div>
          </div>
          <div class="cq-plan_mypage-infoplan-rightContent">
            <div
              class="cq-plan_mypage-infoplan-planName cq-title d-flex align-items-center flex-wrap"
            >
              <span class="mr-2">{{ isWaitingApprove ? userPlanInfo.plan_to_change.plan_name : infoPlan.planName }}</span>
              <span v-if="!userPlanInfo.is_update_now && futurePlanName && futurePlanName != infoPlan.planName" class="input-error w-content"
                >※{{datePlanUser}}から「{{futurePlanName}}」に自動{{(upDownGrade === 'mypage plan downgrade') ? 'ダウン' : 'アップ'}}グレードされる</span
              >
            </div>
            <div class="cq-plan_mypage-infoplan-part mt-3 mt-md-4">基本情報</div>
            <div class="cq-plan_mypage-infoplan-partInfo mt-2">
              申込日 : {{ isWaitingApprove ? new Date() : userPlanInfo.user_plan.actived_date_on | moment('YYYY/MM/DD') }}
            </div>
            <div class="cq-plan_mypage-infoplan-partInfo" v-if="!isFreePlan || isWaitingApprove">
              決済方法：{{ isWaitingApprove ? userPlanInfo.plan_to_change.payment_type === "wire_transfer" ? "銀行振込" : "クレジットカード" : userPlanInfo.user_plan.plan_info.payment_type == 1 ? "クレジットカード" : "銀行振込" }}
            </div>
            <div class="cq-plan_mypage-infoplan-partInfo">金額 : 
              <span v-if="isFreePlan && !isWaitingApprove">無料</span>
              <span v-else>{{ isWaitingApprove ? userPlanInfo.plan_to_change.amount : userPlanInfo.user_plan.plan_info.amount | setComma }}円</span>
              <!-- <span v-if="userPlanInfo.has_invoice_plan" class="input-error w-content ml-2"
                >※今月分の請求書をダウンロードする場合は
                <span class="link-custom" @click="invoiceDownload(type = 'plan')">こちら</span>
                をクリックしてください。
              </span> -->
            </div>
            <div class="cq-plan_mypage-infoplan-partInfo row m-0" v-if="userPlanInfo.is_show_msg">状況 : 
              <span class="mr-2">承認中 </span>
              <div class="text-danger">
                <p>※管理者にてご入金を確認後、新しいプランのご利用が可能となります。</p>
                <p class="link-custom" @click="showModalBankingInfo">振込先を参照</p>
              </div>
            </div>
            <div class="cq-plan_mypage-infoplan-part mt-3 mt-md-4" v-if="!isFreePlan || optionData">
              追加オプション情報
            </div>
            <div v-if="optionData">
              <div
                class="cq-plan_mypage-infoplan-partInfo d-flex align-item-center flex-wrap mt-2"
              >
                <div class="cq-plan_mypage-infoplan-partInfoGroup">
                  流れ車両AIアラート：{{ optionData.flow_option_total }}台/１日
                </div>
                <div class="cq-plan_mypage-infoplan-partInfoGroup">
                  共有在庫AIアラート：{{ optionData.shared_inventory_option_total }}台/１週
                </div>
              </div>
              <div
                class="cq-plan_mypage-infoplan-partInfo d-flex align-items-center flex-wrap"
              >
                <span v-if="userPlanInfo.has_invoice_option">金額 : {{((optionData.flow_option_total * 50) + (optionData.shared_inventory_option_total * 100)) +
                  (((optionData.flow_option_total * 50) + (optionData.shared_inventory_option_total * 100)) * 0.1) | setComma}}円</span>
                <!-- <span v-if="userPlanInfo.has_invoice_option" class="input-error w-content ml-2"
                  >※今月分の請求書をダウンロードする場合は
                  <span class="link-custom" @click="invoiceDownload(type = 'option')">こちら</span>
                  をクリック
                </span> -->
              </div>
            </div>
            <div v-if="!isFreePlan && !hasCardCredit" class="cq-plan_mypage-infoplan-partInfo">
              ※追加オプションを利用する場合は<router-link id="link-credit" class="cq-link-green" :to="{name: 'newCredit', query: { action: 'new_credit' }}">クレジットカードの登録</router-link>が必要になります。
            </div>
            <div v-if="listInvoice.length" class="cq-plan_mypage-infoplan-paymentHistory mt-3">
              <div class="cq-plan_mypage-infoplan-part">請求書一覧</div>
              <div class="cq-plan_mypage-infoplan-paymentHistoryList overflow-auto">
                <div
                  v-for="(item, index) in listInvoice"
                  class="cq-plan_mypage-infoplan-paymentHistoryItem row flex-nowrap align-items-center justify-content-between w-100 m-0"
                  :key="index"
                >
                  <div class="d-flex col-auto p-0">
                    <div class="cq-plan_mypage-infoplan-paymentHistoryDate">
                      {{ item.created_at | moment('YYYY/MM/DD')  }}
                    </div>
                    <div class="cq-plan_mypage-infoplan-paymentHistoryDesc">
                      {{ item.invoice_info.title }}
                    </div>
                  </div>
                  <div class="d-flex col-auto p-0">
                    <div class="cq-plan_mypage-infoplan-paymentHistoryDate">
                      {{ item.payment_status_text }}
                    </div>
                    <div class="cq-plan_mypage-infoplan-paymentHistoryPrice">
                      {{ item.invoice_info.total_amount | setComma }}円
                    </div>
                    <div
                      class="cq-plan_mypage-infoplan-paymentHistoryDown d-flex justify-content-center align-items-center"
                      title="請求書ダウンロード"
                      @click="invoiceDownload(id = item.id, invoice_number_text = item.invoice_info.invoice_number_text)">
                      <font-awesome-icon icon="fa-solid fa-file-arrow-down" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cq-plan_mypage-infoOption mt-4">
          <div class="cq-plan_mypage-desc">
            追加オプション情報
              <b-button class="mx-3 btn cq-bg-orange" v-if="!isFreePlan" v-on:click="handlerAddOptionsPlan" id="btn_register_option">
                {{(optionData && (optionData.flow_option_total || optionData.shared_inventory_option_total)) ? '変更' : '追加'}}
              </b-button>
            </div>
          <div class="cq-plan_mypage-infoOption-content mt-3">
            <div class="cq-plan_mypage-boxTitle">オプション利用履歴</div>
            <TableOptionPayment class="mt-3" ref="TableOptionPayment" :isLoading="isLoadingOption" :dataTable="listDataTableOption" />
          </div>
        </div>
        <div class="cq-plan_mypage-listPlan mt-4" v-bind="introPlan[0]">
          <div class="cq-plan_mypage-desc">プラン一覧</div>
          <VueSlickCarousel class="custom-carousel mt-3 px-3" v-bind="settings">
            <div v-for="(value, index) in listPlan" class="d-flex justify-content-center" :key="index">
              <div class="cq-plan_mypage-listPlan-item" >
                <div
                  v-if="value.planName !== 'フリー'"
                  class="cq-plan_mypage-infoplan-bager d-flex justify-content-center align-items-center"
                  :class="value.isPopular ? 'cq-bg-orange cq-white' : 'cq-btn-bg-none cq-border-green cq-green'"
                >
                  {{ value.status }}
                </div>
                <div
                  v-else
                  class="cq-plan_mypage-infoplan-bager d-flex justify-content-center align-items-center cq-bg-green cq-white"
                >
                  {{ value.status }}
                </div>
                <div class="cq-plan_mypage-infoplan-planName cq-title mt-3 mt-md-4">
                  {{ value.planName }}
                </div>
                <div class="cq-plan_mypage-infoplan-listPlanPrice">
                  <div v-for="(price, i) in value.priceInfo" class="cq-plan_mypage-infoplan-planPrice mt-2" :key="i">
                    <span> {{ price }} </span>
                  </div>
                </div>
                <hr class="cq-hr-green mt-2 mb-4" />
                <div class="cq-plan_mypage-infoplan-listData">
                  <div v-for="(infoPlan, indexInfo) in value.info" class="cq-plan_mypage-infoplan-data d-flex align-items-center  mt-2" :key="indexInfo">
                    <div class="cq-green mr-2">
                      <font-awesome-icon icon="fa-solid fa-check" />
                    </div>
                    <div>
                      <div v-for="(infoPlanDetail, indexPlanDetail) in infoPlan" :key="indexPlanDetail">
                        {{ infoPlanDetail }}
                      </div>
                    </div>
                  </div>
                </div>
                <b-button v-if="value.planId === 4"
                  id="btn_mypage_plan_order_contact"
                  v-on:click="$bvModal.show('contactPlan')"
                  class="cq-plan_mypage-infoplan-cancelPlan text-white d-flex justify-content-center align-items-center btn mt-4 cq-btn-bg-green">
                  {{ getCurrentPlan(value.planId, infoPlan.planId, userPlanInfo.user_plan.is_first).text}}
                </b-button>
                <router-link :to="getCurrentPlan(value.planId, infoPlan.planId, userPlanInfo.user_plan.is_first).link"
                  :id="`link_to_plan_${value.planId}`"
                  v-else-if="getCurrentPlan(value.planId, infoPlan.planId, userPlanInfo.user_plan.is_first).show && getCurrentPlan(value.planId, infoPlan.planId, userPlanInfo.user_plan.is_first).link"
                   class="cq-plan_mypage-infoplan-cancelPlan text-white d-flex justify-content-center align-items-center btn mt-4"
                  :class="getCurrentPlan(value.planId, infoPlan.planId, userPlanInfo.user_plan.is_first).isCancel ? 'cq-btn-bg-brown' : 'cq-btn-bg-green' ">
                  {{ getCurrentPlan(value.planId, infoPlan.planId, userPlanInfo.user_plan.is_first).text}}
                </router-link>
                <b-button
                  id="btn_mypage_plan_show_modal_cancel_plan"
                  v-else-if="getCurrentPlan(value.planId, infoPlan.planId, userPlanInfo.user_plan.is_first).show && !getCurrentPlan(value.planId, infoPlan.planId, userPlanInfo.user_plan.is_first).link"
                  class="cq-plan_mypage-infoplan-cancelPlan text-white mt-4"
                  :class="getCurrentPlan(value.planId, infoPlan.planId, userPlanInfo.user_plan.is_first).isCancel ? 'cq-btn-bg-brown' : 'cq-btn-bg-green' "
                  v-on:click="$bvModal.show('cancelPlan')"
                  >{{ getCurrentPlan(value.planId, infoPlan.planId, userPlanInfo.user_plan.is_first).text}}</b-button
                >
              </div>
            </div>
          </VueSlickCarousel>
          <b-modal
            id="cancelPlan"
            ref="cancelPlan"
            modal-class="modal-confirm modal-confirm-sm"
            body-class="p-md-4 p-3"
            hide-footer
            hide-header
            centered
          >
            <div class="d-block text-center title w-100">
              <h1 class="text-warning">
                <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
              </h1>
              <p>
                解約してもよろしいでしょうか？「はい」を選択するとフリープランに変更されます。
              </p>
            </div>
            <div
              class="mt-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap"
            >
              <b-button class="btn-bot" v-on:click="$bvModal.hide('cancelPlan')" id="btn_mypage_plan_hide_modal_cancel">いいえ</b-button>
              <b-button class="cq-btn-bg-green btn-top" :disabled="isLoading" @click="CancelPlan()" id="btn_mypage_plan_confirm_cancel_plan">
                <b-spinner v-if="isLoading" small />
                はい
              </b-button>
            </div>
          </b-modal>
          <CQModalContact :modalId="'contactPlan'" :type-str="'「カスタム」プランについて '" />
          <b-modal
            id="registerOption"
            ref="registerOption"
            modal-class="modal-confirm modal-confirm-sm"
            body-class="p-md-4 p-3"
            hide-footer
            hide-header
            centered
          >
            <form>
              <div class="d-block text-center title w-100 mb-4">
                <h5>リコメンド車両オプション{{(optionData && (optionData.flow_option_total || optionData.shared_inventory_option_total)) ? '変更' : '追加'}}</h5>
              </div>
              <div class="groupInput-input position-relative mb-4">
                <div class="d-flex justify-content-between">
                  <div>毎日流れ車両AIアラート（台数/１日）</div>
                  <div class="input-error d-none d-sm-block">※オプション費用：50円/台（税抜）</div>
                </div>
                <b-input id="input-option-1" placeholder="例）10" autocomplete="off" :state="$v.formOption.flow_option_total.$error ? false : null" v-model="$v.formOption.flow_option_total.$model" />
                <div class="row">
                  <div class="col-12">
                    <div v-if="$v.formOption.flow_option_total.$error" id="option1-error" class="input-error">
                      <span v-if="!$v.formOption.flow_option_total.integer">{{ message.NUMBER_ERROR }}</span>
                      <span v-else>{{ message.ERROR_MIN_VALUE }}</span>
                    </div>
                  </div>
                  <div class="col-12">
                    <div class="input-error d-none text-option">※オプション費用：50円/台（税抜）</div>
                  </div>
                </div>
              </div>
              <div class="groupInput-input position-relative mb-2">
                <div class="d-flex justify-content-between">
                  <div>毎週共有在庫AIアラート（台数/１週間）</div>
                  <div class="input-error d-none d-sm-block">※オプション費用：100円/台（税抜）</div>
                </div>
                <b-input id="input-option-2" placeholder="例）10" autocomplete="off" :state="$v.formOption.shared_inventory_option_total.$error ? false : null" v-model="$v.formOption.shared_inventory_option_total.$model" />
                <div class="row">
                  <div class="col-md-6 col-12">
                    <div v-if="$v.formOption.shared_inventory_option_total.$error" id="option2-error" class="input-error">
                      <span v-if="!$v.formOption.shared_inventory_option_total.integer">{{ message.NUMBER_ERROR }}</span>
                      <span v-else>{{ message.ERROR_MIN_VALUE }}</span>
                    </div>
                  </div>
                  <div class="col-md-6 col-12 text-md-right">
                    <div class="">
                      <div class="input-error d-none text-option">※オプション費用：100円/台（税抜）</div>
                      <div class="input-error">※不要な場合は0を入力してください</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="groupInput-input position-relative mb-5">
                <div class="d-flex justify-content-between">
                  <div>クーポンを利用する:</div>
                </div>
                <div class="cq-plan_mypage-infoplan-input">
                  <form @submit.prevent.stop="handleCheckCoupon">
                    <div class="mt-2 d-flex align-items-center flex-wrap">
                      <b-form-input placeholder="例）HO19BXQOZ" autocomplete="off" v-model="couponCode" />
                      <button class="btn cq-btn-bg-orange btn-secondary" id="btn_mypage_plan_search_coupon" :disabled="isSearchingCoupon">
                        <b-spinner small v-if="isSearchingCoupon" />
                        適用
                      </button>
                    </div>
                  </form>
                </div>
                <div v-if="couponData" class="mt-2">
                  <div class="cq-plan_mypage-infoplan-boxCheckout mt-2">
                    <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap">
                      <div class="cq-plan_mypage-infoplan-partInfo-left">
                        <span>クーポン名</span>
                      </div>
                      <div class="cq-plan_mypage-infoplan-partInfo-right">
                        {{couponData.name}}
                      </div>
                    </div>
                    <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                      <div class="cq-plan_mypage-infoplan-partInfo-left">
                        <span>適用範囲</span>
                      </div>
                      <div class="cq-plan_mypage-infoplan-partInfo-right">
                        <div class="text-danger">
                          {{scopeList[couponData.scope]}}
                        </div>
                      </div>
                    </div>
                    <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                      <div class="cq-plan_mypage-infoplan-partInfo-left">
                        <span>開始日</span>
                      </div>
                      <div class="cq-plan_mypage-infoplan-partInfo-right">
                        {{couponData.valid_from_on  | moment('YYYY/MM/DD')}}
                      </div>
                    </div>
                    <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                      <div class="cq-plan_mypage-infoplan-partInfo-left">
                        <span>終了日</span>
                      </div>
                      <div class="cq-plan_mypage-infoplan-partInfo-right">
                        {{couponData.valid_till_on | moment('YYYY/MM/DD')}}
                      </div>
                    </div>
                    <div class="cq-plan_mypage-infoplan-partInfo d-flex flex-wrap ">
                      <div class="cq-plan_mypage-infoplan-partInfo-left">
                        <span>利用可能金額</span>
                      </div>
                      <div class="cq-plan_mypage-infoplan-partInfo-right text-danger">
                        {{couponData.discount_price | setComma}}円
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="mt-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap"
              >
                <b-button class="btn-bot" v-on:click="$bvModal.hide('registerOption')" id="btn_mypage_plan_hide_modal_option">キャンセル</b-button>
                <b-button class="cq-btn-bg-green btn-top" :disabled="isLoading || (!formOption.shared_inventory_option_total && !formOption.flow_option_total)" @click="getAmountOption" id="btn_mypage_plan_confirm_modal_option">
                  <b-spinner v-if="isLoading" small />
                  {{((optionData && (optionData.flow_option_total || optionData.shared_inventory_option_total)) ? '変更する' : '追加する')}}
                </b-button>
              </div>
            </form>
          </b-modal>
          <b-modal
            id="confirmCredit"
            ref="confirmCredit"
            modal-class="modal-confirm modal-confirm-sm"
            body-class="p-md-4 p-3"
            hide-footer
            centered
          >
            <div class="d-block text-center title w-100 mb-4">
              <h1 class="text-danger">
                <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
              </h1>
              <p class="mt-1">追加オプションを利用する場合はクレジットカードが必要です。<router-link id="link-credit" class="cq-link-green" :to="{name: 'newCredit', query: { action: 'new_credit' }}">「支払情報」</router-link>から登録してください。</p>
            </div>
          </b-modal>
          <b-modal
            id="modalConfirmOption"
            ref="modalConfirmOption"
            modal-class="modal-confirm modal-confirm-sm"
            body-class="p-md-4 p-3"
            hide-footer
            centered
          >
            <div class="d-block text-center title w-100 mb-4">
              <h1 class="text-warning">
                <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
              </h1>
              <div class="text-left mt-4 px-1">
                <p class="mt-2">
                  追加オプションはリコメンド送信の実績に基づいて月末締めの請求となります。
                </p>
                <p class="mt-2">
                  1ヶ月に請求される金額は下記を上限とします。
                </p>
                <p class="mt-2">
                  流れ車両AIアラート：
                  <span>
                    {{ optionsAmount.flow_option_total | setComma }}台
                  </span>
                  ×
                  <span>
                    {{ optionsAmount.count_date | setComma }}日
                  </span>
                  ×
                  <span>
                    {{ optionsAmount.flow_option_price | setComma }}円
                  </span>
                  =
                  <span>
                    {{ optionsAmount.flow_option_amount | setComma }}(税抜)
                  </span>
                </p>
                <p class="mt-2">
                  共有在庫AIアラート：
                  <span>
                    {{ optionsAmount.shared_inventory_option_total | setComma }}台
                  </span>
                  ×
                  <span>
                    {{ optionsAmount.count_weeks | setComma }}週
                  </span>
                  ×
                  <span>
                    {{ optionsAmount.shared_inventory_option_price | setComma }}円
                  </span>
                  =
                  <span>
                    {{ optionsAmount.shared_inventory_amount | setComma }}(税抜)
                  </span>
                </p>
                <p class="mt-2">
                  合計：{{ optionsAmount.total_amount | setComma }}円（税込）
                </p>
                <div class="mt-2" v-if="couponData">
                  <span>利用可能金額: {{ couponData.discount_price | setComma }}円</span>
                  <span class="text-danger text-sm ml-2">(クーポンコードはサイクルの終わりに適用されます。)</span>
                </div>
                <p class="mt-4 text-md">
                  ※1ヶ月のオークション稼働日数が26日、5週間として計算しています。
                </p>
              </div>
            </div>
            <div
                class="mt-4 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap"
              >
                <b-button class="btn-bot" v-on:click="$bvModal.hide('modalConfirmOption')" id="btn_mypage_plan_hide_modal_option">キャンセル</b-button>
                <b-button class="cq-btn-bg-green btn-top" :disabled="isLoading " @click="handleChangeOption()" id="btn_mypage_plan_confirm_modal_option">
                  <b-spinner v-if="isLoading" small />
                    はい
                </b-button>
              </div>
          </b-modal>
          <b-modal
            id="modalBankingInfo"
            ref="modalBankingInfo"
            modal-class="modal-confirm modal-confirm-sm modal-banking-info"
            body-class="p-md-4 p-3"
            hide-footer
            hide-header
            centered
          >
            <div class="d-block text-center title w-100">
              <h2>お振込先</h2>
              <div class="titel-succus mb-4">
                <div class="item-comfirm">
                  <div class="row">
                    <label class="col-auto text-left" for="">金融機関：</label>
                    <span class="col text-right">みずほ銀行</span>
                  </div>
                  <div class="row">
                    <label class="col-auto text-left" for="">支店名：</label>
                    <span class="col text-right">大森支店</span>
                  </div>
                  <div class="row">
                    <label class="col-auto text-left" for="">支店番号：</label>
                    <span class="col text-right">196</span>
                  </div>
                  <div class="row">
                    <label class="col-auto text-left" for="">科目 :</label>
                    <span class="col text-right">普通</span>
                  </div>
                  <div class="row">
                    <label class="col-auto text-left" for="">口座番号:</label>
                    <span class="col text-right">1249607</span>
                  </div>
                  <div class="row">
                    <label class="col-auto text-left" for="">受取人名:</label>
                    <span class="col text-right">カ）ハッピー</span>
                  </div>
                  <div class="row text-danger">
                    <label class="col-auto text-left" for="">確認コード:</label>
                    <span class="col text-right">{{userPlanInfo.plan_to_change ? userPlanInfo.plan_to_change.confirm_code : ""}}</span>
                  </div> 
                </div>
              </div>
              <div class="my-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-center flex-wrap">
                <b-button
                  id="btn-continue"
                  class="btn-confirm cq-btn-bg-green"
                  @click="$bvModal.hide('modalBankingInfo')"
                  >
                  閉じる
                </b-button>
              </div>
            </div>
          </b-modal>
        </div>
      </b-container>
    </div>
  </div>
</template>

<script>
import TableOptionPayment from "@/components/mypage/TableOptionPayment.vue";
import CQPreLoad from "@/components/CQPreLoad.vue";
import CQModalContact from "@/components/CQModalContact.vue";
import { minValue, integer } from 'vuelidate/lib/validators'
import { loadListPlan, listPlan } from "@/utils/listPlan"
import { mapActions, mapGetters } from 'vuex';
import { Message } from '@/utils/message';
import intro, { default_configuration } from "@/utils/intro"
import "@/assets/scss/mypage/plan.scss";

export default {
  name: "MypagePlan",
  layout: "user",
  auth: "user",
  head() {
    return {
      title: "プラン",
    };
  },
  components: {
    TableOptionPayment,
    CQPreLoad,
    CQModalContact
  },
  data() {
    return {
      preLoading: true,
      listPlan: structuredClone(loadListPlan()),
      infoPlan: {},
      isLoading: false,
      isBanking: false,
      isFreePlan: true,
      message: Message.CHANGE_PLAN,
      formOption: {
        shared_inventory_option_total: null,
        flow_option_total: null,
      },
      optionsAmount: {
        total_amount: 0,
        flow_option_amount: 0,
        shared_inventory_amount: 0,
      },
      settings: {
        dots: true,
        arrows: true,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        touchThreshold: 5,
        responsive: [
          {
            breakpoint: 1919,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              touchThreshold: 5,
            }
          },
          {
            breakpoint: 1399,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            }
          },
          {
            breakpoint: 991,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 767,
            settings: {
              arrows: false,
              centerMode: true,
              centerPadding: '20px',
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
          {
            breakpoint: 480,
            settings: {
              arrows: false,
              centerMode: false,
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          },
        ]
      },
      cardInfo: null,
      listInvoice: [],
      optionData: null,
      planNames: null,
      upDownGrade: null,
      datePlanUser: null,
      flagDate: false,
      isFirstPlan: false,
      couponData: null,
      isSearchingCoupon: false,
      scopeList: ['全て','初期登録手数料','プラン費用','追加オプション'],
      couponCode: null,
      isLoadingOption: false,
      listDataTableOption: [],
      introPlan: intro.plan,
    }
  },
  computed: {
    ...mapGetters({
      userPlanInfo: 'userPlanInfo',
      userInfo: 'userInfo'
    }),
    isIntro () {
      // Condition to show intro
      return !isNaN(this.$route.query.option_plan) ? true : false
    },
    futurePlanName () {
      const name = this.userPlanInfo?.plan_to_change?.plan_name
      return name
    },
    isWaitingApprove () {
      return this.userPlanInfo.plan_to_change?.payment_type === 'wire_transfer' && this.userPlanInfo.is_update_now
    },
    hasCardCredit () {
      return this.cardInfo.data?.cards?.count
    },
    planListCommon() {
      const options = {}
      const idPlan = this.userPlanInfo.user_plan.plan_info.id
      if(idPlan) {
        options[idPlan] = {}
        if(this.userPlanInfo.user_plan.plan_info.adv_vehicle_automatic_search_setting_number) {
          options[idPlan].ADV_VEHICLE_NUM = this.userPlanInfo.user_plan.plan_info.adv_vehicle_automatic_search_setting_number
        }
        if(this.userPlanInfo.user_plan.plan_info.flow_alert_setting_number) {
          options[idPlan].FLOW_ALERT_NUM = this.userPlanInfo.user_plan.plan_info.flow_alert_setting_number
        }
        if(this.userPlanInfo.user_plan.plan_info.shared_inventory_alert_setting_number){
          options[idPlan].SHARED_INVENTORY_NUM = this.userPlanInfo.user_plan.plan_info.shared_inventory_alert_setting_number
        }
      }
      return loadListPlan(options)
    }
  },
  watch: {
    async userPlanInfo() {
      this.preLoading = false
      const planName = this.userPlanInfo.user_plan.plan_info.plan_name
      const isFirst = this.userPlanInfo.user_plan.is_first
      this.isFirstPlan = this.userPlanInfo.is_first;
      this.planNames = planName;
      if (!isFirst && planName !== 'フリー') {
        this.isBanking = (this.userPlanInfo.user_plan.list_payment_transaction.length && this.userPlanInfo.user_plan.list_payment_transaction[0].payment_type === "wire_transfer")
      }
      // this.userPlanInfo.user_plan.plan_info.amount = (this.userPlanInfo.user_plan.list_payment_transaction.length) ? this.userPlanInfo.user_plan.list_payment_transaction[0].amount : 0
      this.listInvoice = this.userPlanInfo.list_invoice
      this.optionData = this.userPlanInfo.option_info
      if (this.userPlanInfo.option_info) {
        const { shared_inventory_option_total, flow_option_total } = this.userPlanInfo.option_info
        this.formOption.shared_inventory_option_total = shared_inventory_option_total ? shared_inventory_option_total : null
        this.formOption.flow_option_total = flow_option_total ? flow_option_total : null
      }
      this.isFreePlan = planName === 'フリー'
      this.datePlanUser = this.$moment(this.getDayMonths(1, new Date(this.userPlanInfo.user_plan.actived_date_on))).format('YYYY/MM/DD');
      (new Date().getTime() > new Date(this.datePlanUser)) ? this.flagDate = false : this.flagDate = true;
      this.getUserPlan(planName)
      await this.getUserOption()
      if(this.isIntro){
        this.$nextTick(() => {
          this.$router.replace({
            query:{}
          })
          this.introJs().setOptions(default_configuration).start();
        })
      }
    },
  },
  async created() {
    this.upDownGrade = localStorage.getItem("upDownGrade");
    this.cardInfo = await this.$store.dispatch('getCardInfo')
    await this.userInfo.handler
    this.getPlanInfo()
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if (modalId === 'registerOption') {
        this.formOption = {
          flow_option_total: (this.optionData?.flow_option_total) ? this.optionData.flow_option_total : null,
          shared_inventory_option_total: (this.optionData?.shared_inventory_option_total) ? this.optionData.shared_inventory_option_total : null,
        }
        this.couponData = null
        this.couponCode = null
      }
    })
  },
  methods: {
    ...mapActions({
      getPlanInfo: 'getPlanInfo'
    }),

    checkoutPayjp() {
      document.getElementById("payjp_checkout_box").childNodes[0].click()
    },
    
    getUserPlan(planName) {
      this.infoPlan = this.planListCommon.find(item => item.planName == planName)
    },

    CancelPlan() {
      const formData = {
        payment_type: 1,
        plan_id: 1,
        is_update_now: true
      };
      this.handleChangePlan(formData)
    },

    handlerAddOptionsPlan() {
      if(this.hasCardCredit) {
        this.$bvModal.show('registerOption')
      }
      else {
        this.$bvModal.show("confirmCredit")
      }
    },

    async handleChangePlan(formData) {
      this.isLoading = true
      const data = await this.$store.dispatch('changePlan', formData)
      if (data.success) {
        this.isLoading = false
        this.$toast.success(this.message.CANCEL_PLAN)
        this.$bvModal.hide('cancelPlan')
        this.getPlanInfo()
      } else {
        this.isLoading = false
        if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message)
      }
    },

    async handleChangeOption() {
      this.$v.$touch()
      const result = !this.$v.formOption.$error
      if (result) {
        this.isLoading = true
        const formData = {...this.formOption}
        formData.shared_inventory_option_total = parseInt(formData.shared_inventory_option_total)
        formData.amount_hold = this.optionsAmount.total_amount
        formData.coupon_code = this.couponCode
        const data = await this.$store.dispatch('changeOption', formData)
        if (data.success) {
          this.isLoading = false
          this.$toast.success(data.message)
          this.$bvModal.hide('registerOption')
          this.$bvModal.hide('modalConfirmOption')
          this.getPlanInfo()
        } else {
          if(data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message)
          this.$bvModal.hide('registerOption')
          this.$bvModal.hide('modalConfirmOption')
          this.isLoading = false
        }
      }
    },

    getCurrentPlan(newPlanId, currentPlanId, isFirst) {
      if(newPlanId == currentPlanId && newPlanId == 1 ) {
        return {
          show: false
        }
      } else if(newPlanId === 4) {
        return {
          text: 'お問い合わせ',
          link: `/contact`,
          isCancel: false,
          show: true
        }
      } else if(isFirst) {
        return {
          text: '登録',
          link: `/mypage/plan/${newPlanId}/register`,
          isCancel: false,
          show: true
        }
      } else if(newPlanId < currentPlanId) {
        return {
          text: 'ダウングレード',
          link: `/mypage/plan/${newPlanId}/downgrade`,
          isCancel: false,
          show: true
        }
      } else if(newPlanId > currentPlanId) {
        return {
          text: 'アップグレード',
          link: `/mypage/plan/${newPlanId}/upgrade`,
          isCancel: false,
          show: true
        }
      } else {
        return {
          text: '解約する',
          isCancel: true,
          show: true
        }
      } 
    },
    async invoiceDownload(id, invoice_number_text) {
      const data = await this.$store.dispatch('invoiceDownload', id)
      const blob = new Blob([data], { type: "application/pdf" });
      const urlDownload = window.URL.createObjectURL(blob);
      const linkDownload = document.createElement("a");
      linkDownload.href = urlDownload;
      linkDownload.download = `invoice_${invoice_number_text}.pdf`;
      linkDownload.click();
    },
    getDayMonths(numOfMonths, date) {
      date.setMonth(date.getMonth() + numOfMonths);
      return date.setDate(date.getDate());
    },

    showModalBankingInfo() {
      this.$bvModal.show("modalBankingInfo")
    },

    async getAmountOption() {
      this.isLoading = true
      const handlers = []
      if(!this.couponData) {
        handlers.push(this.handleCheckCoupon())
      }
      else {
        handlers.push(Promise.resolve())
      }
      handlers.push(this.$store.dispatch("getAmountOptions", { params: this.formOption }))
      const [,{ data }] = await Promise.all(handlers)
      this.optionsAmount = data
      this.isLoading = false
      this.$bvModal.show('modalConfirmOption')
    },

    async handleCheckCoupon () {
      if(!this.couponCode) return
      this.isSearchingCoupon = true
      const res = await this.$store.dispatch('searchCoupon', {
        code: this.couponCode
      })
      if(res.success) {
        this.couponData = res.data.coupon
      }else {
        if(res.errors.length && res.errors[0].message) this.$toast.error(res.errors[0].message)
      }
      this.isSearchingCoupon = false
    },

    async getUserOption() {
      this.isLoadingOption = true
      const getUserOption = await this.$store.dispatch('getUserOption')
      this.isLoadingOption = false
      if (getUserOption.success) {
        let listDataTable = [];
        listDataTable = getUserOption.data.list_data
        this.listDataTableOption = listDataTable;
        await this.$nextTick()
        this.$refs.TableOptionPayment.setUserOption()
      } else {
        if (getUserOption.errors.length && getUserOption.errors[0].message) this.$toast.error(getUserOption.errors[0].message)
        this.isLoading = false
      }
    },
  },
  validations () {
    return {
      formOption: {
        flow_option_total: { 
          minValue: minValue(0),
          integer
        },
        shared_inventory_option_total: { 
          minValue: minValue(0),
          integer
        },
      },
    }
  }
};
</script>
