<template>
  <div class="cq-sended d-flex justify-content-center align-items-center">
    <div class="cq-sended-box">
      <div class="vcheck d-flex justify-content-center align-items-center mb-3">
        <img src="@/assets/img/check.svg" alt="check" />
      </div>
      <div class="cq-sended-title text-center">メール送信済</div>
      <div class="cq-sended-desc text-center mt-3">
        {{message.SUCCESS}}
      </div>
      <hr class="mt-4">
    </div>
  </div>
</template>

<script>
import "@/assets/scss/sended/forgot-sended.scss";
import { Message } from '@/utils/message';

export default {
  name: "forgotSended",
  layout: "main",
  auth: false,
  head() {
    return {
      title: "認証メール送信完了"
    };
  },
  data() {
    return {
      message: Message.FORGOT_INPUT_EMAIL
    };
  },
};
</script>
