export default {
  methods: {
    confirmFeedbackDisLike () {
      const payload = {
        message: this.messageFeedback,
        id: this.currentIdFeedback
      }
      this.editing ? payload.type = null : payload.type = "dislike"
      this.confirmFeedback(payload)
      this.editing = false
    },
    editFeedback({ type, id, message}) {
      this.editing = true
      this.currentIdFeedback = id
      this.messageFeedback = message
      this.$bvModal.show("confirmFeedback");
    },
    handleFeedback({ type, id, force }) {
      if (type === "dislike" && force !== "force") {
        this.currentIdFeedback = id
        this.messageFeedback = ""
        this.$bvModal.show("confirmFeedback");
        return;
      }
      this.confirmFeedback({ type, id });
    },
    async confirmFeedback({ type, id , message }) {
      this.isLoadingFeedBack = true
      const payload = {}
      if(type === null) {
        payload.is_like = null
      }
      else{
        payload.is_like = type === "like" ? true: false
      }
      payload.comment = message
      const data = await this.$store.dispatch('submitFeedback', {
        data: payload,
        recommendId: id
      })
      this.isLoadingFeedBack = false
      this.messageFeedback = ""
      if(data.success){
        this.$bvModal.hide("confirmFeedback");
        this.recommendList = this.recommendList.map((el) => {
          if(el.id === data.data.recommend.id) {
            el = data.data.recommend
          }
          return el
        })
      }
      else {
         if(data.errors[0]?.message) {
          this.$toast.error(data.errors[0].message) 
         }
      }
    },
  }
}