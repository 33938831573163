<template>
  <div class="cq-header d-flex justify-content-between align-items-center">
    <router-link to="/" id="link_header_logo_home">
      <div class="cq-header-logo">
        <img class="img-contain" src="../assets/img/cq-logo.png" alt="" />
      </div>
    </router-link>
    <div class="col d-xl-flex flex-nowrap d-none justify-content-center">
    </div>
    <div class="col-auto d-xl-flex flex-nowrap d-none">
    </div>
  </div>
</template>

<script>
export default {
  name: "CQHeaderKiyaku",
  data() {
    return {
    };
  },
  methods: {
  },
  created () {
    if(this.userId) this.$router.push({
      name: 'MypageDashboard'
    })
  },
};
</script>
<style>
.slide-fade-enter-active,
.slide-fade-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(100%);
}
.fade-blur-enter-active,
.fade-blur-leave-active {
  transition: all 0.5s;
}

.slide-fade-enter,
.slide-fade-leave-to {
  opacity: 0;
}
</style>
<style lang="scss" scoped>
.cq-header {
  height: 70px;
  width: 100%;
  padding: 0.0rem 4.0rem;
  background-color: $white;
  box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1051;
  .icon-close {
    position: absolute;
    left: -50px;
    top: 10px;
    z-index: 3;
  }
  .bg-blur {
    backdrop-filter: blur(12px);
    z-index: 1;
    position: fixed;
    inset: 0;
    background-color: rgba($color: #000000, $alpha: 0.4) !important;
  }
  @include screen(767) {
    padding: 0.0rem 1.3rem;
  }
  &-logo {
    width: 160px;
    height: auto;
    @include screen(767) {
      width: 140px;
    }
  }
}
</style>