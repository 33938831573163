<template>
  <div class="profile-mypage w-100 pb-5">
    <CQPreLoad :preload="preLoading" />
    <div class="profile-mypage-titel pl-3 pt-3">会員情報</div>
    <div class="profile-mypage-information container-custom mx-auto mt-3" v-if="!preLoading">
      <div class="profile-mypage-information-header row">
        <div class="text-account-information col-6">
          <span>アカウント情報</span>
        </div>
        <div class="change-password col-6 text-right pb-4 d-flex justify-content-end">
          <b-button class="d-flex justify-content-center align-items-center cq-bg-orange"
            v-on:click="showHideChangePass()" id="btn_mypage_profile_change_password">パスワード変更</b-button>
        </div>
      </div>
      <div class="profile-mypage-change-password w-100 d-flex mt-5 row mx-auto">
        <div class="titel-change-pass mt-2 col-xl-2 col-lg-3 col-12 mb-2">
          メールアドレス
        </div>
        <div class="titel-change-input position-relative col-xl-7 col-lg-6 col-12">
          <b-input id="input-email-address" v-model="formData.email" disabled></b-input>
          <font-awesome-icon v-on:click="showHideChangeMail()"
            class="d-block d-lg-none position-absolute cq-sidebar-mypage-icon mr-md-2 text-danger"
            icon="fa-solid fa-rotate" />
        </div>
        <div class="titel-change-action d-none mt-1 text-center d-lg-block col-xl-3 col-lg-3 col-12"
          v-on:click="showHideChangeMail()">
          <span>メールアドレス変更</span>
        </div>
      </div>
      <div class="profile-mypage-information-header w-100 mt-5">
        <div class="text-account-information">
          <span>基本情報</span>
        </div>
      </div>
      <div class="profile-mypage-basic-information w-100 mt-5">
        <div class="corporation-proprietorship">
          <CQOptionsRadio :options="optionsCorporation" v-model="formData.business_type" class="options-item" />
        </div>

        <div class="w-100 mx-auto d-flex mt-5 profile-mypage-basic-information-input" v-if="!showCorporation">
          <div class="w-50 mr-3 position-relative">
            <label for="" class="w-100">姓（漢字）<span class="text-danger">※</span></label>
            <b-input class="w-100" id="first_name_kanji" autocomplete="off" placeholder="姓（漢字）"
              v-model="$v.formData.profile.first_name_kanji.$model"
              :state="$v.formData.profile.first_name_kanji.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.profile.first_name_kanji.$error }"
              @keypress="specialCharacters($event)"></b-input>
            <div v-if="$v.formData.profile.first_name_kanji.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.profile.first_name_kanji.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.profile.first_name_kanji.isWrong">{{ message.NOT_NUMBER }}</span>
            </div>
          </div>
          <div class="w-50 ml-3 position-relative">
            <label for="" class="w-100">名（漢字）<span class="text-danger">※</span></label>
            <b-input class="w-100" id="last_name_kanji" autocomplete="off" placeholder="名（漢字）"
              v-model="$v.formData.profile.last_name_kanji.$model"
              :state="$v.formData.profile.last_name_kanji.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.profile.last_name_kanji.$error }"></b-input>
            <div v-if="$v.formData.profile.last_name_kanji.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.profile.last_name_kanji.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.profile.last_name_kanji.isWrong">{{ message.NOT_NUMBER }}</span>
            </div>
          </div>
        </div>

        <div class="w-100 mx-auto d-flex mt-5 profile-mypage-basic-information-input" v-if="!showCorporation">
          <div class="w-50 mr-3 position-relative">
            <label for="" class="w-100">姓（フリガナ）<span class="text-danger">※</span></label>
            <b-input class="w-100" id="first_name_kata" autocomplete="off" placeholder="姓（フリガナ）"
              v-model="$v.formData.profile.first_name_kata.$model"
              :state="$v.formData.profile.first_name_kata.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.profile.first_name_kata.$error }"
              @keypress="specialCharacters($event)"></b-input>
            <div v-if="$v.formData.profile.first_name_kata.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.profile.first_name_kata.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.profile.first_name_kata.isWrong">{{ message.FONT_ERROR }}</span>
            </div>
          </div>
          <div class="w-50 ml-3 position-relative">
            <label for="" class="w-100">名（フリガナ）<span class="text-danger">※</span></label>
            <b-input class="w-100" id="last_name_kata" autocomplete="off" placeholder="名（フリガナ）"
              v-model="$v.formData.profile.last_name_kata.$model"
              :state="$v.formData.profile.last_name_kata.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.profile.last_name_kata.$error }"></b-input>
            <div v-if="$v.formData.profile.last_name_kata.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.profile.last_name_kata.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.profile.last_name_kata.isWrong">{{ message.FONT_ERROR }}</span>
            </div>
          </div>
        </div>

        <div class="w-100 mx-auto d-flex mt-5 profile-mypage-basic-information-input" v-if="showCorporation">
          <div class="w-100 position-relative">
            <label for="" class="w-100">法人名<span class="text-danger"> ※ </span></label>
            <b-input id="corporate_name" autocomplete="off" placeholder="法人名" class="w-100"
              v-model="$v.formData.corporate_name.$model" :state="$v.formData.corporate_name.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.corporate_name.$error }"
              @keypress="specialCharacters($event)"></b-input>
            <div v-if="$v.formData.corporate_name.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.corporate_name.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.corporate_name.isWrong"> {{ message.NOT_NUMBER }}</span>
            </div>
          </div>
        </div>

        <div class="w-100 mx-auto d-flex mt-5 profile-mypage-basic-information-input" v-if="showCorporation">
          <div class="w-50 mr-3 position-relative">
            <label for="" class="w-100">代表者の姓（漢字）<span class="text-danger">※</span></label>
            <b-input class="w-100" id="first_representative_name" autocomplete="off" placeholder="代表者の姓（漢字）"
              v-model="$v.formData.first_representative_name.$model"
              :state="$v.formData.first_representative_name.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.first_representative_name.$error }"
              @keypress="specialCharacters($event)"></b-input>
            <div v-if="$v.formData.first_representative_name.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.first_representative_name.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.first_representative_name.isWrong">{{ message.NOT_NUMBER }}</span>
            </div>
          </div>
          <div class="w-50 ml-3 position-relative">
            <label for="" class="w-100">代表者の名（漢字）<span class="text-danger">※</span></label>
            <b-input class="w-100" id="last_name_kanji" autocomplete="off" placeholder="代表者の名（漢字）"
              v-model="$v.formData.last_representative_name.$model"
              :state="$v.formData.last_representative_name.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.last_representative_name.$error }"></b-input>
            <div v-if="$v.formData.last_representative_name.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.last_representative_name.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.last_representative_name.isWrong">{{ message.NOT_NUMBER }}</span>
            </div>
          </div>
        </div>
        <div class="w-100 mx-auto d-flex mt-5 profile-mypage-basic-information-input" v-if="showCorporation">
          <div class="w-50 mr-3 position-relative">
            <label for="" class="w-100">代表者の姓（フリガナ）<span class="text-danger">※</span></label>
            <b-input class="w-100" id="first_representative_kata" autocomplete="off" placeholder="代表者の姓（フリガナ）"
              v-model="$v.formData.first_representative_kata.$model"
              :state="$v.formData.first_representative_kata.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.first_representative_kata.$error }"
              @keypress="specialCharacters($event)"></b-input>
            <div v-if="$v.formData.first_representative_kata.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.first_representative_kata.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.first_representative_kata.isWrong"> {{ message.FONT_ERROR }} </span>
            </div>
          </div>
          <div class="w-50 ml-3 position-relative">
            <label for="" class="w-100">代表者の名（フリガナ）<span class="text-danger">※</span></label>
            <b-input class="w-100" id="last_representative_kata" autocomplete="off" placeholder="代表者の名（フリガナ）"
              v-model="$v.formData.last_representative_kata.$model"
              :state="$v.formData.last_representative_kata.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.last_representative_kata.$error }"></b-input>
            <div v-if="$v.formData.last_representative_kata.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.last_representative_kata.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.last_representative_kata.isWrong"> {{ message.FONT_ERROR }} </span>
            </div>
          </div>
        </div>
        <div class="w-100 mx-auto d-flex mt-5 profile-mypage-basic-information-input" v-if="showCorporation">
          <div class="w-50 mr-3 position-relative">
            <label for="" class="w-100">担当者の姓（漢字）<span class="text-danger">※</span></label>
            <b-input class="w-100" id="first_name_kanji" autocomplete="off" placeholder="担当者の姓（漢字）"
              v-model="$v.formData.first_name_kanji.$model" :state="$v.formData.first_name_kanji.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.first_name_kanji.$error }"
              @keypress="specialCharacters($event)"></b-input>
            <div v-if="$v.formData.first_name_kanji.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.first_name_kanji.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.first_name_kanji.isWrong">{{ message.NOT_NUMBER }}</span>
            </div>
          </div>
          <div class="w-50 ml-3 position-relative">
            <label for="" class="w-100">担当者の名（漢字）<span class="text-danger">※</span></label>
            <b-input autocomplete="off" class="w-100" id="last_name_kanji" placeholder="担当者の名（漢字）"
              v-model="$v.formData.last_name_kanji.$model" :state="$v.formData.last_name_kanji.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.last_name_kanji.$error }"></b-input>
            <div v-if="$v.formData.last_name_kanji.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.last_name_kanji.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.last_name_kanji.isWrong">{{ message.NOT_NUMBER }}</span>
            </div>
          </div>
        </div>
        <div class="w-100 mx-auto d-flex mt-5 profile-mypage-basic-information-input" v-if="showCorporation">
          <div class="w-50 mr-3 position-relative">
            <label for="" class="w-100">担当者の姓（フリガナ）<span class="text-danger">※</span></label>
            <b-input autocomplete="off" class="w-100" id="first_name_kata" placeholder="担当者の姓（フリガナ）"
              v-model="$v.formData.first_name_kata.$model" :state="$v.formData.first_name_kata.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.first_name_kata.$error }"></b-input>
            <div v-if="$v.formData.first_name_kata.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.first_name_kata.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.first_name_kata.isWrong"> {{ message.FONT_ERROR }} </span>
            </div>
          </div>
          <div class="w-50 ml-3 position-relative">
            <label for="" class="w-100">担当者の名（フリガナ）<span class="text-danger">※</span></label>
            <b-input autocomplete="off" class="w-100" id="last_name_kata" placeholder="担当者の名（フリガナ）"
              v-model="$v.formData.last_name_kata.$model" :state="$v.formData.last_name_kata.$error ? false : null"
              v-bind:class="{ 'is-invalid': $v.formData.last_name_kata.$error }"></b-input>
            <div v-if="$v.formData.last_name_kata.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.last_name_kata.required">{{ message.ERROR_REQUIRE }}</span>
              <span v-else-if="!$v.formData.last_name_kata.isWrong"> {{ message.FONT_ERROR }} </span>
            </div>
          </div>
        </div>
        <div class="w-100 mx-auto d-flex mt-5 profile-mypage-basic-information-input" v-if="showCorporation">
          <div class="w-50 mr-3" @click="setOptionTop($event)">
            <label for="user_corp_number" class="w-100">従業員数<span class="text-danger"> ※ </span></label>
            <model-select id="number_of_employees" v-model="formData.user_company_info.number_of_employees"
              :options="numberEmployeesOp" class="w-100">
            </model-select>
            <div v-if="$v.formData.user_company_info.number_of_employees.$error" class="position-absolute input-error">
              <span v-if="!$v.formData.user_company_info.number_of_employees.required">{{ message.ERROR_REQUIRE }}</span>
            </div>
          </div>
          <div class="w-50 ml-3" @click="setOptionTop($event)">
            <label for="number_of_vehicles_inventory" class="w-100">車両在庫台数<span class="text-danger"> ※ </span></label>
            <model-select id="number_of_vehicles_inventory"
              v-model="formData.user_company_info.number_of_vehicles_inventory" :options="numberVehiclesInventory"
              class="w-100">
            </model-select>
            <div v-if="$v.formData.user_company_info.number_of_vehicles_inventory.$error"
              class="position-absolute input-error">
              <span v-if="!$v.formData.user_company_info.number_of_vehicles_inventory.required">{{ message.ERROR_REQUIRE
              }}</span>
            </div>
          </div>
        </div>
        <div class="w-100 mx-auto d-flex mt-5 profile-mypage-basic-information-input" v-if="showCorporation">
          <div class="w-50 mr-3" @click="setOptionTop($event)">
            <label for="" class="w-100">月間販売車両数（年間平均）</label>
            <model-select id="monthly_number_of_vehicles_sold"
              v-model="formData.user_company_info.monthly_number_of_vehicles_sold" :options="numberVehiclesMonthly"
              class="w-100">
            </model-select>
          </div>
          <div class="w-50 ml-3" @click="setOptionTop($event)">
            <label for="" class="w-100">年間売上</label>
            <model-select id="annual_sales_scale" v-model="formData.user_company_info.annual_sales_scale"
              :options="annualSalesScale" class="w-100">
            </model-select>
          </div>
        </div>
      </div>
      <div class="profile-mypage-information-header w-100 mt-5">
        <div class="text-account-information">
          <span>住所</span>
        </div>
      </div>
      <div class="profile-mypage-basic-information w-100 mt-5">
        <div class="w-100 mx-auto d-flex mt-3 profile-mypage-postal-code">
          <div class="w-100 postal-code">
            <p> 郵便番号 <span class="text-danger text-postal-code pt-2"> ※ </span></p>
            <div class="d-flex mt-3 w-100">
              <p class="p-2">〒</p>
              <div class="position-relative w-100" v-if="showCorporation">
                <b-input autocomplete="off" id="zip_code" maxlength="8" v-model="$v.formData.zip_code.$model"
                  v-bind:class="{ 'is-invalid': $v.formData.zip_code.$error || errorZipCode }" placeholder="100-0001"
                  @keyup="getAdress(formData.zip_code, formData, $event)"
                  v-on:blur="getAdress(formData.zip_code, formData)"
                  v-on:mousedown="getAdress(formData.zip_code, formData)" class="before-postal-code">
                </b-input>
                <div v-if="$v.formData.zip_code.$error || errorZipCode || zipCodeWrong"
                  class="position-absolute input-error">
                  <span v-if="!$v.formData.zip_code.minLength || errorZipCode">{{ message.ERROR_ZIPCODE }}</span>
                  <span v-else-if="!$v.formData.zip_code.required">{{ message.ERROR_REQUIRE }}</span>
                  <span v-else-if="zipCodeWrong">{{ message.ZIP_CODE_WRONG }}</span>
                </div>
              </div>
              <div class="position-relative w-100" v-else>
                <b-input autocomplete="off" id="zip_code" maxlength="8" v-model="$v.formData.profile.zip_code.$model"
                  v-bind:class="{ 'is-invalid': $v.formData.profile.zip_code.$error || errorZipCodeProfile }"
                  placeholder="100-0001" v-on:keyup="getAdress(formData.profile.zip_code, formData.profile, $event)"
                  v-on:blur="getAdress(formData.profile.zip_code, formData.profile)"
                  v-on:mousedown="getAdress(formData.profile.zip_code, formData.profile)" class="before-postal-code">
                </b-input>
                <div v-if="$v.formData.profile.zip_code.$error || errorZipCodeProfile || zipCodeProfileWrong"
                  class="position-absolute input-error">
                  <span v-if="!$v.formData.profile.zip_code.minLength || errorZipCodeProfile">{{ message.ERROR_ZIPCODE
                  }}</span>
                  <span v-else-if="!$v.formData.profile.zip_code.required">{{ message.ERROR_REQUIRE }}</span>
                  <span v-else-if="zipCodeProfileWrong">{{ message.ZIP_CODE_WRONG }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 mt-5 profile-mypage-prefectures position-relative" v-if="showCorporation">
        <p> 都道府県</p>
        <model-select v-model="formData.prefecture" placeholder="都道府県" :isDisabled="true" :options="items"
          class="w-100 mt-3" id="formData_prefecture"></model-select>
      </div>
      <div class="w-100 mt-5 profile-mypage-prefectures position-relative" v-else>
        <p> 都道府県</p>
        <model-select v-model="formData.profile.prefecture" :isDisabled="true" placeholder="都道府県" :options="items"
          class="w-100 mt-3" id="formData_prefecture"></model-select>
      </div>
      <div class="profile-mypage-basic-information w-100 mt-5 position-relative" v-if="showCorporation">
        <label for="" class="w-100">市区町村</label>
        <b-input autocomplete="off" id="city" placeholder="市区町村" v-model="formData.city" disabled class="w-100"></b-input>
      </div>
      <div class="profile-mypage-basic-information w-100 mt-5 position-relative" v-else>
        <label for="" class="w-100">市区町村</label>
        <b-input autocomplete="off" id="city" placeholder="市区町村" v-model="formData.profile.city" disabled
          class="w-100"></b-input>
      </div>
      <div class="profile-mypage-basic-information w-100 mt-5 position-relative" v-if="showCorporation">
        <label for="" class="w-100">町名・番地</label>
        <b-input autocomplete="off" id="town" placeholder="町名・番地" v-model="formData.town" class="w-100" disabled>
        </b-input>
      </div>
      <div class="profile-mypage-basic-information w-100 mt-5 position-relative" v-else>
        <label for="" class="w-100">町名・番地</label>
        <b-input autocomplete="off" id="town" placeholder="町名・番地" v-model="formData.profile.town" class="w-100" disabled>
        </b-input>
      </div>
      <div class="profile-mypage-basic-information w-100 mt-5 position-relative">
        <label for="" class="w-100">建物名・部屋番号等</label>
        <b-input autocomplete="off" id="building" placeholder="建物名・部屋番号等" v-model="formData.building" class="w-100"
          v-if="showCorporation"></b-input>
        <b-input autocomplete="off" id="building" placeholder="建物名・部屋番号等" v-model="formData.profile.building"
          class="w-100" v-else></b-input>
      </div>
      <div class="w-100 mx-auto d-flex mt-5 profile-mypage-basic-information position-relative">
        <div class="w-50 mr-3" v-if="showCorporation">
          <label for="" class="w-100">電話番号（固定）</label>
          <b-input autocomplete="off" placeholder="電話番号（固定）" v-model="$v.formData.tel_mobile.$model" class="w-100"
            @paste="isNumberPhone($event, 11)" @keypress="isNumberPhone($event, 11)"></b-input>
        </div>
        <div class="w-50 mr-3" v-else>
          <label for="" class="w-100">電話番号（固定）</label>
          <b-input autocomplete="off" placeholder="電話番号（固定）" v-model="$v.formData.profile.tel_mobile.$model" class="w-100"
            @keypress="isNumberPhone($event, 11)" @paste="isNumberPhone($event, 11)"></b-input>
        </div>
        <div class="w-50 ml-3 phone-number-mobile position-relative" v-if="showCorporation">
          <label for="" class="w-100">電話番号（携帯）</label>
          <b-input autocomplete="off" id="tel_office" placeholder="電話番号（携帯）" v-model="$v.formData.tel_office.$model"
            class="w-100" @keypress="isNumberPhone($event, 11)" @paste="isNumberPhone($event, 11)"></b-input>
        </div>
        <div class="w-50 ml-3 phone-number-mobile position-relative" v-else>
          <label for="" class="w-100">電話番号（携帯）</label>
          <b-input autocomplete="off" id="tel_office" placeholder="電話番号（携帯）"
            v-model="$v.formData.profile.tel_office.$model" class="w-100" @paste="isNumberPhone($event, 11)"
            @keypress="isNumberPhone($event, 11)"></b-input>
        </div>
      </div>
      <p class="text-danger mt-2 text-sm"
        v-if="$v.formData.profile.tel_office.$error || $v.formData.tel_office.$error || $v.formData.profile.tel_mobile.$error || $v.formData.tel_mobile.$error">
        <template
          v-if="!showCorporation && !$v.formData.profile.tel_office.required && !$v.formData.profile.tel_mobile.required">
          {{ message.PHONE_REQUIRED }}
        </template>
        <template
          v-else-if="showCorporation && !$v.formData.profile.tel_office.required && !$v.formData.profile.tel_mobile.required">
          {{ message.PHONE_REQUIRED }}
        </template>
      </p>
      <div class="profile-mypage-information-header w-100 mt-5">
        <div class="text-account-information">
          <span ref="markGuide">AAアカウント登録確認</span>
        </div>
      </div>
      <div class="profile-mypage-basic-information registration-confirmation w-100 d-flex" v-bind="intro_aa_account[1]">
        <div class="list-registration-confirmation" v-for="(confirmation, index) in registrationConfirmation"
          v-bind:key="confirmation.name">
          <div class="d-flex position-relative" v-if="confirmation.id < 6">
            <div class="action-unconfimred"
              :class="{ 'wait-for-confirmation': confirmation.status === 1, 'action-confirmation': confirmation.status === 2 }"
              v-on:click="registrationConfirm(index + 1, confirmation.name)"></div>
            <font-awesome-icon v-if="confirmation.status === 2" class="cq-sidebar-mypage-icon mr-md-2 text-success mt-4"
              icon="fa-solid fa-square-check" />
            <div class="name-action-comfirm p-2">{{ confirmation.name }}</div>
            <div class="tooltip-hover ml-4" v-b-tooltip.hover v-on:click="showHideSelected(index + 1, confirmation.name)"
              title="ご利用のオークション会場を選択してください。">
              <b-button v-if="confirmation.status === 2" class="cq-btn-bg-white btn-secondary"
                :id="'btn_mypage_profile_change_aa_account_' + index"><b-spinner v-if="confirmation.loadingCheck" small />
                会場選択</b-button>
            </div>
          </div>
          <div class="text-danger ml-5" v-if="confirmation.status === 3">{{ message.CANCEL_CONFIRM }}</div>
          <div v-if="confirmation.status === 1" class="text-danger ml-5">{{ message.CONFIRM_ACOUNT }}</div>
        </div>
      </div>
      <div class="profile-mypage-basic-information w-100 mt-5">
        <b-button id="btn_mypage_profile_save" v-on:click="updateProfileUser()"
          class="w-100 save-information mt-5 cq-btn-bg-green">
          <b-spinner v-if="isLoading" small /> 保存
        </b-button>
      </div>
    </div>
    <b-modal id="alert-change-password" ref="alert-change-password" modal-class="modal-confirm modal-confirm-sm"
      body-class="p-md-4 p-3" hide-footer hide-header centered>
      <div class="d-block text-center title w-100" v-if="showPopupChangeMail">
        <div class="text-center titel-header-modal mb-5">メールアドレス変更</div>
        <div class="row w-100 mt-4 mail-address">
          <div class="col-md-4 col-12 text-left">
            <label for="" class="pt-2">メールアドレス</label>
          </div>
          <div class="col-md col-12 pr-md-4">
            <b-input autocomplete="off" id="modal-mail-address" v-model="formData.email" placeholder=""
              class="input-change-mail" disabled></b-input>
          </div>
        </div>
        <div class="row w-100 mt-4 mail-address">
          <div class="col-md-4 col-12 text-left">
            <label for="" class="pt-2">新メールアドレス <span class="text-danger">※</span></label>
          </div>
          <div class="col-md col-12 pr-md-4">
            <div class="position-relative input-change-mail">
              <b-input autocomplete="off" id="modal-new-email" v-on:keyup="checkEmailExist"
                v-model="$v.emailChanges.$model" :state="$v.emailChanges.$error ? false : null" placeholder=""
                class=""></b-input>
              <div v-if="$v.emailChanges.$error || emailExist" class="position-absolute span-noti input-error">
                <span v-if="!$v.emailChanges.required">{{ message.ERROR_REQUIRE }}</span>
                <span v-else-if="!$v.emailChanges.email">{{ message.EMAIL_INCORRECT }}</span>
                <span v-else-if="emailExist">{{ emailExist }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-block text-center title w-100" v-if="showPopupChangePass">
        <div class="text-center titel-header-modal mb-5">パスワード変更</div>
        <div class="row mt-4 change-password">
          <div class="col-md-4 col-12 text-left pl-4">
            <label for="" class="pt-2">現在のパスワード <span class="text-danger">※</span></label>
          </div>
          <div class="col-md col-12 pr-md-4">
            <div class="position-relative input-change-password">
              <b-input autocomplete="off" class="mr-5" type="password" id="modal-old-password" placeholder="••••••"
                v-model="$v.dataChangePassword.old_password.$model"
                :state="$v.dataChangePassword.old_password.$error ? false : null"></b-input>
              <div v-if="$v.dataChangePassword.old_password.$error" class="mt-1 text-left input-error">
                <span v-if="!$v.dataChangePassword.old_password.required">{{ message.ERROR_REQUIRE }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 change-password">
          <div class="col-md-4 col-12 text-left pl-4">
            <label for="" class="pt-2">新しいパスワード <span class="text-danger">※</span></label>
          </div>
          <div class="col-md col-12 pr-md-4">
            <div class="position-relative input-change-password">
              <b-input autocomplete="off" id="modal-new-password" v-model="$v.dataChangePassword.password.$model"
                :state="$v.dataChangePassword.password.$error ? false : null" type="password" placeholder="••••••"
                class="mr-5"></b-input>
              <div v-if="$v.dataChangePassword.password.$error" class="mt-1 text-left input-error">
                <span v-if="!$v.dataChangePassword.password.minLength || !$v.dataChangePassword.password.maxLength">{{
                  message.ERROR_LENGTH }}</span>
                <span v-else-if="!$v.dataChangePassword.password.formatPass">{{ message.ERROR_FORMAT }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="row mt-4 change-password">
          <div class="col-md-4 col-12 text-left pl-4">
            <label for="" class="pt-2">パスワードを認証する <span class="text-danger">※</span></label>
          </div>
          <div class="col-md col-12 pr-md-4">
            <div class="position-relative input-change-password">
              <b-input autocomplete="off" id="modal-confirm-password"
                v-model="$v.dataChangePassword.passwordConfirm.$model"
                :state="$v.dataChangePassword.passwordConfirm.$error ? false : null" type="password" placeholder="••••••"
                class="mr-5"></b-input>
              <div v-if="$v.dataChangePassword.passwordConfirm.$error" class="mt-1 text-left input-error">
                <span v-if="!$v.dataChangePassword.password.formatPass">{{ message.ERROR_FORMAT }}</span>
                <span v-else-if="!$v.dataChangePassword.passwordConfirm.sameAsPassword">{{ message.COMFIRMPASSWORD_ERROR
                }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-100 d-flex mt-4 btn-action">
        <b-button variant="secondary" class="w-50 btn-confirm ml-4 mr-5"
          v-on:click="$bvModal.hide('alert-change-password')"
          id="btn_mypage_profile_hide_modal_change_password">キャンセル</b-button>
        <b-button id="modal-btn-send-email" class="w-50 btn-confirm mr-4 cq-btn-bg-green" v-if="showPopupChangePass"
          v-on:click="changePassword"><b-spinner v-if="isLoading" small /> 変更する</b-button>
        <b-button id="modal-btn-continue" class="w-50 btn-confirm mr-4 cq-btn-bg-green" v-on:click="verifyChangeMail"
          v-else>
          <b-spinner v-if="isLoading" small /> 変更する</b-button>
      </div>
    </b-modal>
    <b-modal id="alert-message-succes" ref="alert-message-succes" modal-class="modal-confirm modal-confirm-sm"
      body-class="p-md-4 p-3" hide-footer hide-header centered>
      <div class="d-block text-center title w-100">
        <div class="icon-check-succus">
          <font-awesome-icon v-if="titelSuccess !== message.ADD_AASITE"
            class="cq-sidebar-mypage-icon mr-md-2 text-success mt-4" icon="fa-solid fa-circle-check" />
          <h1 class="text-warning" v-else>
            <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
          </h1>
          <font-awesome-icon v-on:click="$bvModal.hide('alert-message-succes')"
            class="cq-sidebar-mypage-icon mr-md-2 position-absolute icon-close" icon="fa-solid fa-circle-xmark" />
        </div>
        <div class="mt-4 mb-4">
          {{ titelSuccess }}
        </div>
        <div class="text-succus-content mt-3" v-if="!showAddAcount">
          <p class="text-succus w-75 mb-4">{{ this.message.MESSAGE_SEND_MAIL }}</p>
          <div class="border-succes mb-5 w-75"></div>
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-preview-image" ref="modal-preview-image" size="xl" body-class="p-md-4 p-3" hide-footer centered>
      <div class="d-block text-center title w-100">
        <img :src="imagePreview" class="w-100" />
      </div>
    </b-modal>
    <b-modal id="alert-selected" ref="alert-selected" modal-class="modal-confirm"
      :class="{ 'modal-confirm-xl': showVenueSelection }" body-class="p-md-4 p-3" hide-footer hide-header
      hide-header-close centered :size="showVenueSelection ? 'xl' : 'lg'">
      <form id="upload_form" v-on:submit.prevent enctype="multipart/form-data">
        <div class="d-block text-center title w-100">
          <div class="icon-check-succus">
            <font-awesome-icon v-on:click="$bvModal.hide('alert-selected')"
              class="cq-sidebar-mypage-icon mr-md-2 position-absolute icon-close" icon="fa-solid fa-circle-xmark" />
          </div>
          <div class="mx-auto text-left content-selected" v-if="showVenueSelection">
            <div>
              <SelectItem :dataListOption="venueSelection" @change="onChangeVuenue">
                <template #title>
                  <div class="titel-modal">{{ siteName }}の会場選択<span>（{{ numberChecked }}件選択済み）</span></div>
                </template>
              </SelectItem>
            </div>
          </div>
          <div class="mx-auto content-account-confirmation" v-else>
            <div class="titel-modal">「{{ siteName }}」アカウント確認</div>
            <div class="mt-4 text-left py-2">アカウント名<span class="text-danger">※</span></div>
            <div class="position-relative">
              <b-input autocomplete="off" id="input-account-name" placeholder="アカウント名" class="w-100"
                v-model="$v.accountName.$model" :state="$v.accountName.$error ? false : null"></b-input>
              <div v-if="$v.accountName.$error" class="position-absolute input-error">
                <span v-if="!$v.accountName.required">{{ message.ERROR_REQUIRE }}</span>
              </div>
            </div>
            <div class="mt-5 content-account-confirmation-upload">
              <p class="text-left">添付画像 (jpg, png, gif, jpeg)<span class="text-danger">※</span></p>
              <div class="position-relative input-upload mt-4">
                <label for="" class="input-add-img">
                  <div class="input-add-img-times">
                    <font-awesome-icon class="cq-sidebar-mypage-icon" icon="fa-solid fa-plus" />
                  </div>
                </label>
                <input id="input-upload-image" type="file" accept="image/png, .jpg, .gif, .jpeg"
                  v-on:change="uploadImage(($event))" />
              </div>
              <p class="mt-3 text-left">※赤枠内のID若しくは会社名が見える様に画像を添付してください。</p>
              <img @click="setImage()" id="myModal" class="image-upload w-100 mt-3" src="">
              <div @click="showModalImg()" class="modal-show-img">
                <img class="modal-content" id="img01">
              </div>
            </div>
            <div class="content-aa-account-example row col-12 justify-content-center" v-if="!imgAaAccount">
              <div class="col-auto item" @click="openPreviewAccount(listAA_account_images[coreSiteId])">
                <img :src="listAA_account_images[coreSiteId]" />
              </div>
            </div>
          </div>
          <div class="w-75 d-flex mt-4 btn-action mx-auto">
            <b-button id="btn-cancel" variant="secondary" class="w-50 btn-confirm ml-4 mr-5"
              v-on:click="cancelModalSelect">キャンセル</b-button>
            <b-button id="btn-continue" v-if="!showVenueSelection" class="w-50 btn-confirm mr-4 cq-btn-bg-green"
              v-on:click="accountConfirmation" :disabled="disabledBtn">送信する</b-button>
            <b-button id="btn-continue" v-else class="w-50 btn-confirm mr-4 cq-btn-bg-green"
              v-on:click="addAccountConfirmation"><b-spinner v-if="isLoading" small />保存する</b-button>
          </div>
        </div>
      </form>
    </b-modal>
  </div>
</template>

<script>
import { PrefectureJson } from '@/utils/listPrefecture'
import { required, email, sameAs, minLength, helpers, maxLength } from 'vuelidate/lib/validators'
import { Message } from '@/utils/message'
import YubinBango from "yubinbango-core2"
import CQPreLoad from "@/components/CQPreLoad.vue";
import SelectItem from '@/components/mypage/SelectItem.vue';
import { convertNumber, regexKanji } from "@/utils/commonEvent";
import CQOptionsRadio from '@/components/CQOptionsRadio.vue';
import intro, { default_configuration } from '@/utils/intro';

const regexFont = helpers.regex('alpha', /^([ァ-ン]|ー)+$/)
const notNumber = function (evt) {
  const test = (evt) ? evt.split('') : '';
  if (!test) return true;
  for (let index = 0; index < test.length; index++) {
    if (Number(test[index])) {
      return false;
    }
  }
  return true;
}
export default {
  name: "profileMypage",
  layout: "user",
  auth: false,
  mixins: [convertNumber],
  head() {
    return {
      title: "プロファイル編集",
    };
  },
  components: {
    CQPreLoad,
    SelectItem,
    CQOptionsRadio
  },
  data() {
    return {
      message: Message.EDIT_PROFILE,
      registrationConfirmation: [],
      siteName: null,
      showAddAcount: false,
      showPopupChangeMail: false,
      showPopupChangePass: false,
      titelSuccess: "",
      isLoading: false,
      showCorporation: true,
      optionsCorporation: [
        { text: '法人', value: 1 },
        { text: '個人事業主', value: 2 }
      ],
      selectNumberEpl: null,
      preLoading: true,
      numberEmployeesOp: [
        { value: null, text: '選択してください', disabled: true },
        { value: 0, text: '５人以下' },
        { value: 1, text: '6～10人' },
        { value: 2, text: '11～15人' },
        { value: 3, text: '16～20人' },
        { value: 4, text: '21～30人' },
        { value: 5, text: '30人以上' }
      ],
      numberVehiclesInventory: [
        { value: null, text: '選択してください', disabled: true },
        { value: "0", text: '５台以下' },
        { value: "1", text: '6～10台' },
        { value: "2", text: '11～20台' },
        { value: "3", text: '21～30台' },
        { value: "4", text: '31～40台' },
        { value: "5", text: '41～50台' },
        { value: "6", text: '51～60台' },
        { value: "7", text: '61～70台' },
        { value: "8", text: '71～80台' },
        { value: "9", text: '81～90台' },
        { value: "10", text: '91～100台' },
        { value: "11", text: '101台～150台' },
        { value: "12", text: '151～200台' },
        { value: "13", text: '201～250台' },
        { value: "14", text: '251～300台' },
        { value: "15", text: '301台以上' }
      ],
      selectNumberVM: null,
      numberVehiclesMonthly: [
        { value: null, text: '選択してください' },
        { value: 0, text: '５台以下' },
        { value: 1, text: '6～10台' },
        { value: 2, text: '11～20台' },
        { value: 3, text: '21～30台' },
        { value: 4, text: '31～40台' },
        { value: 5, text: '41～50台' },
        { value: 6, text: '51～60台' },
        { value: 7, text: '61～70台' },
        { value: 8, text: '71～80台' },
        { value: 9, text: '81～90台' },
        { value: 10, text: '91～100台' },
        { value: 11, text: '101台以上' }
      ],
      selectAnnualSalesS: null,
      annualSalesScale: [
        { value: null, text: '選択してください' },
        { value: 0, text: '3千万円以下' },
        { value: 1, text: '3千万～1億円' },
        { value: 2, text: '1～2億円' },
        { value: 3, text: '2～3億円' },
        { value: 4, text: '3～4億円' },
        { value: 5, text: '4～5億円' },
        { value: 6, text: '5～10億円' },
        { value: 7, text: '10億円以上' },
      ],
      formData: {
        email: null,
        last_representative_name: null,
        first_representative_name: null,
        last_name_kanji: null,
        first_name_kanji: null,
        first_representative_kata: null,
        last_representative_kata: null,
        last_name_kata: null,
        first_name_kata: null,
        corporate_name: null,
        city: null,
        tel_mobile: null,
        tel_office: null,
        town: null,
        building: "",
        prefecture: null,
        zip_code: null,
        business_type: "company",
        user_company_info: {
          number_of_employees: null,
          monthly_number_of_vehicles_sold: null,
          annual_sales_scale: null,
          number_of_vehicles_inventory: null
        },
        profile: {
          first_name_kanji: null,
          last_name_kanji: null,
          first_name_kata: null,
          last_name_kata: null,
          zip_code: null,
          prefecture: null,
          city: null,
          town: null,
          building: null,
          tel_mobile: null,
          tel_office: null,
        }
      },
      dataChangePassword: {
        old_password: null,
        password: null,
        passwordConfirm: null,
      },
      emailChanges: null,
      venueSelection: [],
      venuesSelected: [],
      showVenueSelection: false,
      coreSiteId: 1,
      accountName: null,
      disabledBtn: true,
      items: PrefectureJson,
      imgAaAccount: null,
      telOfficeErr: false,
      telMobileErr: false,
      errorZipCode: false,
      errorZipCodeProfile: false,
      searchSelection: null,
      emailExist: null,
      userId: localStorage.getItem('userId'),
      listAA_account_images: {
        "1": require("@/assets/img/aa-accounts/USS.png"),
        "2": require("@/assets/img/aa-accounts/asnet.png"),
        "3": require("@/assets/img/aa-accounts/tcweb.png"),
        "4": require("@/assets/img/aa-accounts/junavi.png"),
        "5": require("@/assets/img/aa-accounts/iauc.png"),
      },
      imagePreview: null,
      zipCodeWrong: false,
      zipCodeProfileWrong: false,
      isShowGuide: localStorage.getItem("guide-profile-2") ? true : false,
      intro_aa_account: intro.aa_account
    };
  },
  computed: {
    numberChecked() {
      return this.venuesSelected.length
    },
  },
  created() {
    const tokenUsers = localStorage.getItem('tokenUser')
    if (tokenUsers) localStorage.setItem('token', tokenUsers)
    localStorage.removeItem('tokenUser')
    this.getProfileUser()
      .then(() => {
        this.bindingRouterAction()
      })
  },
  watch: {
    'formData.business_type'(value) {
      (value !== 1) ? this.showCorporation = false : this.showCorporation = true;
      this.$v.$reset()
    },
    accountName(value) {
      (value && this.imgAaAccount) ? this.disabledBtn = false : this.disabledBtn = true;
    },

  },
  methods: {
    setShowGuide() {
      this.isShowGuide = false
      localStorage.removeItem("guide-profile-2")
    },
    bindingRouterAction() {
      const action = this.$route.query.action
      if (action) {
        if (action === "validation") {
          this.$v.formData.$touch();
        }
      }
    },
    async getProfileUser(status) {
      const data = await this.$store.dispatch('getProfileUser', this.userId)
      const dataCoreSite = await this.$store.dispatch('getCoreSite');
      if (data.success && status !== "createAcount") {
        for (const key in this.formData) {
          if (key in data.data.user) {
            if (data.data.user[key]) {
              if (data.data.user[key]?.constructor === Object) {
                this.formData[key] = { ...this.formData[key], ...data.data.user?.[key] };
              }
              else {
                this.formData[key] = data.data.user[key];
              }
            }
          }
        }
        if (this.formData.business_type === null) {
          this.formData.business_type = "company";
        }
        const businessTypeList = {
          "company": 1,
          "personal": 2
        }
        this.formData.business_type = businessTypeList[this.formData.business_type]
        if (this.formData.business_type !== 1) {
          for (let key in this.formData.profile) {
            if (key in this.formData) {
              this.formData.profile[key] = this.formData[key]
              this.formData[key] = ""
            }
          }
          this.getAdress(this.formData.profile.zip_code, this.formData.profile, undefined, false);
          const listDeletion = [
            "annual_sales_scale",
            "monthly_number_of_vehicles_sold",
            "number_of_employees",
            "number_of_vehicles_inventory"
          ]
          listDeletion.forEach(key => {
            this.formData.user_company_info[key] = null
          });
        }
        else this.getAdress(this.formData.zip_code, this.formData, undefined, false);
        this.preLoading = false;
        if (this.isShowGuide) {
          this.$nextTick(() => {
            this.introJs().setOptions(default_configuration).onexit(() => {
              this.setShowGuide()
            }).start();
          })
        }
      } else if (status !== "createAcount") {
        if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message);
      };
      if (dataCoreSite.success) {
        this.registrationConfirmation = dataCoreSite.data.core_sites;
        this.registrationConfirmation.forEach(coreSites => {
          coreSites["loadingCheck"] = false;
          (coreSites.status === 2) ? coreSites["checked"] = true : coreSites["checked"] = false;
        });
      } else {
        if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message);
      };
    },
    setImage() {
      if (window.screen.width < 550) {
        document.getElementById("myModal").style.cursor = "pointer";
        var modal = document.getElementsByClassName('modal-show-img');
        var modalImg = document.getElementById("img01");
        modal[0].style.display = "block";
        modalImg.onload = () => {
          URL.revokeObjectURL(modalImg.src);
        }
        modalImg.src = URL.createObjectURL(this.imgAaAccount);
      }
    },
    showModalImg() {
      var modal = document.getElementsByClassName('modal-show-img');
      modal[0].style.cursor = "pointer";
      modal[0].style.display = "none";
    },
    openPreviewAccount(image) {
      this.$bvModal.show("modal-preview-image")
      this.imagePreview = image
    },
    cancelModalSelect() {
      this.$refs['alert-selected'].hide();
      this.registrationConfirmation.forEach(coreSites => {
        (coreSites.status === 2) ? coreSites["checked"] = true : coreSites["checked"] = false;
      });
    },
    showHideChangePass() {
      this.$refs['alert-change-password'].show();
      this.showPopupChangeMail = false;
      this.showPopupChangePass = true;
      this.$v.dataChangePassword.old_password.$reset();
      this.$v.dataChangePassword.password.$reset();
      this.$v.dataChangePassword.passwordConfirm.$reset();
      this.dataChangePassword.password = null;
      this.dataChangePassword.old_password = null;
      this.dataChangePassword.passwordConfirm = null;
    },
    showHideChangeMail() {
      this.$refs['alert-change-password'].show();
      this.showPopupChangePass = false;
      this.showPopupChangeMail = true;
      this.$v.emailChanges.$reset()
      this.emailChanges = null;
    },
    async showHideSelected(id, name) {
      /*
        Close tooltip on mobile when clicked
      */
      setTimeout(() => {
        this.$root.$emit('bv::hide::tooltip')
      }, 50)
      this.siteName = name;
      this.registrationConfirmation = this.registrationConfirmation.map((registration, index) => {
        let loading = false;
        if (index === (id - 1)) loading = true;
        return {
          ...registration,
          loadingCheck: loading
        }
      })
      this.showVenueSelection = true;
      this.coreSiteId = id;
      const data = await this.$store.dispatch('addressListSelect', this.coreSiteId);
      const checkSelect = await this.$store.dispatch('checkListSelect', this.coreSiteId);
      if (data.success) {
        this.venueSelection = [];
        data.data.auction_venues.forEach(auction_venue => {
          auction_venue.picked = false
          auction_venue.shipping_fee = ""
          const selectedVenue = checkSelect.data.user_auction_venues.find((venue) => {
            return auction_venue.id === venue.auctions_venue_id
          })
          if (selectedVenue) {
            auction_venue.picked = true
            auction_venue.shipping_fee = selectedVenue.shipping_fee
          }
          this.venueSelection.push(auction_venue);
        });
      } else {
        if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message);
      }
      if (checkSelect.success) {
        this.$refs['alert-selected'].show();
        this.registrationConfirmation[id - 1] = {
          ...this.registrationConfirmation[id - 1],
          loadingCheck: false
        };
      } else {
        if (checkSelect.errors.length && checkSelect.errors[0].message) this.$toast.error(checkSelect.errors[0].message);
      }
    },
    getAdress(value, object, event, loadAddress = true) {
      if (this.showCorporation) this.errorZipCode = false;
      else this.errorZipCodeProfile = false
      if (loadAddress) {
        object.prefecture = "";
        object.city = "";
        object.town = "";
      }
      const checkZipCode = /〒?[0-9]{3}-?[0-9]{4}/g;
      if (value && value.length >= 7) {
        event?.preventDefault()
        event?.stopImmediatePropagation()
        if (checkZipCode.test(value)) {
          if (!value.includes("-")) object.zip_code = value.substring(0, 3) + "-" + value.substring(3, 7);
          if (!loadAddress) return
          if (value.substring(0, 3) == "000") {
            // Issue https://github.com/yubinbango/yubinbango-core/issues/7
            if (this.showCorporation) this.zipCodeWrong = true
            else this.zipCodeProfileWrong = true
            return
          }
          new YubinBango.Core(value, (addr) => {
            const { region, locality, street } = addr;
            if (region || locality || street) {
              object.prefecture = region;
              object.city = locality;
              object.town = street;
              if (this.showCorporation) this.zipCodeWrong = false
              else this.zipCodeProfileWrong = false
              return
            }
            if (this.showCorporation) this.zipCodeWrong = true
            else this.zipCodeProfileWrong = true
          });
        } else {
          if (this.showCorporation) this.errorZipCode = true;
          else this.errorZipCodeProfile = true
        }
      }
    },
    uploadImage(e) {
      let img = document.querySelector('.image-upload');
      img.onload = () => {
        URL.revokeObjectURL(img.src);  // no longer needed, free memory
      }
      img.src = URL.createObjectURL(e.target.files[0]);
      this.imgAaAccount = e.target.files[0];
      (this.imgAaAccount && this.accountName) ? this.disabledBtn = false : this.disabledBtn = true;
    },
    async accountConfirmation() {
      this.$toast.clear();
      this.$v.accountName.$touch();
      const result = !this.$v.accountName.$error;
      if (result) {
        let formData = new FormData();
        formData.append('core_site_id', this.coreSiteId);
        formData.append('username', this.accountName);
        formData.append('file', this.imgAaAccount);
        const data = await this.$store.dispatch('createAcountProfile', formData);
        if (data.success) {
          this.isLoading = false;
          this.$refs['alert-selected'].hide();
          this.$toast.success(this.message.ADD_ACCOUNT_SUCCESS);
          this.imgAaAccount = "";
          this.getProfileUser("createAcount");
        } else {
          this.isLoading = false;
          if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message);
        }
      }
    },
    onChangeVuenue(data) {
      this.venuesSelected = data
    },
    async addAccountConfirmation() {
      this.isLoading = true;
      let params = {
        "user_action_venues": [],
        core_site_id: this.coreSiteId
      };
      params.user_action_venues = this.venuesSelected.map((el) => {
        return {
          auctions_venue_id: el.id,
          shipping_fee: el.shipping_fee ? parseInt(el.shipping_fee.toString().replaceAll(",", "")) : "0"
        }
      })
      const data = await this.$store.dispatch('createAuctionVenues', params);
      if (data.success) {
        this.isLoading = false;
        this.$toast.success(this.message.ADD_PLACE_SUCCESS);
        this.$refs['alert-selected'].hide();
      } else {
        this.isLoading = false;
        if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message);
      }
    },
    async registrationConfirm(id, name) {
      this.coreSiteId = id;
      this.siteName = name;
      this.showVenueSelection = false;
      this.accountName = null;
      this.imgAaAccount = null;
      this.$v.accountName.$reset();
      this.$refs['alert-selected'].show();
    },
    async updateProfileUser() {
      this.$toast.clear()
      this.$v.formData.$touch();
      let result = (!this.$v.formData.$error)
      const dataAASite = this.registrationConfirmation.filter(item => item.status !== 0)
      if (this.telMobileErr || this.telOfficeErr) result = false;
      if (this.showCorporation && this.zipCodeWrong) result = false
      if (!this.showCorporation && this.zipCodeProfileWrong) result = false
      if (result) {
        if (!dataAASite.length) {
          this.showAddAcount = true
          this.titelSuccess = this.message.ADD_AASITE
          this.$refs['alert-message-succes'].show()
        }
        this.isLoading = true;
        const userId = localStorage.getItem('userId');
        const dataRq = JSON.parse(JSON.stringify(this.formData));
        dataRq['id'] = userId;
        dataRq['user_company_info_attributes'] = dataRq.user_company_info;
        delete dataRq.user_company_info;
        if (!this.showCorporation) {
          let listDeletion = [
            "corporate_name",
            "first_representative_name",
            "last_representative_name",
            "first_name_kanji",
            "last_name_kanji",
            "first_name_kata",
            "last_name_kata",
            "zip_code",
            "prefecture",
            "city",
            "town",
            "building",
            "tel_mobile",
            "tel_office",
          ]
          listDeletion.forEach(key => {
            this.formData[key] = dataRq[key] = ""
          });
          for (let key in dataRq.profile) {
            dataRq[key] = dataRq.profile[key]
          }
          listDeletion = [
            "annual_sales_scale",
            "monthly_number_of_vehicles_sold",
            "number_of_employees",
            "number_of_vehicles_inventory"
          ]
          listDeletion.forEach(key => {
            this.formData.user_company_info[key] = null
          });
          delete dataRq.user_company_info_attributes
          delete dataRq.profile
        }
        else {
          for (let key in this.formData.profile) {
            this.formData.profile[key] = ""
          }
        }
        dataRq['zip_code'] = dataRq.zip_code.replaceAll("-", "");
        dataRq["full_address"] = `〒${dataRq.zip_code} ${dataRq.prefecture}${dataRq.city}${dataRq.town}${dataRq.building}`
        const data = await this.$store.dispatch('updateProfileUser', dataRq);
        if (data.success) {
          this.isLoading = false;
          this.$toast.success(this.message.SUCCESS);
          this.$store.commit("setDataUser", data.data.user)
        } else {
          this.isLoading = false;
          if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message);
        }
      } else {
        this.focusFormData(this.$v.formData);
      }
    },
    async changePassword() {
      this.$toast.clear()
      this.$v.dataChangePassword.$touch()
      const result = !this.$v.dataChangePassword.$error
      if (result) {
        this.isLoading = true;
        const data = await this.$store.dispatch('changePasswordProfile', this.dataChangePassword);
        if (data.success) {
          this.isLoading = false;
          this.$toast.success(this.message.SUCCESS_CHANGEPASSWORD);
          this.$refs['alert-change-password'].hide();
        } else {
          this.isLoading = false;
          if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message);
        }
      }
    },
    async verifyChangeMail() {
      this.$toast.clear();
      this.$v.emailChanges.$touch();
      const result = !this.$v.emailChanges.$error;
      if (this.emailExist) result = false
      if (result) {
        this.isLoading = true;
        const emailChange = {
          "email": this.emailChanges,
        }
        const data = await this.$store.dispatch('verifyChangeEmailProfile', emailChange);
        if (data.success) {
          this.isLoading = false;
          this.$refs['alert-change-password'].hide();
          this.titelSuccess = "認証メール送信完了";
          this.showAddAcount = false;
          this.$refs['alert-message-succes'].show();
        } else {
          this.isLoading = false;
          if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message);
        }
      }
    },
    checkEmailExist() {
      (this.emailChanges === this.formData.email) ? this.emailExist = this.message.EMAIL_EXIST : this.emailExist = null;
    }
  },
  validations() {
    return {
      formData: {
        corporate_name: this.showCorporation ? { required, isWrong: (notNumber) } : {},
        last_representative_name: this.showCorporation ? { required, isWrong: (notNumber) } : {},
        first_representative_name: this.showCorporation ? { required, isWrong: (notNumber) } : {},
        first_name_kanji: this.showCorporation ? { required, isWrong: (notNumber) } : {},
        last_name_kanji: this.showCorporation ? { required, isWrong: (notNumber) } : {},
        first_name_kata: this.showCorporation ? { required, isWrong: (regexFont) } : {},
        last_name_kata: this.showCorporation ? { required, isWrong: (regexFont) } : {},
        first_representative_kata: this.showCorporation ? { required, isWrong: (regexFont) } : {},
        last_representative_kata: this.showCorporation ? { required, isWrong: (regexFont) } : {},
        user_company_info: {
          number_of_employees: this.showCorporation ? { required } : {},
          number_of_vehicles_inventory: this.showCorporation ? { required } : {},
        },
        zip_code: this.showCorporation ? {
          required,
          minLength: minLength(8),
        } : {},
        profile: {
          first_name_kanji: !this.showCorporation ? { required, isWrong: (notNumber) } : {},
          last_name_kanji: !this.showCorporation ? { required, isWrong: (notNumber) } : {},
          first_name_kata: !this.showCorporation ? { required, isWrong: (regexFont) } : {},
          last_name_kata: !this.showCorporation ? { required, isWrong: (regexFont) } : {},
          zip_code: !this.showCorporation ? { required, minLength: minLength(8) } : {},
          tel_office: !this.showCorporation && !this.formData.profile.tel_mobile?.length ? { required } : {},
          tel_mobile: !this.showCorporation && !this.formData.profile.tel_office?.length ? { required } : {},
        },
        tel_office: this.showCorporation && !this.formData.tel_mobile?.length ? { required } : {},
        tel_mobile: this.showCorporation && !this.formData.tel_office?.length ? { required } : {},
      },
      dataChangePassword: {
        old_password: {
          required,
        },
        password: {
          minLength: minLength(6),
          maxLength: maxLength(64),
          formatPass: () => {
            if (this.dataChangePassword.password) {
              return /^(?=.*[a-z])(?=.*[A-Z])([0-9a-zA-Z!-~]{6,64})$/.test(this.dataChangePassword.password)
            }
            return false
          }
        },
        passwordConfirm: {
          minLength: minLength(6),
          maxLength: maxLength(64),
          formatPass: () => {
            if (this.dataChangePassword.passwordConfirm) {
              return /^(?=.*[a-z])(?=.*[A-Z])([0-9a-zA-Z!-~]{6,64})$/.test(this.dataChangePassword.passwordConfirm)
            }
            return false
          },
          sameAsPassword: sameAs('password')
        },
      },
      emailChanges: { required, email },
      accountName: { required }
    }
  }
};
</script>
<style lang="scss" scoped>
@import '@/assets/scss/mypage/profile/edit.scss';
</style>
