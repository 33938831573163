<template>
  <div class="cq-signup d-flex justify-content-center align-items-center">
    <div class="cq-signup-box">
      <div class="cq-signup-check">
        <font-awesome-icon icon="fa-solid fa-check" />
      </div>
      <div class="cq-signup-title text-center mt-3">認証メール送信完了</div>
      <p class="mt-4">{{ message.SUCCESS }}</p>
      <hr class="mt-4">
    </div>
  </div>
</template>

<script>
import '../../assets/scss/auth/signup-complete.scss'
import { Message } from '../../utils/message'

export default {
  data() {
    return {
      message: Message.SIGNUP_COMPLETE,
    }
  },
  head() {
    return {
      title: "認証メール送信完了",
    };
  },
}
</script>