import Vue from "vue"
import Vuex from "vuex"
import router from "../router";
import { Api } from "../utils/http-common";
import { getDatabase, ref, onValue, query, set, get } from "firebase/database";

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: false,
    userPlanInfo: null,
    dataListChat: [],
    isReadChat: true,
    recommendCount: {},
    carMap: {
      brands: [],
      cars: [],
      site: [],
      colors: [],
      venues: [],
      gears: [],
      equipments: [],
      years: [],
      countries: [],
      auctions_venue: [],
      loader: null
    },
    showModalCondition: false,
    extensionLink: "https://chrome.google.com/webstore/detail/aiハント-extension/kibcggfnaabfacjhdemfcabnobmfihlk",
    extensionId: process.env.VUE_APP_ROOT_CHROME_EXTENSION_ID,
    userInfo: {
      data: {},
      handler: null // Will set promise here to async
    },
    refUserInfoFirebase: null,
    isError: false,
    isHideSidebar: true,
    analyzeGetDataOld: false,
    modalOpenQueued: [], // Modals are opening store here
  },
  getters: {
    status: (state) => state.status,
    userPlanInfo: (state) => state.userPlanInfo,
    dataListChat: (state) => state.dataListChat,
    isReadChat: (state) => {
      return state.isReadChat
    },
    isHideSidebar: (state) => {
      return state.isHideSidebar
    },
    recommendCount: (state) => {
      return state.recommendCount
    },
    carMap: (state) => {
      return state.carMap
    },
    extensionLink: (state) => state.extensionLink,
    showModalCondition: (state) => state.showModalCondition,
    userInfo: (state) => state.userInfo,
    refUserInfoFirebase: (state) => state.refUserInfoFirebase,
    isError: (state) => state.isError,
    modalOpenQueued: (state) => state.modalOpenQueued,
    extensionId: (state) => state.extensionId,
    analyzeGetDataOld: (state) => state.analyzeGetDataOld,
  },
  mutations: {
    // Setters
    set(state, [variable, value]) {
      state[variable] = value
    },
    pushModalOpenQueued(state, modalId)  {
      state.modalOpenQueued.push(modalId)
    },
    deleteModalOpenQueued(state, modalId) {
      const indexQueued = state.modalOpenQueued.findIndex((modalQueuedId) => {
        return modalQueuedId === modalId
      })
      if(indexQueued > -1) {
        state.modalOpenQueued.splice(indexQueued, 1)
      }
    },
    setDataUser (state, userData) {
      state.userInfo.data = userData
    }
  },
  actions: {
    //test
    setStatus({ commit }, data) {
      commit("set", ["status", data])
    },
    //
    //start auth
    redirectLogout({commit}, response) {
      if (response?.status === 401 && response?.data?.data?.mode === 0 && response?.data?.data?.status === 1) {
        router.push('/maintenance')
      }
      else if (response?.status == 403 && response?.data.message == "locked") {
        router.push('/locked')
      }
      else if (response?.status == 401) {
        router.push('/logout')
      }
      else {
        commit('set', ['isError', true])
      }
    },
    async login({ commit }, formData) {
      return await Api.requestServer
        .post(`/client/login`,formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          return error.response.data
        });
    },
    async forgot({ commit }, formData) {
      return await Api.requestServer
        .post(`/client/verify_email`,formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          return error.response.data
        });
    },
    async newPassword({ commit }, formData) {
      return await Api.requestServer
        .post(`/client/reset_password`,formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          return error.response.data
        });
    },
    async logout({ commit }) {
      return await Api.requestServer
        .post(`/client/logout`)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          return error.response.data
        });
    },
    async signupSendmail({ commit }, formData) {
      return await Api.requestServer
        .post(`/client/verify_account`,formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          return error.response.data
        });
    },
    async signupInput({ commit }, formData) {
      return await Api.requestServer
        .post(`/client/create`,formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          return error.response.data
        });
    },
    //end auth
    async getProfileUser({ commit, dispatch }, formData) {
      return await Api.requestServer
        .get(`/client/users/${formData}`)
        .then(async (response) => {
          const { data } = response;
          return await data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async updateProfileUser({ commit, dispatch }, formData) {
      return await Api.requestServer
        .put(`/client/users/${formData.id}`, formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async changePasswordProfile({ commit }, formData) {
      return await Api.requestServer
        .patch(`/client/users/change_password`, formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          return error.response.data
        });
    },
    //plan info
    async getPlanInfo({ commit, dispatch }) {
      return await Api.requestServer
        .get(`/client/user_plans/infos`)
        .then((response) => {
          const { data } = response;
          commit('set', ['userPlanInfo', data.data])
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
        });
    },

    async createPlan({ dispatch }, data) {
      return await Api.requestServer
        .post(`/client/user_plans`, data)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },

    async changePlan({ dispatch }, data) {
      return await Api.requestServer
        .patch(`/client/user_plans`, data)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },

    async getBankInfo({ dispatch }) {
      return await Api.requestServer
        .get(`/client/user_plans/bank_confirm_code`)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },

    async downloadFile({ dispatch }, data) {
      return await Api.requestServer
        .get(data, {responseType: 'arraybuffer'})
        .then((response) => {
          const { data } = response
          return data
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
        
    async verifyChangeEmailProfile({ commit, dispatch }, formData) {
      return await Api.requestServer
        .post(`/client/users/verify_change_email`, formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async completeChangeEmailProfile({ commit }, formData) {
      return await Api.requestServer
        .patch(`/client/users/change_email_complete `, formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          return error.response.data
        });
    },
    async changeEmailProfile({ commit, dispatch }, formData) {
      return await Api.requestServer
        .post(`/client/users/change-email-complete`, formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async getCoreSite({ commit, dispatch }) {
      return await Api.requestServer
        .get(`/client/core_sites`)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async addressListSelect({ commit, dispatch }, id) {
      return await Api.requestServer
        .get(`/client/users/auction_venues?core_site_id=${id}`)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async checkListSelect({ commit, dispatch }, id) {
      return await Api.requestServer
        .get(`/client/users/user_auction_venues?core_site_id=${id}`)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async createAcountProfile({ commit, dispatch }, formData) {
      return await Api.requestServer
        .post(`/client/users/aa_acount`, formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async createAuctionVenues({ commit, dispatch }, formData) {
      return await Api.requestServer
        .post(`/client/users/user_auction_venues`, formData)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async uploadFileChat({ dispatch }, formData) {
      return await Api.requestServer
      .post(`/client/users/chat/upload`, formData)
      .then(async (response) => {
        const { data } = response
        return await data
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getDataLotNo({ commit, dispatch }, { params }) {
      return await Api.requestServer
        .get(`client/stocks/register_params`, {
          params
        })
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async getOptionStock({ commit, dispatch }, params) {
      return await Api.requestServer
        .get(`client/masters`, {
          params
        })
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async searchListStock({ commit, dispatch }, { params } = {}) {
      return await Api.requestServer
        .get(`client/stocks/search`, {
          params
        })
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async searchDeletedListStock({ commit, dispatch }, request) {
      return await Api.requestServer
        .get(`client/stocks/deleted?${request}`)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async deleteStock({ commit, dispatch }, id) {
      return await Api.requestServer
        .delete(`client/stocks/${id}`)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async getListRemoveStock({ commit, dispatch }) {
      return await Api.requestServer
        .get(`client/stocks/deleted`)
        .then((response) => {
          const { data } = response;
          return data;
        })
        .catch((error) => {
          dispatch('redirectLogout', error.response)
          return error.response.data
        });
    },
    async restoreStock({ dispatch }, id) {
      return await Api.requestServer
      .post(`client/stocks/${id}/restore`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getDataGrade({ dispatch }, id) {
      return await Api.requestServer
      .get(`client/stocks/grades?core_model_id=${id}`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getDataModel({ dispatch }, id) {
      return await Api.requestServer
      .get(`client/stocks/models?core_car_id=${id}`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getDataCarName({ dispatch }, id) {
      return await Api.requestServer
      .get(`client/stocks/cars?core_brand_id=${id}`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async createStockCar({ commit, dispatch }, formData) {
      return await Api.requestServer
      .post(`client/stocks`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getDetailStock({ commit, dispatch }, id) {
      return await Api.requestServer
      .get(`client/stocks/${id}`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async updateStockCar({ commit, dispatch }, formData) {
      return await Api.requestServer
      .patch(`client/stocks/${formData.id}`, formData.data)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async updateFavorited({ commit, dispatch }, formData) {
      return await Api.requestServer
      .post(`client/stocks/${formData.id}/favorite`, formData.data)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async createCreditCard({ commit }, formData) {
      return await Api.requestServer
      .post(`/client/users/card`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async editCreditCard({ commit }, formData) {
      return await Api.requestServer
      .patch(`/client/users/card`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async delCreditCard({ commit }) {
      return await Api.requestServer
      .delete(`/client/users/card`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getCardInfo() {
      return await Api.requestServer
      .get(`/client/users/card_payment_info`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getCoreVenueStock({ commit }) {
      return await Api.requestServer
      .get(`client/core_venues`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        return error.response.data
      });
    },
    getThreadChat({ commit }) {
      const listThreadChat = ref(getDatabase(this.$firebase), 'conversations');
      onValue(listThreadChat, (snapshot) => {
        const data = snapshot.val();
        commit('set', ['dataListChat', data])
      });
    },
    getIsRead({ commit }, userId) {
      const isReadChat = ref(getDatabase(this.$firebase), `conversations/${userId}/recent_message/is_user_read`);
      onValue(isReadChat, (snapshot) => {
        const data = snapshot.val();
        if(data !== null) commit('set', ['isReadChat', data])
      });
    },
    async createVehicleConditions({ commit, dispatch }, formData) {
      return await Api.requestServer
      .post(`client/rec_cond_car_infos`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async detailVehicleConditions({ commit, dispatch }, id) {
      return await Api.requestServer
      .get(`client/rec_cond_car_infos/${id}`,)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async searchRecommendCondition ({ commit, dispatch }, { params }) {
      return await Api.requestServer
      .get(`client/rec_conds/search`, {
        params
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async updateVehicleConditions({ commit, dispatch }, formData) {
      return await Api.requestServer
      .patch(`client/rec_cond_car_infos/${formData.id}`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async deleteRecommendCondition({ commit, dispatch }, { id }) {
      return await Api.requestServer
      .delete(`client/rec_conds/${id}`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async conditionSelections({ commit, dispatch }, { params }) {
      return await Api.requestServer
      .get(`client/rec_conds/options`, {
        params
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async deleteRecommendConditionCar({ commit, dispatch }, recommentCarParam) {
      return await Api.requestServer
      .delete(`client/${recommentCarParam.path}/${recommentCarParam.id}`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async duplicateRecommendConditionCar({ commit, dispatch }, recommentCarParam) {
      return await Api.requestServer
      .get(`client/${recommentCarParam.path}/${recommentCarParam.id}/duplicate`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async createCarPrice({ commit, dispatch }, formData) {
      return await Api.requestServer
      .post(`client/rec_cond_prices`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getCarPriceDetail({ commit, dispatch }, id) {
      return await Api.requestServer
      .get(`client/rec_cond_prices/${id}`,)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async updateCarPrice({ commit, dispatch }, formData) {
      return await Api.requestServer
      .patch(`client/rec_cond_prices/${formData.id}`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async createCarProfit({ commit, dispatch }, formData) {
      return await Api.requestServer
      .post(`client/rec_cond_profits`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getCarProfitDetail({ commit, dispatch }, id) {
      return await Api.requestServer
      .get(`client/rec_cond_profits/${id}`,)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async updateCarProfit({ commit, dispatch }, formData) {
      return await Api.requestServer
      .patch(`client/rec_cond_profits/${formData.id}`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async changeOption({ commit, dispatch }, formData) {
      return await Api.requestServer
      .post(`/client/user_plans/option`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async createRecommentGroup({ commit, dispatch }, formData) {
      return await Api.requestServer
      .post(`client/rec_conds`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async updateRecommentGroup({ commit, dispatch }, formData) {
      return await Api.requestServer
      .patch(`client/rec_conds/${formData.id}`, formData)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async searchRecommendResult({ commit, dispatch }, { params }) {
      return await Api.requestServer
      .get(`client/recommends/search`, {
        params
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async submitFeedback({ commit, dispatch }, { params, data, recommendId }) {
      return await Api.requestServer
      .post(`client/recommends/${recommendId}/feedback`, 
      data,
      {
        params
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async countRecommend({ commit, dispatch }, { params } = {}) {
      return await Api.requestServer
      .get(`client/recommends/count_recommend_types`, 
      {
        params
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async setRecommend({ commit, dispatch }) {
      const res = await dispatch('countRecommend')
      commit('set', [
        'recommendCount',
        res.data
      ])
    },
    async updateReadedRecommend({ commit, dispatch }, { recommendId } = {}) {
      return await Api.requestServer
      .patch(`client/recommends/${recommendId}/update_is_readed`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async invoiceDownload({ commit, dispatch }, id) {
      let requestApi = `client/pdfs/download_invoice/?id=${id}`
      return await Api.requestServer
        .get(requestApi, { responseType: 'arraybuffer' })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async recommnedDate({ commit, dispatch }, {params} = {}) {
      return await Api.requestServer
      .get(`client/recommends/recommmends_current_day`, {
        params
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async findAACar({ commit, dispatch }, {params} = {}) {
      return await Api.requestServer
      .get(`client/analyze_graphs/aa_car_info`, {
        params
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getUserOption({ commit, dispatch }) {
      return await Api.requestServer
      .get(`client/user_plans/get_options`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    updateProfileUserFirebase({ getters }, { profileData }) {
      return new Promise(async (resolve, reject) => {
        const refUserInfoFirebase = getters.refUserInfoFirebase
        if(refUserInfoFirebase) {
          const userQuery = await get(query(refUserInfoFirebase))
          const userInfo = userQuery.val()
          if(userInfo){
            const res = Object.assign(userInfo, profileData)
            set(refUserInfoFirebase, res)
            .then(res => {
              resolve(res)
            })
            .catch((e) => {
              reject(e)
            })
          }
        }
        resolve(null)
      })
    },
    async searchCoupon({ dispatch }, { code }) {
      return await Api.requestServer
      .get(`client/coupons/show`, {
        params: {
          code
        }
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getDataChart ({ dispatch }, { params }) {
      return await Api.requestServer
      .post(`client/analyze_graphs/analysis_price`,params)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getAdminInfo({ dispatch }, { token }) {
      return await Api.requestServer.get(`/admin/info`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        return error.response.data
      });
    },
    async sendContact ({ dispatch }, { body, params }) {
      return await Api.requestServer
      .post(`client/create_contact`, body, {
        params
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async dashboardInfo({ dispatch }) {
      return await Api.requestServer
      .get(`client/dashboard/info`)
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getRecommendsNew({ dispatch }, { params }) {
      return await Api.requestServer
      .get(`client/recommends/recommmends_new`, { params })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async countStatisticLandingPage({ dispatch }, { params } = {}) {
      return await Api.requestServer
      .get(`client/landing_page_info`, { params })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async getAmountOptions({ dispatch }, { params } = {}) {
      return await Api.requestServer
      .get(`client/user_plans/cal_amount_hold`, { params })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    },
    async changePriorityRecCond({ dispatch }, { params, data } = {}) {
      return await Api.requestServer
      .post(`client/rec_conds/update_priority`, data, {
        params,
      })
      .then((response) => {
        const { data } = response;
        return data;
      })
      .catch((error) => {
        dispatch('redirectLogout', error.response)
        return error.response.data
      });
    }
  },
})
