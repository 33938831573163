export const tableFields = {
  LIST_OPTION_RECOMMENT: [
    {
      key: 'is_new',
      label: '',
      thStyle: ''
    },
    {
      key: 'option_name',
      label: 'オプション名',
      thStyle: 'min-width:180px'
    },
    {
      key: 'date',
      label: '日付',
      thStyle: 'min-width:100px'
    },
    {
      key: 'option_total',
      label: '登録台数',
      thStyle: 'min-width:145px'
    },
    {
      key: 'recommend_count',
      label: 'リコメンド済台数',
      thStyle: 'min-width:145px'
    },
    {
      key: 'amount',
      label: '金額',
      thStyle: 'min-width:60px'
    }
  ],
  ADMIN_USER_LIST: [
    {
      key: 'buttonEdit',
      label: '',
      thStyle: 'min-width: 10px;text-align: center;'
    },
    {
      key: 'buttonCheck',
      label: '',
      thStyle: 'min-width: 10px;text-align: center;'
    },
    {
      key: 'id',
      label: '#ID',
      thStyle: 'min-width:350px;text-align: center;'
    },
    {
      key: 'email',
      label: 'メールアドレス',
      thStyle: 'min-width:150px;text-align: center;'
    },
    {
      key: 'name',
      label: 'フルネーム',
      thStyle: 'min-width:150px;text-align: center;'
    },
    {
      key: 'phone',
      label: '電話番号',
      thStyle: 'min-width:100px;text-align: center;'
    },
    {
      key: 'namePlan',
      label: 'プラン名',
      thStyle: 'min-width:150px;text-align: center;'
    },
    {
      key: 'address',
      label: '住所',
      thStyle: 'min-width:250px;text-align: center;'
    },
    {
      key: 'status',
      label: 'ステータス',
      thStyle: 'min-width:150px;text-align: center;'
    }
  ],
  ADMIN_COUPON_LIST: [
    {
      key: "action",
      label: "",
    },
    {
      key: "code",
      label: "クーポンコード",
    },
    {
      key: "name",
      label: "クーポン名",
    },
    {
      key: "scope",
      label: "適用範囲",
    },
    {
      key: "valid_from_on",
      label: "開始日",
    },
    {
      key: "valid_till_on",
      label: "終了日",
    },
    {
      key: "discount_price",
      label: "利用可能金額",
    },
    {
      key: "usage_histories",
      label: "利用した件数",
    },
    {
      key: "status",
      label: "ステータス",
    },
  ],
  ADMIN_HISTORY_LIST: [
    {
      key: "id",
      label: "ID"
    },
    {
      key: "code",
      label: "クーポンコード"
    },
    {
      key: "user",
      label: "会員名"
    },
    {
      key: "idTransaction",
      label: "取引ID"
    },
    {
      key: "type",
      label: "申請する"
    },
    {
      key: "money",
      label :"金額"
    },
    {
      key: "timeUsed",
      label: "使用時間"
    }
  ],
  LIST_AUCTION_INFORMATION: [
    {
      key: "アクション",
      label: "アクション",
      thStyle: 'min-width:100px'
    },
    {
      key: "auction_venue",
      label: "会場",
      thStyle: 'min-width:100px'
    },
    {
      key: "lot_no",
      label: "出品番号",
      thStyle: 'min-width:100px'
    },
    {
      key: "current_price",
      label: "現在価値",
      thStyle: 'min-width:100px'
    },
    {
      key: "open_on",
      label: "開催日",
      thStyle: 'min-width:100px'
    }
  ]
};