<template>
  <div class="mr-4">
    <div class="d-flex group-select">
      <div class="row ml-0 row-group py-2">
        <div class="col-auto row align-items-center group-items pl-4 px-0" v-if="groupsData.rec_cond_car_info">
          <div class="col group-select-text px-0" :id="`popper-rec_cond_car_info-${popperID}`">
            {{ groupsData.rec_cond_car_info.name }}
          </div>
          <b-popover
            :target="`popper-rec_cond_car_info-${popperID}`"
            triggers="hover focus"
            placement="bottomright"
          >
            <p v-if="renderBrand">メーカー：{{renderBrand}}</p>
            <p v-if="renderCar">車両名：{{renderCar}}</p>
            <p v-if="renderModel">型式：{{renderModel}}</p>
            <p v-if="renderGrade">グレード： {{renderGrade}}</p>
            <p v-if="renderYear">年式：{{renderYear}}</p>
            <p v-if="renderColor">色：{{renderColor}}</p>
            <p v-if="renderGear">シフト：{{renderGear}}</p>
            <p v-if="renderDisplacement">排気量：{{renderDisplacement}}</p>
            <p v-if="renderOdo">走行距離：{{renderOdo}}</p>
            <p v-if="renderScore">評価点：{{renderScore}}</p>
          </b-popover>
        </div>
        <div class="col-auto row align-items-center group-items pl-4 px-0" v-if="groupsData.rec_cond_price">
          <div class="col-span col-auto px-3" v-if="groupsData.rec_cond_car_info && groupsData.rec_cond_price">~</div>
          <div class="col group-select-text px-0" :id="`popper-rec_cond_price-${popperID}`">
            {{ groupsData.rec_cond_price.name }}
          </div>
          <b-popover
            :target="`popper-rec_cond_price-${popperID}`"
            triggers="hover focus"
            placement="bottomright"
          >
            <p>価格帯：{{(groupsData.rec_cond_price.from / 10000) | setComma}}万円 〜 {{(groupsData.rec_cond_price.to / 10000) | setComma}}万円</p>
          </b-popover>
        </div>
        <div class="col-auto row align-items-center group-items pl-4 px-0" v-if="groupsData.rec_cond_profit">
          <div class="col-span col-auto px-3" v-if="groupsData.rec_cond_price && groupsData.rec_cond_profit">~</div>
          <div class="col-span col-auto px-3" v-if="groupsData.rec_cond_car_info && !groupsData.rec_cond_price && groupsData.rec_cond_profit">~</div>
          <div class="col group-select-text px-0" :id="`popper-rec_cond_profit-${popperID}`">
            {{ groupsData.rec_cond_profit.name }}
          </div>
          <b-popover
            :target="`popper-rec_cond_profit-${popperID}`"
            triggers="hover focus"
            placement="bottomright"
          >
            <p>粗利：{{(groupsData.rec_cond_profit.from / 10000) | setComma}}万円 〜 {{(groupsData.rec_cond_profit.to / 10000) | setComma}}万円</p>
          </b-popover>
        </div>
        <div
          v-if="isShowDescription"
          class="col-12 p-0 mt-2 py-3 pl-4 description-text font-weight-normal"
        >
          同様の条件で 過去１ヶ月で<span class="text-success">1,300件</span>リコメンドしました
        </div>
      </div>
      <!-- <div
        class="col-auto d-flex align-items-center">
      </div> -->
      <!-- <div
        class="col-auto btn-append d-flex align-items-center"
        @click="setDescription"
      >
        <font-awesome-icon
          icon="fa-solid fa-angle-up"
          v-if="isShowDescription"
        />
        <font-awesome-icon icon="fa-solid fa-angle-down" v-else />
      </div> -->
    </div>
  </div>
</template>
<script>
import odoList from '@/utils/odoList';
import { SCORE_OBJ } from '@/utils/score';
export default {
  props: {
    groupsData: {
      type: Object,
      required: true,
    },
    description: {
      type: String,
      default() {
        return "";
      },
    },
  },
  data() {
    return {
      isShowDescription: false,
      popperID: Math.round(Math.random() * new Date().getTime()),
    };
  },
  computed: {
    carInfo () {
      return this.groupsData.rec_cond_car_info
    },
    renderBrand () {
      const brands= this.carInfo.brands.map((brand) => brand.name)
      return brands.join()
    },
    renderCar () {
      const cars = this.carInfo.cars.map(car => car.name)
      return cars.join()
    },
    renderGrade () {
      const grades = this.lodash.uniqBy(this.carInfo.grades, (grade) => grade.name).map((grade) => grade.name)
      return grades.join()
    },
    renderModel () {
      return this.carInfo.models.map(model => model.name).join()
    },
    renderYear() {
      const dataMaster = this.$store.getters.carMap
      const year_start_on = this.carInfo.year_start_on
      const year_end_on = this.carInfo.year_end_on
      let yearStart = ""
      let yearEnd = ""
      if (!this.carInfo.year_start_on && !this.carInfo.year_end_on) return 
      if(year_start_on) {
        for (const yearItem of dataMaster.years) {
          const year = new Date(yearItem.value)
          const yearStartOn = new Date(year_start_on)
          if(year.getFullYear() == yearStartOn.getFullYear()) {
            yearStart = yearItem.text
            break;
          }
        }
      }
      if(year_end_on) {
        for (const yearItem of dataMaster.years) {
          const year = new Date(yearItem.value)
          const yearEndOn = new Date(year_end_on)
          if(year.getFullYear() == yearEndOn.getFullYear()) {
            yearEnd = yearItem.text
            break;
          }
        }
      }
      yearStart = yearStart.replace(/^(\d{4})/,"$1年")
      yearEnd = yearEnd.replace(/^(\d{4})/,"$1年")
      return `${yearStart} 〜 ${yearEnd}`
    },
    renderColor () {
      const colors = this.carInfo.colors.map((color) => color.name)
      return colors.join()
    },
    renderGear () {
      const gears = this.carInfo.gears.map((gear) => gear.name)
      return gears.join()
    },
    renderDisplacement () {
      if(this.carInfo.displacement_from || this.carInfo.displacement_to) {
        return `${this.carInfo.displacement_from ?? ""} 〜 ${this.carInfo.displacement_to ?? ""}`
      }
      return ""
    },
    renderOdo() {
      if(this.carInfo.odo_from || this.carInfo.odo_to){
        const textOdoFrom = odoList.find((odo) => odo.value == this.carInfo.odo_from)?.text ?? ""
        const textOdoTo = odoList.find((odo) => odo.value == this.carInfo.odo_to)?.text ?? ""
        return `${textOdoFrom ?? ""} 〜 ${textOdoTo ?? ""}`
      }
      return ""
    },
    renderScore() {
      if(this.carInfo.score_from || this.carInfo.score_to){
        return `${SCORE_OBJ[this.carInfo?.score_from ?? ""] ?? ""} 〜 ${SCORE_OBJ[this.carInfo?.score_to ?? ""] ?? ""}`
      }
      return ""
    }
  },
  methods: {
    setDescription() {
      this.isShowDescription = !this.isShowDescription;
    },
  },
};
</script>
<style lang="scss" scoped>
.group-select {
  width: fit-content;
  border: 1px solid;
  cursor: pointer;
  border-radius: 7px;
  max-width: 100%;
  &-text {
    width: max-content;
    color: var(--orange);
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    padding: 0.7rem;
  }
  .btn-append {
    border-left: 1px solid;
    width: 50px;
    
  }
  .row-group {
    // width: calc(100% - 30px);
    padding-right: 2.5rem;
    .group-items {
      margin-right: initial;
      margin-left: initial;
    }
    .group-select-text {
      overflow: hidden;
    }
    .col-span {
      padding-right: 50px !important;
    }
  }
  .description-text {
    border-top: 1px solid;
  }
}
</style>
