<template>
  <div>
    <div class="cq-sidebar-mypage" :class="{ isActive: !isHideSidebar }">
      <slot name="header"></slot>
      <div class="position-relative cq-sidebar-mypage-list d-flex flex-column">
        <div class="cq-sidebar-mypage-info align-items-center mb-4 row flex-nowrap">
          <div class="cq-sidebar-mypage-avatar col-auto">
            <font-awesome-icon icon="fa-regular fa-circle-user" class="icon-avatar" />
          </div>
          <div class="cq-sidebar-mypage-nickname pl-0">
            <div class="nowrap">
              {{ nameUser }}
            </div>
          </div>
        </div>
        <div class="cq-sidebar-mypage-content col p-0 p-2">
          <div class="cq-sidebar-mypage-link" :class="{ active: isActiveTab }" @click="toggleTab()">
            <div class="cq-sidebar-mypage-item mb-3">
              <font-awesome-icon class="cq-sidebar-mypage-icon mr-md-2" icon="fa-solid fa-user" /><span
                class="cq-sidebar-mypage-text">マイページ</span>
            </div>
          </div>
          <div class="cq-sidebar-mypage-childMenu pl-5" :class="{ active: isOpenTab }">
            <router-link to="/mypage" custom v-slot="{ href, navigate }">
              <a :href="href" @click="handlerRedirect($event, navigate)" :class="{ active: $route.path === '/mypage' }"
                id="link_to_mypage" class="cq-sidebar-mypage-childLink">
                <div class="cq-sidebar-mypage-childItem mb-3">
                  <div class="d-flex align-items-center d-block">
                    <div class="cq-sidebar-mypage-dot mr-2" /><span class="cq-sidebar-mypage-text">ダッシュボード</span>
                  </div>
                </div>
              </a>
            </router-link>
            <router-link to="/mypage/profile" custom v-slot="{ href, navigate, isActive }" v-bind="intro_aa_account[0]">
              <a :href="href" @click="handlerRedirect($event, navigate)" ref="guideProfile"
                class="cq-sidebar-mypage-childLink" :class="{ active: isActive }" id="link_to_mypage">
                <div class="cq-sidebar-mypage-childItem mb-3">
                  <div class="d-flex align-items-center d-block">
                    <div class="cq-sidebar-mypage-dot mr-2" /><span class="cq-sidebar-mypage-text">会員情報</span>
                  </div>
                </div>
              </a>
            </router-link>
            <router-link to="/mypage/plan" custom v-slot="{ href, navigate, isActive }"
              class="cq-sidebar-mypage-childLink">
              <a :href="href" @click="handlerRedirect($event, navigate)" class="cq-sidebar-mypage-childLink"
                :class="{ active: isActive }" id="link_to_mypage_plan">
                <div class="cq-sidebar-mypage-childItem mb-3">
                  <div class="d-flex align-items-center d-block">
                    <div class="cq-sidebar-mypage-dot mr-2" /><span class="cq-sidebar-mypage-text">プラン情報</span>
                  </div>
                </div>
              </a>
            </router-link>
            <router-link to="/mypage/credit/new" custom v-slot="{ href, navigate, isActive }">
              <a :href="href" @click="handlerRedirect($event, navigate)" class="cq-sidebar-mypage-childLink"
                :class="{ active: isActive }" id="link_to_credit_new">
                <div class="cq-sidebar-mypage-childItem mb-3">
                  <div class="d-flex align-items-center d-block">
                    <div class="cq-sidebar-mypage-dot mr-2" /><span class="cq-sidebar-mypage-text">支払い情報</span>
                  </div>
                </div>
              </a>
            </router-link>
          </div>
          <router-link to="/stock/list" custom v-slot="{ href, navigate }">
            <a class="cq-sidebar-mypage-link" :class="{ active: $route.fullPath.includes('/stock') }" :href="href"
              @click="handlerRedirect($event, navigate)" id="link_to_stock_list">
              <div class="cq-sidebar-mypage-item mb-3">
                <font-awesome-icon class="cq-sidebar-mypage-icon mr-md-2" icon="fa-solid fa-warehouse" /><span
                  class="cq-sidebar-mypage-text">在庫車両</span>
              </div>
            </a>
          </router-link>
          <router-link to="/recommend-condition/group/list" custom v-slot="{ href, navigate }">
            <a class="cq-sidebar-mypage-link" :class="{ active: $route.fullPath.includes('/recommend-condition') }"
              :href="href" @click="handlerRedirect($event, navigate)" id="link_to_recommed_condition">
              <div class="cq-sidebar-mypage-item mb-3">
                <font-awesome-icon class="cq-sidebar-mypage-icon mr-md-2" icon="fa-solid fa-heart" /><span
                  class="cq-sidebar-mypage-text">リコメンド条件</span>
              </div>
            </a>
          </router-link>
          <router-link to="/chat" custom v-slot="{ href, navigate, isActive }">
            <a :href="href" @click="handlerRedirect($event, navigate)" class="cq-sidebar-mypage-link"
              :class="{ active: isActive }" id="link_to_chat">
              <div class="cq-sidebar-mypage-item mb-3 position-relative">
                <font-awesome-icon class="cq-sidebar-mypage-icon mr-md-2" icon="fa-solid fa-message" /><span
                  class="cq-sidebar-mypage-text">チャット</span>
                <div v-if="!isReadChat" class="position-absolute notify-chatDot" />
              </div>
            </a>
          </router-link>
          <div class="cq-sidebar-mypage-link"
            @click="handlerRedirect($event, () => { $bvModal.show('modal-analyze'), setShowModal() })"
            :class="{ 'active': $route.name === 'analyzePage' }" id="link_to_modal_analyze">
            <div class="cq-sidebar-mypage-item mb-3">
              <imageAndIcon class="img-chart mr-md-2 cq-sidebar-mypage-icon"></imageAndIcon><span
                class="cq-sidebar-mypage-text">分析支援</span>
            </div>
          </div>
          <a class="cq-sidebar-mypage-link" :href="chromeExtensionLink" target="blank" id="link_to_chrome_extension">
            <div class="cq-sidebar-mypage-item mb-3 position-relative">
              <font-awesome-icon class="cq-sidebar-mypage-icon mr-md-2" icon="fa-brands fa-chrome" />
              <span class="cq-sidebar-mypage-text">Chrome拡張機能</span>
            </div>
          </a>
          <div class="alert-recommendation cursor-pointer" v-if="titelSuccess"
            @click="$bvModal.show('message-add-account')">
            <p class="alert-recommendation-text"><img width="40" height="33" src="@/assets/img/thumb-Artboard – 1.png"
                alt="" srcset=""></p>
          </div>
          <b-modal id="confirmLogout" ref="confirmLogout" modal-class="modal-confirm modal-confirm-sm"
            body-class="p-md-4 p-3" hide-footer hide-header centered>
            <div class="d-block text-center title w-100">
              <h1 class="text-warning">
                <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
              </h1>
              <p>
                {{ message.LOGOUT }}
              </p>
            </div>
            <div class="mt-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap">
              <b-button class="btn-bot" @click="$bvModal.hide('confirmLogout')"
                id="btn_sidebar_cancel_logout">いいえ</b-button>
              <b-button class="cq-btn-bg-green btn-top" @click="$router.push('/logout')"
                id="btn_sidebar_confirm_logout">はい</b-button>
            </div>
          </b-modal>
          <b-modal id="message-add-account" ref="message-add-account" modal-class="modal-confirm modal-confirm-sm"
            body-class="p-md-4 p-3" hide-footer centered>
            <div class="d-block text-center title w-100">
              <h1 class="icon-check-succus">
                <font-awesome-icon class="cq-sidebar-mypage-icon mr-md-2 text-success" icon="fa-solid fa-circle-check" />
              </h1>
              <p class="py-4">
                {{ titelSuccess }}
              </p>
            </div>
          </b-modal>
        </div>
        <div class="cq-sidebar-mypage-footer col-auto p-0 p-lg-2">
          <div class="cq-sidebar-mypage-link">
            <div class="cq-sidebar-mypage-item cq-sidebar-mypage-logout"
              @click="handlerRedirect($event, () => { $bvModal.show('confirmLogout') })">
              <font-awesome-icon class="cq-sidebar-mypage-icon mr-md-2"
                icon="fa-solid fa-arrow-right-from-bracket" /><span class="cq-sidebar-mypage-text">ログアウト</span>
            </div>
          </div>
        </div>
      </div>
      <modalAnalyze ref="modalAnalyze" />
    </div>
    <transition name="fade">
      <div class="cq-sidebar-mypage-bg" v-show="!isHideSidebar"></div>
    </transition>
  </div>
</template>

<script>
import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
import { mapGetters } from 'vuex';
import modalAnalyze from "./analyze/modal.vue";
import imageAndIcon from "@/components/imageAndIcon.vue";
import { ref, getDatabase } from "@firebase/database";
import { Message } from '@/utils/message'
import intro, { default_configuration } from "@/utils/intro";

export default {
  name: "SidebarMypage",
  components: {
    modalAnalyze,
    imageAndIcon,
  },
  data() {
    return {
      tabActive: false,
      isOpenTab: false,
      userId: localStorage.getItem('userId'),
      titelSuccess: "",
      message: Message.EDIT_PROFILE,
      isShowGuide: localStorage.getItem("guide-profile-1") ? true : false,
      intro_aa_account: [],
    }
  },
  computed: {
    ...mapGetters({
      isReadChat: 'isReadChat',
      userInfo: "userInfo",
      isHideSidebar: 'isHideSidebar',
    }),
    isActiveTab() {
      return this.$route.path.includes('mypage')
    },
    nameUser() {
      return this.userInfo?.data?.email
    },
    chromeExtensionLink() {
      return this.$store.getters.extensionLink
    }
  },
  watch: {
    '$route.path'() {
      if (!this.isActiveTab) {
        this.isOpenTab = false
      }
    },
    isActiveTab(isActiveTab) {
      this.isOpenTab = isActiveTab
    }
  },
  async created() {
    this.isOpenTab = this.isActiveTab
    this.getDataMaster()
    const handler = new Promise((resolve, reject) => {
      this.$store.dispatch('getProfileUser', this.userId)
        .then((data) => {
          if (data.data.user.system_locked_at && this.$route.path != '/') {
            this.$router.push("/locked");
            return
          }
          this.$store.commit("set", ['userInfo', { handler, data: data.data.user }])
          const token_admin = localStorage.getItem('token_admin')
          const { email_firebase, password_firebase } = data.data.user
          if (token_admin) {
            this.$store.dispatch('getAdminInfo', { token: token_admin })
              .then((res) => {
                const { email_firebase, password_firebase } = res.data.admin
                this.loginFirebase(email_firebase, password_firebase)
                  .then(() => {
                    this.$store.dispatch('getIsRead', this.userId)
                    resolve(data)
                  })
                  .finally(() => {
                    resolve(data)
                  })
              })
              .catch(() => {
                localStorage.removeItem("token_admin")
                this.loginFirebase(email_firebase, password_firebase)
                  .then(() => {
                    this.$store.dispatch('getIsRead', this.userId)
                    resolve(data)
                  })
                  .finally(() => {
                    resolve(data)
                  })
              })
          }
          else {
            this.loginFirebase(email_firebase, password_firebase)
              .then(() => {
                this.$store.dispatch('getIsRead', this.userId)
                resolve(data)
              })
              .finally(() => {
                resolve(data)
              })
          }
        })
    })
    this.$store.commit("set", ['userInfo', {
      handler
    }])
    const dataCoreSite = await this.$store.dispatch('getCoreSite');
    const confirmationObj = dataCoreSite.data.core_sites.filter(coreSites => coreSites.status === 1);
    const noConfirm = dataCoreSite.data.core_sites.filter(coreSites => coreSites.status === 0);
    (!confirmationObj.length && noConfirm.length !== dataCoreSite.data.core_sites.length) ? this.titelSuccess = this.message.MESSAGE_ADD_ACOUNT : this.titelSuccess = "";
  },
  mounted() {
    if (this.isShowGuide) {
      this.intro_aa_account = intro.aa_account
      const config = {
        ...default_configuration,
        doneLabel: default_configuration.nextLabel
      }
      this.$nextTick(() => {
        this.introJs().setOptions(config).onexit(() => {
          this.nextGuide()
        }).start();
      })
    }
    this.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
      if (modalId === "modal-analyze") {
        this.$store.commit('set', ['analyzeGetDataOld', false])
        this.$refs.modalAnalyze.formData.core_brand_id = null
      }
    })
  },
  methods: {
    setShowGuide() {
      this.intro_aa_account = []
      this.isShowGuide = false
      localStorage.removeItem("guide-profile-1")
    },
    setShowModal() {
      this.$store.commit('set', ['showModalCondition', true])
    },
    loginFirebase(email_firebase, password_firebase) {
      const auth = getAuth(this.$firebase);
      const handler = signInWithEmailAndPassword(auth, email_firebase, password_firebase)
        .then(() => {
          const refUser = ref(getDatabase(this.$firebase), `conversations/${this.userId}/user_info`)
          this.$store.commit('set', ['refUserInfoFirebase', refUser])
        })
        .catch((error) => {
          this.$toast.error(error.message)
          // this.$router.push('/logout')
        });
      return handler
    },

    toggleTab() {
      this.isOpenTab = !this.isOpenTab
    },

    async getDataMaster() {
      const options = [
        "core_brand",
        "core_colors",
        "core_cars",
        "core_sites",
        "core_venues",
        "core_gears",
        "core_equipments",
        "core_years",
        "core_country",
        "auctions_venue",
      ];
      const params = {
        options: options.toString(),
      };
      const handler = this.$store.dispatch("getOptionStock", params)
      const car_info = {
        brands: [],
        cars: [],
        site: [],
        colors: [],
        venues: [],
        gears: [],
        equipments: [],
        years: [],
        countries: [],
        auctions_venue: [],
        loader: handler
      }
      this.$store.commit('set', ['carMap', car_info])
      const res = await handler
      car_info.brands = res.data.options.core_brands
      car_info.cars = res.data.options.core_cars;
      car_info.site = res.data.options.core_sites;
      car_info.colors = res.data.options.core_colors;
      car_info.venues = res.data.options.core_venues;
      car_info.gears = res.data.options.core_gears;
      car_info.equipments = res.data.options.core_equipments;
      car_info.countries = res.data.options.core_countries;
      car_info.auctions_venue = res.data.options.auction_venues;
      car_info.years = res.data.options.core_years.map((year) => {
        return {
          text: year.name,
          value: this.$moment(year.date).format("YYYY-MM-DD")
        }
      });
      this.$store.commit('set', ['carMap', car_info])
    },

    handlerRedirect($event, callback) {
      this.$store.commit('set', ['isHideSidebar', true])
      if (callback) callback($event)
    },
    nextGuide() {
      this.setShowGuide()
      this.$router.push({ path: "/mypage/profile", query: { showGuide: true } })
    }
  }
}
</script>

<style lang="scss">
.sidebarGuide {
  .sidebarGuide-carousel-wrapper {
    position: fixed;
    z-index: 1003;
    left: 180px;
    transform: translateY(-50%);

    .sidebarGuide-carousel-content {
      width: 330px;
      position: relative;

      .sidebarGuide-carousel-close {
        position: absolute;
        top: 5px;
        right: 10px;

        svg {
          width: 25px !important;
          height: 35px !important;
          fill: #9f9f9f;
        }
      }

      .sidebarGuide-carousel {
        background-color: white;
        width: 100%;
        z-index: 2;
        height: 140px;
      }

      .sidebarGuide-carousel-item p {
        padding: 24px;
        padding-top: 50px;
      }
    }

    .sidebarGuide-carousel-triangle {
      position: absolute;
      top: 50%;
      left: -15px;
      transform: translateY(-50%);
      width: 0;
      height: 0;
      border-top: 15px solid transparent;
      border-bottom: 15px solid transparent;
      border-right: 15px solid $white;
    }

    .next-btn {
      background-color: #5DAF5D;
      padding: 0.4rem 1.3rem;
      color: $white;
      position: absolute;
      right: 10px;
      bottom: 10px;
    }
  }

  .bg-blur {
    position: fixed;
    top: 0px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    z-index: 1002;
    background-color: rgba(0, 0, 0, 0.3);
  }
}

.cq-sidebar-mypage {
  position: fixed;
  background: $white;
  width: 270px;
  top: 70px;
  left: 0;
  z-index: 1001;
  bottom: 0;
  padding: 2.0rem 1.3rem;
  overflow: auto;
  box-shadow: 4px 0 12px rgb(0 0 0 / 10%);
  transform: translateX(-100%);
  transition: transform 0.5s ease;

  @media (min-width: 1181px) {
    transform: translateX(0px);
  }

  &.isActive {
    transform: translateX(0%);
  }

  &-bg {
    position: fixed;
    top: 0px;
    left: 0px;
    bottom: 0px;
    right: 0px;
    z-index: 1000;
    background-color: rgba(0, 0, 0, 0.5);

    @include screen(768) {
      display: none;
    }
  }

  &-content {
    overflow: auto;

    &::-webkit-scrollbar {
      width: 4px;
      height: 4px;
      cursor: default;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
      -webkit-border-radius: 6px;
      border-radius: 6px;
      cursor: default;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      -webkit-border-radius: 6px;
      border-radius: 6px;
      background-color: #797979;
      -webkit-box-shadow: #bebebe;
      cursor: default;
    }
  }

  &-info {
    height: 58px;
  }

  &-avatar {
    height: 100%;

    .icon-avatar {
      width: 45px;
      height: 100%;
      fill: #797979;

      path {
        fill: inherit
      }
    }
  }

  &-nickname {
    font-size: 1.125rem;
    overflow: hidden;
  }

  &-list {
    height: 100%;
  }

  &-item {
    padding: 0.75rem 1.3rem;
    border: 1px solid;
    border-radius: 10px;
    color: $cq-brown;
    font-size: 1rem;
    display: flex;
    align-items: center;
  }

  &-icon {
    font-size: 1rem;
  }

  &-link {
    cursor: pointer;

    .cq-sidebar-mypage-logout {
      svg {
        transform: rotate(182deg);
      }
    }

    &.active {
      .cq-sidebar-mypage-item {
        border: 1px solid $cq-brown;
        color: $white;
        background: $cq-brown;
      }
    }
  }

  &-childMenu {
    max-height: 0;
    transition: max-height 0.15s ease-out;
    overflow: hidden;

    &.active {
      max-height: 500px;
      transition: max-height 0.25s ease-in;
    }
  }

  &-childItem {
    color: #423931;

    &-small {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45px;
      height: 45px;
      margin: auto;
      border: 1px solid;
      border-radius: 10px;
    }

    &-icon {
      font-size: 1rem
    }
  }

  &-dot {
    width: 15px;
    height: 15px;
    border-radius: 100%;
    background: #9F9F9F;
  }

  &-childLink {
    &.active {
      .cq-sidebar-mypage-dot {
        background: #664500;
      }

      .cq-sidebar-mypage-text {
        font-weight: 500;
      }

      .cq-sidebar-mypage-childItem-small {
        border: 1px solid $cq-brown;
        color: $white;
        background: #686868;
      }
    }
  }

  &-logout {
    width: 100%;
  }

  @include screen (991) {
    padding: 1.0rem 0.7rem;
    width: 240px;
  }

  .alert-recommendation {
    width: 35px;
    height: 35px;
    position: relative;
    left: 11rem;
    bottom: 3.7rem;

    &-triangle-exclamation {
      color: rgb(255, 193, 7);
      padding-left: 8px;
      padding-top: 2px;
      font-size: 18px;
    }

    &-text {
      font-size: 12px;
      text-align: center;
      color: $white;
    }
  }
}

.notify-chatDot {
  width: 10px;
  height: 10px;
  background: red;
  border-radius: 100%;
  top: calc(50% - 10px);
  left: 35px;
  transform: translateY(-50%);
}

.nowrap {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.show-item-child {
  display: none !important;
}

.introjs-helperLayer {
  box-shadow: rgba(33, 33, 33, 0.8) 0px 0px 1px 2px, rgba(33, 33, 33, 0.5) 0px 0px 0px 5000px !important;
  width: 0px !important;
  height: 0px !important;
  top: 0px !important;
  left: 0px !important;
}
</style>