<template>
  <div
    class="cq-list-condition px-4 py-4"
    :class="{ 'mobile-show': showContentSearch }"
  >
    <div
      class="row align-items-center justify-content-between m-0"
      v-if="!showContentSearch"
    >
      <div class="col-auto">
        <div class="h4">リコメンド条件（グループ定義）</div>
      </div>
      <div class="col-auto panel-action">
        <b-dropdown variant="warning" text="新規登録" id="dropDown">
          <b-dropdown-item @click="toCreatePage('car-info')">車種条件</b-dropdown-item>
          <b-dropdown-item @click="toCreatePage('car-price')">価格帯条件</b-dropdown-item>
          <b-dropdown-item @click="toCreatePage('car-profit')" v-if="recCondType == 2">AA割安条件</b-dropdown-item>
          <b-dropdown-item disabled v-if="recCondType != 2">粗利条件（Coming Soon）</b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="mt-4 content">
      <div class="close-filter" @click="toogleContentSearch">
        <font-awesome-icon icon="fa-solid fa-xmark" class="icon-large" />
      </div>
      <b-tabs content-class="mt-3" v-model="currentTab" id="tabNavs" align="center">
        <b-tab @click="recCondType = 1" title="お得車両AIサーチ">
          </b-tab>
        <b-tab @click="recCondType = 2" title="流れ車両AIアラート"></b-tab>
        <b-tab @click="recCondType = 3" title="共有在庫AIアラート"></b-tab>
      </b-tabs>
      <div :class="{'show-mobile': showContentSearch}" class="mt-3 mobile-hidden">
        <div class="form-search">
          <div>
            <CQFormGroupSelectCar v-model="dataSearch" />
            <div class="row mt-md-2">
              <div class="col-lg-6 col-md-12 mt-md-2">
                <div class="row align-items-center">
                  <div class="col col-mobile">
                    <model-select
                      v-model="dataSearch.min_prices"
                      id="min_prices"
                      :options="minPrice"
                      :max="dataSearch.max_prices"
                    />
                  </div>
                  <div class="col-auto col-span d-none d-lg-block">~</div>
                  <div class="col col-mobile">
                    <model-select
                      v-model="dataSearch.max_prices"
                      id="max_prices"
                      :options="maxPrice"
                      :min="dataSearch.min_prices"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 mt-md-2">
                <div class="row align-items-center">
                  <div class="col col-mobile">
                    <model-select
                      :options="coreYearsFrom"
                      v-model="dataSearch.from_produced_on"
                      id="from_produced_on"
                      :max="dataSearch.to_produced_on"
                    />
                  </div>
                  <div class="col-auto col-span d-none d-lg-block">~</div>
                  <div class="col col-mobile">
                    <model-select
                      :options="coreYearsTo"
                      v-model="dataSearch.to_produced_on"
                      id="to_produced_on"
                      :min="dataSearch.from_produced_on"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="row mt-md-2">
              <div class="col-lg-6 col-md-12 mt-md-2">
                <div class="row align-items-center">
                  <div class="col col-mobile">
                    <model-select
                      v-model="dataSearch.min_profits"
                      id="min_profits"
                      :options="minProfit"
                      :max="dataSearch.max_profits"
                    />
                  </div>
                  <div class="col-auto col-span d-none d-lg-block">~</div>
                  <div class="col col-mobile">
                    <model-select
                      v-model="dataSearch.max_profits"
                      id="max_profits"
                      :options="maxProfit"
                      :min="dataSearch.min_profits"
                    />
                  </div>
                </div>
              </div>
              <div class="col-lg-6 col-md-12 mt-md-2">
                <div class="row">
                  <div class="col col-mobile">
                    <model-select
                      v-model="dataSearch.min_odo"
                      :options="listOdoFrom"
                      id="min_odo"
                      :max="dataSearch.max_odo"
                    />
                  </div>
                  <div class="col-auto col-span d-none d-lg-block">~</div>
                  <div class="col col-mobile">
                    <model-select
                      v-model="dataSearch.max_odo"
                      :options="listOdoTo"
                      id="max_odo"
                      :min="dataSearch.min_odo"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto zone-pc-search mt-3 p-0">
              <button
                class="btn btn-success btn-search"
                @click="fetchData"
                :disabled="isLoadingSearch"
                id="btn_recommend_condition_search"
              >
                <font-awesome-icon
                  icon="fa-solid fa-magnifying-glass"
                />
              </button>
              <b-button type="submit" id="btn_recommend_condition_reset_form" class="ml-3 search__button px-3 cq-btn-gray" @click="resetForm">
                <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
              </b-button>
            </div>
            <div class="row mt-2 show-mobile">
              <div class="col-12">
                <button
                  class="btn btn-success btn-search"
                  :disabled="isLoadingSearch"
                  @click="fetchSearch()"
                  id="btn_recommend_condition_search_mobile"
                >
                  <font-awesome-icon
                    icon="fa-solid fa-magnifying-glass"
                    class="icon"
                  />
                  <span class="text">検索</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-4">
        <div class="content-result">
          <div class="border-bottom h5 pb-2">
            {{ countCondition }}件のリコメンド条件が見つかりました
          </div>
          <draggable v-model="listCondition" handle=".handle" @change="changePriorityRecCond()">
            <transition-group type="transition" name="flip-list">
              <div
                class="mt-4"
                v-for="(condition, index) in listCondition"
                :key="condition.conditionGroup.id || 'condition_draft'"
                :class="{handle: condition.conditionGroup.id}"
              >
                <template v-if="Object.keys(condition.conditionGroup).length">
                  <div class="flex content-result-delete" v-if="Object.keys(condition.conditionGroup).length">
                    <span class="ban" v-b-modal.modal-delete @click="setCurrentDelete(condition.conditionGroup), nameAction = 'deleteCurrent'">
                      <font-awesome-icon icon="fa-solid fa-minus" />
                    </span>
                    <div class="input-group mb-2 form-condition" v-if="isEditNameGroup === index">
                      <input v-model="condition.conditionGroup.name" class="border form-control" id="editNameGroup" @blur="fadeBlur(condition)" :autofocus="true" autocomplete="off" />
                      <div class="input-group-append">
                        <div class="input-group-text" @click="updateNameGroup(condition)" id="btnSaveGroupName">
                          <font-awesome-icon icon="fa-solid fa-check" />
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <span class="desc">{{ condition.conditionGroup.name }}</span>
                      <button class="btn ml-2" @click="isEditNameGroup = index" :id="'btn_recommend_condition_edit_name_group_2_'+index">
                        <font-awesome-icon icon="fa-solid fa-pen" />
                      </button>
                    </div>
                  </div>
                </template>
                <div class="mt-2"
                  :class="{ 'list-btn-group': Object.keys(condition.conditionGroup).length }">
                  <CQDraggableDots class="draggable-dots" v-if="Object.keys(condition.conditionGroup).length" v-b-tooltip="'リコメンド条件の優先順位を変更できます。優先度の高い条件は上に、低いものは下に移動してください。'" />
                  <div class="condition-group" @click="showModalUpdateConditon(condition, $event), flagUpdateCondition = true, indexQueueGroups = index" v-if="Object.keys(condition.conditionGroup).length">
                    <CQConditionGroup :groupsData="condition.conditionGroup"/>
                  </div>
                  <template v-if="condition.queueGroups.nameGroup.rec_cond_show">
                    <div class="flex content-result-delete" >
                      <span class="ban" v-b-modal.modal-delete @click="messageModal = message.DELETE, indexQueueGroups = index, nameAction = 'deleteAddGroup'">
                        <font-awesome-icon icon="fa-solid fa-minus" />
                      </span>
                      <div class="input-group mb-2 form-condition" v-if="isEditNameGroup === index">
                        <b-input v-model="condition.queueGroups.itemGroup.name" class="border" id="editNameGroup" @blur="fadeBlur(condition)" :autofocus="true" autocomplete="off" />
                        <div class="input-group-append">
                          <div class="input-group-text" @click="updateNameGroup(condition,'draft')" id="btnSaveGroupName">
                            <font-awesome-icon icon="fa-solid fa-check" />
                          </div>
                        </div>
                      </div>
                      <div v-else>
                        <span class="desc">{{condition.queueGroups.itemGroup.name}}</span>
                        <button class="btn ml-2" @click="isEditNameGroup = index" :id="'btn_recommend_condition_edit_name_group_1_'+index">
                          <font-awesome-icon icon="fa-solid fa-pen" />
                        </button>
                      </div>
                    </div>
                    <!-- <CQOptionsRadioSmall 
                      :options="optionsRecondTypeCarPrice"
                      v-model="condition.queueGroups.itemGroup.rec_cond_profit_type"
                      class="mt-2"
                      v-if="recCondType == 2"
                    /> -->
                  </template>
                  <div class="d-flex list-btn mt-2">
                    <div class="flex align-items-center">
                      <button 
                        class="btn btn-add mr-4" 
                        v-if="condition.queueGroups.nameGroup.rec_cond_btn_show"
                        @click="showModalConditon(condition, $event, true), indexQueueGroups = index, flagUpdateCondition = false"
                        id="openModalCondCar">条件を追加
                        <b-spinner v-if="condition.queueGroups.nameGroup.loadingBtn" small />
                      </button>
                      <font-awesome-icon class="mr-4 pt-3 pb-2" icon="fa-solid fa-angles-right" 
                        @click="condition.queueGroups.nameGroup.rec_cond_btn_show = !condition.queueGroups.nameGroup.rec_cond_btn_show" 
                        v-if="!condition.queueGroups.nameGroup.rec_cond_btn_show && !(condition.conditionGroup.rec_cond_car_info_id && condition.conditionGroup.rec_cond_price_id && condition.conditionGroup.rec_cond_profit_id)" />
                    </div>
                  </div>
                </div>
              </div>
            </transition-group>
          </draggable>
          <div class="mt-4" v-if="listCondition.length">
            <button class="btn-plus" @click="addGroupCondition" id="btnAddGroup">
              <font-awesome-icon icon="fa-solid fa-plus" />
            </button>
          </div>
          <div class="mt-4 w-100 message-add-group" v-else>
            <p class="text-center mt-3 title-group">リコメンド条件を設定することで提案の精度を上げることができます。<br />どのような車両をお求めか条件をご指定してお知らせください。</p>
            <button class="btn-plus mx-auto mt-4" @click="addGroupCondition" id="btnAddGroup">
              <font-awesome-icon icon="fa-solid fa-plus" />
            </button>
          </div>
        </div>
      </div>
    </div>
    <button
      class="btn btn-filter"
      v-if="!showContentSearch"
      @click="toogleContentSearch"
      id="btnShowSearch"
    >
      <font-awesome-icon icon="fa-solid fa-filter" />
    </button>
    <CQPreLoad :preload="isPreLoad" />
    <b-spinner
      variant="success"
      label="Spinning"
      class="loadingSearch"
      v-if="isLoadingSearch"
    ></b-spinner>
    <b-modal
      id="modal-delete"
      ref="modal-delete"
      modal-class="modal-confirm modal-confirm-sm"
      body-class="p-md-4 p-3"
      hide-footer
      hide-header
      centered
    >
      <div class="d-block text-center title w-100">
        <h1 class="text-warning">
          <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
        </h1>
        <p>
          {{messageModal}}
        </p>
      </div>
      <div
        class="mt-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap"
      >
        <b-button class="btn-bot" id="btn_recommend_condition_hide_modal_delete" v-on:click="$bvModal.hide('modal-delete')">いいえ</b-button>
        <b-button v-if="nameAction.includes('delete')" class="cq-btn-bg-red btn-top" id="btn_recommend_condition_confirm_delete" @click="deleteCondition" :disabled="isDeleting">
          <b-spinner v-if="isDeleting" small />
          はい 
        </b-button>
        <b-button v-if="nameAction.includes('duplicate')" class="cq-btn-bg-green btn-top" id="btn_recommend_condition_confirm_duplicate" @click="duplicateRecommentCar">はい</b-button>
      </div>
    </b-modal>
    <b-modal
      id="conditionSelection"
      ref="conditionSelection"
      modal-class="modal-confirm modal-confirm-xl modal-condition-selection mt-4"
      body-class="p-md-4 p-3"
      hide-footer
      hide-header
      centered
    >
      <div class="d-block text-center title w-100 condition-selection">
        <div class="d-flex w-100">
          <div class="col-auto w-50 text-left">
            <div class="h2">条件選択</div>
          </div>
        </div>
        <div class="condition-selection-title mx-auto">
          <div class="d-flex">
            <div class="w-50 text-left pt-4 h5">
              <template v-if="lengthCondition">
                現在{{lengthCondition >= 10 ? lengthCondition : `${lengthCondition}つの` }}条件が登録されています。
              </template>
              <template v-else>
                リコメンド条件が設定されていません。
              </template>
            </div>
            <div class="col-auto panel-action w-50 text-right pb-3 pt-3 p-0">
               <b-form-select 
                  v-model="conditionOption"
                  :options="optionsCondition"
                  @change="changeCondition"
                  class="stockList-list-header-select w-auto text-center" />
            </div>
          </div>
        </div>
        <div class="condition-selection-content mx-auto d-flex mt-4">
          <div class="condition-selection-content-item ml-md-4 mr-md-4 mt-3 pb-2" v-for="(infos, index) in rec_cond_car_infos" :key="index">
            <div class="d-flex w-100">
              <div class="content-item-title text-left" type-rec-cond="rec_cond_car_info_name" v-b-tooltip="infos.name">
                <span class="w-50 text-truncate">
                  {{infos.name}}    
                </span>
              </div>
              <div class="content-item-btn d-flex w-50 justify-content-end">
                <div class="btn-edit mr-2 mb-2" v-on:click="editRecConditon(infos.id, 'rec_cond_car_infos')">
                  <font-awesome-icon icon="fa-solid fa-pen-to-square" class="icon-large" />
                </div>
                <div class="btn-duplicate mr-2 mb-2" v-on:click="duplicateRecConditon(infos.id, 'rec_cond_car_infos')">
                  <font-awesome-icon icon="fa-solid fa-clone" class="icon-large" />
                </div>
                <div class="btn-delete mr-2 mb-2" v-on:click="deleteRecConditon(infos.id, 'rec_cond_car_infos')">
                  <font-awesome-icon icon="fa-solid fa-trash-can" class="icon-large" />
                </div>
              </div>
            </div>
            <div class="content-item-child text-left p-4" v-bind:class="{ bgChecked: infos.checked }" v-on:click="selectOptionCodition(infos, 'rec_cond_car_info_id', 'changeSelect')">
              <div v-if="infos.brands.length"><label for="">メーカー：</label><span>{{(infos.brands.length) ? infos.brands[0].name : ""}}</span></div>
              <div v-if="infos.cars.length"><label for="">車両名：</label><span>{{(infos.cars.length) ? infos.cars.map(item => item.name).join(',') : ""}}</span></div>
              <div v-if="infos.modelsName"><label for="">型式：</label><span>{{infos.modelsName}}</span></div>
              <div v-if="infos.gradesName"><label for="">グレード：</label><span>{{infos.gradesName}}</span></div>
              <div v-if="infos.year_start_on || infos.year_end_on"><label for="">年式：</label>
                <span>
                  {{renderYear(infos.year_start_on, infos.year_end_on)}}
                </span>
              </div>
              <div v-if="renderColor(infos.colors)"><label>色：</label><span>{{renderColor(infos.colors)}}</span></div>
              <div v-if="renderGear(infos.gears)"><label>シフト：</label><span>{{renderGear(infos.gears)}}</span></div>
              <div v-if="renderDisplacement(infos.displacement_from, infos.displacement_to)"><label>排気量：</label><span>{{renderDisplacement(infos.displacement_from, infos.displacement_to)}}</span></div>
              <div v-if="renderOdo(infos.odo_from, infos.odo_to)"><label>走行距離：</label><span>{{renderOdo(infos.odo_from, infos.odo_to)}}</span></div>
              <div v-if="renderScore(infos.score_from, infos.score_to)"><label>評価点：</label><span>{{renderScore(infos.score_from, infos.score_to)}}</span></div>
            </div>
          </div>
          <div class="condition-selection-content-item ml-md-4 mr-md-4 mt-3 pb-2" v-for="(prices, index) in rec_cond_prices" :key="'prices' + index">
            <div class="d-flex w-100">
              <div class="content-item-title text-left" type-rec-cond="rec_cond_price_name" v-b-tooltip="prices.name">
                <span class="w-50 text-truncate">
                  {{prices.name}}    
                </span>
              </div>
              <div class="content-item-btn d-flex w-50 justify-content-end">
                <div class="btn-edit mr-2 mb-2" v-on:click="editRecConditon(prices.id, 'rec_cond_prices')">
                  <font-awesome-icon icon="fa-solid fa-pen-to-square" class="icon-large" />
                </div>
                <div class="btn-duplicate mr-2 mb-2" v-on:click="duplicateRecConditon(prices.id, 'rec_cond_prices')">
                  <font-awesome-icon icon="fa-solid fa-clone" class="icon-large" />
                </div>
                <div class="btn-delete mr-2 mb-2" v-on:click="deleteRecConditon(prices.id, 'rec_cond_prices')">
                  <font-awesome-icon icon="fa-solid fa-trash-can" class="icon-large" />
                </div>
              </div>
            </div>
            <div class="content-item-child text-left p-4" v-bind:class="{ bgChecked: prices.checked }" v-on:click="selectOptionCodition(prices, 'rec_cond_price_id', 'changeSelect')">
              <div><label for="">価格帯：</label><span>
                {{(prices.from / 10000) | setComma}} {{(prices.from >= 0) ? "万円" : ""}} 
                {{(prices.to >= 0 && prices.from >= 0) ? "〜" : ""}}
                {{(prices.to/ 10000) | setComma}} {{(prices.to >= 0) ? "万円" : ""}}</span></div>
            </div>
          </div>
          <div :class="{'condition-selection-content-item ml-md-4 mr-md-4 mt-3 pb-2': (profits.profit_type == 'aa_bargain' && recCondType == 2) || (profits.profit_type == 'gross_profit' && recCondType != 2)}" v-for="(profits, index) in rec_cond_profits" :key="'profits' + index">
            <template v-if="(profits.profit_type == 'aa_bargain' && recCondType == 2) || (profits.profit_type == 'gross_profit' && recCondType != 2)">
              <div class="d-flex w-100">
                <div class="content-item-title text-left" type-rec-cond="rec_cond_profit_name" v-b-tooltip="profits.name">
                  <span class="w-50 text-truncate">
                    {{profits.name}}    
                  </span>
                </div>
                <div class="content-item-btn d-flex w-50 justify-content-end">
                  <div class="btn-edit mr-2 mb-2" v-on:click="editRecConditon(profits.id, 'rec_cond_profits', profits)">
                    <font-awesome-icon icon="fa-solid fa-pen-to-square" class="icon-large" />
                  </div>
                  <div class="btn-duplicate mr-2 mb-2" v-on:click="duplicateRecConditon(profits.id, 'rec_cond_profits')">
                    <font-awesome-icon icon="fa-solid fa-clone" class="icon-large" />
                  </div>
                  <div class="btn-delete mr-2 mb-2" v-on:click="deleteRecConditon(profits.id, 'rec_cond_profits')">
                    <font-awesome-icon icon="fa-solid fa-trash-can" class="icon-large" />
                  </div>
                </div>
              </div>
              <div class="content-item-child text-left p-4" v-bind:class="{ bgChecked: profits.checked }" v-on:click="selectOptionCodition(profits, 'rec_cond_profit_id', 'changeSelect')">
                <div><label for="">{{profits.profit_type == 2 ? "AA割安価格" : "粗利"}}：</label><span>
                  {{(profits.from / 10000) | setComma}} {{(profits.from >= 0) ? "万円" : ""}} 
                  {{(profits.to >= 0 && profits.from >= 0) ? "〜" : ""}}
                  {{(profits.to / 10000) | setComma}} {{(profits.to >= 0) ? "万円" : ""}}</span></div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div
        class="mt-5 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-center w-100 col-md-8 col-12 mx-auto"
      >
        <div class="col-6">
          <b-button class="btn-bot w-100 mr-4" @click="$bvModal.hide('conditionSelection')" id="btn_recommend_condition_hide_condition_selection">いいえ</b-button>
        </div>
        <div class="col-6">
          <b-button class="cq-btn-bg-green btn-top w-100" :disabled="checkBtnAddGroup" @click="createRecommentGroup()" id="btn_recommend_condition_check_condition">はい</b-button >
        </div>
      </div>
    </b-modal>
    <b-modal
        id="alertMessage"
        ref="alertMessage"
        modal-class="modal-confirm modal-confirm-sm modal-alert-messager"
        body-class="p-md-4 p-3"
        hide-footer
        centered
      >
        <div class="d-block text-center title w-100 title-content">
          <p>ご希望の車両条件を設定する事が出来ます。</p>
          <p>いずれかの条件をご指定ください。</p>
          <p class="text-danger">※保存後に変更・追加・削除は出来ますのでご安心ください。</p>
        </div>
        <div
          class="mt-3 mb-4 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex"
        >
          <b-button id="vehicle-type-conditions" class="btn-bot cq-btn-bg-orange mr-3" @click="$router.push('/recommend-condition/car-info/new')">車種条件</b-button>
          <b-popover
            target="vehicle-type-conditions"
            triggers="hover focus"
            placement="bottomright"
          >
            <p>メーカーや車名など車両の情報を条件に設定可能です。</p>
          </b-popover>
          <b-button id="price-range" class="btn-bot cq-btn-bg-orange mr-3" @click="$router.push('/recommend-condition/car-price/new')">価格帯</b-button>
          <b-popover
            target="price-range"
            triggers="hover focus"
            placement="bottomright"
          >
            <p>AI価格予測によって期待される仕入れ価格の価格帯を指定可能です。</p>
          </b-popover>
          <b-button id="gross-profit-conditions" class="btn-bot cq-btn-bg-orange" @click="$router.push('/recommend-condition/car-profit/new')">粗利条件</b-button>
          <b-popover
            target="gross-profit-conditions"
            triggers="hover focus"
            placement="bottomright"
          >
            <p>AI価格予測とAI市場価格予測と陸送費によって期待される粗利を条件として指定可能です。</p>
          </b-popover>
        </div>
      </b-modal>
  </div>
</template>
<script>
import "@/assets/scss/recommend-condition/list.scss";
import CQConditionGroup from "@/components/recommend-condition/group/list/CQConditionGroup.vue";
import CQPreLoad from "@/components/CQPreLoad.vue";
import { Message } from '@/utils/message'
import { convertNumber } from "@/utils/commonEvent";
import { listOdoFrom, listOdoTo } from "@/utils/odoList"
import { SCORE_OBJ } from "@/utils/score";
import listPrice from "@/utils/priceList"
import CQOptionsRadioSmall from "@/components/CQOptionsRadioSmall.vue";
import CQFormGroupSelectCar from "@/components/CQFormGroupSelectCar.vue";
import draggable from 'vuedraggable'
import CQDraggableDots from "@/components/CQDraggableDots.vue";

export default {
  layout: "user",
  auth: "user",
  mixins: [convertNumber],
  head() {
    return {
      title: "リコメンド条件",
      meta: [
        { charset: "utf-8" },
        {
          name: "viewport",
          content:
            "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0",
        },
      ],
    };
  },
  components: {
    CQConditionGroup,
    CQPreLoad,
    CQOptionsRadioSmall,
    CQFormGroupSelectCar,
    draggable,
    CQDraggableDots,
},
  data() {
    return {
      listCondition: [],
      messageDate: Message.LABLE_DATE,
      message: Message.RECOMMEND_CONDITION,
      description: "同様の条件で 過去１ヶ月で1,300件リコメンドしました",
      showContentSearch: false,
      carNames: [{
        value: null,
        text: '車名'
      }],
      carBrands: [{
        value: null,
        text: 'メーカー'
      }],
      grades: [{
        value: null,
        text: 'グレード'
      }],
      models: [{
        value: null,
        text: '型式'
      }],
      isPreLoad: true,
      isLoadingSearch: false,
      dataSearch: {
        core_brand_id: null,
        core_car_id: null,
        core_grade_id: null,
        core_grade_ids: [],
        model: null,
        core_model_ids: [],
        min_prices: null,
        max_prices: null,
        from_produced_on: null,
        to_produced_on: null,
        min_profits: null,
        max_profits: null,
        min_odo: null,
        max_odo: null,
      },
      currentTab: 0,
      queueGroups: [],
      isEditNameGroup: false,
      currentDeleting: {},
      rec_cond_car_infos: [],
      rec_cond_prices: [],
      rec_cond_profits: [],
      optionsCondition: [
        { value: "total", text: "全て" },
        { value: "rec_cond_car_infos", text: "車種条件" },
        { value: "rec_cond_prices" , text: "価格帯条件"},
        { value: "rec_cond_profits" , text: "粗利条件"}
      ],
      optionsRecondTypeCarPrice: [
        {
          value: 1,
          text: '市場価格との価格差が大きい流れ車両'
        },{
          value: 2,
          text: 'オークション相場よりも安く購入する車両'
        }
      ],
      conditionOption: "total",
      isDeleting: false,
      lengthCondition: 0,
      nameAction: "",
      recommentCarParam: {},
      messageModal: "",
      existOption: [],
      recCondType: null,
      indexQueueGroups: 0,
      flagUpdateCondition: true,
      checkBtnAddGroup: true,
      listOdoFrom,
      listOdoTo,
      minPrice: [
        {
          text: '下限価格（万円）',
          value: null
        },
        ...listPrice
      ],
      maxPrice: [
        {
          text: '上限価格（万円）',
          value: null
        },
        ...listPrice
      ],
      minProfit: [
        {
          text: '下限粗利（万円）',
          value: null
        },
        ...listPrice
      ],
      maxProfit: [
        {
          text: '上限粗利（万円）',
          value: null
        },
        ...listPrice
      ],
      coreYearsFrom: [{
        value: null,
        text: "下限年式"
      }],
      coreYearsTo: [{
        value: null,
        text: "上限年式"
      }]
    };
  },
  computed: {
    countCondition() {
      return this.listCondition.length || 0;
    },
  },
  async mounted() {
    await this.showModalConditon();
    if (!this.rec_cond_car_infos.length && !this.rec_cond_prices.length 
      && !this.rec_cond_profits.length) this.$bvModal.show('alertMessage');
    let indexGroup = localStorage.getItem("indexGroup");
    if (indexGroup) {
      setTimeout(() => {
        const targeGroup = document.querySelectorAll(".group-select .row-group")[indexGroup];
        this.showModalUpdateConditon(this.listCondition[indexGroup], targeGroup);
        this.indexQueueGroups = Number(indexGroup);
        this.flagUpdateCondition = true;
        localStorage.removeItem("indexGroup");
      }, "500")
    }
  },
  methods: {
    toogleContentSearch() {
      this.showContentSearch = !this.showContentSearch;
    },
    async fetchData() {
      this.isEditNameGroup = false;
      const params = { ...this.dataSearch };
      for (const key in params) {
        if (params[key] == "") {
          params[key] = null;
        }
      }
      params["rec_cond_type"] = this.recCondType;
      this.isLoadingSearch = true;
      const res = await this.$store.dispatch("searchRecommendCondition", {
        params,
      });
      this.isLoadingSearch = false;
      this.listCondition = res.data.rec_conds;
      this.listCondition = this.listCondition.map((condition) => {
        const newCondition = {
          conditionGroup: {...condition},
          queueGroups: {
            itemGroup: {
              name: condition.name,
              rec_cond_type: this.recCondType,
              rec_cond_car_info_id: condition.rec_cond_car_info_id,
              rec_cond_price_id: condition.rec_cond_price_id,
              rec_cond_profit_id: condition.rec_cond_profit_id,
              rec_cond_profit_type: condition.rec_cond_profit_type_value ?? 1
            },
            nameGroup: {
              rec_cond_all: ((condition.rec_cond_car_info) && (condition.rec_cond_price) && (condition.rec_cond_profit)) ? false : true,
              rec_cond_btn_show: false,
              rec_cond_show: false,
              loadingBtn: false,
            }
          }
        }
        const itemGroup = {
          name: condition.name,
          rec_cond_type: this.recCondType,
          rec_cond_car_info_id: condition.rec_cond_car_info_id,
          rec_cond_price_id: condition.rec_cond_price_id,
          rec_cond_profit_id: condition.rec_cond_profit_id,
        }
        itemGroup.rec_cond_profit_type = newCondition.conditionGroup.rec_cond_profit_type = condition.rec_cond_profit_type_value ?? 1
        return newCondition
      })
    },
    async fetchStock() {
      const dataMaster = this.$store.getters.carMap
      await dataMaster.loader
      this.coreYearsFrom = [this.coreYearsFrom[0], ...dataMaster.years]
      this.coreYearsTo = [this.coreYearsTo[0], ...dataMaster.years]
    },
    toCreatePage (slug) {
      let params = {}
      if(this.recCondType == 2){
        params = {
          type: this.recCondType,
        }
      }
      this.$router.push({path: `/recommend-condition/${slug}/new`, query: params});
    },
    addGroupCondition () {
      this.listCondition.push({
        conditionGroup:{},
        queueGroups: {
          itemGroup: {
            name: "グループ名",
            rec_cond_type: this.recCondType,
            rec_cond_car_info_id: null,
            rec_cond_price_id: null,
            rec_cond_profit_id: null,
            rec_cond_profit_type: 1,
          },
          nameGroup: {
            rec_cond_all: true,
            rec_cond_btn_show: true,
            rec_cond_show: true,
            loadingBtn: false,
          }
        }
      })
    },
    isNumber (evt) {
      if (Number(evt) && evt.indexOf('.') > -1) {
        return true;
      }
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    fadeBlur (condition) {
      setTimeout(() => {
        this.isEditNameGroup = false
        this.updateNameGroup(condition)
      },500)
    },
    setCurrentDelete (condition) {
      this.currentDeleting = condition
      this.messageModal = this.message.DELETE;
    },
    editRecConditon(id, typeRecCond, profits) {
      let params = {}
      if(profits?.profit_type == 2) {
        params.type = 2
      }
      localStorage.setItem("indexGroup", this.indexQueueGroups);
      if (typeRecCond === "rec_cond_car_infos") this.$router.push(`/recommend-condition/car-info/${id}/edit`);
      if (typeRecCond === "rec_cond_prices") this.$router.push(`/recommend-condition/car-price/${id}/edit`);
      if (typeRecCond === "rec_cond_profits") this.$router.push({path: `/recommend-condition/car-profit/${id}/edit`, query: params});
    },
    duplicateRecConditon(id, typeRecCond) {
      this.messageModal = this.message.DUPLICATE;
      this.nameAction = "duplicateCarConditon";
      this.$bvModal.show('modal-delete');
      this.recommentCarParam = {
        id: id,
        path: typeRecCond
      };
    },
    deleteRecConditon(id, typeRecCond) {
      this.nameAction = "deleteCarConditon";
      this.messageModal = this.message.DELETE;
      this.$bvModal.show('modal-delete');
      this.recommentCarParam = {
        id: id,
        path: typeRecCond
      };
    },
    async deleteCondition() {
      this.isDeleting = true
      if (this.nameAction === "deleteAddGroup") {
        this.listCondition.splice(this.indexQueueGroups, 1);
      } else if (this.nameAction === "deleteCurrent") {
        const id = this.currentDeleting.id;
        const data = await this.$store.dispatch('deleteRecommendCondition', {id})
        if(data.success){
          this.$toast.success(data.message)
        }
        else {
          if(data.errors && data.errors[0]){
            this.$toast.error(data.errors[0].message)
          }
        }
        this.fetchData()
      } else {
        const data = await this.$store.dispatch('deleteRecommendConditionCar', this.recommentCarParam)
        if (data.success) {
          this.$toast.success(data.message)
          this.showModalConditon();
        } else {
          if (data.errors && data.errors[0]) {
            this.$toast.error(data.errors[0].message)
          }
        }
      }
      this.$bvModal.hide('modal-delete')
      this.isDeleting = false
    },
    async duplicateRecommentCar() {
      const data = await this.$store.dispatch('duplicateRecommendConditionCar', this.recommentCarParam)
      if (data.success){
        this.$toast.success(data.message);
        this.showModalConditon();
        this.$bvModal.hide('modal-delete');
      } else {
        if(data.errors && data.errors[0]){
          this.$toast.error(data.errors[0].message)
        }
      }
    },
    async changeCondition() {
      if (this.conditionOption === "total") {
        this.showModalConditon();
      } else {
        const params = {
          filter: this.conditionOption,
        };
        const changeCondition = await this.$store.dispatch('conditionSelections', { params } );
        if (changeCondition.success) {
          this.rec_cond_car_infos = [];
          this.rec_cond_prices = [];
          this.rec_cond_profits = [];
          this[this.conditionOption] = changeCondition.data[this.conditionOption];
          this.lengthCondition = this[this.conditionOption].length;
        } else {
          if (changeCondition.errors.length && changeCondition.errors[0].message) this.$toast.error(changeCondition.errors[0].message);
        };
      }
    },
    selectOptionCodition(dataOption, action, changeSelect) {
      const keyUpdate = action.replace("_id", "s");
      this[keyUpdate] = this[keyUpdate].map((option) => {
        let checked = false
        if (dataOption.id === option.id) checked = !option.checked;
        if (checked) this.listCondition[this.indexQueueGroups].queueGroups.itemGroup[action] = dataOption.id;
        return {
        ...option,
        checked: checked
        }
      })
      if (changeSelect) {
        const active = this[keyUpdate].find(option => option.checked === true);
        if (!active) {
          this.listCondition[this.indexQueueGroups].queueGroups.itemGroup[action] = null;
          const keyName = action.replace("_id", "_name");
          this.listCondition[this.indexQueueGroups].queueGroups.nameGroup[keyName] = null;
        }
      }
      const listOptionCondition = [
        ...this.rec_cond_car_infos,
        ...this.rec_cond_prices,
        ...this.rec_cond_profits
      ]
      const activeList = listOptionCondition.find(option => option.checked === true);
      (activeList) ? this.checkBtnAddGroup = false : this.checkBtnAddGroup = true;
      if (!this.existOption.includes(action)) this.existOption.push(action);
    },
    async showModalConditon(condition, $event, action) {
      if (action) condition.queueGroups.nameGroup.loadingBtn = true;
      const params = {};
      const conditionSelections = await this.$store.dispatch('conditionSelections', { params });
      if (conditionSelections.success) {
        this.rec_cond_car_infos = conditionSelections.data.rec_cond_car_infos.map((carInfos) => {
          return {
          ...carInfos,
          checked: false
          }
        })
        this.rec_cond_prices = conditionSelections.data.rec_cond_prices.map((carPrices) => {
          return {
          ...carPrices,
          checked: false
          }
        })
        this.rec_cond_profits = conditionSelections.data.rec_cond_profits.map((carProfits) => {
          return {
          ...carProfits,
          checked: false
          }
        })
        this.lengthCondition = this.rec_cond_car_infos.length + this.rec_cond_prices.length + this.rec_cond_profits.length;
        const listOptionCondition = [
          ...this.rec_cond_car_infos,
          ...this.rec_cond_prices,
          ...this.rec_cond_profits
        ]
        const activeList = listOptionCondition.find(option => option.checked === true);
        (activeList) ? this.checkBtnAddGroup = false : this.checkBtnAddGroup = true;
        this.rec_cond_car_infos.forEach(infos => {
            const groupGrades = this.lodash.uniqBy(infos.grades, (grade) => grade ? grade.name : null)
            infos["gradesName"] = "";
            groupGrades.forEach(grades => {
              if(grades) {
                (!infos.gradesName) ? (infos.gradesName = grades.name) : (infos.gradesName += "・" + grades.name); 
              }
            });
            infos["modelsName"] = infos.models.map((model) => model.name).join()
        });
        let conditionIdGroup = ['rec_cond_car_info_id', 'rec_cond_price_id', 'rec_cond_profit_id'];
        let flag = true;
        if (!this.flagUpdateCondition && condition) {
          conditionIdGroup.forEach(id => {
            if (flag) {
              if (condition.conditionGroup[id] && action) {
                this.flagUpdateCondition = true;
                this.showModalUpdateConditon(condition, $event);
                flag = false;
              } else this.flagUpdateCondition = false;
            }
          });
        }
        if (condition) {
          this.$refs['conditionSelection'].show();
          condition.queueGroups.nameGroup.loadingBtn = false;
        }
      } else {
        if (conditionSelections.errors.length && conditionSelections.errors[0].message) this.$toast.error(conditionSelections.errors[0].message);
      };
    },
    async showModalUpdateConditon(condition, $event) {
      if ($event.target && !$event.target.parentElement.parentNode.classList.contains("row-group") && 
        !$event.target.parentElement.parentNode.classList.contains("list-btn")) {
        return;
      } else {
        await this.showModalConditon(condition, $event);
        if (condition.conditionGroup.rec_cond_car_info) this.selectOptionCodition(condition.conditionGroup.rec_cond_car_info, 'rec_cond_car_info_id')
        if (condition.conditionGroup.rec_cond_price) this.selectOptionCodition(condition.conditionGroup.rec_cond_price, 'rec_cond_price_id')
        if (condition.conditionGroup.rec_cond_profit) this.selectOptionCodition(condition.conditionGroup.rec_cond_profit, 'rec_cond_profit_id') 
        this.$refs['conditionSelection'].show();
      }
    },
    async updateNameGroup(condition, mode) {
      if(mode == 'draft') {
        if(!condition.queueGroups.itemGroup.name) {
          this.$toast.error(this.message.NAME_REQUIRED)
        }
        return
      }
      if(!Object.hasOwnProperty.call(condition.conditionGroup, "name")) {
        return
      }
      else if(!condition.conditionGroup.name) {
        this.$toast.error(this.message.NAME_REQUIRED)
        return
      }
      if (condition.queueGroups.itemGroup.name !== condition.conditionGroup.name) {
        const params = condition.queueGroups.itemGroup;
        params.name = condition.conditionGroup.name;
        params['id'] = condition.conditionGroup.id;
        const updateRecommentGroup = await this.$store.dispatch('updateRecommentGroup', params);
        if (updateRecommentGroup.success) {
          this.$toast.success(updateRecommentGroup.message);
          this.fetchData();
        } else {
          if (updateRecommentGroup.errors.length && updateRecommentGroup.errors[0].message) this.$toast.error(updateRecommentGroup.errors[0].message);
        };
      }
      this.isEditNameGroup = false
    },
    async updateRecommendGroup (params, rec_cond_type) {
      const body = {}
      body.id = params.id
      body.rec_cond_profit_type = params.rec_cond_profit_type
      body.rec_cond_car_info_id = params.rec_cond_car_info_id
      body.rec_cond_price_id = params.rec_cond_price_id
      body.rec_cond_profit_id = params.rec_cond_profit_id
      body.rec_cond_type = rec_cond_type
      body.nam = params.name
      const updateRecommentGroup = await this.$store.dispatch('updateRecommentGroup', body);
      if (updateRecommentGroup.success) {
        this.$toast.success(updateRecommentGroup.message);
        this.fetchData();
        this.$refs['conditionSelection'].hide();
      } else {
        if (updateRecommentGroup.errors.length && updateRecommentGroup.errors[0].message) this.$toast.error(updateRecommentGroup.errors[0].message);
      };
    },
    async createRecommentGroup() {
      const lengthOption = document.getElementsByClassName("condition-selection-content-item");
      this.listCondition[this.indexQueueGroups].queueGroups.nameGroup.rec_cond_show = false;
      this.listCondition[this.indexQueueGroups].queueGroups.nameGroup.rec_cond_btn_show = false;
      for (let index = 0; index < lengthOption.length; index++) {
        const checkedOption = lengthOption[index].querySelectorAll(".bgChecked");
        let titleOption = lengthOption[index].querySelectorAll(".content-item-title")[0];
        let nameAttrOption = titleOption.getAttribute("type-rec-cond");
        if (checkedOption.length) {
          this.listCondition[this.indexQueueGroups].queueGroups.nameGroup[nameAttrOption] = titleOption.textContent;
        }
      }
      if (this.flagUpdateCondition) {
        const params = this.listCondition[this.indexQueueGroups].queueGroups.itemGroup;
        params['id'] = this.listCondition[this.indexQueueGroups].conditionGroup.id;
        if(this.recCondType == 2) params.rec_cond_profit_type = 2
        if (!params.rec_cond_car_info_id && !params.rec_cond_price_id && !params.rec_cond_profit_id) {
          this.nameAction = "deleteCurrent";
          this.currentDeleting = this.listCondition[this.indexQueueGroups].conditionGroup;
          this.deleteCondition();
          this.$refs['conditionSelection'].hide();
        } else {
          const updateRecommentGroup = await this.$store.dispatch('updateRecommentGroup', params);
          if (updateRecommentGroup.success) {
            this.$toast.success(updateRecommentGroup.message);
            this.fetchData();
            this.$refs['conditionSelection'].hide();
          } else {
            if (updateRecommentGroup.errors.length && updateRecommentGroup.errors[0].message) this.$toast.error(updateRecommentGroup.errors[0].message);
          };
        }
      } else {
        const params = this.listCondition[this.indexQueueGroups].queueGroups.itemGroup
        if(this.recCondType == 2) params.rec_cond_profit_type = 2
        const createRecommentGroup = await this.$store.dispatch('createRecommentGroup', params);
        if (createRecommentGroup.success) {
          this.$toast.success(createRecommentGroup.message);
          await this.fetchData()
          this.changePriorityRecCond(false)
          this.$refs['conditionSelection'].hide();
        } else {
          this.$refs['conditionSelection'].hide();
          this.listCondition.pop();
          if (createRecommentGroup.errors.length && createRecommentGroup.errors[0].message) this.$toast.error(createRecommentGroup.errors[0].message);
        };
      }
    },
    async changePriorityRecCond (showMessage = true) {
      const data = {
        ids: this.getListIdRecCond()
      }
      const updateRecommentGroup = await this.$store.dispatch('changePriorityRecCond', { data, });
      if (updateRecommentGroup.success) {
        if(showMessage) this.$toast.success(updateRecommentGroup.message);
        this.fetchData();
      } else {
        if (updateRecommentGroup.errors.length && updateRecommentGroup.errors[0].message) this.$toast.error(updateRecommentGroup.errors[0].message);
      };
    },
    getListIdRecCond() {
      return this.listCondition.map(({ conditionGroup }) => {
        return conditionGroup?.id
      })
    },
    resetForm() {
      Object.keys(this.dataSearch).forEach(key => {
        this.dataSearch[key] = null;
      });
      this.dataSearch.rec_cond_profit_type = 1
      this.fetchData();
    },
    renderColor(colorArr) {
      return colorArr.map((_) => _.name).join(", ")
    },
    renderGear (gears) {
      return gears.map((gear) => gear.name).join(", ")
    },
    renderDisplacement (displacement_from, displacement_to) {
      if(displacement_from || displacement_to) {
        return `${displacement_from ?? ""} 〜 ${displacement_to ?? ""}`
      }
      return ""
    },
    renderOdo(odo_from, odo_to) {
      if(odo_from || odo_to){
        let textOdoFrom
        if(odo_from) textOdoFrom = listOdoFrom.find((odo) => odo.value == odo_from)?.text ?? ""
        let textOdoTo
        if(odo_to) textOdoTo = listOdoFrom.find((odo) => odo.value == odo_to)?.text ?? ""
        return `${textOdoFrom ?? ""} 〜 ${textOdoTo ?? ""}`
      }
      return ""
    },
    renderScore(score_from, score_to) {
      if(score_from || score_to){
        return `${SCORE_OBJ[score_from] ?? ""} 〜 ${SCORE_OBJ[score_to] ?? ""}`
      }
      return ""
    },
    renderYear(year_start_on, year_end_on) {
      let yearStart = ""
      let yearEnd = ""
      if(year_start_on) {
        for (const yearItem of this.coreYearsFrom) {
          const year = new Date(yearItem.value)
          const yearStartOn = new Date(year_start_on)
          if(year.getFullYear() == yearStartOn.getFullYear()) {
            yearStart = yearItem.text
            break;
          }
        }
      }
      if(year_end_on) {
        for (const yearItem of this.coreYearsFrom) {
          const year = new Date(yearItem.value)
          const yearEndOn = new Date(year_end_on)
          if(year.getFullYear() == yearEndOn.getFullYear()) {
            yearEnd = yearItem.text
            break;
          }
        }
      }
      yearStart = yearStart.replace(/^(\d{4})/,"$1年")
      yearEnd = yearEnd.replace(/^(\d{4})/,"$1年")
      return `${yearStart} 〜 ${yearEnd}`
    }
  },
  watch: {
    recCondType () {
      this.resetForm();
    },
  },
  async created() {
    this.recCondType = 1;
    await this.fetchData();
    await this.fetchStock();
    this.isPreLoad = false;
  },
};
</script>
