<template>
  <div v-if="showSucces" class="cq-done d-flex justify-content-center align-items-center">
    <div class="cq-done-box">
      <div class="vcheck d-flex justify-content-center align-items-center mb-3">
        <img src="@/assets/img/check.svg" alt="check" />
      </div>
      <div class="cq-done-title text-center">メールアドレスを変更しました。</div>
      <b-button to="/login" class="cq-btn-bg-green w-100 d-flex align-items-center justify-content-center mt-5" id="btn_mypage_profile_change_mail_confirm">閉じる</b-button>
      <hr class="mt-5">
    </div>
  </div>
</template>

<script>
import "@/assets/scss/password/reset/done.scss";
import { ref, getDatabase } from 'firebase/database'
import { signInWithEmailAndPassword, getAuth } from 'firebase/auth'

export default {
  name: "changeMail",
  layout: "main",
  auth: false,
  head() {
    return {
      title: "メールアドレスを変更",
    };
  },
  data() {
    return {
      showSucces: false,
      userId: localStorage.getItem("userId")
    };
  },
  computed: {
    currentEmail () {
      return this.$route.query.email
    }
  },
  async created() {
    const formData = {
      email: this.currentEmail,
      token_change_email: this.$route.params.token,
    }
    const res = await this.$store.dispatch('completeChangeEmailProfile', formData)
    if (res.success) {
      this.showSucces = true;
      const email = this.currentEmail
      const user = res.data.user
      const userId = user.id
      const { email_firebase, password_firebase } = user
      const auth = getAuth(this.$firebase);
      signInWithEmailAndPassword(auth, email_firebase, password_firebase)
      .then(() => {
        const refUser = ref(getDatabase(this.$firebase), `conversations/${userId}/user_info`)
        this.$store.commit('set', ['refUserInfoFirebase', refUser])
        this.$store.dispatch("updateProfileUserFirebase", {
          profileData: {
            email
          }
        })
      })
      .catch((error) => {
        this.$toast.error(error.message)
        // this.$router.push('/logout')
      });
    } else {
      const message = res.errors.length !== 0 ? res.errors[0].message : ''
      if(message) this.$toast.error(message)
    }
  },
};
</script>
