<template>
  <div class="cq-login d-flex justify-content-center align-items-center">
    <div class="cq-login-box">
      <div class="cq-login-title text-center">会員ログイン</div>
      <div class="cq-login-desc text-center mt-3">メールアドレスとパスワードをご入力ください</div>
      <form class="cq-login-form mt-4" v-on:submit.stop.prevent="handleLogin">
        <div class="position-relative">
          <b-input id="input-email" autocomplete="username" placeholder="メールアドレス" v-model.trim="$v.formData.email.$model"
            :state="$v.formData.email.$error ? false : null" />
          <div v-if="$v.formData.email.$error" class="position-absolute input-error">
            <span v-if="!$v.formData.email.required">{{ message.ERROR_REQUIRE }}</span>
            <span v-else-if="!$v.formData.email.email">{{ message.EMAIL_INCORRECT }}</span>
          </div>
        </div>
        <div class="position-relative mt-4">
          <b-input id="input-password" autocomplete="current-password" placeholder="パスワード" type="password"
            v-model="$v.formData.password.$model" :state="$v.formData.password.$error ? false : null" />
          <div v-if="$v.formData.password.$error" class="position-absolute input-error">
            <span v-if="!$v.formData.password.required">{{ message.ERROR_REQUIRE }}</span>
          </div>
        </div>
        <b-button :disabled="isLoading" id="btn-login" class="mt-4 cq-btn-bg-green" type="submit">
          <b-spinner v-if="isLoading" small />
          ログイン
        </b-button>
      </form>
      <p class="mt-1">パスワードをお忘れの場合は <router-link id="link-forgot" class="cq-link-green"
          to="/forgot/input">こちら</router-link></p>
      <p class="mt-4"><router-link id="link-signup" class="cq-link-green" to="/signup">新規会員登録</router-link></p>
    </div>
    <b-modal id="modalAlertLogin" ref="modalAlertLogin" modal-class="modal-confirm modal-confirm-sm"
      body-class="p-md-4 p-3" hide-footer hide-header centered>
      <div class="d-block text-center title w-100">
        <div>
          <div style="position: absolute; top: 20px; right: 20px" @click="$bvModal.hide('modalAlertLogin')">
            <font-awesome-icon icon="fa-solid fa-xmark" class="icon-xl" style="fill: var(--gray)" />
          </div>
        </div>
        <div class="px-4 mt-4 py-4">
          <h6>
            {{ messageAlert }}
          </h6>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import '../assets/scss/auth/login.scss'
import { required, email } from 'vuelidate/lib/validators'
import { Message } from '../utils/message'

export default {
  name: "login",
  layout: "main",
  auth: false,
  head() {
    return {
      title: "ログイン",
    };
  },
  data() {
    return {
      formData: {
        email: null,
        password: null
      },
      isLoading: false,
      message: Message.LOGIN,
      redirectURL: this.$route.query.redirect_url,
      redirectMode: this.$route.query.mode,
    }
  },
  computed: {
    canRedirect() {
      const siteHostName = window.location.hostname
      if (!this.redirectURL) return false
      if (this.redirectMode === 'router') return true
      const redirectURL = new URL(this.redirectURL)
      const redirectHostName = redirectURL.hostname
      return siteHostName === redirectHostName
    },
    messageAlert() {
      const alertMessage = this.$route.query.alert_message
      if (alertMessage && this.message[alertMessage]) {
        return this.message[alertMessage]
      }
      return false
    }
  },
  methods: {
    async handleLogin() {
      this.$toast.clear()
      this.$v.$touch()
      const result = !this.$v.$error
      if (result) {
        this.isLoading = true
        const data = await this.$store.dispatch('login', this.formData)
        if (data.success) {
          this.isLoading = false
          localStorage.setItem('token_user', data.data.token)
          localStorage.setItem('auth_user', 'user')
          localStorage.setItem('userId', data.data.user.id)
          if (this.canRedirect) {
            if (this.redirectMode === "router") this.$router.push(this.redirectURL)
            else window.location.href = this.redirectURL;
            return
          }
          this.$router.push('/mypage')
        } else {
          if (data.errors.length && data.errors[0].message) this.$toast.error(data.errors[0].message)
          this.isLoading = false
        }
      }
    },
  },
  mounted() {
    if (this.messageAlert) {
      this.$bvModal.show("modalAlertLogin")
    }
  },
  validations() {
    return {
      formData: {
        email: { required, email },
        password: { required }
      }
    }
  }
};
</script>
