const listYear = [
  { value: null, text: "年式" }
]
const convertYear = (coreYears, dateStr) => {
  if(dateStr) {
    const yearDate = coreYears.find((year) => {
      const coreYeardate = new Date(year.value)
      const date = new Date(dateStr)
      return date.getFullYear() == coreYeardate.getFullYear()
    })
    const year = yearDate?.text
    return year
  }
}
export default listYear
export {
  listYear,
  convertYear
}