<template>
  <div class="cq-layout-main">
    <CQHeaderKiyaku />
    <div class="cq-content">
      <slot />
    </div>
    <CQFooter />
  </div>
</template>

<script>
import CQHeaderKiyaku from "@/components/CQHeaderKiyaku.vue";
import baseLayout from '@/utils/mixinsLayout'
import CQFooter from "@/components/CQFooter.vue";

export default {
  components: {
    CQHeaderKiyaku,
    CQFooter,
  },
  mixins: [ baseLayout ]
};
</script>

<style lang="scss" scoped>
.cq-layout-main {
  padding-top: 70px;
}
</style>
