<template>
  <div>
    <div class="row">
      <div class="col-lg-6 col-12">
        <div class="row">
          <div class="col col-mobile mt-0">
            <ModelSelectBrands :options="brands" v-model="value.core_brand_id" :is-convert2-full-width="true" />
          </div>
          <div class="col-auto col-span"></div>
          <div class="col col-mobile">
            <model-select
              v-model="value.core_car_id"
              :options="cars"
              id="core_car_id"
              :is-convert2-full-width="true"
            >
            </model-select>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-12 pt-sm-3 pt-lg-0">
        <div class="row">
          <div class="col col-mobile" @click="setOptionTop($event)">
            <model-select
              v-model="value.model"
              :options="models"
              id="model">
            </model-select>
          </div>
          <div class="col-auto col-span"></div>
          <div class="col col-mobile" @click="setOptionTop($event)">
            <model-select
              v-model="value.core_grade_id"
              :options="grades"
              :is-convert2-full-width="true"
              id="core_grade_id">
            </model-select>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { setOptionTop } from "@/utils/commonEvent"
import ModelSelectBrands from './ModelSelectBrands.vue'
export default {
  components: {
    ModelSelectBrands
  },
  props: {
    value: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      brands: [{
        text: "メーカー",
        value: null
      }],
      cars: [{
        value: null,
        text: '車名'
      }],
      grades: [{
        value: null,
        text: 'グレード'
      }],
      models: [{
        value: null,
        text: '型式'
      }],
      groupByGrade: null,
    }
  },
  computed: {
    dataMaster () {
      return this.$store.getters.carMap
    }
  },
  watch: {
    "value.core_brand_id"(newId) {
      if (newId) {
        this.fetchCarList(newId);
      }
      this.value.core_car_id = null
      this.value.core_grade_id = null
      this.cars = [this.cars[0]];
      this.grades = [this.grades[0]]
    },
    "value.core_car_id"(newId) {
      if (newId) {
        this.fetchCarModel(newId);
      }
      this.value.model = null
      this.models = [this.models[0]]
    },
    "value.model"(newId) {
      if (newId) {
        this.fetchCarGrade(newId);
        this.value.core_model_ids = [newId]
      }
      else {
        this.fetchCarModel(this.value.core_car_id);
        this.value.core_model_ids = []
      }
      this.value.core_grade_id = null
      this.grades = [this.grades[0]]
    },
    "value.core_grade_id" (newId) {
      const grade = this.grades.find((grade) => grade.value === newId)
      if(grade) {
        const grades_id = this.groupByGrade[grade.text] ?? []
        this.value.core_grade_ids = grades_id.map((grade) => grade.value)
      }
      else {
        this.value.core_grade_ids = []
      }
    }
  },
  methods: {
    async fetchCarList(newId) {
      const res = await this.$store.dispatch(
        "getDataCarName",
        newId
      );
      this.cars = [this.cars[0], ...res.data.cars.map((car) => {
        return {
          value: car.id,
          text: car.name
        }
      })];
    },
    async fetchCarModel(newId) {
      const res = await this.$store.dispatch(
        "getDataModel",
        newId
      );
      this.models = [this.models[0], ...res.data.models.map((model) => {
        return {
          value: model.id,
          text: model.name
        }
      })]
      const gradesRes = res.data.grades.map((grade) => {
        return {
          value: grade.id,
          text: grade.name
        }
      })
      const grades = this.lodash.uniqBy(gradesRes, (grade) => grade.text)
      this.groupByGrade = this.lodash.groupBy(gradesRes, (grade) => grade.text)
      this.grades = [this.grades[0], ...grades]
    },
    async fetchCarGrade(newId) {
      const res = await this.$store.dispatch(
        "getDataGrade",
        newId
      );
      this.grades = [this.grades[0], ...res.data.grades.map((grade) => {
        return {
          value: grade.id,
          text: grade.name
        }
      })]
    },
    async initData () {
      const dataMaster = this.dataMaster
      await dataMaster.loader
      this.brands = [this.brands[0], ...dataMaster.brands.map((brand) => {
        return {
          value: brand.id,
          text: brand.name,
          core_country_id: brand.core_country_id
        }
      })];
    },
    setOptionTop
  },
  created () {
    this.initData()
  }
}
</script>