<template>
  <div
    class="ui fluid search selection dropdown"
    :class="{ 'active visible':showMenu && !menuTop, 'visible active-top': showMenu && menuTop, 'error': isError, 'disabled': isDisabled }"
    @click="handleOpenOptions"
    @focus="handleOpenOptions"
    ref="dropdown"
    :style="styleSearch"
  >
    <i class="dropdown icon"></i>
    <input
      class="search"
      :tabindex="isDisabled ? -1 : 0"
      autocomplete="off"
      :id="id"
      :name="name"
      :value="searchText"
      @input="searchText = convertToFullWidth($event.target.value)"
      ref="input"
      @focus.prevent="handleOpenOptions"
      @keyup.esc="handleCloseOptions"
      @blur="handleBlurInput"
      @keydown.up="prevItem"
      @keydown.down="nextItem"
      @keydown.enter.prevent=""
      @keyup.enter.prevent="enterItem"
      @keydown.delete="deleteTextOrItem"
    />
    <div
      class="text"
      :class="[textClass, classModelSelectText]"
      :data-vss-custom-attr="searchTextCustomAttr"
    >{{inputText}}
    </div>
    <div
      class="menu"
      ref="menu"
      @mousedown.prevent
      :class="menuClasses"
      :style="menuStyle"
      tabindex="-1"
    >
      <div
        v-for="(option, idx) in filteredOptions"
        :disabled="canSelect(option)"
        :key="idx"
        class="item"
        :class="[{ 'selected': option.selected || pointer === idx || canSelect(option) }, classModelSelectText]"
        :data-vss-custom-attr="customAttrs[idx] ? customAttrs[idx] : ''"
        @click.stop="handleSelectItem(option)"
        @mousedown="mousedownItem"
        @mouseenter="pointerSet(idx)"
      >
        {{option.text}}
      </div>
    </div>
  </div>
</template>
<script>
import { ModelSelect } from 'vue-search-select';
import fullwidth from 'fullwidth';
export default {
  mixins: [ ModelSelect ],
  props: {
    min: {
      required: false,
    },
    max: {
      required: false,
    },
    /**
     * Help component indentify type of data min, max
     */
    type: {
      type: String,
      required: false,
    },
    fixedWidth: {
      type: [Boolean, Number],
      default: () => true
    },
    isConvert2FullWidth: {
      type: Boolean,
      default () {
        return false
      }
    }
  },
  data () {
    return {
      menuTop: false,
      styleSearch: null
    }
  },
  computed: {
    classModelSelectText(){
      return this.filteredOptions[1]?.class
    },
    menuClasses () {
      return {...this.menuClass, "menu-active-top": true}
    }
  },
  methods: {
    handleOpenOptions () {
      this.$emit('shown', true)
      this.openOptions()
      this.$nextTick(() => {
        const reactBound = this.$refs.menu.getBoundingClientRect()
        // const MAX_HEIGHT = 550
        const MIN_HEIGHT = 300
        const parent = window
        const menu = this.$refs.menu
        if(parent.innerHeight < reactBound.height + reactBound.y) {
          let maxHeight = parent.innerHeight - reactBound.y - 30
          if(maxHeight < MIN_HEIGHT) {
            this.menuTop = true
            /**
             * //TODO Set to top if height of list selections < perfect height (MIN_HEIGHT)
             */
            menu.style.top = "unset";
            menu.style.bottom = "100%"
            maxHeight = reactBound.y - 130
            // return 
          }
          menu.style.maxHeight = `${maxHeight}px`
        }
      })
    },
    handleCloseOptions () {
      this.$emit('hidden', true)
      this.closeOptions()
    },
    handleBlurInput () {
      this.$emit('hidden', true)
      this.blurInput()
    },
    handleSelectItem (option) {
      if(option.disabled || this.canSelect(option)){
        return
      }
      this.$emit('hidden', true)
      this.selectItem(option)
    },
    canSelect (option) {
      return option.disabled ||
      (this.min !== null && option.value < this.min && option.value !== null) ||
      (this.max !== null && option.value > this.max && option.value !== null)
    },
    convertToFullWidth(str) {
      if(this.isConvert2FullWidth) return fullwidth(str)
      return str
    }
  },
  mounted () {
    if(this.fixedWidth) {
      /**
       * //TODO Waiting for parent node was mounted
       */
      setTimeout(() => {
        this.styleSearch = `max-width: ${this.$refs.dropdown?.offsetWidth}px`
      }, 500)
    }
  }
}
</script>
<style scoped>
.search.selection.dropdown.active-top {
  border-top-left-radius: 0px!important;
  border-top-right-radius: 0px!important;
}
.menu-active-top {
  border-radius: 0.28571429rem 0.28571429rem 0 0!important;
  box-shadow: 2px -2px 2px 0 rgb(34 36 38 / 15%)!important;
}
</style>