
export const listPlan = [
  {
    planId: 1,
    planName: "フリー",
    status: "お試し",
    isPopular: false,
    price: ["無料プラン"],
    priceInfo: ["無料プラン"],
    statement: [
      ["お得車両AIサーチ：{{ADV_VEHICLE_NUM}}台/日","（夕方配信）"],
      ["流れ車両AIアラート：前日の", "アラート実績のサンプル{{FLOW_ALERT_NUM}}台"],
      ["共有在庫AIアラート：先週の", "アラート実績のサンプル{{SHARED_INVENTORY_NUM}}台"],
    ],
    info: [[],[],[]],
    fallbacks: {
      ADV_VEHICLE_NUM: 30,
      FLOW_ALERT_NUM: 10,
      SHARED_INVENTORY_NUM: 10,
    },
  },
  {
    planId: 2,
    planName: "スモール",
    status: "人気",
    isPopular: true,
    price: ["毎月10,000円 (税抜)"],
    priceInfo: ["毎月10,000円 (税抜)", "初期登録手数料30,000円 (税抜)"],
    fisrtFee: 30000,
    changeFee: 10000,
    bankingFee: 200,
    statement: [
      ["お得車両AIサーチ：{{ADV_VEHICLE_NUM}}台/日","（早朝配信）"],
      ["流れ車両AIアラート：{{FLOW_ALERT_NUM}}台/日"],
      ["共有在庫AIアラート：{{SHARED_INVENTORY_NUM}}台/週"],
    ],
    info: [[],[],[]],
    fallbacks: {
      ADV_VEHICLE_NUM: 10,
      FLOW_ALERT_NUM: 3,
      SHARED_INVENTORY_NUM: 10,
    },
  },
  {
    planId: 3,
    planName: "プレミアム",
    status: "企業用",
    isPopular: false,
    price: ["毎月30,000円 (税抜)"],
    priceInfo: ["毎月30,000円 (税抜)", "初期登録手数料30,000円 (税抜)"],
    fisrtFee: 30000,
    changeFee: 30000,
    bankingFee: 200,
    statement: [
      ["お得車両AIサーチ：{{ADV_VEHICLE_NUM}}台/日", "（早朝配信）優先的にお得な情報が届きます"],
      ["流れ車両AIアラート：{{FLOW_ALERT_NUM}}台/日"],
      ["共有在庫AIアラート：{{SHARED_INVENTORY_NUM}}台/週"],
    ],
    info: [[],[],[]],
    fallbacks: {
      ADV_VEHICLE_NUM: 50,
      FLOW_ALERT_NUM: 3,
      SHARED_INVENTORY_NUM: 10,
    },
  },
  {
    planId: 4,
    planName: "カスタム",
    status: "企業用",
    isPopular: false,
    price: ["要お見積り"],
    priceInfo: ["要お見積り"],
    statement: [
      ["ご希望に合わせて柔軟にプランを作成いたします。"],
      ["詳細についてはお問い合わせください"],
    ],
    info: [[],[]],
  },
]

const loadListPlan = (options) => {
  /**
   * options: {
   *  1: {
   *  ...fallbacks   
   * }
   * }
   */
  const infoFactory = (plan) => {
    for (const statementKey in plan.statement) {
      const statement = plan.statement[statementKey]
      for (const index in statement) {
        let obj = statement[index]
        obj = obj.replaceAll("{{ADV_VEHICLE_NUM}}", plan.fallbacks?.ADV_VEHICLE_NUM)
        obj = obj.replaceAll("{{FLOW_ALERT_NUM}}", plan.fallbacks?.FLOW_ALERT_NUM)
        obj = obj.replaceAll("{{SHARED_INVENTORY_NUM}}", plan.fallbacks?.SHARED_INVENTORY_NUM)
        plan.info[statementKey][index] = obj
      }
    }
    // plan.info = structuredClone(plan.statement)
  }
  const plans = structuredClone(listPlan)
  for (const plan of plans) {
    plan.fallbacks = {
      ...plan.fallbacks,
      ...(options?.[plan.planId] ?? {})
    }
    infoFactory(plan)
  }
  return plans
}

export {
  loadListPlan
}