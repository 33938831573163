<template>
  <b-modal
    id="modalRecommendResult"
    hide-backdrop
    dialog-class="modal-recommend"
    no-fade
    hide-footer
    hide-header
    @hidden="hiddenModal"
    size="lg"
  >
    <b-card bg-variant="sencondary">
      <template #header>
        <div class="row justify-content-center">
          <div class="col-auto">
            <button
              class="w-100 btn btn-warning d-flex align-items-center mt-2"
              id="btn_modal_recommend_result_advantageous"
              @click="setCommendType(1)"
            >
              <span class="number-notify" v-if="recommendCount && recommendCount.advantageous">{{recommendCount.advantageous}}</span>
              <span> お得車両AIサーチ </span>
            </button>
          </div>
          <div class="col-auto">
            <button
              class="w-100 btn btn-success d-flex align-items-center mt-2"
              @click="setCommendType(2)"
              id="btn_modal_recommend_result_flovehiclew"
            >
              <span class="number-notify" v-if="recommendCount && recommendCount.flovehiclew">{{recommendCount.flovehiclew}}</span>
              <span> 流れ車両AIアラート </span>
            </button>
          </div>
          <div class="col-auto">
            <button
              class="w-100 btn btn-secondary d-flex align-items-center mt-2"
              @click="setCommendType(3)"
              id="btn_modal_recommend_result_shared_inventory"
            >
              <span class="number-notify" v-if="recommendCount && recommendCount.shared_inventory">{{recommendCount.shared_inventory}}</span>
              <span> 共有在庫AIアラート </span>
            </button>
          </div>
        </div>
        <div class="text-lg-right mt-4 text-center">
          <router-link
            to="/recommend-result/all"
            id="link_to_recommend-result-all_header"
            custom
            v-slot="{ navigate, href }"
          >
            <a :href="href" @click="handleToRecommendPage($event, navigate)"
              >すべて見る</a
            >
          </router-link>
        </div>
      </template>
      <template v-if="isLoading">
          <div class="d-flex justify-content-center mb-3">
            <b-spinner variant="primary"></b-spinner>
          </div>
      </template>
      <template v-else>
        <div class="row">
          <div
            v-for="(item, index) in listResult"
            :key="index"
            class="col-12 col-md-6 mt-3"
          >
            <itemRecommend
              :itemData="item"
              :noButton="true"
              :referer="'popup'"
            >
              <template #body="{ handlePreview, isFinished }">
                <div class="text-center">
                  <p v-if="isFinished">
                    ※結果は当日しかみれません
                  </p>
                  <p @click="handlePreview" v-else class="cursor-pointer" target="blank" :class="`text-${type(item.rec_type)}`"
                    >詳細情報を見る</p
                  >
                </div>
              </template>
            </itemRecommend>
          </div>
        </div>
      </template>
    </b-card>
  </b-modal>
</template>
<script>
import itemRecommend from "./all/item.vue";
export default {
  components: {
    itemRecommend,
  },
  data() {
    return {
      listResult: [],
      dataSearch: {
        recommend_type: 1,
      },
      isLoading: true
    };
  },
  computed: {
    recommendCount () {
      return this.$store.getters.recommendCount
    }
  },
  emits: ["closeModal"],
  methods: {
    type(index) {
      const mapColor = {
        "1": "warning",
        "2": "success",
        "3": "secondary" 
      }
      return mapColor[index]
    },
    hiddenModal() {
      this.$emit("resetModal");
    },
    handleToRecommendPage(e, callback) {
      this.$emit("closeModal");
      callback(e);
    },
    async fetchSearch() {
      if(!this.isLoading) this.isLoading = true
      const { data } = await this.$store.dispatch("getRecommendsNew", {
        params: this.dataSearch,
      });
      this.isLoading = false
      this.listResult = data.recommends.filter(item => item.is_readed === false);
    },
    fetchStock() {
      this.coreCars = [];
      this.coreGrades = [];
      const dataMaster = this.$store.getters.carMap
      return dataMaster.loader
    },
    setCommendType(state){
      this.dataSearch.recommend_type = state
    }
  },
  watch: {
    'dataSearch.recommend_type' () {
      this.fetchSearch()
    }
  },
  created() {
    const handle = [this.fetchStock()];
    this.isLoading = true;
    Promise.all(handle).finally(() => {
      this.isLoading = false;
    });
  },
  mounted () {
    this.$root.$on('bv::modal::show', (bvEvent, modalId) => {
      if(modalId === 'modalRecommendResult') {
       this.fetchSearch()
      }
    })
  }
};
</script>
<style lang="scss">
.modal-recommend {
  position: absolute;
  top: 55px;
  right: 0px;
  background-color: #f7f7f7;
  .card-header {
    border: 0px;
    .number-notify {
      width: 25px;
      height: 25px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      color: $white;
      margin-right: 0.7rem;
      background-color: var(--danger);

      &.not-set {
        background-color: unset;
      }
    }
  }
  .modal-body {
    padding: 0.0rem;
  }
}
@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 800px;
  }
}
</style>