<script>
import CQOptionsRadio from "@/components/CQOptionsRadio.vue"
export default {
  extends: CQOptionsRadio,
  data () {
    return {
      classList: ["cq-option-group-small"]
    }
  }
}
</script>
<style lang="scss" >
.cq-option-group-small {
  .cq-option-group-label {
    &::before {
      width: 20px!important;
      height: 20px!important;
    }
  }
}
</style>