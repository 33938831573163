const INTERIOR_SCORE = [
  { text: "---", value: null },
  { text: "A", value: 5 },
  { text: "B", value: 4 },
  { text: "C", value: 3 },
  { text: "D", value: 2 },
  { text: "E", value: 1 },
]

const SCORE = [
  { text: "---", value: null },
  { text: "S", value: 7 },
  { text: "6.5", value: 6.5 },
  { text: "6", value: 6 },
  { text: "5.5", value: 5.5 },
  { text: "5", value: 5 },
  { text: "4.5", value: 4.5 },
  { text: "4", value: 4 },
  { text: "3.5", value: 3.5 },
  { text: "3", value: 3 },
  { text: "2.5", value: 2.5 },
  { text: "2", value: 2 },
  { text: "1", value: 1 },
  { text: "R", value: 0.5 },
]

const EXTERIOR_SCORE = [
  { text: "---", value: null },
  { text: 5, value: "A" },
  { text: 4, value: "B" },
  { text: 3, value: "C" },
  { text: 2, value: "D" },
  { text: 1, value: "E" },
]

const SCORE_OBJ = {
  7: "S",
  6.5: "6.5",
  6: "6",
  5.5: "5.5",
  5: "5",
  4.5: "4.5",
  4: "4",
  3.5: "3.5",
  3: "3",
  2.5: "2.5",
  2: "2",
  1: "1",
  0.5: "R",
}

const EXTERIOR_SCORE_OBJ = {
  5: "A",
  4: "B",
  3: "C",
  2: "D",
  1: "E",
}

export {
  INTERIOR_SCORE,
  EXTERIOR_SCORE,
  EXTERIOR_SCORE_OBJ,
  SCORE,
  SCORE_OBJ,
}