<template>
  <div>
    <div class="cq-account-locked p-4">
      <div class="cq-account-content" v-html="html">
      </div>
    </div>
  </div>
</template>
<script>
import '@/assets/scss/lock/index.scss'
export default {
  layout: "main",
  auth: "user",
  head() {
    return {
      title: "ロック画面",
      meta: [
        { charset: 'utf-8' },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0'
        },
      ],
    };
  },
  data () {
    return {
      html: "",
      userId: localStorage.getItem('userId'), 
    }
  },
  async created (){
    const { data } = await this.$store.dispatch('getProfileUser', this.userId)
    if(data.user.system_locked_at){
      this.html = data.user.system_locked_message
      return
    }
    this.$router.push("/")
  }
}
</script>
