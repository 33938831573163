<template>
  <div class="stockList">
    <CQPreLoad :preload="preLoading" />
    <div class="stockList-header d-flex" v-if="!preLoading">
      <div class="stockList-header-title col-auto cursor-pointer">削除済みの車両 
        <font-awesome-icon v-b-tooltip.bottomleft.hover 
        title="削除した在庫情報がゴミ物になってこの画面に表示する。
    ユーザーが「復旧」ボタンで在庫情報を復旧可能です。"  
        icon="fa-solid fa-circle-info" class="stockList-tooltip position-absolute" />
      </div>
      <div class="col-auto"> 
        <b-button class="pl-5 pr-5" @click="$router.push('/stock/list')" id="btn_stock_trash_return"><font-awesome-icon icon="fa-solid fa-circle-arrow-left" /> 在庫情報</b-button>
      </div>
    </div>
    <div class="container-custom mt-3 mt-lg-5">
      <div class="stockList-search" >
        <b-button v-on:click="isHiddenSearchNav = !isHiddenSearchNav" class="stockList-search-btnToggle" id="btn_stock_trash_filter_mobile" ><font-awesome-icon icon="fa-solid fa-filter" /></b-button>
        <div class="d-none d-md-block">
          <CQFormGroupSelectCar v-model="listSearchStock" />
          <div class="row">
            <div class="col-lg-6 col-md-12 mt-2">
              <div class="row align-items-center" @click="setOptionTop($event)">
                <div class="col-lg col-6">
                  <model-select
                    v-model="listSearchStock.color"
                    :options="optionsColor"
                    :is-convert2-full-width="true"
                    size="sm"
                    id="stockList-search-color"
                    class="mt-3"
                  ></model-select>
                </div>
                <div class="col-auto col-span d-none d-lg-block"></div>
                <div class="col-lg col-6">
                  <model-select 
                    id="produced_on"
                    v-model="listSearchStock.produced_on"
                    :options="listYear"
                    class="mt-3"
                  />
                </div>
              </div>
            </div>
            <div class="col-lg-6 col-md-12 mt-2">
              <div class="row align-items-center" @click="setOptionTop($event)">
                <div class="col-lg col-6">
                  <model-select
                    v-model="listSearchStock.min_amount"
                    :options="listPriceFrom"
                    size="sm"
                    id="stockList-search-min-price"
                    class="mt-3"
                    :max="listSearchStock.max_amount"
                  />
                </div>
                <div class="col-auto col-span d-none d-lg-block">~</div>
                <div class="col-lg col-6">
                  <model-select
                    v-model="listSearchStock.max_amount"
                    :options="listPriceTo"
                    size="sm"
                    id="stockList-search-max-price"
                    class="mt-3"
                    :min="listSearchStock.min_amount"
                  />
                </div>
              </div>
            </div>
          </div>
          <div class="w-100">
            <b-button id="stockList-search-form-btn-submit" 
              type="submit" 
              class="stockList-search-form-btn cq-btn-bg-green ml-2 mt-2"
              @click="searchListStock"
            >
              <font-awesome-icon icon="fa-solid fa-magnifying-glass" />
            </b-button>
            <b-button id="stockList-search-form-btn-reset" 
              class="stockList-search-form-btn cq-btn-gray ml-2 mt-2"
              v-on:click="reloadDataSearch"
            >
              <font-awesome-icon icon="fa-solid fa-arrows-rotate" />
            </b-button>
          </div>
        </div>
          <!-- form mobile -->
        <div v-if="!isHiddenSearchNav" class="stockList-search-formNav">
          <span v-on:click="isHiddenSearchNav = !isHiddenSearchNav" class="stockList-search-formNav-btncClose mb-3" ><font-awesome-icon icon="fa-solid fa-x" /></span>
          <CQFormGroupSelectCarMobile v-model="listSearchStock" />  
          <model-select
            v-model="listSearchStock.color"
            :options="optionsColor"
            :is-convert2-full-width="true"
            size="sm"
            id="stockList-search-color"
            class="mt-3"
          ></model-select>
          <model-select 
            id="produced_on"
            v-model="listSearchStock.produced_on"
            :options="listYear"
            class="mt-3 pt-1 pl-2"
          />
          <div class="row">
            <div class="col-6">
              <model-select
                v-model="listSearchStock.min_amount"
                :options="listPriceFrom"
                size="sm"
                id="stockList-search-min-price"
                class="mt-3"
                :max="listSearchStock.max_amount"
              />
            </div>
            <div class="col-6">
              <model-select
                v-model="listSearchStock.max_amount"
                :options="listPriceTo"
                size="sm"
                id="stockList-search-max-price"
                class="mt-3"
                :min="listSearchStock.min_amount"
              />
            </div>
          </div>
          <b-button id="stockList-search-formNav-btn-submit" @click="searchListStock" type="submit" class="cq-btn-bg-green w-100 mt-3"><font-awesome-icon class="mr-2" icon="fa-solid fa-magnifying-glass" />検索</b-button>
        </div>
      </div>
      <!-- list card -->
      <div class="stockList-list mt-3">
        <div class="stockList-list-header d-flex justify-content-between align-items-center">
          <p class="stockList-list-header-title">
            {{rows}}車両が見つかりました
          </p>
          <b-form-select
          v-model="perPage"
          :options="optionsPage"
          v-on:change="searchListStock"
          id="stockList-list-header-selectPage"
          class="stockList-list-header-select mb-3" />
        </div>
        <div class="stockList-list-content w-100">
          <div class="stockList-list-content-items w-100 mt-3 d-flex" v-for="(stockData, index) in listDataStock" v-bind:key="index">
            <div class="ml-2 col-auto">
              <img v-if="stockData.imgs" :src="stockData.imgs[0].url" alt="">
              <img v-else src="@/assets/img/stock-img-3.jpg" alt="">
            </div>
            <div class="ml-xl-3 stockList-list-content-car col">
              <div class="items-title d-flex flex-row align-items-center">{{stockData.core_car.name}}｜{{stockData.core_grade.name}}</div>
              <div class="items-text">
                <span v-if="stockData.core_brand">{{stockData.core_brand.name}}</span>
                <span v-if="stockData.produced_on">｜{{ renderYear(stockData.produced_on) }}</span>
                <span v-if="stockData.core_color">｜{{ stockData.core_color.name}}</span>
                <span v-if="stockData.odo">｜{{stockData.odo | setComma}}km</span>
              </div>
              <div class="items-text"><span class="pr-2" v-for="(equipmentsName, x) in stockData.equipments_name" v-bind:key="x">{{equipmentsName}}</span></div>
              <div class="items-reviews-point">
                <label v-if="stockData.interior_score">内装 <span class="mr-2"> {{ stockData.interior_score }} </span> </label>
                <label v-if="stockData.exterior_score">外装 <span class="mr-5"> {{ stockData.exterior_score }} </span> </label>
                <label v-if="stockData.estimated_price">予定価格 <span>{{ stockData.estimated_price / 10000 | setComma }}万円</span></label>
              </div>
            </div>
            <div class="stockList-list-content-items-actions col-auto">
              <b-button v-on:click="$bvModal.show('restoreStock'), idStock = stockData.id" id="btn_stock_trash_show_modal_restore" class="cq-btn-bg-orange stockList-list-content-items-actions-button mt-0 mt-xl-1"><font-awesome-icon icon="fa-solid fa-rotate-right" /></b-button>
            </div>
          </div>
        </div>
        <div v-if="!listDataStock.length" class="h4 text-secondary text-center pt-3">削除済みの車両がありません</div>
        <CQPagination
          v-if="listDataStock.length"
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          class="justify-content-center mt-3"
        />
      </div>
      <b-modal
        id="restoreStock"
        ref="restoreStock"
        modal-class="modal-confirm modal-confirm-sm"
        body-class="p-md-4 p-3" 
        hide-footer
        hide-header
        centered
        >
        <div class="d-block text-center title w-100">
          <h1 class="text-warning">
            <font-awesome-icon icon="fa-solid fa-triangle-exclamation" />
          </h1>
          <p>復元しますか？</p>
        </div>
        <div class="mt-3 mt-md-4 cq-plan_mypage-infoplan-groupBtn d-flex justify-content-between flex-wrap">
          <b-button class="btn-bot" v-on:click="$bvModal.hide('restoreStock')" id="btn_stock_trash_hide_modal_restore">いいえ</b-button>
          <b-button class="cq-btn-bg-green btn-top" v-on:click="restoreStock"  id="btn_stock_trash_confirm_modal_restore">はい</b-button >
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import CQPagination from '@/components/CQPagination.vue';
import '@/assets/scss/stock/stockList.scss';
import DatePicker from 'v-calendar/lib/components/date-picker.umd';
import { Message } from '@/utils/message';
import CQPreLoad from "@/components/CQPreLoad.vue";
import { convertNumber } from "@/utils/commonEvent";
import { listPriceFrom, listPriceTo } from "@/utils/priceList"
import listYear from "@/utils/listYear"
import ModelSelectBrands from '@/components/ModelSelectBrands.vue';
import CQFormGroupSelectCar from '@/components/CQFormGroupSelectCar.vue';
import CQFormGroupSelectCarMobile from '@/components/CQFormGroupSelectCarMobile.vue';

export default {
  name:'delete-car-list',
  layout:'user',
  auth: 'user',
  mixins: [convertNumber],
  head() {
    return {
      title: "削除済の在庫一覧",
    };
  },
  components: {
    DatePicker,
    CQPreLoad,
    CQPagination,
    ModelSelectBrands,
    CQFormGroupSelectCar,
    CQFormGroupSelectCarMobile,
  },
  data() {
    return {
      messageDate: Message.LABLE_DATE,
      rows: 20,
      preLoading: true,
      perPage: 10,
      currentPage: 1,
      idStock: 0,
      ishiddenItem_1: false,
      refInside: '4.9',
      refOutside: '4.0',
      prices: '250万円',
      ishiddenStar_1: false,
      isHiddenSearchNav: true,
      listDataStock: [],
      optionsBrands:[{
        text: "メーカー",
        value: null,
        disabled: true
      }],
      optionsCarName: [{
        text: "車名",
        value: null,
        disabled: true
      }],
      optionsGrade: [{
        text: "グレード",
        value: null,
        disabled: true
      }],
      optionsColor: [{
        text: "色",
        value: null,
        disabled: true
      }],
      optionsModel: [{
        text: "型式",
        value: null,
        disabled: true
      }],
      selectedDate: null,
      inputMinPrice: null,
      inputMaxPrice: null,
      optionsPage: [
        { value: 5, text: "5件" },
        { value: 10, text: "10件" },
        { value: 20 , text: "20件"}
      ],
      listSearchStock: {
        core_brand_id: null,
        core_car_id: null,
        model: null,
        core_model_ids: [],
        core_grade_id: null,
        core_grade_ids: [],
        color: null,
        produced_on: null,
        min_amount: null,
        max_amount: null,
        page: null,
        per_page: null,
      },
      listSearchStockBase: {},
      listPriceFrom,
      listPriceTo,
      listYear,
    }
  },
  watch: {
    currentPage() {
      this.searchListStock();
    },
  },
  created() {
    this.getListRemoveStock();
    this.fetchDataMaster()
    this.listSearchStockBase = {...this.listSearchStock}
  },
  methods: {
    async fetchDataMaster () {
      const dataMaster = this.$store.getters.carMap
      await dataMaster.loader
      const years = dataMaster.years
      this.listYear = [
        ...this.listYear,
        ...years
      ]
      dataMaster.colors.forEach(colors => {
        this.optionsColor.push({ value: colors.id, text: colors.name });
      });
    },
    async getListRemoveStock() {
      const listStock = await this.$store.dispatch('getListRemoveStock');
      if (listStock.success) {
        this.listDataStock = listStock.data.stocks;
        this.rows = listStock.data.meta.total_objects;
        this.preLoading = false;
      } else {
        if (listStock.errors.length && listStock.errors[0].message) this.$toast.error(listStock.errors[0].message);
      };
    },
    reloadDataSearch() {
      this.listSearchStock = {...this.listSearchStockBase},
      this.searchListStock();
    },
    async searchListStock() {
      this.isHiddenSearchNav = true
      let searchStock = "";
      for (const key in this.listSearchStock) {
        if (this.listSearchStock[key] !== "全て" && (this.listSearchStock[key])) {
          console.log(this.listSearchStock);
          (!searchStock) ? searchStock += key + "=" + ((key === "produced_on") ? new Date(this.listSearchStock[key]).getFullYear() :this.listSearchStock[key]) 
          : searchStock += "&" + key + "=" + ((key === "produced_on") ? new Date(this.listSearchStock[key]).getFullYear() :this.listSearchStock[key]);
        }
      }
      searchStock += (searchStock) ? `&page=${this.currentPage}` : `page=${this.currentPage}`;
      if (this.perPage !== "全て") searchStock += `&per_page=${this.perPage}`;
      const listSearch = await this.$store.dispatch('searchDeletedListStock', searchStock);
      if (listSearch.success) {
        this.listDataStock = listSearch.data.stocks;
        this.rows = listSearch.data.meta.total_objects;
      } else {
        if (listSearch.errors.length && listSearch.errors[0].message) this.$toast.error(listSearch.errors[0].message);
      };
    },
    async restoreStock() {
      this.ishiddenItem_1 = true;
      const restoreStockCar = await this.$store.dispatch('restoreStock', this.idStock);
      if (restoreStockCar.success) {
        this.$refs['restoreStock'].hide();
        this.getListRemoveStock();
      } else {
        if (restoreStockCar.errors.length && restoreStockCar.errors[0].message) this.$toast.error(restoreStockCar.errors[0].message);
      };
    },
    isNumber: function(evt) {
      if (Number(evt) && evt.indexOf('.') > -1) {
        return true;
      }
      evt = (evt) ? evt : window.event;
      var charCode = (evt.which) ? evt.which : evt.keyCode;
      if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    renderYear (produced_on) {
      const dataMaster = this.$store.getters.carMap
      let producedOn = ""
      if (!produced_on) return 
      if(produced_on) {
        for (const yearItem of dataMaster.years) {
          const year = new Date(yearItem.value)
          const yearProducedOn = new Date(produced_on)
          if(year.getFullYear() == yearProducedOn.getFullYear()) {
            producedOn = yearItem.text
            break;
          }
        }
      }
      producedOn = producedOn.replace(/^(\d{4})/,"$1年")
      return producedOn
    },
  }
}
</script>

<style scoped lang="scss">
.stockList-list {
  &-header {
    &-title {
      .stockList-search {
        .stockList-search-form {
          margin: 0rem 5rem;
        }
      }
      .stockList-list {
        padding: 0rem 7.3rem;
      }
    }
  }
  &-content {
    &-car {
      @include screen(991) {
        position: unset !important;
      }
    }
    &-items {
      border: 1px solid $brown;
      padding: 1.3rem 1.7rem;
      background-color: $white;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include screen(1199) {
        flex-direction: column;
        align-items: flex-start;
        padding: 1.3rem;
      }
      @include screen(991) {
        padding: 0.8rem;
      }
      @include screen(576) {
        padding: 0.3rem;
      }
      img {
        width: 100px;
        height: 100px;
        object-fit: cover;
        border-radius: 8px;
        @include screen(1199) {
          width: 100%;
          height: auto;
        }
      }
      &-star {
        left: 10px;
        top: 50%;
        transform: translateY(-50%);
        @include screen(991) {
          transform: translateY(0);
        }
        &-img {
          width: 20px !important;
          height: 20px !important;
          @include screen(576) {
            width: 12px !important;
            height: 12px !important;
          }
        }
      }
      .stockList-lg-none {
        @include screen(1199) {
          display: none;
        }
      }
      .stockList-lg-flex {
        display: none;
        @include screen(1199) {
          display: flex;
        }
      }
      .items-reviews-point {
        color: $cq-boldgray;
        font-weight: 400;
        font-size: 1rem;
        @include screen(991) {
          font-size: 0.675rem;
        }
        span {
          font-weight: 500;
          font-size: 1.875rem;
          color: $cq-red;
          @include screen(1023) {
            font-size: 1.3rem;
          }
          @include screen(991) {
            font-size: 1.2rem;
          }
          @include screen(576) {
            font-size: 0.875rem;
          }
        }
      }
      .items-title {
        font-weight: 400;
        font-size: 1.5rem;
        color: $black;
        @include screen(576) {
          font-size: 0.875rem;
        }
      }
      .items-text {
        font-weight: 400;
        font-size: 0.875rem;
        color: $cq-darkgray;
        @include screen(576) {
          font-size: 0.5rem;
        }
      }
      &-actions {
        display: flex;
        flex-direction: column;
        @include screen(1199) {
          flex-direction: row;
          padding-left: 1rem;
          margin-top: 0.7rem;
        }
        .cq-btn-bg-red {
          background: $cq-red !important;
          background-color: $cq-red !important;
          &:hover,
          &:active,
          &:focus {
            background: $red !important;
            background-color: $red !important;
          }
        }
        &-button {
          width: 30px;
          height: 30px !important;
          display: flex;
          justify-content: center;
          align-items: center;
          @include screen(1199) {
            margin-right: 0.7rem;
          }
          @include screen(576) {
            width: 20px;
            height: 20px !important;
            font-size: 0.675rem;
          }
        }
      }
    }
  }
}
</style>